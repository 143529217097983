import React, { useEffect, useState } from "react";
import { Alert, Badge, Modal } from "antd";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMutation } from "@apollo/client";
import { newTrainedEmployeeMutation } from "@src/util/mutation/trainedEmployee.mutation";
import { useDispatch } from "react-redux";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import Spinner from "../../components/spinner";



export const CheckFailureModal = ({visible, close, failureChecks, save, closeForSave=false })=>{
    const [checks, setChecks] = useState({});
    useEffect(()=>{
        setChecks({...failureChecks});
    }, [failureChecks]);

    const [confirmationModal, setConfirmationModal] = useState(false);
    const [newTrainedEmployee, {loading: newTrainedEmployeeLoading}] = useMutation(newTrainedEmployeeMutation);

    const dispatch = useDispatch();
    const handelSubmit = async (employeeId, siteId)=>{
        try{
            setConfirmationModal(false);
            await newTrainedEmployee({
                variables:{
                    input: [{employeeId, siteId}]
                }
            });
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Employee marked as trained"
            }));
            
            const {trainingCheck, ...rest} = failureChecks;
            setChecks({...rest});
            // close();

        }catch(err){
            setConfirmationModal(false);

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: err?.message
            }));
        }
    }

    return(
        <Modal
            open={visible}
            width={550}
            footer={
                <div style={{padding:'16px 40px', textAlign:'right', display:'flex', columnGap:'16px', marginTop:'-25px',justifyContent:'right',
                    borderTop: '2px solid rgb(245, 248, 250)' }}>
                    <button className="drawer-outlined-btn" onClick={close}>Cancel</button> 
                    {checks?.empHolidayCheck || checks?.skillCheck || checks?.blockedSite || checks?.restPeriodCheck || checks?.skillExpiryCheck || checks?.visaExpiryCheck ? null
                        :
                        <button className="drawer-filled-btn" onClick={()=>{save({ _id: checks?.employeeData?._id, isSuitable: 0, isFromSearch: checks?.otherEmployee? 0 : 1, emp:checks?.employeeData }); closeForSave? closeForSave() : close();}}>Save & Schedule</button>  

                    } 
                </div>
            }
            closable={false}
        
        >
            
                
            <React.Fragment>
                {/* {contextHolder} */}
                <div className='modal-header-title'>
                    <span>Checks failure for this duty</span>
                    <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                </div>
                {

                    newTrainedEmployeeLoading?
                        <div className="modal-body" style={{display:'flex', justifyContent:'center', alignItems:'center', padding:'50px'}}>
                            <Spinner/>
                        </div>
                    :
                        <div className='modal-body failureCheckModal'>
                            {
                                checks?.restPeriodCheck?
                                <Alert type="error" message={"Employee's rest period of 8h is not completed yet"} showIcon/>
                                : null
                            } 

                            {
                                checks?.empHolidayCheck?
                                <Alert type="error" message={"Employee is on leave"} showIcon/>
                                :
                                null
                            }

                            {
                                checks?.blockedSite?
                                <Alert type="error" message={"Employee is banned on this site"} showIcon/>
                                : null
                            }

                            {
                                checks?.skillCheck?
                                <Alert type="error" message={"Employee's skill does not match with this area requirement"} showIcon/>
                                :null
                            }

                            {
                                checks?.skillExpiryCheck?
                                <Alert type="error" message={"Employee's skill has been expired"} showIcon/>
                                :null
                            }

                            {
                                checks?.visaExpiryCheck?
                                <Alert type="error" message={"Employee's visa has been expired"} showIcon/>
                                :null
                            }

                            {
                                checks?.branchCheck?
                                <Alert type="error" message={"Employee is not working for this branch"} showIcon/>
                                :null
                            }

                            {
                                checks?.trainingCheck?
                                <Badge.Ribbon
                                    className="markTrainWrapper"
                                    color="#00bda5"
                                    text={<div className="markTrain" onClick={()=>setConfirmationModal(!confirmationModal)}>
                                        <div style={{width:'18px', height:'20px'}}>
                                            <FontAwesomeIcon className="trainIcoCheck" icon={faCheck}/> 
                                        </div>
                                        <div>Mark as Trained</div> 
                                    </div>}
                                >
                                    <Alert type="error" message={"Employee is not trained on this site"} showIcon/>
                                </Badge.Ribbon>

                                :null
                            }

                        </div>

                }
            </React.Fragment>

            
            {
                confirmationModal &&
                <ConfirmationModal
                    visible={confirmationModal}
                    close={()=>setConfirmationModal(!confirmationModal)}
                    handelSubmit={handelSubmit}
                    employeeId={checks?.employeeData?._id}
                    siteId={checks?.employeeData?.site}
                    newTrainedEmployeeLoading={newTrainedEmployeeLoading}
                />
            }
        </Modal>
    )
    
}


const ConfirmationModal = ({visible, close, handelSubmit, employeeId, siteId,})=>{
    return(
        <Modal    
            visible={visible}
            width={600}
            footer={
                <div 
                    className="drawer-footer"
                >

                    <button className={'drawer-filled-btn'} onClick={()=>{handelSubmit(employeeId, siteId)}}>
                        Confirm
                    </button>

                    <button disabled={false}  className={'drawer-outlined-btn'} onClick={close}>
                        Cancel
                    </button>

                </div>
            }
            closable={false}
        >
            <div className='modal-header-title archive-modal-header'>
                <span style={{letterSpacing:'0.2px'}}> Confirmation </span>
                <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
            </div>

            <div className='modal-body modal-body-padding'>
                <div className="text" style={{textAlign:'center', margin:'0'}}>Are you sure to perform this action?</div>
            </div>

        </Modal>
    )
}