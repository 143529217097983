import { gql } from "@apollo/client";

export const getRotaLatestBySiteId = gql `
query($siteId: String!){
  getRotaLatestBySiteId(siteId: $siteId) {
    message
    response
  }
}
`;


export const getRotaDutiesCountBySiteId = gql`
query($siteId: String!){
  dutiesSiteRota(siteId: $siteId) {
    message
    response
  }
}
`;

export const getLoadedRotaBySiteId = gql`
query($siteId: String!){
  getLoadedRota(siteId: $siteId) {
    message
    response
  }
}
`;