import { Checkbox, Collapse, Input } from "antd";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client"
import { GROUPLIST } from "@src/util/query/group.query"
import { objectType } from "@src/util/types/object.types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { newSiteDetailViewMutation } from "@src/util/mutation/siteDetailView.mutation";
import { deleteSiteDetailViewMutation } from "@src/util/mutation/siteDetailView.mutation";
import { getSiteDetailViewQuery } from "@src/util/query/siteDetailView.query";
import { useSelector } from "react-redux";

export const EditLeftSideView = ()=>{

    const {data:siteGrpFields, loading, refetch } = useQuery(GROUPLIST,{
        variables: {
            objectType: objectType?.Site
        }
    });

    const [items, setItems] = useState([]);

    const userDetail = useSelector(state=>state?.userAuthReducer?.authenticatedUserDetail);

    const [newSiteDetailView, {loading: newSiteDetailViewLoading}] = useMutation(newSiteDetailViewMutation);
    const [deleteSiteDetailView, {loading: deleteSiteDetailViewLoading}] = useMutation(deleteSiteDetailViewMutation);
    const {data:getSiteDetailViewData, loading: getSiteDetailViewDataLoading, refetch: newSiteDetailViewRefetch} = useQuery(getSiteDetailViewQuery,{
        variables:{
            userId: userDetail?._id
        },
        fetchPolicy:'network-only'
    });

    
    useEffect(()=>{
        if(siteGrpFields?.groupList?.length>0){
            setItems(siteGrpFields?.groupList?.map((list)=>{
                return(
                    {
                        key: list?.key,
                        label: <span>
                                {list?.name} 
                            </span>,
                        children: list?.propertyList?.map((property)=>(
                            <div className='field-presentation'>
                                
                            <Checkbox 
                                defaultChecked={getSiteDetailViewData?.getSiteDetailViewField?.response?.find((siteDetail)=>siteDetail?.propertyId==property?._id)}
                                onChange={async (e)=>{
                                    if(e.target.checked){
                                        await newSiteDetailView({
                                            variables:{
                                                input:{
                                                    propertyId: property?._id,
                                                }
                                            }
                                        }); newSiteDetailViewRefetch();
                                        
                                    }else{
                                        await deleteSiteDetailView({
                                            variables:{
                                                userId: userDetail?._id,
                                                propertyId: property?._id,
                                                
                                            }
                                        }); await newSiteDetailViewRefetch();
                                    }
                                }}
                            />
                                 
                                <div style={{width: '100%'}}>
                                    
                                    <div className='allpropList-propHead'>
                                        {property?.label} 
                                    </div>
                                    <div className='field-prop-value'>
                                        <span>
                                            {"--"}
                                        </span>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        ))

                    }
                );
            }))
        }
    },[siteGrpFields, getSiteDetailViewData?.getSiteDetailViewField?.response]);

    const handelSearch = (keyword)=>{
        const resultedFields = siteGrpFields?.groupList?.map((props)=>{
            const filteredProperties = props?.propertyList.filter((prop)=> prop.label.toLowerCase().includes(keyword.toLowerCase()) && prop?.isArchive!=true );
            return {
                ...props,
                propertyList: filteredProperties
            }
        });
        setItems(resultedFields?.map((list)=>{
            return(
                {
                    key: list?.key,
                    label: <span>
                            {list?.name} 
                        </span>,
                    children: list?.propertyList?.map((property)=>(
                        <div className='field-presentation'>
                            
                            <Checkbox 
                                checked={getSiteDetailViewData?.getSiteDetailViewField?.response?.find((siteDetail)=>siteDetail?.propertyId==property?._id)}
                                onChange={async (e)=>{
                                    if(e.target.checked){
                                        await deleteSiteDetailView({
                                            variables:{
                                                userId: userDetail?._id,
                                                propertyId: property?._id,
                                                
                                            }
                                        }); await newSiteDetailViewRefetch();
                                    }else{
                                        await newSiteDetailView({
                                            variables:{
                                                input:{
                                                    propertyId: property?._id,
                                                }
                                            }
                                        }); newSiteDetailViewRefetch();
                                    }
                                }}
                            />
                            {/* <FontAwesomeIcon style={getSiteDetailViewData?.getSiteDetailViewField?.response?.find((siteDetail)=>siteDetail?.propertyId==property?._id)? {visibility:'visible'} : {visibility:'hidden'}} icon={faCheck}/> */}
                            
                            <div style={{width: '100%'}}>
                                
                                <div className='allpropList-propHead'>
                                    {property?.label} 
                                </div>
                                <div className='field-prop-value'>
                                    <span>
                                        {"--"}
                                    </span>
                                    <span className='field-prop-btn-grp'>
                                        {getSiteDetailViewData?.getSiteDetailViewField?.response?.find((siteDetail)=>siteDetail?.propertyId==property?._id)?
                                            <button className='grid-sm-btn'                                                
                                            style={{ padding: "4px 10px" }} 
                                            onClick={async()=>{ await deleteSiteDetailView({
                                                variables:{
                                                    userId: userDetail?._id,
                                                    propertyId: property?._id,
                                                    
                                                }
                                            }); await newSiteDetailViewRefetch();}}
                                            >Remove from your view</button>
                                            :
                                            <button className='grid-sm-btn'                                                
                                            style={{ padding: "4px 10px" }} 
                                            onClick={async()=>{ await newSiteDetailView({
                                                variables:{
                                                    input:{
                                                        propertyId: property?._id,
                                                    }
                                                }
                                            }); newSiteDetailViewRefetch();}}
                                            >Add to your view</button>
                                        
                                        }
                                    </span>
                                </div>
                                
                            </div>
                        </div>
                    ))

                }
            );
        }))

    }

    return(
        <div className="hr-section" style={{maxHeight:'83vh', overflow:'auto'}}>
            <div className="hr-info">
                <div className="hr-info-title">
                    Customise your view
                </div>
                <div className="text">Manage your view by select the data fields below</div>
            </div>
            <Input type="search" 
                style={{width:'100%'}} 
                className='generic-input-control' 
                placeholder="Search Fields"
                onChange={(e)=>handelSearch(e.target.value)}
                autoComplete="off"
                suffix={
                <FontAwesomeIcon style={{color:'#0091ae'}} icon={faSearch}/> }
            />
            <Collapse defaultActiveKey={siteGrpFields?.groupList[0]?.key} items={items} />
        </div>
    )

}