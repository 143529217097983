import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { getPayandBillColumnQuery } from '@src/util/query/payandbillColumn.query';
import { useMutation, useQuery } from '@apollo/client';
import { getPayLevelQuery } from '@src/util/query/paylevel.query';
import { getBranchPayTableQuery } from '@src/util/query/branchPaytable.query';
import { upsertBranchPayTableMutation } from '@src/util/mutation/branchPayTable.mutation';
import { PayRateModal } from '@src/components/payrateModal/payrate.modal.component';
import { useDispatch } from 'react-redux';
import { findPayDate } from '@src/util/findPayDate/findPayDate';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';




export const BranchPayTable = ({id})=>{


    const [remoteColumns, setRemoteColumns] = useState([]);

    const {data, loading:getPayandBillColumnLoading, refetch} = useQuery(getPayandBillColumnQuery);
   
    useEffect(()=>{
      if(data?.getPayandBillColumn?.response){
        setRemoteColumns([
          {
            title: '',
            dataIndex: 'payLevel',
            width: '20%',
          },
          ...data?.getPayandBillColumn?.response?.map((col)=>(
            {
              title: col?.columnName,
              dataIndex: col?._id,
              editable: true,
              // width:'20%'
            }
          ))
        ]);
      }
    },[data?.getPayandBillColumn?.response]);

    
    const {data: payLevel, loading: payLevelLoading} = useQuery(getPayLevelQuery,{
      fetchPolicy: 'network-only',
    });

    const [dataSource, setDataSource] = useState([]);

    const {data: branchPayTableData, loading: branchPayTableLoading, refetch: branchPayTableRefetch} = useQuery(getBranchPayTableQuery,{
      variables:{
        branchId: id
      },
      fetchPolicy:'network-only'
    });


    const [columnToBeEdit, setColumnToBeEdit] = useState(null);
    const [payrateModal, setPayrateModal] = useState(false);

    const handelEditPayRate = (pay, payLevelColData, column, pl)=>{
      setColumnToBeEdit({
        value: pay,
        column,
        metadata: payLevelColData,
        payLevel: pl
      });
      setPayrateModal(true);
    }

    const [upsertBranchPayTable, {loading: upsertSitePayTableLoading}] = useMutation(upsertBranchPayTableMutation);


    useEffect(()=>{
      setDataSource(payLevel?.getPayLevel?.response?.map((pl)=>{
        
        const columns = data?.getPayandBillColumn?.response?.map((col)=>(col?._id));
        const resultObject = {};
        
        const payLevelData = branchPayTableData?.getBranchPayTable?.response;

        for (let i = 0; i < columns?.length; i ++) {
          const payLevelColData = payLevelData?.find((pld)=>pld?.payLevelId===pl?._id);
          

          resultObject[columns[i]] =  <span className="link" 
          onClick={()=>handelEditPayRate(payLevelColData?.payTableMeta[columns[i]], payLevelColData, columns[i], pl)}
          >{payLevelColData?.payTableMeta?.hasOwnProperty([columns[i]])? findPayDate(payLevelColData?.payTableMeta[columns[i]]) : 0}</span>
          
          if(columnToBeEdit && Object.keys(columnToBeEdit)?.length>0 && columnToBeEdit?.column==columns[i] && 
              columnToBeEdit?.payLevel?._id == pl?._id)
          {
            setColumnToBeEdit({
              ...columnToBeEdit,
              value: payLevelColData?.payTableMeta[columns[i]]
            });
          }
        
        }

        return({
          key:pl?._id,
          payLevel: pl?.name,
          ...resultObject,
          payLevelId: pl?._id
        })

      }))
    },[upsertSitePayTableLoading, payLevelLoading, branchPayTableLoading, getPayandBillColumnLoading ,payLevel, branchPayTableData?.getBranchPayTable?.response]);


    const handleSave = async(input) => {

      try{
        await upsertBranchPayTable({
          variables:{
            input
          }
        });

        await refetch();
        await branchPayTableRefetch();
        
      }catch(err){

        dispatch(setNotification({
          error: true,
          notificationState: true,
          message: "An error has been occured while adding rates on this branch",
        }));

      }

    };



    return(
        <div className="hr-section">
          <div className="hr-info">
            <div className="hr-info-title">
              Pay Table
            </div>

            <Table
                className='payTable curvedTable'
                rowClassName={() => 'editable-row'}
                dataSource={dataSource}
                columns={remoteColumns}
            />

          
          {
            payrateModal &&
              <PayRateModal 
                visible={payrateModal}
                close={()=>{
                  setPayrateModal(false);
                  setColumnToBeEdit(null);
                }}
                name={columnToBeEdit?.column}
                rawData={columnToBeEdit}
                label={`${data?.getPayandBillColumn?.response?.find((payBill)=>payBill?._id==columnToBeEdit?.column)?.columnName} rate`}
                handleSave={handleSave}
                moduleId={"branchId"}
                loading={branchPayTableLoading || upsertSitePayTableLoading}
              />
          }



          </div>
        </div>        
    )
}