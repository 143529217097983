// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rotaDateSelectionField .ant-select-show-search:where(.css-dev-only-do-not-override-12jzuas).ant-select:not(.ant-select-customize-input) .ant-select-selector input{
    margin-top: 7% !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/schedule/rota/loadRota/loadRota.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B","sourcesContent":[".rotaDateSelectionField .ant-select-show-search:where(.css-dev-only-do-not-override-12jzuas).ant-select:not(.ant-select-customize-input) .ant-select-selector input{\n    margin-top: 7% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
