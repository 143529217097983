import React,{ useEffect, useState } from 'react';
import { Input, Drawer, Popover, Collapse } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faChevronLeft, faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import './advanceFilter.css';
import { PlainFilter } from './plainFilter';
import { DateFilter } from './dateFilter';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAdvanceFilter } from '@src/middleware/redux/reducers/quickFilter';
import { createdDateList } from '@src/util/date';
import { GET_BRANCHES } from "@src/util/query/branch.query";
import { useQuery } from '@apollo/client';
import { getCustomerQuery } from '@src/util/query/customer.query';
import { getAgencies } from "@src/util/query/agency.query";
import { getSitesQuery } from "@src/util/query/site.query";
import { GetEmployeeRecord } from "@src/util/query/employee.query";
import { getSiteGroups } from "@src/util/query/siteGroup.query";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { setTogglenewCreateView } from "@src/middleware/redux/reducers/newView.reducer";
import { Confirmation } from '../confirmation/confirmation.modal';


export const AdvanceFilter = ({
    firstFiltername="", secondFiltername="", refetchAll, updateView,
    visible, onClose, object,
    objectData, // object data is the specific properties that are in the schema of specific module F.e Branch object schema/ employee object schema
    groupProperty // group of all available property
}) =>{

    

    const [propList, setPropList] = useState([]);
    const [quickFilter, setQuickFilter] = useState(true);
    const [isFilterEnable, setFilterEnable] = useState(false);
    const [filtervalues, setFilterValues] = useState([]);
    const [filterValueSearch, setFilterValueSearch] = useState("");
    const [conditionOperator, setConditionOperator] = useState(null)
   

    const quickFilterReducer = useSelector(state=>state.quickFilterReducer);
    const { data: branchData, } = useQuery(GET_BRANCHES ,{
        fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });

    const {data: siteData, loading: siteLoading,} = useQuery(getSitesQuery ,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
              filters: null
          }
        }
    });

    const {data: customerData, loading: customerLoading,} = useQuery(getCustomerQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });

    const {data: employeeData, loading: employeeDataLoading, refetch} = useQuery(GetEmployeeRecord,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        },
        fetchPolicy: 'network-only'
    });

    const {data: agencyData, loading: agencyLoading} = useQuery(getAgencies,{
        fetchPolicy: 'network-only',
        variables: {
            input: {
                filters: null
            }
        },
    });
    

    useEffect(()=>{
        setFilterEnable(false);
    },[visible]);

    useEffect(()=>{
        if(groupProperty){
            setPropList(groupProperty?.map((props)=>{
                // const properties = props?.properties?.filter((prop)=>objectData?.find((branchProp)=>branchProp.propertyId==prop._id))
                const properties = props?.properties?.filter((prop)=>prop)
                return {
                    ...props,
                    properties
                }
            }));
            // setPropList([...data?.getPropertyByGroup?.data]);
            
        }
    },[groupProperty, objectData, isFilterEnable]);

    const [propSearch, setPropSearch] = useState("");
    useEffect(()=>{
        setPropList(groupProperty?.map((props)=>{
            const properties = props?.properties?.filter((prop)=>prop)
            // const properties = props?.properties?.filter((prop)=>objectData?.find((branchProp)=>branchProp.propertyId==prop._id))
            const filteredProperties = properties.filter((prop)=>prop.label.toLowerCase().includes(propSearch.toLowerCase()));
           
            return {
                ...props,
                properties: filteredProperties
            }
        }));
    }, [propSearch]);

   

    const handelOption = () =>{
        const isExist = filtervalues.find((val)=>val==filterValueSearch);
        if(isExist){
            setFilterValueSearch("");
        }else{
            setFilterValues([...filtervalues, filterValueSearch]);
            setFilterValueSearch("");
        }
    };

    const handelChange = (e) => {
        if(e.length<filtervalues?.length){
            setFilterValues([...e]);
            setFilterValueSearch("");
        }
    };
    
    const [filters, setFilters] = useState([]);
    const [filterDetail, setFilterDetail] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState("");

    const [isFilterChanged, setIsFilterChanged] = useState(false);

    useEffect(()=>{
        if(quickFilterReducer?.advanceFilter && filters?.length>0 && JSON.stringify(filters) !== JSON.stringify(quickFilterReducer?.advanceFilter)){            
            setIsFilterChanged(true);
        }else{
            setIsFilterChanged(false);

        }
    },[visible]);
    
    useEffect(()=>{
        if(quickFilterReducer?.advanceFilter && JSON.stringify(filters) !== JSON.stringify(quickFilterReducer?.advanceFilter)){            
            setFilters(prev => {
                if( JSON.stringify(prev)!==JSON.stringify(quickFilterReducer?.advanceFilter)){
                    return quickFilterReducer?.advanceFilter;
                }
            });
        }else if(quickFilterReducer?.advanceFilter?.length>0 && filters?.length==0 && visible){
            setIsFilterChanged(false);
            setFilters(quickFilterReducer?.advanceFilter);
        }
         
    },[quickFilterReducer?.advanceFilter, visible]);


    const renderFilterOption=(prop)=>{
        if(prop.fieldType.includes('date')){
            setFilterDetail('date');
        }else{
            setFilterDetail('plain');
        }
        setSelectedFilter({label:prop.label, id: prop._id, type: prop.fieldType, prop})
    };

    const [singleFilter, setSingleFilter] = useState(null);

    const handelFilter = (e)=>{
        setSingleFilter(e.target.value);
    };

    const clearFilter=()=>{
        setFilterDetail(null);
        setSingleFilter(null);

    }


    useEffect(()=>{
        const isEmptyExist = filters?.find((filter)=>filter?.length<1)
        if(filters?.length>0 && isEmptyExist){
            setFilters(filters?.filter((filter)=>filter?.length>0))
        }
    }, [filters]);

    const upsertFilter = (prop)=>{
        setIsFilterChanged(true);

        if(filters?.length===0){
            setFilters([[{...prop, condition: 'and'}]])
        }else{
            if(conditionOperator?.operator=='and'){
                setFilters(prevData => {
                    const newData = [...prevData]; // Clone the outer array
                    newData[conditionOperator?.tag] = [...prevData[conditionOperator?.tag], {...prop, condition: 'and'}]; // Clone and modify the inner array
                    return newData;
                });
                
            }else if(conditionOperator?.operator=='or'){
                setFilters([...filters, [{...prop, condition: 'or'}]])
            }
        }


    }


    const cloneFilter = (index)=>{
        setIsFilterChanged(true);

        setFilters([...filters, [...filters[index]]]);
    }

    const deleteFilterBlock = (index) => {
        setIsFilterChanged(true);

        if(filters?.length === 1){
            setFilters([]);
            dispatch(setAdvanceFilter([]));
        }else{
            setFilters(filters?.filter((filter,i)=>(i!==index)))
        }
    }


    const deleteSingleFilter = (mainIndex, index)=>{
        setIsFilterChanged(true);

        setFilters(filters?.map((mainFilter, i)=>(
            mainIndex==i? mainFilter?.filter((filter,i)=> i!==index):mainFilter
        )));
    };

    const dispatch = useDispatch();

    useEffect(()=>{
        if(!visible){
            setFilters([]);
        }
    }, [visible]);

    useEffect(()=>{ 
        if(filters?.length){
            dispatch(setAdvanceFilter(filters));
        }
    },[filters]);




    const advanceFilterNumber = quickFilterReducer?.advanceFilter.reduce((accumulator, currentArray) => {
        return accumulator + currentArray.length;
    }, 0);

    
    const {data: siteGroupData, loading: siteGroupLoading} = useQuery(getSiteGroups,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });

    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);
    const {quickFilter: quickFilterData, advanceFilter} = useSelector(state=>state.quickFilterReducer);
    const [confirmationModal, setConfirmationModal] = useState(false);
    

    const handelSaveView = async () =>{
        setIsFilterChanged(false);
        await updateView({
            variables:{
                input:{
                    _id: sessionStorage.getItem('selectedViewId'),
                    quickFilter: quickFilterData,
                    advanceFilter,
                    updatedBy: authenticatedUserDetail?._id
                }
            }
        });

        dispatch(setNotification({
            notificationState:true, 
            message:"View saved successfully",
            error: false,
        }));

        onClose();
        setConfirmationModal(false);
        await refetchAll();


    };

    const handelClose = ()=>{ 

        if(isFilterChanged){
            setConfirmationModal(true);
        }else{
            onClose();clearFilter();
        }
    }

    return(
    <>
        <Drawer
            title="All filters"
            placement="right"
            className="advanceFilter"
            closable={true}
            onClose={()=>{onClose();clearFilter();}}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={handelClose} className='close-icon'/>}
            open={visible}
            width={500}
            mask={true}
            maskClosable={false}
            maskClassName='filtermask'
            footer={
            <div className='drawer-footer'>
                <button disabled={isFilterChanged==false} className={isFilterChanged==false ? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={handelSaveView}>
                 {false? <Spinner color={"#ff7a53"}/> : 'Save filter'} 
                </button>
                <button  onClick={()=>{ dispatch(setTogglenewCreateView(true)); setIsFilterChanged(false); onClose(); }} disabled={isFilterChanged==false} className={isFilterChanged==false ? 'disabled-btn drawer-outlined-btn' : 'drawer-outlined-btn'} >
                  {false? <Spinner color={"#ff7a53"}/> : 'Save as new view'} 
                </button>
                <button disabled={false} className='drawer-outlined-btn' onClick={handelClose}>Cancel</button>
            </div>
            }
      >


        

        {!isFilterEnable?

        // static welcoming filter section
        <div className="filterMainBody">
        {/* quick filter section */}
            {
                Object.values(quickFilterReducer?.quickFilter)?.length > 0 ?
            <>
                <div className="quickFilter">

                    <div className="filter-header">
                        <div className="h5" style={{letterSpacing: '0.4px'}}>Quick filter ({Object.values(quickFilterReducer.quickFilter)?.filter(filter=>filter!=null)?.length})</div>
                        <div style={{cursor:'pointer'}} onClick={()=>setQuickFilter(!quickFilter)}>{ quickFilter? "Show" : "Hide" }</div>

                    </div>
                    {!quickFilter &&
                    <div className='quickFilterInner'>
                        <div className="text">These filters were set within the current table.</div>
                        {Object.values(quickFilterReducer.quickFilter)?.length>0 ? 
                            <>
                                {
                                quickFilterReducer?.quickFilter?.createdDate?
                                    <div className="filterBox">
                                        <span>
                                            <strong>{firstFiltername || "Created date"}</strong>&nbsp; is &nbsp;<strong>{createdDateList.find((datelist)=>datelist?.title === quickFilterReducer?.quickFilter?.createdDate?.title)?.value}</strong>
                                        </span>
                                    </div>
                                    : null
                                }
                                
                                {Object.values(quickFilterReducer.quickFilter)?.filter(filter=>filter!=null)?.length == 2 ?
                                <>
                                
                                    <div className='verticalBorder'></div>
                                    
                                    <span className='text' style={{color:'#99ACC2'}}>and</span>
                                    
                                    <div className='verticalBorder'></div>
                                
                                </>

                                :
                                    null
                                }

                                {
                                quickFilterReducer?.quickFilter?.updatedDate?
                                    <div className="filterBox">
                                        <span>
                                            <strong>{secondFiltername || "Last activity date"}</strong>&nbsp; is &nbsp;<strong>{quickFilterReducer?.quickFilter?.updatedDate?.value}</strong>
                                        </span>
                                    </div>
                                    : null
                                }
                            </>
                            : null
                        }
                    </div>
                    }

                </div>
                <div className='dividerline'></div>
            </>
            : null
            }


        {/* advance filter section */}

            <div className="advanceFilter-inner">
                
                <div className="filter-header">
                    <div className="h5" style={{letterSpacing: '0.4px'}}>Advance filters ({advanceFilterNumber})</div>
                </div>

                <div style={{marginTop:'16px'}}>
                    {filters?.length>0 ?
                    filters?.map((filter, mainindex)=>(
                    <>
                        
                        <div style={{display:'flex',direction:'rtl', columnGap:'10px'}}>
                            <span className='back-btn' onClick={()=>deleteFilterBlock(mainindex)}>Delete</span>
                            <span className='back-btn' onClick={()=>cloneFilter(mainindex)}>Clone</span>
                        </div>

                        <div className="filterMainBox">
                        
                        {filter?.length>0 ? filter?.map((filterDetail, index) => (
                            <>
                                <div className="filterbox-inner">
                                    <div>
                                        <span className='grid-text-btn'>{filterDetail?.operator} </span> 
                                        {filterDetail?.filter?.includes("_")?
                                            <span className='text'> {filterDetail?.filter.split('_')[0]} {filterDetail?.filter.split('_')[1]} </span>
                                            :
                                            <span className='text'> {filterDetail?.filter} </span>
                                        }
                                        {filterDetail?.operator?.toLowerCase().includes("branch")?
                                        <strong style={{color:'black'}}>
                                            {branchData?.branches?.find((branch)=>branch?._id==filterDetail?.filterValue)?.branchname || filterDetail?.filterValue}
                                        </strong>
                                        :
                                        filterDetail?.operator?.toLowerCase().replace(/\s/g,"")?.includes("group")? 
                                        <strong style={{color:'black'}}>
                                            {(siteGroupData?.sitegroups?.find((emp)=>emp?._id==filterDetail?.filterValue))?.sitegroupname}
                                        </strong>
                                        :
                                        filterDetail?.operator?.toLowerCase()?.includes("site")?
                                        <strong style={{color:'black'}}>
                                            {siteData?.sites?.find((site)=>site?._id==filterDetail?.filterValue)?.sitename || filterDetail?.filterValue}
                                        </strong>
                                        :
                                        filterDetail?.operator?.toLowerCase()?.includes("customer")?
                                        <strong style={{color:'black'}}>
                                            {customerData?.customers?.find((customer)=>customer?._id==filterDetail?.filterValue)?.customername || filterDetail?.filterValue}
                                        </strong>
                                        :
                                        filterDetail?.operator?.toLowerCase()?.includes("agency")?
                                        <strong style={{color:'black'}}>
                                            {agencyData?.agencies?.find((agency)=>agency?._id==filterDetail?.filterValue)?.agencyname || filterDetail?.filterValue}
                                        </strong>
                                        :
                                        filterDetail?.operator?.toLowerCase()?.includes("employee")?
                                        <strong style={{color:'black'}}>
                                            {employeeData?.getEmployee?.response?.find((emp)=>emp?._id==filterDetail?.filterValue)?.firstname}
                                            {" "+employeeData?.getEmployee?.response?.find((emp)=>emp?._id==filterDetail?.filterValue)?.lastname}
                                        </strong>:

                                        <strong style={{color:'black'}}>
                                            {(typeof(filterDetail?.filterValue) === "object")? filterDetail?.filterValue?.map((filter, index)=> (<span>{filter} {index<filterDetail?.filterValue?.length-1 ? <span className='text'> or </span> : null} </span>))  : filterDetail?.filterValue} 
                                            {filterDetail?.filterValue1?.includes("-") ? <span className='text'> To </span> : null} 
                                            {filterDetail?.filterValue1}
                                        </strong>
                                        }
                                    </div>
                                    <span className='trash-icon-wrapper'>
                                        <Popover content={"delete this condition"} placement='left'>
                                            <FontAwesomeIcon icon={faTrashAlt} onClick={()=>deleteSingleFilter(mainindex, index)}/>
                                        </Popover>
                                    </span>
                                </div>
                                {index<filter?.length-1?
                                <>
                                <div className='verticalBorder'></div>
                                
                                <span className='text' style={{color:'#99ACC2'}}>and</span>
                                
                                <div className='verticalBorder'></div>
                                </> : null}
                            </>
                            
                        )): null}

                            {filter?.length>0?
                            <button className='conditionalBtn' onClick={()=>{
                                setFilterEnable(true);
                                setConditionOperator({operator:'and',tag:mainindex});
                            }} style={{marginTop:'2%', marginBottom:'2%'}}>AND</button>
                            : null}    
                        </div>
                        {mainindex < filters?.length-1 ?
                        <div className="text" style={{marginBottom:'-4%', marginTop:'4%', fontWeight:'600'}}>OR</div>
                        : <button className='conditionalBtn'  onClick={()=>{
                            setFilterEnable(true);
                            setConditionOperator({operator:'or'});
                        }} style={{marginTop:'2%', marginBottom:'2%'}}>OR</button>
                        }
                    </>
                    ))
                    

                    :
                        <center>
                            <div className="text" style={{textAlign:'center'}}>This view has no advance filters.</div>
                            <button className='filter-btn' onClick={()=>setFilterEnable(true)}>
                                <FontAwesomeIcon icon={faAdd} color='white'/> Add filter
                            </button>
                        </center>
                    }   
                </div>

            </div>
        </div>
        :
        // do render the concerned property list when the filter is not clicked
        isFilterEnable && filterDetail == null ?
        <div className="propList">
            {filters?.length > 0 &&
                <div className='back-btn' style={{marginBottom:'6%'}} onClick={()=>setFilterEnable(false)}> <FontAwesomeIcon style={{fontSize: '8px'}} icon={faChevronLeft}/> <span>Back</span> </div>
            }

            <div className="h5" style={{marginTop:'-4%', marginBottom:'2.6%',letterSpacing: '0.4px'}}>{object} Properties</div>
            <Input 
                type="search"
                style={{ width: '-webkit-fill-available', backgroundColor: 'white'  }}
                placeholder='Search'
                className='generic-input-control'
                value={propSearch}
                onChange={(e)=>setPropSearch(e.target.value)}
                suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
            />
            <div className="proplistView">
                {propList?.map((list, index)=>(
                    list?.properties?.length>0?
                    propSearch?.length>1? 
                    <Collapse  activeKey={propSearch?.length>1?[index?.toString()]:[]}>
                        <Collapse.Panel  key={index.toString()} header={<div className="proplistHead">{list._id}</div>}>
                            {list?.properties?.map((prop)=>(

                            <div className="list-item popoverdataitem" 
                            onClick={()=>renderFilterOption(prop)}
                            >{prop.label}</div>
                            ))}
                        </Collapse.Panel>
                    </Collapse>
                    :
                    <Collapse>
                        <Collapse.Panel  key={index.toString()} header={<div className="proplistHead">{list._id}</div>}>
                            {list?.properties?.map((prop)=>(

                            <div className="list-item popoverdataitem" 
                            onClick={()=>renderFilterOption(prop)}
                            >{prop.label}</div>
                            ))}
                        </Collapse.Panel>
                    </Collapse>
                    
                    : null
                ))}
            </div>
        </div>
        
        //  else select the filter detail according the prop type
        :
        filterDetail=="plain" ?
            <PlainFilter
              clearFilter={clearFilter}
              handelFilter={handelFilter}
              handelOption={handelOption}
              setFilterValueSearch={setFilterValueSearch}
              filtervalues={filtervalues}
              filterValueSearch={filterValueSearch}
              setFilterValues={setFilterValues}
              handelChange={handelChange}
              selectedFilter={selectedFilter}
              singleFilter={singleFilter}
              setFilterEnable={setFilterEnable}
              upsertFilter={upsertFilter}
            /> 

        : filterDetail=="date" ? 
            <DateFilter
                clearFilter={clearFilter}
                handelFilter={handelFilter}
                handelOption={handelOption}
                setFilterValueSearch={setFilterValueSearch}
                filtervalues={filtervalues}
                filterValueSearch={filterValueSearch}
                setFilterValues={setFilterValues}
                handelChange={handelChange}
                selectedFilter={selectedFilter}
                singleFilter={singleFilter}
                setFilterEnable={setFilterEnable}
                upsertFilter={upsertFilter}
            /> : null
        
        

    }

        </Drawer>
        <Confirmation 
            visible={confirmationModal} 
            close={()=>{setConfirmationModal(false); onClose(); clearFilter();}}
            message={<div style={{margin:'auto', textAlign:'center', display:'table', width:'100%'}}>
                These filters will remain applied until the tab is changed. <br/> <b style={{color:'green', fontSize:'16px'}}>Do you want to save applied filters?</b></div>} 
            error={true}
            submit={handelSaveView}
            btnText1='Yes'
            btnText2='No'
            loading={false}
        />
    </>
    );
}

