import { gql } from "@apollo/client";

export const createAgencyViewMutation = gql `
mutation($input: AgencyViewInput!){
  newAgencyView(input: $input) {
    response
    message
  }
}
`;


export const updateAgencyViewMutation = gql `
mutation($input: AgencyViewInput!){
  updateAgencyView(input: $input) {
    response
    message
  }
}
`;


export const cloneAgencyViewMutation = gql`
mutation($input: AgencyViewInput!){
  cloneAgencyView(input: $input) {
    message
    response
    success
  }
}
`;


export const deleteAgencyViewMutation = gql`
mutation($id: String!){
  deleteAgencyView(_id: $id) {
    message
    response
    success
  }
}
`;