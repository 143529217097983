import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import TextArea from "antd/es/input/TextArea";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker, Form, Select, Drawer } from "antd"
import { newHolidayMutation, updateHolidayMutation } from "@src/util/mutation/holiday.mutation";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import dayjs from "dayjs"; // Make sure dayjs is installed
import isBetween from "dayjs/plugin/isBetween"; // Optional if you need 'between' functionality
import customParseFormat from "dayjs/plugin/customParseFormat";
import Spinner from "@src/components/spinner";

dayjs.extend(customParseFormat);
dayjs.extend(isBetween); 

export const HolidayDrawer = ({visible, close, refetchHolidays, editEmpLeaveData})=>{
    const {Option} = Select;
    const dispatch = useDispatch();

    const [newHoliday, {loading: newHolidayLoading}] = useMutation(newHolidayMutation);

    const param = useParams();

    const [holidayType, setHolidayType] = useState(null);
    const [holidayComment, setHolidayComment] = useState(null);
    const [holidayDate, setHolidayDate] = useState([]);
    const [isbtnDisabled, setBtnDisabled] = useState(true);

    useEffect(()=>{
        if(editEmpLeaveData && Object.keys(editEmpLeaveData)?.length>0){
            setHolidayType(editEmpLeaveData?.holidayType);
            setHolidayComment(editEmpLeaveData?.holidayComment);
            setHolidayDate(editEmpLeaveData?.holidayDate);
        }
    },[editEmpLeaveData]);

    const getDatesBetween = (startDate, endDate) => {
        let dateArray = [];
        let currentDate = dayjs(startDate);
        const stopDate = dayjs(endDate);
    
        while (currentDate.isBefore(stopDate) || currentDate.isSame(stopDate, "day")) {
          dateArray.push(currentDate.format("YYYY-MM-DD"));
          currentDate = currentDate.add(1, "day");
        }
    
        return dateArray;
    };
    
    // Handle the date range change
    const onDateChange = (dates) => {
        if (dates && dates.length === 2) {
            const startDate = dates[0];
            const endDate = dates[1];
            const allDates = getDatesBetween(startDate, endDate);
            setHolidayDate(allDates);
        } else {
            setHolidayDate([]);
        }
    };

    useEffect(()=>{
        if(holidayDate?.length>0 && holidayType?.length>0 && holidayComment?.length>0){
            setBtnDisabled(false);
        }else{
            setBtnDisabled(true);
        }
    },[holidayDate, holidayComment, holidayType]);

    const [updateHoliday, {loading: updateHolidayLoading}] = useMutation(updateHolidayMutation);

    const handelNewHoliday = async ()=>{
        try{

            if(editEmpLeaveData?._id){
    
                await updateHoliday({
                    variables:{
                        input: {
                            _id: editEmpLeaveData?._id,
                            holidayType,
                            holidayComment,
                        }
                    }
                });
                
                dispatch(setNotification({
                    error: false,
                    notificationState: true,
                    message: "Holiday was updated successfully"
                }));

            }else{

                const holidayInput = holidayDate?.map((date)=>({
                    entityId: param?.id,
                    entityType: "Employee",
                    holidayType,
                    holidayComment,
                    holidayDate: date
                }));
    
    
                await newHoliday({
                    variables:{
                        input: holidayInput
                    }
                });
                
                dispatch(setNotification({
                    error: false,
                    notificationState: true,
                    message: "Holiday was added successfully"
                }));

            }
            

            close();

            await refetchHolidays();
        }
        catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered while adding the holiday "+ err?.message
            }));
            close();
            await refetchHolidays();

        }
    }


    return(
        <Drawer
            title={editEmpLeaveData?._id? "Update Holidays/Leaves" : "Add Holidays/Leaves"}
            placement="right"
            closable={true}
            onClose={close}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={close} className='close-icon'/>}
            open={visible}
            width={500}
            mask={true}
            maskClosable={false}
            maskClassName='filtermask'
            footer={
                <div className='drawer-footer' style={{display:'flex', }}>
                    
                  <button onClick={handelNewHoliday} disabled={newHolidayLoading || isbtnDisabled || updateHolidayLoading} className={newHolidayLoading || isbtnDisabled ? 'disabled-btn drawer-filled-btn': 'drawer-filled-btn'}>
                  {newHolidayLoading || updateHolidayLoading? <Spinner/> : editEmpLeaveData?._id ?'Update' :'Submit'}
                  </button>
                  <button disabled={newHolidayLoading || updateHolidayLoading} className='drawer-outlined-btn' onClick={close} >Cancel</button>
                    
  
                </div>
            }
      >
            <div style={newHolidayLoading || updateHolidayLoading?{opacity:0.3}:{opacity:1}}>

                    <Form.Item className="holidayField">
                        <Select
                            showSearch
                            optionFilterProp="children"
                            className="custom-select schedule-section" 
                            placeholder="Select Holiday"
                            allowClear
                            style={{width:'100%'}}
                            suffixIcon={<span className="dropdowncaret"></span>}
                            value={holidayType}
                            onChange={(e)=>setHolidayType(e)}
                        >
                            <Option value={"BH"}>Bank Holidays</Option>
                            <Option value={"AH"}>Annual Holidays</Option>
                            <Option value={"SL"}>Sick Leaves</Option>
                            <Option value={"OL"}>Other Leaves</Option>

                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <DatePicker.RangePicker
                            className="generic-input-control"
                            suffixIcon={null}
                            format={"DD/MM/YYYY"}
                            disabled={editEmpLeaveData?.holidayDate}
                            defaultValue={editEmpLeaveData?.holidayDate?[dayjs(editEmpLeaveData?.holidayDate), dayjs(editEmpLeaveData?.holidayDate)]:null}
                            onChange={(date, dateString)=>onDateChange(date)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <TextArea
                            className="generic-input-control"
                            placeholder="Comments (if any?)"
                            value={holidayComment}
                            onChange={(e)=>setHolidayComment(e.target.value)}
                        />
                    </Form.Item>

            </div>
        

        

      </Drawer>
    )
}