import './leftsidebar.css';
import Spinner from '@src/components/spinner';
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Popover, Skeleton, Tag } from 'antd';
import {faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { PhoneOutlined,  FormOutlined, MessageOutlined, UserAddOutlined } from '@ant-design/icons';
import { GET_BRANCHES,} from '@src/util/query/branch.query';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { PropertyDetailDrawer } from '../../allProperties/propertyDetail.drawer';
import { EmployeeDetailViewQuery, getUserEmployeeDetailView } from '@src/util/query/employeeDetailView.query';
import { getEmployeePropHistoryQuery } from '@src/util/query/employee.query';
import { useSelector } from 'react-redux';
import { EditableAvatar } from '@src/components/avatar/editableAvatar';
import { updateEmployeeMutation } from '@src/util/mutation/employee.mutation';
import { isArray } from '@apollo/client/utilities';
import { findPayDate } from '../../../util/findPayDate/findPayDate';

export const DetailPageLeftSideBar = ({showDetail=true, employeeObject, singleEmployee, loading, handelInputChange})=>{
    
    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);

    const [phoneNumber, setPhoneNumber] = useState();

    const phoneInputRef = useRef(null);

    const [isAction, setAction] = useState(false);
    const containerRef = useRef(null);

    const {data: employeeDetailViewData, loading: employeeDetailViewLoading, refetch} = useQuery(getUserEmployeeDetailView,{
        variables:{
            createdBy: authenticatedUserDetail?._id,
            createdFor: singleEmployee?._id,
        },
        fetchPolicy: 'network-only'
    });

    
    const userDetail = useSelector(state=>state?.userAuthReducer?.authenticatedUserDetail);
    const {data:employeeDetailView, loading: getEmployeeDetailViewDataLoading, refetch: employeeDetailViewRefetch} = useQuery(EmployeeDetailViewQuery,{
        variables:{
            userId: userDetail?._id
        },
        fetchPolicy: 'network-only'
    });


    const [viewProperties, setViewProperties] = useState([]);
    useEffect(()=>{
        if(employeeDetailView?.getEmployeeDetailViewField?.response){
            setViewProperties(employeeDetailView?.getEmployeeDetailViewField?.response);
        }           
    },[employeeDetailView?.getEmployeeDetailViewField?.response]);

    
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setAction(false);
        }
      };
  
      document.addEventListener('click', handleOutsideClick);
  
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, []);

    const navigate = useNavigate();

    const [propertyDetailDrawer, setPropertyDetailDrawer] = useState(false);

    const [selectedProp, setSelectedProp] = useState();
    
    const {data: employeeProp, loading: employeePropLoading, error} = useQuery(getEmployeePropHistoryQuery,{
        variables:{
            input: {
                propertyId: selectedProp?.propertyId,
                employeeId: singleEmployee?._id
            }
        },
        skip: !selectedProp?.propertyId || !singleEmployee?._id,
        fetchPolicy: 'network-only'
    });

    const { data: branchData, } = useQuery(GET_BRANCHES ,{
        fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });

    const [readonlyProp, setReadOnlyProp] = useState([]);
    useEffect(()=>{
        let readOnly = [];
        for (const key in authenticatedUserDetail?.permission?.Employee) {
            if (authenticatedUserDetail?.permission?.Employee.hasOwnProperty(key) && authenticatedUserDetail?.permission?.Employee[key].hasOwnProperty('edit')) {
                if(authenticatedUserDetail?.permission?.Employee[key]?.edit==0){

                    readOnly.push(key);
                    
                }
                
            }
        };
        setReadOnlyProp([...readOnly]);
        // console.log(Object.values(authenticatedUserDetail?.permission?.Employee), "authenticatedUserDetail");
    }, [authenticatedUserDetail]);
    // console.log(authenticatedUserDetail?.permission?.Employee, "authenticatedUserDetail", employeeObject);


    // branch multi select
        
  const popoverRef = useRef(null);
  const inputRef = useRef(null);
  const [localGroup, setLocalGroup] = useState(branchData?.branches||[]);
  const [groupInput, setGroupInput] = useState();
  const [groupPopover, setGroupPopover] = useState(false);

  useEffect(()=>{
    if(branchData?.branches?.length>0){
        setLocalGroup(branchData?.branches);
    }
  },[branchData?.branches]);

  
  const [parentWidth, setParentWidth] = useState(null);
  const parentRef = useRef(null);

  const [tags, setTags] = useState([]);

  useEffect(() => {

    const updateParentWidth = () => {
      if (parentRef.current) {
        const width = parentRef.current.offsetWidth;
        setParentWidth(width);
      }
    };

    // Call the update function on initial mount and window resize
    updateParentWidth();
    window.addEventListener('resize', updateParentWidth);
    inputRef?.current?.focus();

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };

  }, [groupPopover]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.group-wrapper')) {
        setGroupPopover(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

 
  useEffect(()=>{
    if(groupInput && !tags?.find((tag)=>tag?.name==groupInput?.name) && groupInput?.id!="dumy"){
        setTags([...tags, groupInput]);
        handelInputChange({name: "branch", value: [...tags, groupInput]});
    }
  }, [groupInput]);



  useEffect(()=>{
        if(singleEmployee?.hasOwnProperty("branch")  || singleEmployee['metadata']?.hasOwnProperty("branch")){
            const branchIds = singleEmployee["branch"] || singleEmployee['metadata']["branch"] ;
            
            if(branchIds?.length>0){
                setTags(
                    branchIds?.map((branchId)=>{
    
                        const filteredBranch = localGroup?.find((lg)=>(lg._id==branchId));
                        return {
                            name: filteredBranch?.branchname,
                            id: filteredBranch?._id
                        }
                        
                    })
                );
            }
        }
    
  },[singleEmployee]);

  const [imageUrl, setImageUrl] = useState(null);
  const [updateEmployee, {loading: uploadLoading}]  = useMutation(updateEmployeeMutation);


  const param = useParams();

    const handleUploadChange = ({ file }) => {
      if (file) {
          // Get this URL from response in real scenario
          const imageUrl = URL.createObjectURL(file.originFileObj);
          setImageUrl(imageUrl);
        

          var reader = new window.FileReader();
            reader.readAsDataURL(file.originFileObj); // Pass the file object here, not the URL
            reader.onloadend = async function () {
                const base64data = reader.result;
                await updateEmployee({
                    variables:{
                        input:{
                            _id: param?.id,
                            properties: [{
                                metadata: 1,
                                name:'imageURL',
                                value: base64data
                            }],

                        }
                    }
                });
            }

      }
    };


    return(
        <div className='sidebar-wrapper' >
            <div className='leftsidebar'>

                <div className='side-intro'>
                    {!loading || singleEmployee?
                    <>
                        <div className='emp-avatar'>
                            <EditableAvatar 
                                size={70} 
                                src={imageUrl || singleEmployee?.metadata?.imageURL}
                                onEdit={handleUploadChange}
                            />
                        </div>
                        
                        <div className='text-head' style={{width:'-webkit-fill-available'}}>
                            <div className='text-title' style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                            >
                                <span>
                                    {singleEmployee?.firstname+" "+(singleEmployee?.lastname || "")}
                                </span>
                                <Popover
                                    overlayClassName='notePopover'
                                    placement='right'
                                    content={
                                        <div className='popover-data'>
                                            <div className="popoverdataitem" onClick={()=>navigate("/user/employee-detail/"+singleEmployee?._id+"/edit")}>
                                                Customise View
                                            </div>
                                            <div className="popoverdataitem" onClick={()=>navigate(`/user/employee-prop-history/`+singleEmployee?._id)}>
                                               Data Fields History
                                            </div>
                                        </div>
                                    }
                                >
                                    <FontAwesomeIcon style={{cursor:'pointer'}} icon={faEllipsisV}/>
                                </Popover>
                            </div>
                            {
                                singleEmployee?.metadata?.email?
                                <div className='text-subtitle'>
                                    <div style={{textTransform:'lowercase', fontSize:'1em', marginBottom:'22px', marginTop:'10px', textOverflow:'wrap'}}>
                                        <FontAwesomeIcon icon={faEnvelope}/> &nbsp; {singleEmployee?.metadata?.email} 
                                    </div>
                                </div>
                                :null
                            }
                            

                        </div>
                    </>
                    : 
                    <div className='skeleton-custom'>

                    <Skeleton.Avatar active size={69} />
                    <Skeleton className='text-head' active/>
                    </div>
                    }
                </div>

                <div className="activity-btn-grp" style={{margin:'auto', width:'fit-content'}}>
                                    
                    <Popover
                        content={"Make a phone call"}
                    >
                        <span>
                            <button>
                                <PhoneOutlined />
                            </button>
                            <span className='tiny-text'>Call</span>
                        </span>
                    </Popover>

                    
                    <Popover
                        content={"Start conversation"}
                    >
                        <span>
                            <button>
                                {/* <FontAwesomeIcon icon={faComment} /> */}
                                <MessageOutlined/>
                            </button>
                            <span className='tiny-text'>Chat</span>
                        </span>
                    </Popover>


                    <Popover
                        content={"Create a note"}
                    >
                        <span>

                            <button>
                                <FormOutlined icon={faPenToSquare} />
                            </button>
                            <span className='tiny-text'>Note</span>
                        </span>
                    </Popover>


                    
                    <Popover content={"Follow this"} >
                        <span>
                            <button>
                                {/* <FontAwesomeIcon icon={faUserPlus} /> */}
                                <UserAddOutlined />
                            </button>
                            <span className='tiny-text'>Follow</span>
                        </span>
                    </Popover>
                </div>
                
            </div>
            

                {showDetail?
                    <>
                    {viewProperties?.map((field, index)=>{
                        const {propertyDetail: prop} = field;
                        if(prop?.label?.replaceAll(" ","")?.toLowerCase()=="firstname" || prop?.label?.replaceAll(" ","")?.toLowerCase()=="lastname"){
                            return(
                                <div className='fieldView'>
                                    <div>{prop?.label}</div>
                                    <div>
                                        {singleEmployee?.hasOwnProperty(prop?.label?.replaceAll(" ","")?.toLowerCase())  || singleEmployee['metadata']?.hasOwnProperty(prop?.label?.replaceAll(" ","")?.toLowerCase())? 
                                        singleEmployee[prop?.label?.replaceAll(" ","")?.toLowerCase()] || singleEmployee['metadata'][prop?.label?.replaceAll(" ","")?.toLowerCase()] : ""}
                                    </div>
                                </div>
    
                            )
                        }
                    })}
                

                
                    
                    <div className="btm-border"></div>

                
                    {(viewProperties)?.map((field, index)=>{
                        const {propertyDetail: prop} = field;
                        if(prop?.label?.replaceAll(" ","")?.toLowerCase()!=="firstname" && prop?.label?.replaceAll(" ","")?.toLowerCase()!=="lastname"){
                            if(prop?.label?.toLowerCase()=="branch"){
                                return(                                
                                    tags?.length>0?
                                    <>
                                        <div className='fieldView'>
                                            <div>{prop?.label}</div>
                                            <div>
                                            {tags?.map((property)=>(
                                                <Tag>
                                                    {property.name}
                                                </Tag>
                                            ))}
                                            </div>
                                        </div>
        
                                        
                                    </>
                                    : null
                                    
                                )
                            }else if(prop?.fieldType?.toLowerCase()=="paywidget"){
                                return(
                                    <div className='fieldView'>
                                        <div>{prop?.label}</div>
                                        <div>
                                            {singleEmployee?.hasOwnProperty(prop?.label?.replaceAll(" ","")?.toLowerCase())  || singleEmployee['metadata']?.hasOwnProperty(prop?.label?.replaceAll(" ","")?.toLowerCase())? 
                                               findPayDate(
                                                Array.isArray(singleEmployee[prop?.label?.replaceAll(" ","")?.toLowerCase()] || singleEmployee['metadata'][prop?.label?.replaceAll(" ","")?.toLowerCase()]) ?
                                                (singleEmployee[prop?.label?.replaceAll(" ","")?.toLowerCase()] || singleEmployee['metadata'][prop?.label?.replaceAll(" ","")?.toLowerCase()]) : [] 
                                               )
                                                : ""}
                                        </div>
                                    </div>
        
                                )
                            }  
                            else{
                                return(
                                    <div className='fieldView'>
                                        <div>{prop?.label}</div>
                                        <div>
                                            {singleEmployee?.hasOwnProperty(prop?.label?.replaceAll(" ","")?.toLowerCase())  || singleEmployee['metadata']?.hasOwnProperty(prop?.label?.replaceAll(" ","")?.toLowerCase())? 
                                            singleEmployee[prop?.label?.replaceAll(" ","")?.toLowerCase()] || singleEmployee['metadata'][prop?.label?.replaceAll(" ","")?.toLowerCase()] : ""}
                                        </div>
                                    </div>
        
                                )
                            }                             
                        }
                    })}

                    </>: null
                }


            <PropertyDetailDrawer
                visible={propertyDetailDrawer}
                selectedProp={selectedProp} 
                clearState={setSelectedProp} 
                loading={employeePropLoading}
                data={employeeProp?.getEmployeePropHistory?.response?.map((data)=>({ ...data, createdby: data?.sourceDetail?.length>0? data?.sourceDetail[0]?.firstname  +" "+ data?.sourceDetail[0]?.lastname : <div style={{textAlign:'center'}}>--</div> }))}
                close={()=>setPropertyDetailDrawer(false)} />


            {/* image upload modal */}
            
            {/* <Modal
                title="Upload Image"
                visible={visible}
                footer={null}
                onCancel={handleCloseModal}
            >
                <Upload
                    name="avatar"
                    listType="picture"
                    showUploadList={true}
                    action={(e)=>console.log("fill upload", e)} // URL for uploading image
                    onChange={handleUploadChange}
                >
                    <UploadOutlined />
                </Upload>
            </Modal> */}
               
        </div>
    );
}