import './table.css';
import React, { useEffect, useState } from 'react';
import { Layout, theme, Table, Input, Avatar } from 'antd';
import { Loader } from '../loader';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckToSlot, faMinus, faPencil, faSearch, faTrashCanArrowUp } from '@fortawesome/free-solid-svg-icons';
  
import { Resizable } from 'react-resizable';
import { useDispatch } from 'react-redux';
import { refreshBranchGrid } from '@src/middleware/redux/reducers/branch.reducer';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setEditGridColumn } from '@src/middleware/redux/reducers/properties.reducer';
import { EditPropertiesModal } from './modal/editproperty.modal';
import { PROPERTYWITHFILTER } from '@src/util/query/properties.query';
import { UserOutlined } from '@ant-design/icons';
import { findPayDate } from '../../util/findPayDate/findPayDate';
const { Header, Content, Footer } = Layout;

export const DataTable = ({
  header, data, loading, title, objectData, selectionAllowed=true,
  viewRefetch, view, detailpage, handelBulkUpdateSave, noHeader, handelArchive, clearSelection, detailbtn="Preview", detailUrl=""}) => {
  
  
  const [dynamicColumn, setDynamicColumn] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  
  const { loading:propertyListLoading, data:propertyDataList } = useQuery(PROPERTYWITHFILTER,{
    variables:{
        input:{fields:[
          {field:'groupId', value: ''},
          {field:'fieldType', value: '' },
          {field: 'objectType', value: title },
        ]}
    },
    skip: !title,
    fetchPolicy: 'network-only'
  });

  useEffect(()=>{
    if(objectData?.length>0 && view && propertyDataList?.getPropertywithFilters?.length>0){
      const mandatoryField = objectData?.filter((prop)=>  prop?.isReadOnly);
      const additionalProp = view?.map((viewProp)=> {
        const isExist = propertyDataList?.getPropertywithFilters?.find((prop)=>viewProp?._id==prop?._id);
        return {propertyDetail: isExist};
      });
      const updateView = [...mandatoryField, ...additionalProp];


      const col = updateView.map((prop)=>{          
          return {
            originalObj: prop,
            title: prop?.propertyDetail?.label,
            dataIndex: prop?.propertyDetail?.label?.replaceAll(" ","").toLowerCase(),
            key: prop?.propertyDetail?.label?.replaceAll(" ","").toLowerCase(), // Initial width of the column
            // width: 120,
            // onHeaderCell: (column) => ({
            //   width: column.width,
            //   onResize: handleResize(column.dataIndex),
            //   textWrap:'no-wrap'
            // }),
            // ellipsis: false,

            render: (_, record) => {

              const showActions = sessionStorage.getItem('hoverItem') == record.key && prop?.propertyDetail?.label == objectData[0]?.propertyDetail?.label &&  selectedRowKeys?.length===0;
              if(prop.propertyDetail?.label.replaceAll(" ","").toLowerCase()=="phonenumber"
              ){
                return(
                  <a style={{textDecoration:'underline', textWrap:'nowrap'}} href={"tel:"+record[prop?.propertyDetail?.label.replaceAll(" ","").toLowerCase()]}>
                    {record[prop?.propertyDetail?.label.replaceAll(" ","").toLowerCase()]}
                  </a>
                )
              }
              else if(prop.propertyDetail?.label.replaceAll(" ","").toLowerCase()=="email"
              ){
                return(
                  <a style={{textDecoration:'underline', textWrap:'nowrap'}} target='_blank' href={"mailto:"+record[prop?.propertyDetail?.label.replaceAll(" ","").toLowerCase()]}>
                    {record[prop?.propertyDetail?.label.replaceAll(" ","").toLowerCase()]}
                  </a>
                )
              }
              else{
              return (          
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', textWrap:'nowrap'}}>
                  
                  <div style={{display:'flex', alignItems:'center', gap:'9px', textWrap:'nowrap'}}>
                  
                    {detailpage?.includes("employee") && prop?.propertyDetail?.label.replaceAll(" ","").toLowerCase()=="firstname" &&
                      <>
                      {record?.imageURL?
                        <Avatar size={30}  src={record?.imageURL}/> 
                      
                      :
                      // <div className="employeeProfile">
                        <Avatar size={30} style={{fontSize:'10px'}}><UserOutlined /></Avatar>
                      // </div>
                      }
                        
                      </>
                    }

                    <div 
                      onClick={detailpage?.length>0? ()=>history('/user/'+detailpage+record.key) : ()=>{}}
                      className={showActions? 'prev-btn' : null}
                      style={prop?.propertyDetail?.label?.replaceAll(" ","").toLowerCase()=="firstname" || prop?.propertyDetail?.label?.replaceAll(" ","").toLowerCase()=="branchname"?
                        {color:'#0091ae'}:
                        {}
                      }
                      >
                      
                      {record[prop?.propertyDetail?.label?.replaceAll(" ","").toLowerCase()]}
                    
                    </div>
                      
                  </div>
  
                

                  <button className={"sm-btn"} style={showActions?{visibility: 'visible'}:{visibility: 'hidden'}} type="link" onClick={()=>history('/user/'+detailpage+record?.key+detailUrl)}>
                    {detailbtn}
                  </button>
                
              </div>
              );
              }
            },
          }
        // }
      })||[];
      setDynamicColumn([...col]);
      dispatch(refreshBranchGrid(false));

    }else if(objectData?.length>0 && view==false){
      const col = objectData.map((prop)=>{  
          return {
            originalObj: prop,
            title: prop.propertyDetail.label,
            dataIndex: prop.propertyDetail.label.replaceAll(" ","").toLowerCase(),
            key: prop.propertyDetail.label.replaceAll(" ","").toLowerCase(), // Initial width of the column
            width: 100,
            onHeaderCell: (column) => ({
              width: column.width,
              onResize: handleResize(column.dataIndex),
              ellipsis: true
            }),
            ellipsis: true,

            render: (_, record) => {
              const showActions = sessionStorage.getItem('hoverItem') == record.key && prop.propertyDetail.label == objectData[0].propertyDetail.label &&  selectedRowKeys?.length===0;
              if(prop.propertyDetail.label.replaceAll(" ","").toLowerCase()=="phonenumber"
              ){
                return(
                  <a style={{textDecoration:'underline'}} href={"tel:"+record[prop.propertyDetail.label.replaceAll(" ","").toLowerCase()]}>
                    {record[prop.propertyDetail.label.replaceAll(" ","").toLowerCase()]}
                  </a>
                )
              }
              else if(prop.propertyDetail.label.replaceAll(" ","").toLowerCase()=="email"
              ){
                return(
                  <a style={{textDecoration:'underline'}} target='_blank' href={"mailto:"+record[prop.propertyDetail.label.replaceAll(" ","").toLowerCase()]}>
                    {record[prop.propertyDetail.label.replaceAll(" ","").toLowerCase()]}
                  </a>
                )
              }
              else{
              return (          
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between',}}>
                  
                  <div 
                    onClick={()=>history('/user/'+detailpage+record.key)}
                    className={showActions? 'prev-btn' : null}
                    style={prop.propertyDetail.label.replaceAll(" ","").toLowerCase()=="firstname" || prop.propertyDetail.label.replaceAll(" ","").toLowerCase()=="branchname" || prop.propertyDetail.label.replaceAll(" ","").toLowerCase()=="sitegroupname"?{color:'#0091ae'}:{}}
                  >
                    {record[prop.propertyDetail.label.replaceAll(" ","").toLowerCase()]}
                  
                  </div>
                    
  
                

                  <button className={"sm-btn"} style={showActions?{visibility: 'visible'}:{visibility: 'hidden'}} type="link" onClick={()=>history('/user/'+detailpage+record.key)}>
                    Preview
                  </button>
                
              </div>
              );
              }
            },
          }
        // }
      })||[];

      setDynamicColumn([...col]);
    }
  }, [objectData, view, propertyDataList?.getPropertywithFilters]);


  useEffect(()=>{
    if(clearSelection){
      setSelectedRowKeys([]);
    }
  },[clearSelection]);


  const handleResize = dataIndex => (e, { size }) => {
    setDynamicColumn(prevColumns => {
      const nextColumns = [...prevColumns];
      const index = nextColumns.findIndex(col => col.dataIndex === dataIndex);
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return nextColumns;
    });
  };

  const dispatch = useDispatch();
  const branchReducer = useSelector(state => state.branchReducer);

  useEffect(()=>{
    if(branchReducer?.refreshGrid){

      viewRefetch();
    }
  },[branchReducer?.refreshGrid]);
  
  const [hoveredRow, setHoveredRow] = useState(null);

  const rowClassName = (record) => {
    return record.key === hoveredRow ? 'hovered-row' : '';
  };

  
  
  const handleRowMouseEnter = (record) => {
    setHoveredRow(record.key);
    sessionStorage.setItem('hoverItem', record.key);
  };


  const handleRowMouseLeave = () => {
    setHoveredRow(null);
    // setMoreoption(false);
    sessionStorage.removeItem('hoverItem');

  };

  const history = useNavigate();

  const [dataSource, setDataSource] = useState([]);

  
  useEffect(()=>{
    setDataSource(data?.map((key,index) => {
      const {metadata, ...rest} = key;
      if(Array.isArray(metadata?.payrate) || Array.isArray(metadata?.overtimepayrate)){
        return {key:key?._id ,...metadata, payrate: findPayDate(metadata?.payrate), overtimepayrate: findPayDate(metadata?.overtimepayrate), ...rest};
      }else{
        return {key:key?._id ,...metadata, ...rest};
      }
    }));
  },[data]);


  const [search, setSearch] = useState("");

  useEffect(()=>{
    if(search?.length>0){

      setDataSource(data?.map((key,index) => {
        const {metadata, ...rest} = key;
        if(Array.isArray(metadata?.payrate) || Array.isArray(metadata?.overtimepayrate)){
          return {key:key?._id ,...metadata, payrate: findPayDate(metadata?.payrate), overtimepayrate: findPayDate(metadata?.overtimepayrate), ...rest};
        }else{
          return {key:key?._id ,...metadata, ...rest};
        }
      })
      ?.filter(obj => 
        Object.values(obj).some(value => {
          // Check if value is an array
          if (Array.isArray(value)) {
            // If it's an array, check if any element matches the searchValue
            return value.some(item => item.toString().toLowerCase().includes(search.toLowerCase()));
          }else{
            // Otherwise, check if the value itself matches
            return value?.toString()?.toLowerCase().includes(search.toLowerCase());

          }
      })));
      

    }else{
      setDataSource(data?.map((key,index) => {
        const {metadata, ...rest} = key;
        if(Array.isArray(metadata?.payrate) || Array.isArray(metadata?.overtimepayrate)){
          return {key:key?._id ,...metadata, payrate: findPayDate(metadata?.payrate), overtimepayrate: findPayDate(metadata?.overtimepayrate), ...rest};
        }else{
          return {key:key?._id ,...metadata, ...rest};
        }
      }));
    }
  },[search]);


  const {
    token: { colorBgContainer },
  } = theme.useToken();
  
  const handleChange = (pagination, filters, sorter) => {};


  const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
  };


  // normal row selection
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  const [propertyModal, setPropertyModal] = useState(false);


  return (
    <Layout className='bg-white'>
      <Content className="site-layout" style={{ padding: '0 42px' }}>
        <div style={{ padding: 5, minHeight: 450, background: colorBgContainer }}>
            {/* <TabsComponent/> */}
            {loading || dynamicColumn?.length<1 || propertyListLoading ?
            <Loader/>
            :

            <Table  
              // bordered
              rowSelection={selectionAllowed? rowSelection : null}
              columns={dynamicColumn} 
              // className={selectedRowKeys?.length>0? 'commonGrid generalGrid' : 'generalGrid'}
              // components={{
              //   header: {
              //     cell: ResizableTitle,
              //   },
              // }}

              dataSource={dataSource} 
              
              title={
                                
                !header? null : () => {
                  if(header){
                  return(
                    <div className='grid-table-search-input'>
                    
                      <div className='table-footer' id="selection-options">
                        <Input type='search' onChange={(e)=>setSearch(e.target.value)} style={{background: 'white', width:'250px', height:'33px', borderRadius:'15px'}} className='generic-input-control' placeholder='Search ...'  suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}/>
                        {selectedRowKeys?.length>0 &&
                          <>
                              <small className='small-text'> {selectedRowKeys?.length} selected</small>

                              {
                                selectedRowKeys?.length===dataSource?.length?
                                
                                <div onClick={()=>setSelectedRowKeys([])}>
                                    <FontAwesomeIcon icon={faMinus} style={{marginRight:'5px'}}/> <span>Unselect All Records</span>
                                </div>

                                :

                                <div onClick={()=>setSelectedRowKeys(dataSource?.map((data)=>data?.key))}>
                                    <FontAwesomeIcon icon={faCheckToSlot} style={{marginRight:'5px'}}/> <span>Select All Records</span>
                                </div>
                              }

                              <div onClick={()=>setPropertyModal(!propertyModal)}>
                                  <FontAwesomeIcon icon={faPencil} style={{marginRight:'5px'}}/> <span>Edit</span>
                              </div>

                            { handelArchive &&
                              <div onClick={()=>handelArchive(selectedRowKeys)} >
                                  <FontAwesomeIcon icon={faTrashCanArrowUp} style={{marginRight:'5px'}}/> <span>Archive</span>
                              </div>
                            }
                          </>
                        }
                      </div>
                      
                      
                      <div className="small-btn">
                        <button className={noHeader? 'sm-btn disabled-btn' : 'sm-btn'}>Export</button> &emsp;
                        <button className={noHeader? 'sm-btn disabled-btn' : 'sm-btn'} onClick={noHeader? ()=>{} : ()=>{dispatch(setEditGridColumn(true))}}>Edit columns</button>
                      </div>
                    </div>
                  )}else{return null}
                  }
              } 
              
          
              onRow={(record) => ({
                onMouseEnter: () => handleRowMouseEnter(record),
                onMouseLeave: () => handleRowMouseLeave(),
              })}
              rowClassName={rowClassName}
              className='grid-curvTable'

            />
            }
        </div>

{/* bulk edit properties modal */}
{propertyModal?
        <EditPropertiesModal
          visible={propertyModal}
          onClose={()=>setPropertyModal(!propertyModal)}
          record={selectedRowKeys}
          clearSelection = {setSelectedRowKeys}
          dynamicColumn={dynamicColumn}
          handelBulkUpdateSave={handelBulkUpdateSave}
        />
:null}
      
      </Content>
      
    </Layout>
  );
};

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={<span className="react-resizable-handle" />}
      onResize={onResize}
    >
      <th {...restProps} />
    </Resizable>
  );
};