export const routes = {
    setting: "#/setting",
    propertySetting: "#/setting/module",
    docSetting: "#/setting/doc",
    addUser: "#/setting/adduser",
    userRole: "#/setting/userRole",
    userAccess: "#/setting/useraccess",
    userDetail: "#/setting/adduser/",
    employeeEditForm: "#/setting/employee/editform",
    editskill: "#/setting/employee/editskill",
    forms: "#/setting/forms",
    branchEditForm: "#/setting/branch/editform",

    // Finance module
    paylevel: "#/setting/finance/paylevel",
    paybillcol: "#/setting/finance/paybillcol",
    shifttype: "#/setting/finance/shifttype",
    paytable: "#/setting/finance/paytable",
    premium: "#/setting/finance/premium",
    calculatepaybill: "#/setting/finance/calculatepaybill",


    // module objects
    module:"#/setting/module/config",

    // billing
    service:"#/setting/billing/service",
    payment:"#/setting/billing/payment",
    billingNotification:"#/setting/billing/billingNotification",

    // report management
    reportTemplates:"#/setting/reportmanagement/reporttemplate",
    systemReport:"#/setting/reportmanagement/sysreport",
    reportField:"#/setting/reportmanagement/reportfield",
    scheduleEvent:"#/setting/reportmanagement/scheduleevent",
}

export const publicRoutes = ["/", "/pwd/*", "/classic/*"];