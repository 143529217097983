import { Avatar, Checkbox, Form, Input, Modal, Popover } from "antd";
import { useLazyQuery } from "@apollo/client";
import { faClose, faInfoCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getEmployeeForScheduleSearchQuery } from "@src/util/query/employee.query";
import { useEffect, useRef, useState } from "react";
import Spinner from "../../components/spinner";
import dayjs from "dayjs";
import { UserOutlined } from "@ant-design/icons";

export const ReAssignEmployee = ({selectedDuties, visible, close, allowedSkilledForSpecificArea, site, confirm:handelConfirm})=>{


    const searchSegmentRef= useRef(null);
    const searchDetailSegmentRef = useRef(null);
    const [searchPopup, setSearchPopup] = useState(false);

    const handleClickOutside = (event) => {
       
        if (searchSegmentRef?.current instanceof HTMLElement && !searchSegmentRef.current.contains(event.target) &&
            !Array.from(event.target.classList).find(cls => cls.includes('ant-select-item-option-content')) &&
            searchDetailSegmentRef?.current instanceof HTMLElement && !searchDetailSegmentRef.current.contains(event.target)
        )
        {
            setSearchPopup(false);
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchSegmentRef, searchDetailSegmentRef]);

    const [dates, setDates] = useState(null);

    const [getEmployeeOnSearch, {data: empSearchData, loading: empSearchDataLoading, refetch} ]= useLazyQuery(getEmployeeForScheduleSearchQuery);

    const [keyword, setKeyword] = useState("");
    
    const handelSearch = async(keyword)=>{
        
        setKeyword(keyword);

        if(keyword?.length>2){
            await getEmployeeOnSearch({fetchPolicy: 'cache-and-network',
                variables: {
                    input: {
                        filters:  {advanceFilter: [[{
                            filter: "contain_exactly",
                            filterValue: [keyword],
                            operator: 'First name'
                        }]]},
                        schedule: {startDate: dates?.startDate, endDate: dates?.endDate}
                    }
                },
                fetchPolicy: 'cache-and-network',
                skip: keyword?.length==0 || dates==null
            });
        }


    };

    useEffect(()=>{
        if(selectedDuties?.length){

            const sortedDuties = selectedDuties.sort((a, b) => {
                return a.startDate.isBefore(b.startDate) ? -1 : a.startDate.isAfter(b.startDate) ? 1 : 0;
            });

            const startDate = dayjs(sortedDuties[0]?.startDate).subtract(8, 'hour');
            const endDate = dayjs(sortedDuties[sortedDuties?.length-1]?.startDate).add(8, 'hour');
            
            setDates({startDate, endDate});

        }
    },[selectedDuties]);


    const [failureChecks, setFailureChecks] = useState({});
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isOverride, setIsOverride] = useState(false);


    const checkEmployeeIfSuitable = (emp)=>{

        setSearchPopup(false);
        setSelectedEmployee(emp?._id);
        const sortedDuties = selectedDuties.sort((a, b) => {
            return a.startDate.isBefore(b.startDate) ? -1 : a.startDate.isAfter(b.startDate) ? 1 : 0;
        })?.map((duty)=> duty?.startDate?.format("DD/MM/YYYY"));

        const empHoliday = emp?.employeeHolidayDetail?.find((eh)=> (sortedDuties).includes(dayjs(eh?.holidayDate).format("DD/MM/YYYY")) )
        const empHolidayCheck = empHoliday && Object.keys(empHoliday)?.length>0? true: false;
        // if site block or not
        let blockedSite = false;
        if(emp?.blockedSites?.length>0){
            blockedSite = emp?.blockedSites?.find((bs)=>bs?.siteId==site?._id)? true: false;
        }
        // branch check
        const isBranchExist = emp?.branch?.find((b)=>b?._id==site?.branchDetail?._id);
        const branchCheck = isBranchExist? false : true;


        // const restPeriodCheck = specificArea && specificArea?.schedule && Object.keys(specificArea?.schedule)?.length>0 ? dayjs(specificArea?.scheduleTimes[0]?.operationalEndDate).isSame(dateWithPrevRestH) || dayjs(specificArea?.scheduleTimes[0]?.operationalEndDate).isBefore(dateWithPrevRestH) ? false : true :false
        
        //============ rest period check terminated
        
        //=================== duty check / experience check
        const trainingCheck= emp?.trainedEmployeeOnSite?.find((te)=>te?.siteId==site?._id)? false: true;

        //=================== duty check / experience check terminated

        //================== skill check 
        const skillCheck = allowedSkilledForSpecificArea?.length==0? false : !allowedSkilledForSpecificArea.every((areaSkill)=> emp?.employeeskills?.find((empSkill)=>empSkill?.skill==areaSkill && empSkill?.isArchive!=true));
        //================== skill check terminated
        
        //======================= visa expiry check
        const visaExpiryCheck = emp?.metadata?.requirevisa=="Yes"? 
            emp?.metadata?.visaexpirydate? 
            dayjs(emp?.metadata?.visaexpirydate, "YYYY/MM/DD").isAfter(dayjs())? false: true
            : true  
        // this check will be true if visa required and date is empty
        :false
        //======================= visa expiry check terminated

        // skill expiry check
        let skillExpiryCheck = false;
        const matchedSkill = emp?.employeeskills?.filter((empSkill)=> 
            allowedSkilledForSpecificArea?.find((skill)=>skill==empSkill?.skill) && empSkill?.isArchive!=true)?.
            filter((skill)=> skill?.fields?.find((f)=>f?.name=="expirydate"))
            .map((skill)=> {
                const isExist = skill?.fields?.find((f)=>f?.name=="expirydate");
                if(isExist){
                    return isExist.value;
                }
            });
            
        if(matchedSkill?.length>0){
            skillExpiryCheck = matchedSkill?.every((date)=> dayjs(date).local().isAfter(dayjs())) ? false : true
        }
            
        
        if(skillCheck || trainingCheck || skillExpiryCheck || visaExpiryCheck || branchCheck || blockedSite || empHolidayCheck){
            setFailureChecks({skillCheck, trainingCheck, skillExpiryCheck, visaExpiryCheck, branchCheck, empHolidayCheck, employeeData: {...emp, site:site?._id}, blockedSite});
           
            
        }else{
            setFailureChecks({});

        }


        
    };

    const handelSubmit = async ()=>{
        const isConfirm = window.confirm("Warning! 8h rest check is not operational on bulk swap");
        if(isConfirm){
            const status = isConfirmed? '1' : '2';
            await handelConfirm(status, selectedEmployee, failureChecks);
            close();
        }else{
            return;
        }
    }


    return (

        <Modal    
            visible={visible}
            width={600}
            footer={
                <div 
                    className="drawer-footer"
                >

                    <button className={'drawer-filled-btn'} onClick={handelSubmit}>
                        Confirm
                    </button>

                    <button disabled={false}  className={'drawer-outlined-btn'} onClick={close}>
                        Cancel
                    </button>

                </div>
        }
        closable={false}
        >

            <div className='modal-header-title '>
                <span style={{letterSpacing:'0.2px'}}> Select Employee </span>
                <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
            </div>

            <div className='modal-body'>


                <div style={{margin:'left', textAlign:'left', width:'500', overflow:'hidden'}} ref={searchDetailSegmentRef}>
                    
                    <Input 
                        type="search"
                        id="inputSearch"
                        name='popoverSearch'
                        style={{ width:'90%', marginBottom:'2px', marginLeft:'1px', marginBottom:'16px' }} 
                        className='generic-input-control' 
                        placeholder={"Search employee"}
                        autoFocus={true}
                        autoComplete="off"
                        onFocus={()=>setSearchPopup(true)}
                        value={keyword}
                        onChange={(e)=>handelSearch(e.target.value)}
                        suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                    />
                    
                    {(searchPopup && keyword?.length>2) &&
                        empSearchData?.getEmployeeForScheduleSearch?.response?.length>0?
                        <div className="popover-data" style={{overflowY:'scroll', position:'absolute', minHeight:'250px', width:'78%', background:'white', border:'1px solid lightgrey', marginLeft:'0', marginTop:'-10px', zIndex:9}}>
                            
                            {
                                
                                empSearchDataLoading?
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <Spinner />
                                </div>
                                :
                                empSearchData?.getEmployeeForScheduleSearch?.response?.map((employee)=>{

                                    const lastname = employee?.lastname? employee?.lastname : "";
                                    const firstname = employee?.firstname? employee?.firstname : "";
                        
                                    return(
                                        
                                        <div className="popoverdataitem"
                                            style={{textAlign:'left'}}
                                            onClick={()=>{ 
                                                setFailureChecks(null); checkEmployeeIfSuitable(employee); setKeyword(firstname+" "+lastname)
                                            }}
                                        >
                                            <div style={{display:'flex', columnGap:'12px'}}>
                                                <div className="employeeProfile">
                                                    <Avatar  size={40} style={{background:'lightgrey'}} src={employee?.metadata?.imageURL || <UserOutlined/>} />
                                                </div>
                                                <div>
                                                    <div>{firstname +" "+ lastname}</div>
                                                    <div style={{color:'grey', fontWeight:300}}>{employee?.metadata?.employmenttype} {employee?.metadata?.agency[0]?.agencyname?  "["+ employee?.metadata?.agency[0]?.agencyname +"]" : null }</div>
                                                
                                                </div>
                                            </div>
                                        </div>                                                                        
                                )})
                                
                                
                            }
                        </div>
                        : null
                    }

                    {
                        keyword?.length>0 && 
                        <Form>

                            <Form.Item>
                                <Checkbox checked disabled onChange={(e)=>setIsOverride(e.target.checked)}>Override rest check 
                                    <Popover
                                        content={
                                            "Warning! 8h rest check is not operational on bulk swap"
                                        }
                                    >
                                        <FontAwesomeIcon icon={faInfoCircle} style={{cursor:'pointer'}} />
                                    </Popover>
                                </Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox checked={isConfirmed} onChange={(e)=>setIsConfirmed(e.target.checked)}>Confirm all selected duties</Checkbox>
                            </Form.Item>

                        </Form>

                    }

                </div>

            </div>

        </Modal>

    )
}