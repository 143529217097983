import {gql} from "@apollo/client";

export const createBranchViewMutation = gql`
mutation($input: BranchViewInput!){
    createBranchView(input: $input) {
      success
      message
    }
}`;


export const updateBranchView = gql`
mutation($input: BranchViewInput!){
  updateBranchView(input: $input) {
    success
  }
}
`;


export const cloneBranchViewMutation = gql`
mutation($input: BranchViewInput!){
  cloneBranchView(input: $input) {
    message
    success
  }
}
`;

export const deleteBranchViewMutation = gql`
mutation($id: String!){
  deleteBranchView(_id: $id) {
    message
    success
  }
}
`;