import '../../components/createFields/createFieldDrawer.css';
import "./setting.css";
import React, { useState } from 'react';
import { Col, Collapse, Popover, Row, Tree } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import { Navbar } from '../../components/navbar';
import { routes } from '../../util/routes/routes';
import { useSelector } from 'react-redux';
import Spinner from '../../components/spinner';
import { PropertySetting } from './dataFields/propertySetting';
import { UserRole } from './userRole/userRole';
import { UserAccess } from './userAccess/userAccess.page';
import { ModuleDocuments } from './moduleDocuments/moduleDocuments';
import { ModuleForms } from './moduleForms/moduleForms';
import { SkillSetting } from './skillsetting/skill.setting';
import { PayLevel } from './finanace/paylevel/paylevel';
import { ShiftType } from './finanace/shiftType/shiftType';
import { PayTable } from './finanace/payTable/payTable';
import { Premiums } from './finanace/premiums/premiums';
import { PayBillColumn } from './finanace/paybillcolumn/payBillColumn';
import { CalculatePayBill } from './finanace/calculatePayBill/calculatePayBill';
import { ReportDataFieldSetting } from './reportDataFields/reportpropertySetting';
import { ReportTemplatePage } from './reportTemplate/reportTemplate.page';
import { ScheduleEvents } from './scheduleEvents/scheduleEvents';
import { BillingServices } from './billing/billingservice/billingservice';
import { PaymentMethod } from './billing/paymentMethod/paymentMethods';
import { BillingNotification } from './billing/billingNotification/billingNotification';
import { User } from './userSetting/user';
import { UserDetailPage } from './userDetail/userDetail.page';


export const Setting = ({themeData, themeLoading, refetchTheme, close})=>{
    const navigate = useNavigate();
    const {pathname, search, hash} = useLocation();
    // const pathId = pathname+search;
    
    const path = hash;

    const active = 'setting-sidebar-nav-list-item setting-navbar-active';
    const inactive = 'setting-sidebar-nav-list-item';
    const {isModalOpen} = useSelector(state => state.searchReducer);


    return(
        <div className='searchOverlayViewParent'
        >
            <div
                
                className='settingOverlayView'
                style={{ top: 0, paddingBottom: 10 }}
            >
                {
                    

                    <>
                        {/* <Navbar  themeData={themeData} themeLoading={themeLoading} refetchTheme={refetchTheme} /> */}
                        <div className='setting-nav-header'>
                            <div className='back-link' onClick={()=>navigate('#/allsetting')}>
                                <FontAwesomeIcon className='icon' icon={faChevronLeft}/>
                                <span >Back</span>
                            </div>

                            <div className='close-link' onClick={close}>
                                <span >Close setting</span>
                                <FontAwesomeIcon className='icon' icon={faTimes}/>
                            </div>

                        </div>

                        <div style={{display:'flex', paddingTop:'60px'}}>
                            <div className='setting-sidebar'>
                                    <div className='setting-sidebar-body'>
                                        <div className="setting-sidebar-inner">
                                            <div className="setting-heading">
                                                <div className='setting-header-title'>
                                                    Setting
                                                </div>
                                                {/* <div style={{lineHeight:'33px'}}>
                                                    <Popover content="Search settings">
                                                        <FontAwesomeIcon icon={faSearch} className='setting-heading-icon' />
                                                    </Popover>
                                                </div> */}
                                            </div>

                                            <nav className='setting-custom-nav' style={{paddingBottom:'32px'}}>
                                                <Collapse  defaultActiveKey={['2','1','3','4','5',]}>
                                                    
                                                    <Collapse.Panel key='1' header={<div className='setting-sidebar-nav'>Your Preferences</div>}>
                                                        <ul className='setting-sidebar-nav-list'>
                                                            <li className='setting-sidebar-nav-list-item'>General</li>
                                                            <li className='setting-sidebar-nav-list-item'>Notifications</li>
                                                            <li className='setting-sidebar-nav-list-item'>Security</li>
                                                        </ul>
                                                    </Collapse.Panel>
                                                    
                                                    <Collapse.Panel key='2'  header={<div className='setting-sidebar-nav'>Account Setup</div>}>    
                                                        <ul className='setting-sidebar-nav-list'>
                                                            {/* <li className='setting-sidebar-nav-list-item'>Account Defaults</li> */}
                                                            <Link to={routes.userRole}><li className={path==routes.userRole?active:inactive}>User Roles</li></Link>
                                                            <Link to={routes.addUser}><li className={path==routes.addUser?active:inactive }>Users & Teams</li></Link>
                                                            <Link to={routes.userAccess}><li className={path==routes.userAccess?active:inactive}>Users Access Log</li></Link>
                                                            {/* <li className='setting-sidebar-nav-list-item'>Privacy & Consent</li> */}
                                                        </ul>
                                                    </Collapse.Panel>

                                                    <Collapse.Panel key='3'  header={<div className='setting-sidebar-nav'>Data Management</div>}> 
                                                        <ul className='setting-sidebar-nav-list'>
                                                            {/* Data fields management */}
                                                            <Link to={routes.propertySetting}><li className={path==routes.propertySetting?active:inactive}>Data Fields</li></Link>
                                                            <Link to={routes.docSetting}><li className={path==routes.docSetting?active:inactive}>Document Checklist</li></Link>
                                                            <Link to={routes.forms}><li className={path==routes.forms?active:inactive}>Forms</li></Link>
                                                            <Link to={routes.editskill}><li className={path==routes.editskill?active:inactive}>Skills</li></Link>

                                                            {/* <Collapse accordion defaultActiveKey={[]} style={{paddingBottom:'16px'}}> */}
                                                                {/* modules */}
                                                                {/* <Collapse.Panel  key="m-1" header={<div className='setting-sidebar-nav' style={{fontWeight:500, marginLeft:'-11px'}}>Objects</div>}>
                                                                    <ul className='setting-sidebar-nav-list' style={{marginTop:'10px', marginLeft:'-17px'}}>
                                                                        <Link to={routes.module+"?agency"}><li className={path==routes?.module+"?agency"?active:inactive}>Agency</li></Link>
                                                                        <Link to={routes.module+"?branch"}><li className={path==routes?.module+"?branch"?active:inactive}>Branch</li></Link>
                                                                        <Link to={routes.module+"?employee"}><li className={path==routes?.module+"?employee"?active:inactive}>Employee</li></Link>
                                                                        <Link to={routes.module+"?siteGroup"}><li className={path==routes?.module+"?siteGroup"?active:inactive}>Site Group</li></Link>
                                                                        <Link to={routes.module+"?site"}><li className={path==routes?.module+"?site"?active:inactive}>Site</li></Link>
                                                                        <Link to={routes.module+"?customer"}><li className={path==routes?.module+"?customer"?active:inactive}>Customer</li></Link>
                                                                    </ul>
                                                                </Collapse.Panel> */}

                                                                {/*
                                                                    Need to implement
                                                                    Data  Managment 
                                                                    
                                                                    - Data Fields
                                                                    - Modules
                                                                    - Forms (should be clone of same as properties in our/hub sys)
                                                                */}

                                                            {/* </Collapse>   */}

                                                        </ul>
                                                        
                                                    </Collapse.Panel>

                                                    
                                                    <Collapse.Panel key='4'  header={<div className='setting-sidebar-nav'>Pay & Bill Management</div>}>    
                                                        <ul className='setting-sidebar-nav-list'>
                                                            <Link to={routes.paylevel}><li className={path==routes.paylevel?active:inactive}>Duty Type</li></Link>
                                                            <Link to={routes.shifttype}><li className={path==routes.shifttype?active:inactive}>Hour Type</li></Link>
                                                            <Link to={routes.paytable}><li className={path==routes.paytable?active:inactive }>Pay Table</li></Link>
                                                            <Link to={routes.premium}><li className={path==routes.premium?active:inactive}>Premiums</li></Link>
                                                            <Link to={routes.paybillcol}><li className={path==routes.paybillcol?active:inactive}>Pay & Bill Columns</li></Link>
                                                            <Link to={routes.calculatepaybill}><li className={path==routes.calculatepaybill?active:inactive}>Calculate Pay & Bill</li></Link>
                                                        </ul>
                                                    </Collapse.Panel>  
                                                    
                                                    <Collapse.Panel key='5'  header={<div className='setting-sidebar-nav'>Report Management</div>}>    
                                                        <ul className='setting-sidebar-nav-list'>
                                                            <Link to={routes.reportField}><li className={path==routes.reportField?active:inactive}>Report Field</li></Link>
                                                            <Link to={routes.reportTemplates}><li className={path==routes.reportTemplates?active:inactive}>Report Templates</li></Link>
                                                            <Link to={routes.systemReport}><li className={path==routes.systemReport?active:inactive}>System Reports</li></Link>
                                                        </ul>
                                                    </Collapse.Panel> 
                                                    
                                                    <Collapse.Panel key='6'  header={<div className='setting-sidebar-nav'>Schedule Management</div>}>    
                                                        <ul className='setting-sidebar-nav-list'>
                                                            <Link to={routes.scheduleEvent}><li className={path==routes.scheduleEvent?active:inactive}>Schedule Events</li></Link>
                                                            <Link to={"#"}><li className={path==routes.service?active:inactive}>Schedule Checks</li></Link>
                                                        </ul>
                                                    </Collapse.Panel>  
                                                    
                                                    <Collapse.Panel key='7'  header={<div className='setting-sidebar-nav'>Subscription & Services</div>}>    
                                                        <ul className='setting-sidebar-nav-list'>
                                                            <Link to={routes.service}><li className={path==routes.service?active:inactive}>Services</li></Link>
                                                            <Link to={routes.payment}><li className={path==routes.payment?active:inactive}>Payment Methods</li></Link>
                                                            <Link to={routes.billingNotification}><li className={path==routes.billingNotification?active:inactive }>Billing Notification</li></Link>
                                                        </ul>
                                                    </Collapse.Panel>  
                                            
                                                    
                                                </Collapse>
                                            </nav>

                                        </div>
                                    </div>
                            </div> 
                            
                            
                            {
                                path==(routes.userRole) ?
                                <UserRole /> 
                                :
                                path==routes.addUser ?
                                <User />
                                :
                                path==routes.userAccess?
                                <UserAccess />
                                :
                                path==routes.propertySetting?
                                <PropertySetting />
                                :
                                path==routes?.docSetting?
                                <ModuleDocuments />
                                :
                                path==routes?.forms?
                                <ModuleForms />
                                :
                                path==routes?.editskill?
                                <SkillSetting />
                                :
                                path==routes?.paylevel?
                                <PayLevel/>
                                :
                                path==routes?.shifttype?
                                <ShiftType/>
                                :
                                path==routes?.paytable?
                                <PayTable/>
                                :
                                path==routes?.premium?
                                <Premiums/>
                                :
                                path==routes?.paybillcol?
                                <PayBillColumn/>
                                :
                                path==routes?.calculatepaybill?
                                <CalculatePayBill/>
                                :
                                path==routes?.reportField?
                                <ReportDataFieldSetting/>
                                :
                                path==routes?.reportTemplates?
                                <ReportTemplatePage/>
                                :
                                path==routes?.systemReport?
                                null
                                :
                                path==routes?.scheduleEvent?
                                <ScheduleEvents/>
                                :
                                path==routes?.service?
                                <BillingServices/>
                                :
                                path==routes?.payment?
                                <PaymentMethod/>
                                :
                                path==routes?.billingNotification?
                                <BillingNotification/>
                                :
                                path.startsWith(routes?.userDetail)?
                                <UserDetailPage />
                                :null

                            }
                        
                        </div>

                    </>
                }
            </div>
        </div>
    );
}

{/* <div style={{width: '100%',}}> */}
                // {isModalOpen? null : 
                // }
                
            {/* </div> */}