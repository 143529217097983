
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { Popover, Skeleton } from 'antd';
import {faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { PhoneOutlined,  FormOutlined, MessageOutlined, UserAddOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@src/components/spinner';
import { CustomerObjectQuery } from '@src/util/query/customer.query';
import { GenerateFields } from '@src/util/generateFields/generateFields';
import { updateCustomerMutation } from '@src/util/mutation/customer.mutation';
import { useDispatch } from 'react-redux';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { useSelector } from 'react-redux';
import { getCustomerDetailViewFieldQuery } from "@src/util/query/customerDetailViewField.query";


export const CustomerDetailPageLeftSideBar = ({customer, loading, setIsFieldChanged, saveUpdate, setSaveUpdate, refetch, isFieldChanged, showDetails})=>{
    
    const dispatch = useDispatch();
    const param = useParams();
    const userDetail = useSelector(state=>state?.userAuthReducer?.authenticatedUserDetail);

    const {data:customerObject, loading: siteGroupObjectLoading, refetch: siteGroupObjectRefetch} = useQuery(CustomerObjectQuery);
    const navigate = useNavigate();

    const [customerSchema, setCustomerSchema] = useState([]);

    
   
    const {data:customerDetailViewData, loading: getSiteDetailViewDataLoading, refetch: newSiteDetailViewRefetch} = useQuery(getCustomerDetailViewFieldQuery,{
        variables:{
            userId: userDetail?._id
        }
    });

    useEffect(()=>{
        if(customerDetailViewData?.getCustomerDetailViewField?.response?.length>0){
            setCustomerSchema(customerDetailViewData?.getCustomerDetailViewField?.response?.map((object)=>({
                label: object?.propertyDetail?.label,
                name: object?.propertyDetail?.label?.toLowerCase().replace(/\s/g,""),
                fieldType: object?.propertyDetail?.fieldType,
                property: object,
            })));
        }
        else if(customerObject?.getCustomerObject?.response?.length>0){
            setCustomerSchema(customerObject?.getCustomerObject?.response?.map((object)=>({
                label: object?.propertyDetail?.label,
                name: object?.propertyDetail?.label?.toLowerCase().replace(/\s/g,""),
                fieldType: object?.propertyDetail?.fieldType,
                property: object,
            }))); 
        }
    },[customerObject?.getCustomerObject?.response, customerDetailViewData?.getCustomerDetailViewField?.response]);
    
    const [edit, setEdit] = useState(false);

    const [field, setField] = useState([]);

    const handelDataValue = ({name, value})=>{
        if(name){
            if(value){
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value}])
                }
            }else{
                // setField(field?.filter(f=>f.name!==name));
                
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value:''
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value:''}])
                }
            }
        }
    }

    
    useEffect(()=>{
        if(Object.values(customer)?.length>0 && customerSchema?.length>0){
            const localFeed = customerSchema?.map((schema)=>{

                const {name} = schema;
                return {name, value: customer[name]}
                
            });
            setField([...localFeed]);
        }
    },[customerSchema, customer]);

    useEffect(()=>{
        if(setIsFieldChanged){
            setIsFieldChanged(false);
            setEdit(false);
        }
    },[loading, setIsFieldChanged]);

    
    useEffect(()=>{
        
        if(Object.values(customer)?.length>0 && customerSchema?.length>0){

            const localFeed = customerSchema?.map((schema, index)=>{
                
                return {name: schema?.name, value: customer[schema?.name]}
                
            });

            
            const isEqual = localFeed.every((local)=>field.find((f)=> {
                if(f?.name==local?.name && ( Array.isArray(f?.value) || Array.isArray(local?.value) )){

                    if(f?.value?.length!=local?.value?.length){
                        return false;
                    }else{
                        return f?.value?.every((value, index)=> value==local?.value[index])
                    }

                }
                else if(f.name==local.name && f.value?.toLowerCase()==local.value?.toLowerCase()){
                    return true;
                }else{
                    return false;
                }
            }));

            setIsFieldChanged(!isEqual);
        }

    },[field]);

    
    useEffect(()=>{
        if(saveUpdate){
            handelUpdateCustomer();
        }
    },[saveUpdate]);

    const [updateCustomer, {loading: updateCustomerLoading}] = useMutation(updateCustomerMutation);

    const handelUpdateCustomer = async()=>{
        try{
            
            let schemaFields = [];

            field?.map((field)=>{
                if(field.name==="customername" ){
                    schemaFields.push(field);
                }
                else{
                    schemaFields.push({...field, metadata:1})
                }
            });


            await updateCustomer({
                variables:{
                    input:{
                        _id: param?.id,
                        properties: schemaFields
                    }
                }
            });

            setEdit(false);
            setSaveUpdate(false);
            await refetch();

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Customer  was updated successfully"
            }));
        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }   
    }

    return(
        <div className='sidebar-wrapper' style={isFieldChanged?{marginBottom:'5.5em'}:{}}>
            <div className='leftsidebar'>

                <div className='side-intro'>
                    {!loading && Object.keys(customer)?.length>0 ?
                    <>
                        
                        <div className='text-head' style={{width:'100%', textAlign:'center'}}>
                            <div className='text-title' style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%', textAlign:'center'}}>

                                <span span style={{width:'100%'}}>
                                    {customer?.customername?.toUpperCase()}
                                </span>
                                <Popover
                                    overlayClassName='notePopover'
                                    placement='right'
                                    content={
                                        <div className='popover-data'>
                                            <div className="popoverdataitem" onClick={()=>setEdit(!edit)}>
                                               {edit? "Cancel Edit" : "Edit"}
                                            </div>
                                            <div className="popoverdataitem" onClick={()=>navigate("/user/customer-detail/"+param?.id+"/edit")}>
                                                Customise View
                                            </div>
                                        </div>
                                    }
                                >
                                    <FontAwesomeIcon style={{cursor:'pointer'}} icon={faEllipsisV}/>
                                </Popover>
                            </div>

                            <div className='text-subtitle'>

                                <div style={{fontSize:'1em', marginBottom:'22px', marginTop:'10px'}}>
                                    <FontAwesomeIcon icon={faLocationDot}/> &nbsp; {customer?.address} 
                                </div>   
                            
                                <div className="activity-btn-grp"  style={{justifyContent:'center'}}>
                                    
                                    <Popover
                                        content={"Make a phone call"}
                                    >
                                        <span>
                                            <button className='disabled-btn'>
                                                <PhoneOutlined />
                                            </button>
                                            <span className='tiny-text disabled'>Call</span>
                                        </span>
                                    </Popover>

                                    
                                    <Popover
                                        content={"Start conversation"}
                                    >
                                        <span>
                                            <button className='disabled-btn'>
                                                {/* <FontAwesomeIcon icon={faComment} /> */}
                                                <MessageOutlined/>
                                            </button>
                                            <span className='tiny-text disabled'>Chat</span>
                                        </span>
                                    </Popover>


                                    <Popover
                                        content={"Create a note"}
                                    >
                                        <span>

                                            <button>
                                                <FormOutlined icon={faPenToSquare} />
                                            </button>
                                            <span className='tiny-text'>Note</span>
                                        </span>
                                    </Popover>


                                    
                                    <Popover content={"Follow this"} >
                                        <span>
                                            <button className='disabled-btn'>
                                                {/* <FontAwesomeIcon icon={faUserPlus} /> */}
                                                <UserAddOutlined />
                                            </button>
                                            <span className='tiny-text disabled'>Follow</span>
                                        </span>
                                    </Popover>
                                </div>

                            </div>
                        </div>
                    </>
                    : 
                    <div className='skeleton-custom'>

                    <Skeleton.Avatar active size={69} />
                    <Skeleton className='text-head' active/>
                    </div>
                    }
                </div>

                

                
            </div>
            

                
                {loading || updateCustomerLoading || siteGroupObjectLoading?
                
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', }}>
                        <Spinner/>
                    </div>

                    :
                    showDetails?
                        customerSchema.map((schema)=>(
                            edit?
                                (
                                    <GenerateFields
                                        label = {schema?.label}
                                        name = {schema?.name}
                                        fieldType= {schema?.fieldType}
                                        handelDataValue = {handelDataValue}
                                        value={ field?.find((f)=>schema?.name==f.name)?.value }
                                        property={schema?.property}
                                    />
                                )
                            :
                                (
                                
                                    <div className='fieldView'>
                                        <div>
                                            {schema?.label}
                                        </div>
                                        <div style={{textAlign:'right', textWrap:'pretty', paddingLeft:'1px'}}>
                                            {schema?.fieldType=="multiCheckbox"?

                                                customer[schema?.name?.toLowerCase().replace(/\s/g,"")]?.join(", ")
                                                :
                                                customer?.hasOwnProperty(schema?.label?.replaceAll(" ","")?.toLowerCase())  || customer['metadata']?.hasOwnProperty(schema?.label?.replaceAll(" ","")?.toLowerCase())? 
                                                customer[schema?.label?.replaceAll(" ","")?.toLowerCase()] || customer['metadata'][schema?.label?.replaceAll(" ","")?.toLowerCase()] : ""
                                            
                                            }
                                        </div>
                                    </div>
        
                                )
                            
                        ))
                    : null
                    
                }
               
        </div>
    );
}