import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { onError } from '@apollo/client/link/error';
import { Observable } from '@apollo/client';

// shared
export const automationEndPoint = "https://intranx.com:3500/getcompanydetail/";
export const liveStatusEndPoint = "https://intranx.com:3500";


// Create an error link to handle network errors globally
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (networkError) {
    console.error('Network Error: ', networkError);

    // Define maximum retries and initial delay
    let retryCount = 0;
    const maxRetries = 5;
    const initialDelay = 5000; // 5 seconds

    // Retry logic with exponential backoff
    return new Observable((observer) => {
      const retry = () => {
        // Increment delay exponentially
        const delay = initialDelay * Math.pow(2, retryCount - 1);

        // Retry the operation after delay
        const timeoutId = setTimeout(() => {
          forward(operation).subscribe({
            next: (result) => {
              observer.next(result);
              observer.complete();
            },
            error: (error) => {
              retryCount++;
              if (retryCount <= maxRetries) {
                retry(); // Retry with increased delay
              } else {
                observer.error(error); // Stop retrying after max attempts
              }
            },
          });
        }, delay);
        
        // Clear timeout when subscription is cancelled
        return () => clearTimeout(timeoutId);
      };

      retry(); // Start the retry loop
    });
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
});


//==============================> Development env

// export const baseURL = "http://localhost:3000";
// const graphqlEndpoint =  "http://localhost:3600/graphql";
// // export const graphqlWsEndpoint = "ws://localhost:4000";
// export const graphqlWsEndpoint = "https://widedownloader.com";

//   const httpLink = createHttpLink({
//     uri: graphqlEndpoint,
//   });

//   // Create a WebSocket link for subscriptions
//   const wsLink = new GraphQLWsLink(createClient({
//     url: graphqlWsEndpoint,
//     options: {
//       reconnect: true,
//     },
//     on: {
//       connected: () => console.log('WebSocket connected'),
//       closed: () => console.log('WebSocket closed'),
//       error: (err) => console.error('WebSocket error', err),
//     },
//   }));


//   const authLink = setContext((_, { headers }) => {
//     // get the authentication token from local storage if it exists
//     const token = localStorage.getItem('authToken');
//     // return the headers to the context so httpLink can read them
//     return {
//       headers: {
//         ...headers,
//         authorization: token ? `Bearer ${token}` : "",
//         credentials: 'include',
//       },
//     }
//   });

//   const splitLink = split(
//     ({ query }) => {
//       const definition = getMainDefinition(query);
//       return (
//         definition.kind === 'OperationDefinition' &&
//         definition.operation === 'subscription'
//       );
//     },
//     wsLink,
//     authLink.concat(httpLink)
//   );

//   export const privateClient = new ApolloClient({
//     // link: authLink.concat(httpLink),
//     link: ApolloLink.from([errorLink, splitLink]),
//     cache: new InMemoryCache()
//   });


//   export const publicClient = new ApolloClient({
    
//     uri: graphqlEndpoint,
    
//     cache: new InMemoryCache(),

//   });


//===========================> Production env


export const baseURL = "https://intranx.com";
const graphqlEndpoint =  "https://www.widedownloader.com/graphql"; 
export const graphqlWsEndpoint = "https://widedownloader.com";
// export const graphqlWsEndpoint = "ws://localhost:3600";

const httpLink = createHttpLink({
  uri: graphqlEndpoint,
  credentials: 'include', 
});

// Create a WebSocket link for subscriptions
const wsLink = new GraphQLWsLink(createClient({
  url: graphqlWsEndpoint,
  on: {
    connected: () => console.log('WebSocket connected'),
    closed: () => console.log('WebSocket closed'),
    error: (err) => console.error('WebSocket error', err),
  },
}));


const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('authToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      // credentials: 'include',
    },
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink)
);


export const privateClient = new ApolloClient({
  // link: authLink.concat(httpLink),
  link: ApolloLink.from([errorLink, splitLink]),
  cache: new InMemoryCache()
});


export const publicClient = new ApolloClient({
  
  uri: graphqlEndpoint,
  
  cache: new InMemoryCache(),

});