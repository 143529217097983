import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import Spinner from "@src/components/spinner";
// import 'dayjs/locale/en-gb';
import { useEffect, useRef, useState } from "react";
import { CaretDownFilled, UserOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Alert, Avatar, DatePicker, Input, Modal, Popover, Select, Divider, Tag, Form } from "antd";
import { GetEmployeeRecord } from "@src/util/query/employee.query";
import { getPayLevelQuery } from "@src/util/query/paylevel.query";
import { shiftTypeQuery } from "@src/util/query/shiftType.query";
import { newScheduleTimesMutation } from "@src/util/mutation/scheduleTimes.mutation";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { updateScheduleMutation } from "@src/util/mutation/schedule.mutation";
import { getScheduledJobById } from "@src/util/query/schedule.query";
import { UpdateScheduleByField } from "@src/util/mutation/schedule.mutation";
import { getEmployeeForScheduleSearchQuery } from "@src/util/query/employee.query";
import { CheckFailureModal } from "../../checkFail.model";
import { newScheduleCheckMutation } from "@src/util/mutation/scheduleChecks.mutation";
import { scheduleCheckFailureList } from "@src/util/scheduleCheckFailureList";
import { deleteScheduleByScheduleIdMutation } from "@src/util/mutation/schedule.mutation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getUkHolidays } from "@src/util/ukholidays/holidays";
import { calculatePayBillMutation } from "@src/util/mutation/calculatePayBill.mutation";
import { getAgencies } from "@src/util/query/agency.query";
import { updateScheduleTimesMutation } from "@src/util/mutation/scheduleTimes.mutation";
import { useSelector } from "react-redux";

// dayjs.locale('en-gb');

export const EditDutyTimes = ({scheduleId, close})=>{
    


    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);   



    const {data: scheduledJobData, refetch: scheduleJobRefetch, loading: scheduledJobLoading} = useQuery(getScheduledJobById,{
        variables: {
            jobId: scheduleId
        },
        fetchPolicy: 'network-only',
        skip: !scheduleId
    });

    // duty type and hour type
    const {data: dutyTypeData, loading: dutyTypeLoading, refetchDutyType} = useQuery(getPayLevelQuery,{
        fetchPolicy: 'network-only',
    });
    const {data: hourTypeData, loading: hourTypeDataLoading, refetch: refetchHourType} = useQuery(shiftTypeQuery);

    const [newScheduleOperationTime, {loading: newScheduleLoading}] = useMutation(newScheduleTimesMutation);
    const [updateSchedule, {loading:updateScheduleLoading}] = useMutation(updateScheduleMutation);
    const [updateScheduleField, {loading:updateScheduleFieldLoading}] = useMutation(UpdateScheduleByField)


   



    const [operationalAssignedEmployee, setOperationalAssignedEmployee] = useState(null);
    const [operationalDutyType, setOperationalDutyType] = useState();
    const [operationalHourType, setOperationalHourType] = useState();
    const [operationalStartDateTime, setOpertionalStartDateTime] = useState();
    const [operationalEndDateTime, setOpertionalEndDateTime] = useState();


    const [scheduleData, setScheduleData] = useState({});
    useEffect(()=>{
        if(scheduledJobData?.getScheduledJobById?.response && !scheduledJobLoading){
            setScheduleData({...scheduledJobData?.getScheduledJobById?.response});
            setOperationalAssignedEmployee(null);
        }
    },[scheduledJobData?.getScheduledJobById?.response, scheduledJobLoading]);
    
    
    useEffect(()=>{
        if(!dutyTypeLoading && !hourTypeDataLoading && !newScheduleLoading && !updateScheduleLoading && !scheduledJobLoading && !hourTypeDataLoading) {
        
            if(scheduleData?.scheduletimesDetail){
                const operationTime = scheduleData?.scheduletimesDetail;
                if(operationTime?.operationalAssignedEmployee && scheduleData?.assignedEmployee){
                    setOperationalAssignedEmployee({emp: scheduleData?.OperationalTimeDetail});
                }else if(operationTime?.operationalAssignedAgency){
                    setAssignedAgency(operationTime?.operationalAssignedAgency);
                }

                setOperationalDutyType(operationTime?.operationalDutyType);
                setOperationalHourType(operationTime?.operationalHourType);

                setOpertionalStartDateTime(dayjs(operationTime?.operationalStartDate));
                setOpertionalEndDateTime(dayjs(operationTime?.operationalEndDate));

            }else{
                
                if(scheduleData?.assignedEmployee){
                    setOperationalAssignedEmployee({_id:scheduleData?.assignedEmployee, emp: scheduleData?.assignedEmployeeDetail});
                }

                if(scheduleData?.assignedAgency){
                    setAssignedAgency(scheduleData?.assignedAgency);
                }
                if(scheduleData?.dutyTypeId){
                    setOperationalDutyType(scheduleData?.dutyTypeId);
                }
                if(scheduleData?.hourTypeId){
                    setOperationalHourType(scheduleData?.hourTypeId);
                }
                if(scheduleData?.firstDate){
                    setOpertionalStartDateTime(dayjs(scheduleData?.firstDate));
                }
                if(scheduleData?.secondDate){
                    setOpertionalEndDateTime(dayjs(scheduleData?.secondDate));
                }else{

                    setOpertionalEndDateTime(dayjs(scheduleData?.shiftEndDateTime));
                }

            }

            setPayDutyType(scheduleData?.payDutyTypeId);
            setPayHourType(scheduleData?.payHourTypeId);
            setPayStartDateTime(scheduleData?.payStartDateTime? dayjs(scheduleData?.payStartDateTime) : null);
            setPayEndDateTime(scheduleData?.payEndDateTime? dayjs(scheduleData?.payEndDateTime) : null);

            
            setBillDutyType(scheduleData?.billDutyTypeId);
            setBillHourType(scheduleData?.billHourTypeId);
            setBillStartDateTime(scheduleData?.billStartDateTime? dayjs(scheduleData?.billStartDateTime) : null);
            setBillEndDateTime(scheduleData?.billEndDateTime? dayjs(scheduleData?.billEndDateTime) : null);


        }
    },[scheduleData , dutyTypeLoading, hourTypeDataLoading, newScheduleLoading, updateScheduleLoading, scheduledJobLoading]);
    

    const [checkFailVisible, setCheckFailVisible] = useState(false);
    const [failureChecks, setFailureChecks] = useState(null);

    const [allowedSkilledForSpecificArea, setAllowedSkillsForSpecificArea] = useState([]);
    useEffect(()=>{
        if(scheduleData?.siteSkills?.length>0){
            setAllowedSkillsForSpecificArea(scheduleData?.siteSkills?.filter((areaSkill)=>areaSkill?.areaId==scheduleData?.siteAreaDetail?._id && areaSkill?.status)?.map((skill)=>skill?.skillId))
        }
    },[scheduleData?.siteSkills, scheduleData?.siteAreaDetail]);
    
    // check employee from search if it is suitable or not for specific job
    const checkEmployeeIfSuitable = (emp)=>{
        
        const empHoliday = emp?.employeeHolidayDetail?.find((eh)=>dayjs(eh?.holidayDate).format("DD/MM/YYYY")===dayjs(scheduleData?.firstDate).local().format("DD/MM/YYYY"))

        const empHolidayCheck = empHoliday && Object.keys(empHoliday)?.length>0? true: false;

        // if site block or not
        let blockedSite = false;
        if(emp?.blockedSites?.length>0){
            blockedSite = emp?.blockedSites?.find((bs)=>bs?.siteId==scheduleData?.site?._id)? true: false;
        }

        // branch check
        const isBranchExist = emp?.branch?.find((b)=>(b?._id==(scheduleData?.site?.branchDetail?._id || scheduleData?.branchId)));
        const branchCheck = isBranchExist? false : true;

        //============ rest period check
        const date = dayjs(scheduleData?.date, "DD/MM/YYYY")?.format("DD/MM/YYYY");
        const dateWithPrevRestH = dayjs(date+" "+scheduleData?.shiftStartTime, "DD/MM/YYYY HH:mm").subtract(8, 'hour');
        // const specificArea = emp?.schedules?.find((schedule)=>schedule?.siteAreaId==area?._id);
        const specificArea = emp?.schedules[emp?.schedules?.length-1];
       
        // const restPeriodCheck = specificArea && specificArea?.schedule && Object.keys(specificArea?.schedule)?.length>0 ? dayjs(specificArea?.scheduleTimes[0]?.operationalEndDate).isSame(dateWithPrevRestH) || dayjs(specificArea?.scheduleTimes[0]?.operationalEndDate).isBefore(dateWithPrevRestH) ? false : true :false
        let restPeriodCheck;
        if(operationalStartDateTime.isSame(dayjs(scheduleData?.firstDate)) && operationalEndDateTime.isSame(dayjs(scheduleData?.secondDate)) && scheduleData?.assignedEmployee == emp?._id){
            restPeriodCheck = false;
        }else{
            restPeriodCheck = emp?.schedules[0]?.schedule?.hasOwnProperty('_id') ? true: false;
        }
        
        //============ rest period check terminated
        
        //=================== duty check / experience check
        
        const trainingCheck= emp?.trainedEmployeeOnSite?.find((te)=>te?.siteId==scheduleData?.site?._id)? false: true

        //=================== duty check / experience check terminated

        //================== skill check 
        const skillCheck = allowedSkilledForSpecificArea?.length==0? false : !allowedSkilledForSpecificArea.every((areaSkill)=> emp?.employeeskills?.find((empSkill)=>empSkill?.skill==areaSkill && empSkill?.isArchive!=true));
        //================== skill check terminated
        
        //======================= visa expiry check
        const visaExpiryCheck = emp?.metadata?.requirevisa=="Yes"? 
        emp?.metadata?.visaexpirydate? 
        dayjs(emp?.metadata?.visaexpirydate, "YYYY/MM/DD").isAfter(dayjs())? false: true
        : 
        // this check will be true if visa required and date is empty
        true  : false
        //======================= visa expiry check terminated

        // skill expiry check
        let skillExpiryCheck = false;
        const matchedSkill = emp?.employeeskills?.filter((empSkill)=> 
            allowedSkilledForSpecificArea?.find((skill)=>skill==empSkill?.skill) && empSkill?.isArchive!=true)?.
            filter((skill)=> skill?.fields?.find((f)=>f?.name=="expirydate"))
            .map((skill)=> {
                const isExist = skill?.fields?.find((f)=>f?.name=="expirydate");
                if(isExist){
                    return isExist.value;
                }
            });
            
        if(matchedSkill?.length>0){
            skillExpiryCheck = matchedSkill?.every((date)=> dayjs(date).local().isAfter(dayjs())) ? false : true
        }
        
        if(empHolidayCheck || blockedSite || restPeriodCheck || skillCheck || trainingCheck || skillExpiryCheck || visaExpiryCheck || branchCheck){
            setFailureChecks({empHolidayCheck, blockedSite, restPeriodCheck, skillCheck, trainingCheck, skillExpiryCheck, visaExpiryCheck, branchCheck, employeeData: {...emp, site:scheduleData?.site?._id}});
            setCheckFailVisible(true);
            setSearch("");
            
        }else{
            setSearch("");
            setOperationalAssignedEmployee({_id:emp?._id, isSuitable: 0, isFromSearch:1,  emp})
        }
        
    };


    const dispatch = useDispatch();
  
    // Function to disable all dates except the two enabled ones
    const disabledDate = (current) => {
        const enabledDates = [dayjs(scheduleData?.firstDate), 
            dayjs(scheduleData?.firstDate).add('1','day'),
        ];
        return !enabledDates.some((date) => current.isSame(date, "day"));
    };

    const [newScheduleCheck, {loading:newScheduleCheckLoading}] = useMutation(newScheduleCheckMutation);
    const [updateScheduleTimes, {loading: updateScheduleTimesLoading}] = useMutation(updateScheduleTimesMutation);

    const handelScheduleOpertaionTime = async()=>{
        try{
            setIsAssigneeIntentToChange(false);
            const startDateTime = dayjs(operationalStartDateTime).format("DD/MM/YYYY HH:mm");
            const endDateTime = dayjs(operationalEndDateTime).format("DD/MM/YYYY HH:mm");
            const operationalTimeInput = {
                siteId: scheduleData?.site?._id,
                scheduleId: scheduleData?._id,
                operationalStartDate: dayjs(operationalStartDateTime, "DD/MM/YYYY HH:mm"),
                operationalStartTime:  startDateTime?.split(" ")[1],
                operationalEndDate: dayjs(operationalEndDateTime, "DD/MM/YYYY HH:mm"),
                operationalEndTime: endDateTime?.split(" ")[1],
                operationalDutyType: operationalDutyType,
                operationalHourType: operationalHourType,
                operationalAssignedEmployee: operationalAssignedEmployee?.emp?._id? operationalAssignedEmployee?.emp?._id : null,
                operationalAssignedAgency: operationalAssignedEmployee?.emp?._id? null : assignedAgency,
            };

            if(scheduleData?.scheduletimesDetail){


                await updateScheduleTimes({
                    variables:{
                        input: operationalTimeInput
                    }
                });

            }else{

                await newScheduleOperationTime({
                    variables:{
                        input: operationalTimeInput
                    }
                });

            }

            // set to unconfirm and assign employee
            await updateSchedule({
                variables:{
                    input:{
                        _id: scheduleId,
                        status: operationalAssignedEmployee?.emp?._id? "2" : assignedAgency? "3" : "0",
                        assignedEmployee: operationalAssignedEmployee?.emp?._id? operationalAssignedEmployee?.emp?._id : null,
                        assignedAgency: operationalAssignedEmployee?.emp?._id? null : assignedAgency,
                    }
                }
            }); 

            const failureIfAny = failureChecks && Object.keys(failureChecks)?.filter((check)=>failureChecks[check]===true)?.map((check)=> scheduleCheckFailureList[check]?.description);
            if(failureIfAny?.length>0 && operationalAssignedEmployee){
                await newScheduleCheck({
                    variables:{
                        input:{
                            scheduleId: scheduleId,
                            employeeId: operationalAssignedEmployee?.emp?._id,
                            check: failureIfAny,
                            date: dayjs(),
                        }
                    }
                });
            }    

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Operation time was updated successfully"
            }));

            const scheduleDate = scheduleData?.secondDate?
            dayjs(scheduleData?.secondDate)
            :
            dayjs(scheduleData?.date+" "+scheduleData?.shiftEndTime, "DD/MM/YYYY HH:mm");
            
            // operational start date time operation end date time 
            // if(dayjs(operationalStartDateTime).isSame(scheduleData?.firstDate) && operationalEndDateTime.isSame(scheduleDate) && scheduleData?.assignedEmployeeDetail?._id == scheduleData?.OperationalTimeDetail?._id){
                
            //     const response = calculateTimeDifference(operationalStartDateTime, operationalEndDateTime);   
            
            //     const isBillFirstDateInBankHoilday = holiday?.englandWales?.find((bh)=>(operationalStartDateTime).format("DD/MM/YYYY")==dayjs(bh?.date, "YYYY/MM/DD").format("DD/MM/YYYY")) ? true : false ;
            //     const isBillSecondDateInBankHoilday = holiday?.englandWales?.find((bh)=>(operationalEndDateTime).format("DD/MM/YYYY")==dayjs(bh?.date, "YYYY/MM/DD").format("DD/MM/YYYY")) ? true : false ;

            //     const isPayFirstDateInBankHoilday = holiday?.englandWales?.find((bh)=>(operationalStartDateTime).format("DD/MM/YYYY")==dayjs(bh?.date, "YYYY/MM/DD").format("DD/MM/YYYY")) ? true : false ;
            //     const isPaySecondDateInBankHoilday = holiday?.englandWales?.find((bh)=>(operationalEndDateTime).format("DD/MM/YYYY")==dayjs(bh?.date, "YYYY/MM/DD").format("DD/MM/YYYY")) ? true : false ;
            
            //     const payBillInput = {

            //         _id: scheduleId,
            //         payFirstDateHour: response?.firstDateHours?.toString(),
            //         isPayFirstDateInBankHoilday,
            //         paySecondDateHour: response?.secondDateHours?.toString(),
            //         isPaySecondDateInBankHoilday,
            //         payStartDateTime,
            //         payEndDateTime,
            //         payHourTypeId: operationalHourType,
            //         payDutyTypeId: operationalDutyType,

            //         billFirstDateHour: response?.firstDateHours.toString(),
            //         isBillFirstDateInBankHoilday,
            //         billSecondDateHour: response?.secondDateHours.toString(),
            //         isBillSecondDateInBankHoilday,
            //         billStartDateTime,
            //         billEndDateTime,
            //         billHourTypeId: operationalHourType,
            //         billDutyTypeId: operationalDutyType

            //     }

            //     await updatePayBill(payBillInput);

            // }else{
             
            //     const payBillInput = {

            //         _id: scheduleId,
            //         payFirstDateHour: null,
            //         isPayFirstDateInBankHoilday: null,
            //         paySecondDateHour: null,
            //         isPaySecondDateInBankHoilday: null,
            //         payStartDateTime: null,
            //         payEndDateTime: null,
            //         payHourTypeId: null,
            //         payDutyTypeId: null,

            //         billFirstDateHour: null,
            //         isBillFirstDateInBankHoilday: null,
            //         billSecondDateHour: null,
            //         isBillSecondDateInBankHoilday: null,
            //         billStartDateTime: null,
            //         billEndDateTime: null,
            //         billHourTypeId: null,
            //         billDutyTypeId: null

            //     }

            //     await updatePayBill(payBillInput);

            // }

            await scheduleJobRefetch();

        }catch(err){
            console.log(err, "errr")
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered while updating the operation time"
            }));
        }
    }

//============================================= 
    // employee search begin here
//=============================================
    const [searchPopup, setSearchPopup] = useState(false);
    const [getEmployeeOnSearch, {data: empSearchData, loading: empSearchDataLoading} ]= useLazyQuery(getEmployeeForScheduleSearchQuery);
    const [activeAssociatedModule, setActiveAssociateModule] = useState("employee");
    const [assignedEmployee, setAssignedEmployee] = useState();
    const [assignedAgency, setAssignedAgency] = useState(null);
    
    
    const [search, setSearch] = useState("");
    useEffect(()=>{
        if(activeAssociatedModule=="employee" && scheduleData && search?.length>0){
            
            const startDate = dayjs(scheduleData?.firstDate).subtract(8, 'hour')
            const endDate = dayjs(scheduleData?.firstDate).add(8, 'hour')
            
            getEmployeeOnSearch({fetchPolicy: 'cache-and-network',
                variables: {
                    input: {
                        filters:  {advanceFilter: [[{
                            filter: "contain_exactly",
                            filterValue: [search],
                            operator: 'First name'
                        }]]},
                        schedule: {startDate, endDate}
                    }
                },
                skip: !startDate || !endDate,
                fetchPolicy: 'cache-and-network'
            });
        }
    },[search, scheduleData, activeAssociatedModule]);

    const [scheduleStartDate, setScheduleStartDate] = useState(null);
    const [scheduleEndDate, setScheduleEndDate] = useState(null);

    useEffect(()=>{
        if(scheduleData?.firstDate){
            const startDate = dayjs(scheduleData?.firstDate).subtract(8, 'hour')
            const endDate = dayjs(scheduleData?.firstDate).add(8, 'hour')
            setScheduleStartDate(startDate);
            setScheduleEndDate(endDate);
        }
    },[scheduleData]);

    
    const searchRef = useRef(null);
    const searchSegmentRef= useRef(null);
    const searchDetailSegmentRef = useRef(null);

    const handleClickOutside = (event) => {
       
        if (searchSegmentRef?.current instanceof HTMLElement && !searchSegmentRef.current.contains(event.target) &&
            !Array.from(event.target.classList).find(cls => cls.includes('ant-select-item-option-content')) &&
            searchDetailSegmentRef?.current instanceof HTMLElement && !searchDetailSegmentRef.current.contains(event.target)
        )
        {
            setSearchPopup(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchSegmentRef, searchDetailSegmentRef]);

    const [assignAgencyLocally, setAssignAgencyLocally] = useState(null);
    
    // get employee based on agency
    const { data: agencyEmployee, loading: agencyEmployeeLoading } = useQuery(getEmployeeForScheduleSearchQuery,{
        variables: {
            input: {
                filters:  {advanceFilter: [[{
                    filter: "contain_exactly",
                    filterValue: [assignAgencyLocally],
                    operator: 'Agency'
                }]]},
                schedule: {startDate:scheduleStartDate, endDate:scheduleEndDate}
            }
        },
        fetchPolicy: 'network-only',
        skip: scheduleStartDate==null || scheduleEndDate==null || assignAgencyLocally?.length<1
    });
    
    
    const [EmployeeSearchResult, setEmpSearchResult] = useState([]);

    useEffect(()=>{
        if(empSearchData?.getEmployeeForScheduleSearch?.response){
            setEmpSearchResult(empSearchData?.getEmployeeForScheduleSearch?.response);
        }else{
            setEmpSearchResult([]);
        }
    },[empSearchData?.getEmployeeForScheduleSearch?.response]);
  

    const {data: agencyData, loading: agencyLoading} = useQuery(getAgencies,{
        fetchPolicy: 'network-only',
        variables: {
            input: {
                filters: null
            }
        },
    });

    // search assign emp duty has been terminated========================

    const [deleteSchedule, {loading:deleteScheduleLoading}] = useMutation(deleteScheduleByScheduleIdMutation)

    // pay and bill state management

    // pay state
    const [payStartDateTime, setPayStartDateTime] = useState(null);
    const [payEndDateTime, setPayEndDateTime] = useState(null);
    const [payDutyType, setPayDutyType] = useState();
    const [payHourType, setPayHourType] = useState();
    const [payEmployeeId, setPayEmployeeId] = useState();

    const [updatePay, setUpdatePay] = useState(false);
    const [updateBill, setUpdateBill] = useState(false);
    const [changePayBill, setChangePayBill] = useState(false);

    const [holiday, setHoliday] = useState();
    const getHolidays = async()=>{
        setHoliday( await getUkHolidays() );
    }
    
    useEffect(()=>{
        getHolidays();
    },[]);

    useEffect(()=>{
        if(payStartDateTime && payEndDateTime){

            const response = calculateTimeDifference(payStartDateTime, payEndDateTime);   
            
            const isPayFirstDateInBankHoilday = holiday?.englandWales?.find((bh)=>(payStartDateTime).format("DD/MM/YYYY")==dayjs(bh?.date, "YYYY/MM/DD").format("DD/MM/YYYY")) ? true : false ;
            const isPaySecondDateInBankHoilday = holiday?.englandWales?.find((bh)=>(payEndDateTime).format("DD/MM/YYYY")==dayjs(bh?.date, "YYYY/MM/DD").format("DD/MM/YYYY")) ? true : false ;
            
            const payInput = {
                _id: scheduleId,
                payFirstDateHour: response?.firstDateHours?.toString(),
                isPayFirstDateInBankHoilday,
                paySecondDateHour: response?.secondDateHours?.toString(),
                isPaySecondDateInBankHoilday,
                payStartDateTime,
                payEndDateTime,
                payHourTypeId: payHourType,
                payDutyTypeId: payDutyType
            }
            if(updatePay){
                updatePayBill(payInput);
            }
        }
    }, [payDutyType, payHourType, payStartDateTime, payEndDateTime, updatePay]);

    

    // bill state
    const [billStartDateTime, setBillStartDateTime] = useState(null);
    const [billEndDateTime, setBillEndDateTime] = useState(null);
    const [billDutyType, setBillDutyType] = useState(null);
    const [billHourType, setBillHourType] = useState(null);
    const [billCustomerId, setBillCustomerId] = useState(null);

    
    useEffect(()=>{
        if(billStartDateTime && billEndDateTime){

            const response = calculateTimeDifference(billStartDateTime, billEndDateTime);   
            
            const isBillFirstDateInBankHoilday = holiday?.englandWales?.find((bh)=>(billStartDateTime).format("DD/MM/YYYY")==dayjs(bh?.date, "YYYY/MM/DD").format("DD/MM/YYYY")) ? true : false ;
            const isBillSecondDateInBankHoilday = holiday?.englandWales?.find((bh)=>(billEndDateTime).format("DD/MM/YYYY")==dayjs(bh?.date, "YYYY/MM/DD").format("DD/MM/YYYY")) ? true : false ;
            
            const billInput = {
                _id: scheduleId,
                billFirstDateHour: response?.firstDateHours.toString(),
                isBillFirstDateInBankHoilday,
                billSecondDateHour: response?.secondDateHours.toString(),
                isBillSecondDateInBankHoilday,
                billStartDateTime,
                billEndDateTime,
                billHourTypeId: billHourType,
                billDutyTypeId: billDutyType
            }
            if(updateBill){
                updatePayBill(billInput);
            }
        }
    }, [billDutyType, billHourType, billStartDateTime, billEndDateTime, updateBill]);

    const updatePayBill = async(input)=>{
        await updateSchedule({
            variables:{
                input
            }
        }); 
        setUpdateBill(false);
        setUpdatePay(false);
        await scheduleJobRefetch();
    }

    const updatePayBillOnChange = async()=>{

        let billresponse;
        if(billStartDateTime && billEndDateTime){
            billresponse = calculateTimeDifference(billStartDateTime, billEndDateTime);   
        }
            
        const isBillFirstDateInBankHoilday = billStartDateTime? holiday?.englandWales?.find((bh)=>(billStartDateTime).format("DD/MM/YYYY")==dayjs(bh?.date, "YYYY/MM/DD").format("DD/MM/YYYY")) ? true : false : false;
        const isBillSecondDateInBankHoilday = billEndDateTime? holiday?.englandWales?.find((bh)=>(billEndDateTime).format("DD/MM/YYYY")==dayjs(bh?.date, "YYYY/MM/DD").format("DD/MM/YYYY")) ? true : false : false ;
            
            
        let payResponse;   
        if(payStartDateTime && payEndDateTime){
            payResponse = calculateTimeDifference(payStartDateTime, payEndDateTime);    
        }
        const isPayFirstDateInBankHoilday = payStartDateTime? holiday?.englandWales?.find((bh)=>(payStartDateTime).format("DD/MM/YYYY")==dayjs(bh?.date, "YYYY/MM/DD").format("DD/MM/YYYY")) ? true : false : false ;
        const isPaySecondDateInBankHoilday = payEndDateTime? holiday?.englandWales?.find((bh)=>(payEndDateTime).format("DD/MM/YYYY")==dayjs(bh?.date, "YYYY/MM/DD").format("DD/MM/YYYY")) ? true : false : false;
        
        const payBillInput = {
            _id: scheduleId,

            billFirstDateHour: billresponse?.firstDateHours.toString() || null,
            isBillFirstDateInBankHoilday,
            billSecondDateHour: billresponse?.secondDateHours.toString() || null,
            isBillSecondDateInBankHoilday,
            billStartDateTime,
            billEndDateTime,
            billHourTypeId: billHourType || null,
            billDutyTypeId: billDutyType || null,

            payFirstDateHour: payResponse?.firstDateHours?.toString() || null,
            isPayFirstDateInBankHoilday,
            paySecondDateHour: payResponse?.secondDateHours?.toString() || null,
            isPaySecondDateInBankHoilday,
            payStartDateTime,
            payEndDateTime,
            payHourTypeId: payHourType || null,
            payDutyTypeId: payDutyType || null,

        };

        await updatePayBill(payBillInput);


    }; 

    const handleDragStart = (event, rowId) => {
        // Set the dragged row's id in the dataTransfer object
        event.dataTransfer.setData('rowId', rowId);
        event.dataTransfer.effectAllowed = 'move';
    };


    // Function to handle drop event
    const handleDrop = (event, targetRowId) => {
        event.preventDefault();
        const draggedRowId = event.dataTransfer.getData('rowId');
        
        
        // Prevent cloning on the same row
        if (draggedRowId && draggedRowId !== targetRowId ) {
            // if scheduled drag over 
            if(draggedRowId==1){
                if(targetRowId==4){
                    // set pay state
                    setUpdatePay(true);

                    setPayStartDateTime( dayjs(scheduleData?.firstDate) );
                    setPayEndDateTime(
                        scheduleData?.secondDate? 
                        dayjs(scheduleData?.secondDate) : 
                        dayjs(scheduleData?.date+" "+scheduleData?.shiftEndTime, "DD/MM/YYYY HH:mm")
                    );
                    setPayDutyType(scheduleData?.dutyTypeId);
                    setPayHourType(scheduleData?.hourTypeId);
                    setPayEmployeeId(scheduleData?.assignedEmployee);
                }
                else{
                    // set bill state
                    setUpdateBill(true);
                    setBillStartDateTime( dayjs(scheduleData?.firstDate) );
                    setBillEndDateTime( 
                        
                        scheduleData?.secondDate? 
                        dayjs(scheduleData?.secondDate) : 
                        dayjs(scheduleData?.date+" "+scheduleData?.shiftEndTime, "DD/MM/YYYY HH:mm")

                     );
                    setBillDutyType(scheduleData?.dutyTypeId);
                    setBillHourType(scheduleData?.hourTypeId);
                    setBillCustomerId();
                }
            }
            // if opertional drag over 
            else if(draggedRowId==2){

                if(targetRowId==4){
                    setUpdatePay(true);

                    // set pay state
                    setPayStartDateTime(dayjs(operationalStartDateTime, "DD/MM/YYYY HH:mm"));
                    setPayEndDateTime(dayjs(operationalEndDateTime, "DD/MM/YYYY HH:mm"));
                    setPayDutyType(operationalDutyType);
                    setPayHourType(operationalHourType);
                    setPayEmployeeId(operationalAssignedEmployee);

                }
                else{
                    setUpdateBill(true);
                    // set bill state
                    setBillStartDateTime(dayjs(operationalStartDateTime, "DD/MM/YYYY HH:mm"));
                    setBillEndDateTime(dayjs(operationalEndDateTime, "DD/MM/YYYY HH:mm"));
                    setBillDutyType(operationalDutyType);
                    setBillHourType(operationalHourType);
                    setBillCustomerId();
                }

            }
            // if actual drag over on which emp clock-in clock-out
            else if(draggedRowId==3){

                if(targetRowId==4){
                    setUpdatePay(true);
                    // set pay state
                    setPayStartDateTime(dayjs(scheduleData?.scheduletimesDetail?.actualStartDate));
                    setPayEndDateTime(dayjs(scheduleData?.scheduletimesDetail?.actualEndDate));
                    setPayDutyType(operationalDutyType);
                    setPayHourType(operationalHourType);
                    setPayEmployeeId(operationalAssignedEmployee);

                }
                else{
                    setUpdateBill(true);
                    // set bill state
                    setBillStartDateTime(dayjs(scheduleData?.scheduletimesDetail?.actualStartDate));
                    setBillEndDateTime(dayjs(scheduleData?.scheduletimesDetail?.actualEndDate));
                    setBillDutyType(operationalDutyType);
                    setBillHourType(operationalHourType);
                    setBillCustomerId();
                }

            }

        }
    };

    // Function to allow dropping
    const handleDragOver = (event) => {
        event.preventDefault(); // Necessary to allow drop
    };

    const [warning, setWarningModal] = useState(false);

    
    const calculateTimeDifference = (time1, time2) => {
        // Define the start and end times
        const firstDate = dayjs(time1, 'DD/MM/YYYY HH:mm').second(0);
        const secondDate = dayjs(time2, 'DD/MM/YYYY HH:mm').second(0);

        if (firstDate.isSame(secondDate, 'day')) {
            // Case 1: Same day
            const firstDateHours = secondDate.diff(firstDate, 'hour', true);

            return {firstDateHours: firstDateHours, secondDateHours:0}

        } else {        
            // Case 2: Different days
            // 1. Calculate remaining hours in the first day (until midnight)
            const endOfFirstDay = firstDate.endOf('day') // Get the end of the first day (23:59:59)
            const hoursInFirstDay = endOfFirstDay.diff(firstDate, 'hour', true); // Convert diff to hours
          
            // 2. Calculate hours on the second day (from midnight to the second date)
            const startOfSecondDay = secondDate.startOf('day'); // Midnight of the second day
            const hoursInSecondDay = secondDate.diff(startOfSecondDay, 'hour', true); // Convert diff to hours
          
            // Display the results;
            return {firstDateHours: hoursInFirstDay, secondDateHours: hoursInSecondDay}
        }


    }

    useEffect(()=>{
        if(payStartDateTime!=null && payEndDateTime!=null){
            const {firstDateHours, secondDateHours} = calculateTimeDifference(payStartDateTime, payEndDateTime);
        }
    },[payStartDateTime, payEndDateTime]);

    useEffect(()=>{
        if(billStartDateTime!=null && billEndDateTime!=null){
            const {firstDateHours, secondDateHours} = calculateTimeDifference(billStartDateTime, billEndDateTime);
           
        }
    },[billStartDateTime, billEndDateTime]);


    const [payBillDisabled, setPayBillDisabled] = useState(true);
    useEffect(()=>{
        if(payStartDateTime || payEndDateTime || payDutyType || payHourType || billStartDateTime || 
            billEndDateTime || billDutyType || billHourType){
            setPayBillDisabled(false);
        }else{
            setPayBillDisabled(true);
        }
    },[payStartDateTime, payEndDateTime, payDutyType, payHourType, billStartDateTime, billEndDateTime, billDutyType, billHourType]);

    const [calculatePayBill, {loading:calculatePayBillLoading}] = useMutation(calculatePayBillMutation);

    const handelPayBill = async()=>{
        try{

            await calculatePayBill({
                variables: {
                    input: {
                        scheduleIds: [scheduleId]
                    }
                }
            });

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Pay & Bill was calculated successfully"
            }));
        }
        catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered while calculating pay/bill"
            }));
        }
            
    };

    const [isEmployeeAssigneeToChange, setIsAssigneeIntentToChange] = useState(false);
   


    return(
        
        <div className="box-shadow border-radius">

            <div style={{padding:'16px'}}>
                <div className='hr-info-title'>
                    Duty Times
                </div>
            </div>

            {calculatePayBillLoading || dutyTypeLoading || hourTypeDataLoading || newScheduleLoading || updateScheduleLoading || scheduledJobLoading?
                
                <div style={{display:'table', margin:'auto'}}>
                    <Spinner/>
                </div>
                :
                Object.keys(scheduleData)?.length>0?


                <table className="times" cellPadding={5}>
                    <thead>
                        <th>Type</th>
                        <th>Start</th>
                        <th>Finish</th>
                        <th>Duty Type</th>
                        <th>Hour Type</th>
                        <th>Employee</th>
                    </thead>

                    <tbody>
                        
                        <tr
                            key={1}
                            id={1}
                            draggable
                            draggedRowId={1}
                            onDragStart={(e)=>handleDragStart(e, 1)}
                            style={{cursor:'grab'}}
                        >
                            <td>Scheduled</td>
                            {/* start date and time */}
                            <td>
                                {dayjs(scheduleData?.firstDate).local().format("DD/MM/YYYY HH:mm")}
                            </td>

                            {/* finish date and time */}
                            <td>
                                {
                                    scheduleData?.secondDate?
                                    dayjs(scheduleData?.secondDate).local().format("DD/MM/YYYY HH:mm")
                                        :
                                    dayjs(scheduleData?.date+" "+scheduleData?.shiftEndTime, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm")
                                }
                            </td>

                            {/* Duty Type */}
                            <td>
                                {scheduleData?.dutyTypeDetail[0]?.name}
                            </td>

                            {/* Hour Type */}
                            <td>
                                {scheduleData?.hourTypeDetail[0]?.name}
                            </td>

                            {/* Select Employee */}
                            <td style={{display:'flex', columnGap:'5px', alignItems:'center'}}>
                                {
                                scheduleData?.assignedEmployeeDetail?
                                <>
                                    {
                                        scheduleData?.assignedEmployeeDetail?.metadata?.imageURL?
                                        <div className="employeeProfile">
                                            <Avatar size={40} src={scheduleData?.assignedEmployeeDetail?.metadata?.imageURL} />
                                        </div>

                                        :
                                        <div className="employeeProfile">
                                            <Avatar size={40} style={{fontSize:'10px'}}> <UserOutlined/> </Avatar>
                                        </div>
                                    
                                    }
                                
                                    {scheduleData?.assignedEmployeeDetail?.firstname+" "+(scheduleData?.assignedEmployeeDetail?.lastname || "")}
                            
                                </>
                                : "No employee assigned"
                                }
                            </td>
                            
                        </tr>

                        {
                            scheduleData?.scheduletimesDetail?.actualStartDate?
                            <tr
                                
                                key={2}
                                id={2}
                                draggable
                                draggedRowId={2}
                                onDragStart={(e)=>handleDragStart(e, 2)}
                                style={{cursor:'grab'}}
                            >
                                <td>Opertional</td>
                                {/* start date and time */}
                                <td>

                                    {/* <DatePicker
                                        className="generic-input-control"
                                        showTime
                                        showToday
                                        style={{marginTop:'-4px'}}
                                        format={"DD/MM/YYYY HH:mm"}
                                        suffixIcon={null}
                                        disabledDate={(currentDate)=> ![dayjs(operationalStartDateTime)].some((date) => currentDate.isSame(date, "day"))}
                                        value={dayjs(operationalStartDateTime).format("DD/MM/YYYY HH:mm")=="Invalid Date"? dayjs(operationalStartDateTime) : dayjs(operationalStartDateTime, "DD/MM/YYYY HH:mm")}
                                        onChange={(e, dateTimeString)=>  dateTimeString?.length>0 ? setOpertionalStartDateTime(dayjs(dateTimeString, "DD/MM/YYYY HH:mm")) : setOpertionalStartDateTime(null)}
                                    /> */}
                                    {dayjs(operationalStartDateTime).format("DD/MM/YYYY HH:mm")}
                                
                                </td>

                                {/* finish date and time */}
                                <td>

                                    {/* <DatePicker
                                        showTime
                                        showToday
                                        suffixIcon={null}
                                        disabledDate={disabledDate}
                                        format={"DD/MM/YYYY HH:mm"}
                                        className="generic-input-control"
                                        style={{marginTop:'-4px'}}
                                        value={dayjs(operationalEndDateTime).format("DD/MM/YYYY HH:mm")=="Invalid Date"? operationalEndDateTime : dayjs(operationalEndDateTime, "DD/MM/YYYY HH:mm")}
                                        onChange={(e, dateTimeString)=>  dateTimeString?.length>0 ? setOpertionalEndDateTime(dayjs(dateTimeString, "DD/MM/YYYY HH:mm")) : setOpertionalEndDateTime(null)}
                                    />
                                     */}

                                    {dayjs(operationalEndDateTime).format("DD/MM/YYYY HH:mm")}
                                </td>

                                {/* Duty Type */}
                                <td>
                                    {/* <Select
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Duty type"
                                        clearIcon
                                        className="custom-select schedule-section" 
                                        suffixIcon={<span className="dropdowncaret"></span>}
                                        style={{width:'100%'}}
                                        loading={dutyTypeLoading}
                                        value={dutyTypeData?.getPayLevel?.response?.find((dutyType)=>dutyType?._id==operationalDutyType)?.name}
                                        onChange={(e)=>setOperationalDutyType(e)}
                                    >
                                        {dutyTypeData?.getPayLevel?.response?.map((dutyTypeData)=>(
                                            <Select.Option value={dutyTypeData?._id}>{dutyTypeData?.name}</Select.Option>
                                        ))}
                                    </Select> */}
                                    {dutyTypeData?.getPayLevel?.response?.find((dutyType)=>dutyType?._id==operationalDutyType)?.name}
                                    
                                </td>

                                {/* Hour Type */}
                                <td>
                                           
                                    {/* <Select
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Hour type"
                                        className="custom-select schedule-section" 
                                        suffixIcon={<span className="dropdowncaret"></span>}
                                        style={{width:'-webkit-fill-available', cursor:'none'}}
                                        loading={hourTypeDataLoading}
                                        value={hourTypeData?.getShiftType?.response?.find((hourType)=>hourType?._id==operationalHourType)?.name}
                                        onChange={(e)=>setOperationalHourType(e)}
                                        status=""
                                    > */}
                                        {/* {hourTypeData?.getShiftType?.response?.map((hour)=>(
                                            <Option value={hour?._id}>{hour?.name}</Option>
                                        ))}
                                    </Select> */}
                                    {hourTypeData?.getShiftType?.response?.find((hourType)=>hourType?._id==operationalHourType)?.name}
                                    
                                </td>

                                {scheduleData?.scheduletimesDetail && isEmployeeAssigneeToChange==false &&
                                // {/* Select Employee */}
                                    <>
                                    {
                                        scheduleData?.OperationalTimeDetail?
                                            <td style={{display:'flex', columnGap:'5px', alignItems:'center'}}>
                                                
                                                {
                                                    scheduleData?.OperationalTimeDetail?.metadata?.imageURL?
                                                    <div className="employeeProfile">
                                                        <Avatar size={40} src={scheduleData?.OperationalTimeDetail?.metadata?.imageURL} />
                                                    </div>
                                                    :
                                                    <div className="employeeProfile">
                                                        <Avatar size={40} style={{fontSize:'10px'}}> <UserOutlined/> </Avatar>
                                                    </div>

                                                }
                                                <span>
                                                    {
                                                    scheduleData?.OperationalTimeDetail?.firstname 
                                                    } {scheduleData?.OperationalTimeDetail?.lastname || ""}
                                                </span>
                                            </td>
                                        :
                                        assignedAgency?
                                            <td style={{display:'flex', columnGap:'5px', alignItems:'center'}}>
                                                <div className="employeeProfile">
                                                    <Avatar size={40} style={{background:'lightgrey', fontSize:'12px', fontWeight:'400'}} >
                                                        <div style={{marginTop:'-5px'}}>A</div>
                                                    </Avatar>
                                                </div>
                                                <span>
                                                    
                                                    {agencyData?.agencies?.find((agency)=>agency?._id==assignedAgency)?.agencyname}
                                                    
                                                </span>
                                            </td>
                                        :
                                        <div style={{width:'100%'}}>
                                                    
                                                    {
                                                        operationalAssignedEmployee?
                                                        <Tag closable onClose={()=>{ setOperationalAssignedEmployee(); setAssignedAgency(null); setAssignAgencyLocally(null); }} style={{marginBottom:'6px', display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%', height:'38px'}}>
                                                            <div style={{display:'flex', columnGap:'10px', alignItems:'center'}}>
                                                                <div className="employeeProfile">
                                                                    <Avatar size={30} style={{background:'lightgrey'}} src={operationalAssignedEmployee?.emp?.metadata?.imageURL || <UserOutlined/>} />
                                                                </div>
                                                                <div style={{fontSize:'14px'}}>
                                                                    {operationalAssignedEmployee?.emp?.firstname} {operationalAssignedEmployee?.emp?.lastname || ""}
                                                                </div>
                                                            </div>
                                                        </Tag>
                                                        :

                                                        scheduleData?.assignedAgencyDetail?
                                                        
                                                        <Tag closable onClose={()=>{ setOperationalAssignedEmployee(); setAssignedAgency(null); setAssignAgencyLocally(null); }} style={{marginBottom:'6px', display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%', height:'38px'}}>
                                                            <div style={{display:'flex', columnGap:'10px', alignItems:'center'}}>
                                                            <div className="employeeProfile">
                                                                    <Avatar size={30} style={{background:'lightgrey', fontSize:'12px', fontWeight:'400'}} >
                                                                        <div style={{marginTop:'-5px'}}>A</div>
                                                                    </Avatar>
                                                                </div>
                                                                <div style={{fontSize:'14px'}}>
                                                                    {agencyData?.agencies?.find((agency)=>agency?._id==assignedAgency)?.agencyname}
                                                                </div>
                                                            </div>
                                                        </Tag>

                                                        :
                                                        
                                                        <div style={{width:'100%'}}>

                                                            <Popover
                                                                id="searchpopup"
                                                                placement="bottom"
                                                                overlayClassName='associationCustomPopover'
                                                                trigger={"click"}
                                                                content={
                                                                    <div className="association">
                                                                        <div className="association-sidebar" ref={searchSegmentRef}>
                                                                            
                                                                            <div className="popoverdataitem">
                                                                                Select
                                                                            </div>
                                                                            
                                                                            <Divider style={{marginTop:'0px', marginBottom:'10px'}}/>

                                                                            <div className={activeAssociatedModule=="employee"? "popoverdataitem activeTab" :"popoverdataitem"} onClick={()=>{ setSearch(""); setEmpSearchResult([]); setActiveAssociateModule("employee");}}>
                                                                                Employee
                                                                            </div>
                                                                            <div className={activeAssociatedModule=="agency"? "popoverdataitem activeTab" :"popoverdataitem"} onClick={()=>{ setSearch(""); setActiveAssociateModule("agency");}}>
                                                                                Agency Staff
                                                                            </div>                                    
                                                                        </div>

                                                                        <div className="association-body" ref={searchDetailSegmentRef}>
                                                                            {activeAssociatedModule=="employee" &&
                                                                                <Input 
                                                                                    type="search"
                                                                                    id="inputSearch"
                                                                                    name='popoverSearch'
                                                                                    style={{ width: '-webkit-fill-available', margin:'8px 8px 16px 8px' }} 
                                                                                    className='generic-input-control' 
                                                                                    placeholder={"Search "+activeAssociatedModule}
                                                                                    autoFocus={searchPopup || true}
                                                                                    autoComplete="off"
                                                                                    value={search}
                                                                                    onChange={(e)=>setSearch(e.target.value)}
                                                                                    suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                                                                                />
                                                                            }
                                                                            <div className="popover-data" style={{overflowY:'scroll', height:'170px', }}>
                                                                                
                                                                                {
                                                                                    activeAssociatedModule=="employee"?
                                                                                    empSearchDataLoading?
                                                                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                                                        <Spinner />
                                                                                    </div>
                                                                                    :
                                                                                    EmployeeSearchResult?.map((employee)=>{

                                                                                        const lastname = employee?.lastname? employee?.lastname : "";
                                                                                        const firstname = employee?.firstname? employee?.firstname : "";
                                                                            
                                                                                        return(
                                                                                            
                                                                                            <div className="popoverdataitem"
                                                                                                onClick={()=>{setSearchPopup(false); setFailureChecks(null); checkEmployeeIfSuitable(employee);}}
                                                                                            >
                                                                                                <div style={{display:'flex', columnGap:'12px'}}>
                                                                                                    <div className="employeeProfile">
                                                                                                        <Avatar  size={40} style={{background:'lightgrey'}} src={employee?.metadata?.imageURL || <UserOutlined/>} />
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <div>{firstname +" "+ lastname}</div>
                                                                                                        <div style={{color:'grey', fontWeight:300}}>{employee?.metadata?.employmenttype} {employee?.metadata?.agency[0]?.agencyname?  "["+ employee?.metadata?.agency[0]?.agencyname +"]" : null }</div>
                                                                                                    
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>                                                                        
                                                                                    )})
                                                                                    :
                                                                                    
                                                                                    <div className="addDutyAgencySelect">

                                                                                        <Form.Item>
                                                                                            <Select
                                                                                                allowClear
                                                                                                showSearch
                                                                                                optionFilterProp="children"
                                                                                                className="custom-select"
                                                                                                style={{width:'98%'}}
                                                                                                placeholder="Search agency"
                                                                                                suffixIcon={agencyLoading? <Spinner/> :<div className="dropdowncaret"></div>}
                                                                                                loading={agencyLoading} 
                                                                                                onChange={(e)=>{setAssignAgencyLocally(e); setAssignedEmployee(null);}}
                                                                                            >
                                                                                                {!agencyLoading && agencyData?.agencies?.length>0?
                                                                                                    agencyData?.agencies?.map((agency, index)=>{
                                                                                                        return(
                                                                                                            <Option key={index} value={agency?._id}>{agency?.agencyname}</Option>
                                                                                                        )
                                                                                                    })
                                                                                                    :
                                                                                                    <Option disabled>No agencies found</Option>
                                                                                                }
                                                                                            </Select>
                                                                                            {
                                                                                                assignAgencyLocally &&
                                                                                                <button className="drawer-filled-btn" onClick={()=>{setAssignedAgency(assignAgencyLocally);setSearchPopup(false);}} > Assign to agency</button>
                                                                                            }
                                                                                        </Form.Item>

                                                                                        
                                                                                        {
                                                                                            assignAgencyLocally &&
                                                                                            <Form.Item>
                                                                                                <Select
                                                                                                    showSearch
                                                                                                    optionFilterProp="children"
                                                                                                    className="custom-select"
                                                                                                    style={{width:'98%'}}
                                                                                                    placeholder="Select Employee"
                                                                                                    suffixIcon={agencyEmployeeLoading? <Spinner/> :<div className="dropdowncaret"></div>}
                                                                                                    loading={agencyEmployeeLoading}
                                                                                                    disabled={agencyEmployeeLoading}
                                                                                                    value={assignedEmployee && assignedEmployee?._id}
                                                                                                    onChange={(e)=>{setSearchPopup(false); setFailureChecks(null); checkEmployeeIfSuitable(agencyEmployee?.getEmployeeForScheduleSearch?.response?.find((emp)=>emp?._id==e));}}
                                                                                                >
                                                                                                    {
                                                                                                        agencyEmployee?.getEmployeeForScheduleSearch?.response?.map((employee)=>{
                                                                                                            return(
                                                                                                                <Option value={employee?._id}>{employee?.firstname} {employee?.lastname || ""}</Option>
                                                                                                            )
                                                                                                        })

                                                                                                    }

                                                                                                </Select>
                                                                                            </Form.Item>
                                                                                            
                                                                                        }


                                                                                    </div>
                                                                                    
                                                                                }
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                }
                                                                open={searchPopup}
                                                            >
                                                                <Input
                                                                    className="generic-input-control"
                                                                    readOnly={true}
                                                                    suffix={<span className="caret"></span>}
                                                                    style={{cursor:'pointer', fontWeight:'400', fontSize:'14px', color:'gray'}}
                                                                    value={assignedAgency? "1 Agency Allocated": assignedEmployee? "1 Employee Allocated" :" Employee / Agency Allocate"}
                                                                    onClick={()=>setSearchPopup(!searchPopup)}
                                                                    ref={searchRef}
                                                                />
                                                                
                                                            </Popover>


                                                        </div>
                                                    }
                                                    
                                        </div>
                                    }
                                    </>
                                }
                                
                            </tr>
                            :
                            <tr>
                                <td>Opertional</td>
                                
                                <td>
                                    <DatePicker
                                        className="generic-input-control"
                                        showTime
                                        showToday
                                        style={{marginTop:'-4px'}}
                                        format={"DD/MM/YYYY HH:mm"}
                                        suffixIcon={null}
                                        // disabledDate={(currentDate)=> ![dayjs(scheduleData?.firstDate)].some((date) => currentDate.isSame(date, "day"))}
                                        value={operationalStartDateTime}
                                        onChange={(e, dateTimeString)=>  dateTimeString?.length>0 ? setOpertionalStartDateTime(dayjs(dateTimeString, "DD/MM/YYYY HH:mm")) : setOpertionalStartDateTime(null)}
                                    />
                                </td>

                                <td>
                                    <DatePicker
                                        showTime
                                        showToday
                                        suffixIcon={null}
                                        // disabledDate={disabledDate}
                                        format={"DD/MM/YYYY HH:mm"}
                                        className="generic-input-control"
                                        style={{marginTop:'-4px'}}
                                        value={operationalEndDateTime}
                                        onChange={(e, dateTimeString)=>  dateTimeString?.length>0 ? setOpertionalEndDateTime(dayjs(dateTimeString, "DD/MM/YYYY HH:mm")) : setOpertionalEndDateTime(null)}
                                    />
                                </td>

                                <td>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Duty type"
                                        clearIcon
                                        className="custom-select schedule-section" 
                                        suffixIcon={<span className="dropdowncaret"></span>}
                                        style={{width:'100%', marginTop:0}}
                                        loading={dutyTypeLoading}
                                        value={operationalDutyType}
                                        onChange={(e)=>setOperationalDutyType(e)}
                                    >
                                        {dutyTypeData?.getPayLevel?.response?.map((dutyTypeData)=>(
                                            <Select.Option value={dutyTypeData?._id}>{dutyTypeData?.name}</Select.Option>
                                        ))}
                                    </Select>
                                </td>

                                <td>          
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Hour type"
                                        className="custom-select schedule-section" 
                                        suffixIcon={<span className="dropdowncaret"></span>}
                                        style={{width:'-webkit-fill-available', cursor:'none', marginTop:0}}
                                        loading={hourTypeDataLoading}
                                        value={operationalHourType}
                                        onChange={(e)=>setOperationalHourType(e)}
                                    >
                                        {hourTypeData?.getShiftType?.response?.map((hour)=>(
                                            <Option value={hour?._id}>{hour?.name}</Option>
                                        ))}
                                    </Select>
                                </td>

                                <td>

                                    <div style={{width:'100%'}}>
                                            
                                            {
                                                operationalAssignedEmployee?
                                
                                                <Tag closable onClose={()=>{ setOperationalAssignedEmployee(); setAssignedAgency(null); setAssignAgencyLocally(null); }} style={{marginBottom:'6px', display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%', height:'38px'}}>
                                                    <div style={{display:'flex', columnGap:'10px', alignItems:'center'}}>
                                                        <div className="employeeProfile">
                                                            <Avatar size={30} style={{background:'lightgrey'}} src={operationalAssignedEmployee?.emp?.metadata?.imageURL || <UserOutlined/>} />
                                                        </div>
                                                        <div style={{fontSize:'14px'}}>
                                                            {operationalAssignedEmployee?.emp?.firstname} {operationalAssignedEmployee?.emp?.lastname || ""}
                                                            
                                                        </div>
                                                    </div>
                                                </Tag>
                                                :

                                                assignedAgency?
                                                
                                                <Tag closable onClose={()=>{ setOperationalAssignedEmployee(); setAssignedAgency(null); setAssignAgencyLocally(null); }} style={{marginBottom:'6px', display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%', height:'38px'}}>
                                                    <div style={{display:'flex', columnGap:'10px', alignItems:'center'}}>
                                                    <div className="employeeProfile">
                                                            <Avatar size={30} style={{background:'lightgrey', fontSize:'12px', fontWeight:'400'}} >
                                                                <div style={{marginTop:'-5px'}}>A</div>
                                                            </Avatar>
                                                        </div>
                                                        <div style={{fontSize:'14px'}}>
                                                            {agencyData?.agencies?.find((agency)=>agency?._id==assignedAgency)?.agencyname}
                                                        </div>
                                                    </div>
                                                </Tag>

                                                :
                                                
                                                <div style={{width:'100%'}}>

                                                    <Popover
                                                        id="searchpopup"
                                                        placement="bottom"
                                                        overlayClassName='associationCustomPopover'
                                                        trigger={"click"}
                                                        content={
                                                            <div className="association">
                                                                <div className="association-sidebar" ref={searchSegmentRef}>
                                                                    
                                                                    <div className="popoverdataitem">
                                                                        Select
                                                                    </div>
                                                                    
                                                                    <Divider style={{marginTop:'0px', marginBottom:'10px'}}/>

                                                                    <div className={activeAssociatedModule=="employee"? "popoverdataitem activeTab" :"popoverdataitem"} onClick={()=>{ setSearch(""); setEmpSearchResult([]); setActiveAssociateModule("employee");}}>
                                                                        Employee
                                                                    </div>
                                                                    <div className={activeAssociatedModule=="agency"? "popoverdataitem activeTab" :"popoverdataitem"} onClick={()=>{ setSearch(""); setActiveAssociateModule("agency");}}>
                                                                        Agency Staff
                                                                    </div>                                    
                                                                </div>

                                                                <div className="association-body" ref={searchDetailSegmentRef}>
                                                                    {activeAssociatedModule=="employee" &&
                                                                        <Input 
                                                                            type="search"
                                                                            id="inputSearch"
                                                                            name='popoverSearch'
                                                                            style={{ width: '-webkit-fill-available', margin:'8px 8px 16px 8px' }} 
                                                                            className='generic-input-control' 
                                                                            placeholder={"Search "+activeAssociatedModule}
                                                                            autoFocus={searchPopup || true}
                                                                            autoComplete="off"
                                                                            value={search}
                                                                            onChange={(e)=>setSearch(e.target.value)}
                                                                            suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                                                                        />
                                                                    }
                                                                    <div className="popover-data" style={{overflowY:'scroll', height:'170px', }}>
                                                                        
                                                                        {
                                                                            activeAssociatedModule=="employee"?
                                                                            empSearchDataLoading?
                                                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                                                <Spinner />
                                                                            </div>
                                                                            :
                                                                            EmployeeSearchResult?.map((employee)=>{

                                                                                const lastname = employee?.lastname? employee?.lastname : "";
                                                                                const firstname = employee?.firstname? employee?.firstname : "";
                                                                    
                                                                                return(
                                                                                    
                                                                                    <div className="popoverdataitem"
                                                                                        onClick={()=>{setSearchPopup(false); setFailureChecks(null); checkEmployeeIfSuitable(employee);}}
                                                                                    >
                                                                                        <div style={{display:'flex', columnGap:'12px'}}>
                                                                                            <div className="employeeProfile">
                                                                                                <Avatar  size={40} style={{background:'lightgrey'}} src={employee?.metadata?.imageURL || <UserOutlined/>} />
                                                                                            </div>
                                                                                            <div>
                                                                                                <div>{firstname +" "+ lastname}</div>
                                                                                                <div style={{color:'grey', fontWeight:300}}>{employee?.metadata?.employmenttype} {employee?.metadata?.agency[0]?.agencyname?  "["+ employee?.metadata?.agency[0]?.agencyname +"]" : null }</div>
                                                                                            
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>                                                                        
                                                                            )})
                                                                            :
                                                                            
                                                                            <div className="addDutyAgencySelect">

                                                                                <Form.Item>
                                                                                    <Select
                                                                                        allowClear
                                                                                        showSearch
                                                                                        optionFilterProp="children"
                                                                                        className="custom-select"
                                                                                        style={{width:'98%'}}
                                                                                        placeholder="Search agency"
                                                                                        suffixIcon={agencyLoading? <Spinner/> :<div className="dropdowncaret"></div>}
                                                                                        loading={agencyLoading} 
                                                                                        onChange={(e)=>{setAssignAgencyLocally(e); setAssignedEmployee(null);}}
                                                                                    >
                                                                                        {!agencyLoading && agencyData?.agencies?.length>0?
                                                                                            agencyData?.agencies?.map((agency, index)=>{
                                                                                                return(
                                                                                                    <Option key={index} value={agency?._id}>{agency?.agencyname}</Option>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            <Option disabled>No agencies found</Option>
                                                                                        }
                                                                                    </Select>
                                                                                    {
                                                                                        assignAgencyLocally &&
                                                                                        <button className="drawer-filled-btn" onClick={()=>{setAssignedAgency(assignAgencyLocally); setOperationalAssignedEmployee(null); setSearchPopup(false);}} > Assign to agency</button>
                                                                                    }
                                                                                </Form.Item>

                                                                                
                                                                                {
                                                                                    assignAgencyLocally &&
                                                                                    <Form.Item>
                                                                                        <Select
                                                                                            showSearch
                                                                                            optionFilterProp="children"
                                                                                            className="custom-select"
                                                                                            style={{width:'98%'}}
                                                                                            placeholder="Select Employee"
                                                                                            suffixIcon={agencyEmployeeLoading? <Spinner/> :<div className="dropdowncaret"></div>}
                                                                                            loading={agencyEmployeeLoading}
                                                                                            disabled={agencyEmployeeLoading}
                                                                                            value={assignedEmployee && assignedEmployee?._id}
                                                                                            onChange={(e)=>{setSearchPopup(false); setFailureChecks(null); checkEmployeeIfSuitable(agencyEmployee?.getEmployeeForScheduleSearch?.response?.find((emp)=>emp?._id==e));}}
                                                                                        >
                                                                                            {
                                                                                                agencyEmployee?.getEmployeeForScheduleSearch?.response?.map((employee)=>{
                                                                                                    return(
                                                                                                        <Option value={employee?._id}>{employee?.firstname} {employee?.lastname || ""}</Option>
                                                                                                    )
                                                                                                })

                                                                                            }

                                                                                        </Select>
                                                                                    </Form.Item>
                                                                                    
                                                                                }


                                                                            </div>
                                                                            
                                                                        }
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        }
                                                        open={searchPopup}
                                                    >
                                                        <Input
                                                            className="generic-input-control"
                                                            readOnly={true}
                                                            suffix={<span className="caret"></span>}
                                                            style={{cursor:'pointer', fontWeight:'400', fontSize:'14px', color:'gray'}}
                                                            value={assignedAgency? "1 Agency Allocated": assignedEmployee? "1 Employee Allocated" :" Employee / Agency Allocate"}
                                                            onClick={()=>setSearchPopup(!searchPopup)}
                                                            ref={searchRef}
                                                        />
                                                        
                                                    </Popover>


                                                </div>
                                            }
                                            
                                    </div>

                                </td>

                            </tr>
                        }

                        <tr  
                            
                            key={3}
                            id={3}
                            draggable={scheduleData?.scheduletimesDetail?.actualEndDate? true: false}
                            draggedRowId={3}
                            onDragStart={(e)=>handleDragStart(e, 3)}
                            style={{cursor:'grab'}}

                        >

                            <td>Actual</td>

                            <td> {scheduleData?.scheduletimesDetail?.actualStartDate? dayjs(scheduleData?.scheduletimesDetail?.actualStartDate).local().format("DD/MM/YYYY HH:mm") : "--"} </td>

                            <td> {scheduleData?.scheduletimesDetail?.actualEndDate? dayjs(scheduleData?.scheduletimesDetail?.actualEndDate).local().format("DD/MM/YYYY HH:mm") : "--"} </td>

                            <td> 
                                {scheduleData?.scheduletimesDetail?.actualStartDate? dutyTypeData?.getPayLevel?.response?.find((dutyType)=>dutyType?._id==operationalDutyType)?.name : "--"}
                            </td>

                            <td>                                     
                                {scheduleData?.scheduletimesDetail?.actualStartDate? hourTypeData?.getShiftType?.response?.find((hourType)=>hourType?._id==operationalHourType)?.name : "--"}   
                            </td>

                            <td> -- </td>

                        </tr>

                        <tr style={{ margin: '0 0 35% 0', display: 'block'}}></tr>

                        {/* pay and bill section */}
                        

                        <tr
                            
                            key={4}
                            id={4}
                            onDrop={(e)=>handleDrop(e, 4)}
                            onDragOver={scheduleData?.scheduletimesDetail?.actualEndDate? handleDragOver : ()=>setWarningModal(true)}
                        >

                            <td>Pay</td>

                            <td> 
                                
                                <DatePicker
                                    className="generic-input-control"
                                    showTime
                                    showToday
                                    style={{marginTop:'-4px'}}
                                    format={"DD/MM/YYYY HH:mm"}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    disabledDate={(currentDate)=> ![dayjs(scheduleData?.firstDate)].some((date) => currentDate.isSame(date, "day"))}
                                    value={payStartDateTime? payStartDateTime : null}
                                    suffixIcon={null}
                                    onChange={(e, dateTimeString)=> { dateTimeString?.length>0 ? setPayStartDateTime(dayjs(dateTimeString, "DD/MM/YYYY HH:mm")) : setPayStartDateTime(null); setChangePayBill(true);}}
                                />

                            </td>

                            <td> 
                                
                                <DatePicker
                                    className="generic-input-control"
                                    showTime
                                    showToday
                                    style={{marginTop:'-4px'}}
                                    format={"DD/MM/YYYY HH:mm"}
                                    suffixIcon={null}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    disabledDate={disabledDate}
                                    value={payEndDateTime}
                                    onChange={(e, dateTimeString)=> { dateTimeString?.length>0 ? setPayEndDateTime(dayjs(dateTimeString, "DD/MM/YYYY HH:mm")) : setPayEndDateTime(null); setChangePayBill(true); }}
                                />
                                    
                            </td>

                            <td> 
                                
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    className="custom-select schedule-section" 
                                    suffixIcon={<span className="dropdowncaret"></span>}
                                    style={{width:'100%', marginTop:'-4px'}}
                                    loading={dutyTypeLoading}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    onChange={(e)=>{setPayDutyType(e);setChangePayBill(true);}}
                                    value={payDutyType}
                                >
                                    {dutyTypeData?.getPayLevel?.response?.map((dutyTypeData)=>(
                                        <Select.Option value={dutyTypeData?._id}>{dutyTypeData?.name}</Select.Option>
                                    ))}
                                </Select>

                            </td>
                            
                            <td> 
                                      
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    className="custom-select schedule-section" 
                                    suffixIcon={<span className="dropdowncaret"></span>}
                                    style={{width:'-webkit-fill-available', cursor:'none', marginTop:'-4px'}}
                                    loading={hourTypeDataLoading}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    onChange={(e)=>{setPayHourType(e);setChangePayBill(true);}}
                                    value={payHourType}
                                >
                                    {hourTypeData?.getShiftType?.response?.map((hour)=>(
                                        <Option value={hour?._id}>{hour?.name}</Option>
                                    ))}
                                </Select>
                            </td>
                            
                            <td style={{display:'flex', columnGap:'5px', alignItems:'center'}}>
                                {
                                    scheduleData?.OperationalTimeDetail?
                                    <>
                                    {
                                        scheduleData?.OperationalTimeDetail?.metadata?.imageURL?
                                        <div className="employeeProfile">
                                            <Avatar size={40} src={scheduleData?.OperationalTimeDetail?.metadata?.imageURL} />
                                        </div>
                                        :
                                        <div className="employeeProfile">
                                            <Avatar size={40} style={{fontSize:'10px'}}> <UserOutlined/> </Avatar>
                                        </div>
                                    }
                                        <span>
                                            {
                                                scheduleData?.OperationalTimeDetail?.firstname+" "+(scheduleData?.OperationalTimeDetail?.lastname || "")
                                            }
                                        </span>
                                    </>
                                    :null
                                }
                            </td>


                        </tr>

                        

                        <tr
                            
                            key={5}
                            id={5}
                            onDrop={(e)=>handleDrop(e, 5)}                            
                            onDragOver={scheduleData?.scheduletimesDetail?.actualEndDate? handleDragOver : ()=>setWarningModal(true)}

                        >

                            <td>Bill</td>
                            
                            <td> 
                                
                                <DatePicker
                                    className="generic-input-control"
                                    showTime
                                    showToday
                                    // style={{marginTop:'-4px'}}
                                    suffixIcon={null}
                                    format={"DD/MM/YYYY HH:mm"}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    disabledDate={(currentDate)=> ![dayjs(scheduleData?.firstDate)].some((date) => currentDate.isSame(date, "day"))}
                                    value={billStartDateTime}
                                    onChange={ (e, dateTimeString)=>{ dateTimeString?.length>0 ? setBillStartDateTime(dayjs(dateTimeString, "DD/MM/YYYY HH:mm")) : setBillStartDateTime(null); setChangePayBill(true); }}
                                />

                            </td>

                            <td> 
                                
                                <DatePicker
                                    className="generic-input-control"
                                    showTime
                                    showToday
                                    // style={{marginTop:'-4px'}}
                                    format={"DD/MM/YYYY HH:mm"}
                                    suffixIcon={null}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    value={billEndDateTime}
                                    disabledDate={disabledDate}
                                    onChange={(e, dateTimeString)=>{ dateTimeString?.length>0 ? setBillEndDateTime(dayjs(dateTimeString, "DD/MM/YYYY HH:mm")) : setBillEndDateTime(null); setChangePayBill(true); }}
                                />
                                    
                            </td>

                            <td> 
                                
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    className="custom-select schedule-section" 
                                    suffixIcon={<span className="dropdowncaret"></span>}
                                    style={{width:'100%'}}
                                    loading={dutyTypeLoading}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    onChange={(e)=>{setBillDutyType(e); setChangePayBill(true);}}
                                    value={billDutyType}
                                >
                                    {dutyTypeData?.getPayLevel?.response?.map((dutyTypeData)=>(
                                        <Select.Option value={dutyTypeData?._id}>{dutyTypeData?.name}</Select.Option>
                                    ))}
                                </Select>

                            </td>
                            
                            <td> 
                                      
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    className="custom-select schedule-section" 
                                    suffixIcon={<span className="dropdowncaret"></span>}
                                    style={{width:'-webkit-fill-available', cursor:'none'}}
                                    loading={hourTypeDataLoading}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    onChange={(e)=>{setBillHourType(e); setChangePayBill(true);}}
                                    value={billHourType}
                                >
                                    {hourTypeData?.getShiftType?.response?.map((hour)=>(
                                        <Option value={hour?._id}>{hour?.name}</Option>
                                    ))}
                                </Select>
                            </td>
                            
                            <td style={{display:'flex', columnGap:'5px', alignItems:'center'}}>
                                
                            </td>


                        </tr>


                    </tbody>
                </table>

                :

                "Duty data is not currently available"
            
            
            }


{/* times footer */}
            <div 
                className="drawer-footer border-radius-bottom"
                style={{display:'flex', alignItems:'center', justifyContent:'space-between', margin:'-10px', marginTop:'100px'}}
            >
                <div>
                {scheduleData?.status=="3" || updateScheduleFieldLoading?
                    <button className="drawer-outlined-btn disabled-btn">Duty {scheduleData?.status=="3"? "Completed" : "Actions"}</button>

                :
                    <Popover
                        overlayClassName='notePopover'
                        content={
                            <div className='popover-data'>

                            {
                                scheduleData?.isPublished?
                                <div className="popoverdataitem"
                                    onClick={async()=>{
                                        await updateScheduleField({
                                            variables:{
                                                input:{
                                                    field: "isPublished",
                                                    value: false,
                                                    _id: scheduleData?._id,
                                                }
                                            }
                                        });
                                        await scheduleJobRefetch();
                                }}>
                                    Unpublish Duty
                                </div>
                                :
                                <div className="popoverdataitem"
                                    onClick={async()=>{
                                        await updateScheduleField({
                                            variables:{
                                                input:{
                                                    field: "isPublished",
                                                    value: true,
                                                    _id: scheduleData?._id,
                                                }
                                            }
                                        });
                                        await scheduleJobRefetch();
                                    }}
                                >
                                    Publish Duty
                                </div>

                            }

                            {
                                // set status to 1 for confirmed status
                                // if it is 1 then status will be confirm
                                scheduleData?.status=="1"?
                                    <div className="popoverdataitem"
                                    onClick={async()=>{
                                        await updateScheduleField({
                                            variables:{
                                                input:{
                                                    field: "status",
                                                    value: "2",
                                                    _id: scheduleData?._id,
                                                }
                                            }
                                        });
                                        await scheduleJobRefetch();
                                    }}>
                                        Unconfirm Duty
                                    </div>
                                :
                                // set status to 1 for confirmed status
                                // if it is 1 then status will be confirm                  
                                    <div className="popoverdataitem"
                                        onClick={async()=>{
                                            await updateScheduleField({
                                                variables:{
                                                    input:{
                                                        field: "status",
                                                        value: "1",
                                                        _id: scheduleData?._id,
                                                    }
                                                }
                                            });
                                            await scheduleJobRefetch();
                                        }}
                                    >
                                        Confirm Duty
                                    </div>
                            }

                            {
                                authenticatedUserDetail?._id=="658bb38d91b98562b83d2249" &&
                                <div className="popoverdataitem"
                                    onClick={async()=>{
                                        await deleteSchedule({
                                            variables: {
                                                scheduleId: scheduleData?._id
                                            }
                                        });
                                        await scheduleJobRefetch();
                                        close();
                                    }}
                                >
                                    Delete Duty
                                </div>
                            }

                            </div>
                        }
                    >

                        <button  
                            // disabled={updateScheduleFieldLoading || scheduleData?.status=="3"} 
                            className={'drawer-outlined-btn'}
                        >
                            {
                                updateScheduleFieldLoading || scheduleData?.status=="3"?
                                <>
                                    Duty Actions  <Spinner/>
                                </> 
                                :
                                <>
                                 Duty Actions <CaretDownFilled />
                                </>
                            }
                        </button>

                    </Popover>
                }
                    &emsp;

                    

                </div>

                {
                    scheduleData?.scheduletimesDetail?.actualStartDate?
                    <div className="row-flex">
                        <button disabled={payBillDisabled || calculatePayBillLoading} className={payBillDisabled || calculatePayBillLoading?"drawer-filled-btn disabled-btn": "drawer-filled-btn"} onClick={handelPayBill}>
                            {calculatePayBillLoading? <Spinner/> : "Pay / Bill"}
                        </button>
                        {changePayBill ? 
                        <button className="drawer-outlined-btn" style={{marginLeft:'15px'}} onClick={updatePayBillOnChange}>Update</button>
                        : null} 
                    </div>
                    :
                    <button disabled={false}  className={false? 'disabled-btn drawer-filled-btn':'drawer-filled-btn'} 
                        onClick={handelScheduleOpertaionTime}
                    >
                        {false? <Spinner/> : "Save"}
                    </button>

                }                    

            </div>

            <CheckFailureModal visible={checkFailVisible} save={setOperationalAssignedEmployee} failureChecks={failureChecks} close={()=>setCheckFailVisible(!checkFailVisible)} />
            <WarningModal visible={warning} close={()=>setWarningModal(false)}/>
        </div>
    );
};

const WarningModal = ({close, visible})=>{
    return(
        <Modal
            visible={visible}
            footer={null}
            closable={false}
        >

        <div className='modal-header-title'>
            <span style={{letterSpacing:'0.2px'}}> Warning </span>
            <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
        </div>

            <div className='modal-body'>
                <Alert type="error" message={"Duty is not completed yet"} showIcon/>
            </div>

        </Modal>
    );
}