import { useParams } from "react-router-dom";
import { Table, Tabs, Tag } from "antd";
import { useDispatch } from "react-redux";
import TabPane from "antd/es/tabs/TabPane";
import { useEffect, useState } from "react";
import Spinner from "@src/components/spinner";
import { SiteCredentialDrawer } from "./siteCredential.drawer";
import { useMutation, useQuery } from "@apollo/client";
import { getBannedSiteByField } from "@src/util/query/blockedSites.query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck, faUserTimes } from "@fortawesome/free-solid-svg-icons";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { getTrainedEmployeeByFieldQuery } from "@src/util/query/trainedEmployee.query";
import { unBannedSiteForEmployeeMutation } from "@src/util/mutation/blockedSites.mutation";
import { removeTrainedEmployeeMutation } from "@src/util/mutation/trainedEmployee.mutation";





export const SiteCredential = ()=>{

    const param = useParams();
    const [hoveredRow, setHoveredRow] = useState(null);
    const [unBannedSiteForEmployee, {loading: newBlockedSiteLoading}] = useMutation(unBannedSiteForEmployeeMutation)
    const [dataSource, setDataSource] = useState([]);

    const dispatch  = useDispatch();

    
    const {data:bannedEmployeeData, loading: bannedEmployeeLoading, refetch: refetchBannedEmployee} = useQuery(getBannedSiteByField,{
        variables:{
            field:'employeeId',
            value: param?.id
        },
        skip:!param?.id,
        fetchPolicy:'network-only'
    });
    
    const {data:trainedEmployeeData, loading: trainedEmployeeLoading, refetch: refetchTrainedEmployee} = useQuery(getTrainedEmployeeByFieldQuery,{
        variables:{
            field:'employeeId',
            value: param?.id
        },
        skip:!param?.id,
        fetchPolicy:'network-only'
    });


    const handelUnblock = async(id)=>{
        try{
            await unBannedSiteForEmployee({
                variables: {
                    input: {id: [id] }
                }
            })
            dispatch(setNotification({
                notificationState: true,
                error: false,
                message: "Site was Unblocked successfully"
                
            }));
            await refetchBannedEmployee();

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An Error occured"
            }));
        }
    }


    const [removeTrainedEmployee, {loading: removeTrainedEmployeeLoading}] = useMutation(removeTrainedEmployeeMutation);

    const handelremoveTrainedEmployee = async(id=null)=>{
        try{
            await removeTrainedEmployee({
                variables:{
                   input:{ id: [id]}
                }
            });

            await refetchTrainedEmployee();

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Trained employee was removed from this site successfully"
            }));

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }
    }

    const columns = [
        {id:1, key: 1, title: 'Site', width: '80%', dataIndex:'sitename',
            render:(_, record)=>{
                
                const showActions = hoveredRow === record.key;
                return(
                   
                    <div style={{display:'flex', columnGap:'20px'}}>
                        <div>{record?.sitename}</div>
                       {<button className="sm-btn" onClick={()=>{
                            if(activeTab==0){
                                handelremoveTrainedEmployee(record?.key);
                            }else if(activeTab==1){
                                handelUnblock(record?.key);
                            }
                        
                        }} style={showActions?{visibility:'visible'}:{visibility:'hidden'}}>
                            {
                                activeTab==0?
                                "Remove"
                                :
                                "Unblock"
                            }
                        </button>}
                    </div>
                )
            }
    
        },
        {id:2, key: 2, title: 'Status', dataIndex:'status'}
    ];

    const handleRowMouseEnter = (record) => {
        setHoveredRow(record.key);
    };


    const handleRowMouseLeave = () => {
        setHoveredRow(null);
    };



    const [blockSiteModel, setBlockSiteModel] = useState(false);
    const [trainSiteModel, setTrainSiteModel] = useState(false);

    const rowClassName = (record) => {
        return record.key === hoveredRow ? 'hovered-row' : '';
    };
    
    const [activeTab, setActiveTab] = useState(0);

    useEffect(()=>{
        if(activeTab==0 && trainedEmployeeData?.getTrainedEmployeeByField?.response){
            refetchTrainedEmployee();
            if(trainedEmployeeLoading==false){
                if(trainedEmployeeData?.getTrainedEmployeeByField?.response?.length>0){
                    setDataSource(trainedEmployeeData?.getTrainedEmployeeByField?.response?.map((trainedSite, index)=>{
                        // console.log(trainedSite?._id, "trainedSite?._id")
                        return (
                            {
                                id: trainedSite?._id,
                                key: trainedSite?._id,
                                sitename: trainedSite?.siteDetail[0]?.sitename,
                                status: "Trained",
                                siteId: trainedSite?.siteDetail[0]?._id
                            }
                        )
                    }));
                }else{
                    setDataSource([]);
                }
            }
        }else if(activeTab==1 && bannedEmployeeData?.getBannedSite?.response){
            refetchBannedEmployee();
            if(bannedEmployeeLoading==false){
                if(bannedEmployeeData?.getBannedSite?.response?.length>0){
                    setDataSource(bannedEmployeeData?.getBannedSite?.response?.map((blockedSite, index)=>{
                        return (
                            {
                                id: blockedSite?._id,
                                key: blockedSite?._id,
                                sitename: blockedSite?.siteDetail[0]?.sitename,
                                status: "Banned",
                                siteId: blockedSite?.siteDetail[0]?._id
                            }
                        )
                    }));
                }else{
                    setDataSource([]);
                }
            }
        }
    },[activeTab, trainedEmployeeData?.getTrainedEmployeeByField?.response, bannedEmployeeData?.getBannedSite?.response, trainedEmployeeLoading, bannedEmployeeLoading]);



    return (
        <div className="hr-section">
            {/* <Spinner />      */}
            <div className="hr-info">
                <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div>Site Credential</div>
                    {/* <button className='middle-note-btn' onClick={()=>setBlockSiteModel(!blockSiteModel)}>Add Ban site</button> */}
                </div>
                
                <div className="setting-body-inner " style={{margin:0, padding:0, marginTop:'16px'}}>
                    <Tabs defaultActiveKey={0} className="grid-tabs" onChange={(e)=>setActiveTab(e)}>
                        <TabPane key={0} tab={"Trained on Sites"}>

                            <button className="btn drawer-outlined-btn" style={{float:'right', marginBottom:'16px'}} onClick={()=>setTrainSiteModel(true)}>
                                <FontAwesomeIcon icon={faUserCheck}/> &nbsp;
                                Set Training Sites
                            </button>
                            <Table
                                className="curvedTable"
                                columns={columns}
                                loading={removeTrainedEmployeeLoading || trainedEmployeeLoading}
                                dataSource={dataSource}                
                                onRow={(record) => ({
                                    onMouseEnter: () => handleRowMouseEnter(record),
                                    onMouseLeave: () => handleRowMouseLeave(),
                                })}
                                rowClassName={rowClassName}
                                locale={{ emptyText: 'No data available' }}
                            />
                        </TabPane>
                        <TabPane key={1} tab={"Banned on Sites"}>

                            <button onClick={()=>setBlockSiteModel(!blockSiteModel)} className="btn drawer-outlined-btn"style={{float:'right', marginBottom:'16px'}}>
                                <FontAwesomeIcon icon={faUserTimes}/> &nbsp;
                                Set Banned Sites
                            </button>

                            <Table
                                className="curvedTable"
                                columns={columns}
                                dataSource={dataSource}    
                                loading={bannedEmployeeLoading || newBlockedSiteLoading}            
                                onRow={(record) => ({
                                    onMouseEnter: () => handleRowMouseEnter(record),
                                    onMouseLeave: () => handleRowMouseLeave(),
                                })}
                                rowClassName={rowClassName}
                                locale={{ emptyText: 'No data available' }}
                            />
                        </TabPane>
                    </Tabs>


                </div>
                {
                    trainSiteModel && <SiteCredentialDrawer  refetch={refetchTrainedEmployee} dataSource={dataSource} onClose={()=>setTrainSiteModel(false)} visible={trainSiteModel} trainedEmployee={true} />
                }
                {
                    blockSiteModel && <SiteCredentialDrawer refetch={refetchBannedEmployee} dataSource={dataSource} onClose={()=>setBlockSiteModel(false)} visible={blockSiteModel} trainedEmployee={false}  />
                }
            </div>
        </div>
    
    )
}