import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ApartmentOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk, faClose, faCodeBranch, faDeleteLeft, faEdit, faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Popover } from "antd";
import { addFieldToBranchSchema, removeFieldFromBranchSchema, resetAndReorderBranchSchema, resetbranchSchemaNewFields, setPropertyToBeRemoveFromSchema } from "../../middleware/redux/reducers/branch.reducer";
import { useDispatch } from "react-redux";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};



const DraggableList = ({list, editColumn, handelAddBranches, updateUserBranchView, reorderSchema, objectRefetch}) => {
  

  const [items, setItems] = useState(list)
  useEffect(()=>{
    setItems(list?.filter((l)=>l.isLocalDeleted!=1));
  }, [list]);

  useEffect(()=>{
    
    // we are calling this function to update the prop order in redux as well 
    sessionStorage.setItem('reorderedItems',
      JSON.stringify(items)
    );
  }, [items]);

  
  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    

    setItems(reorder(
      items,
      result.source.index,
      result.destination.index
    ));

    
    if(updateUserBranchView){
      
      await updateUserBranchView(reorder(
        items,
        result.source.index,
        result.destination.index
      )?.map((prop)=>prop._id));
    }else{
      if(reorderSchema){
        await reorderSchema({
          variables:{
            input:{fields: reorder(
              items,
              result.source.index,
              result.destination.index
            ).map((item)=>({propertyId: item._id}))}
          }
        });
      }
      if(objectRefetch){
        await objectRefetch();
      }
    }

    
  }


  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  const dispatch = useDispatch();
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items?.map((item, index) => (
                item?.isLocalDeleted ? null :
                <Draggable key={item._id} draggableId={item._id} index={index}
                 
                >
                  {(provided, snapshot) => (
                    <div className="icon-wrapper">
                      {editColumn? 
                        null :
                        <div className="delete-icon">
                          {/* <Popover 
                            overlayClassName="custom-popover"
                            content={"This Property is a part of object schema"} 
                            placement='top'
                          > */}
              
                            <FontAwesomeIcon onClick={async()=>{
                              await dispatch(setPropertyToBeRemoveFromSchema(item._id));
                              }} className="active" icon={faTrashAlt} />
                          {/* </Popover> */}
                          
                          {/* <Popover 
                            overlayClassName="custom-popover"
                            content={"Conditional logic is not available for non-enumerated properties."} 
                            placement='top'
                          > */}
                          <FontAwesomeIcon icon={faCodeBranch}/>
                          {/* </Popover> */}

                          
                          {/* <Popover 
                          overlayClassName="custom-popover"
                          content={"Change the label of this property"} 
                          placement='top'
                          > */}
                            <FontAwesomeIcon 
                              className={item?.isMandatory? "mandatory" : "active"} 
                              icon={faAsterisk} 
                              onClick={()=>dispatch(addFieldToBranchSchema({_id:item._id, isMandatory: item?.isMandatory ? false : true}))} 
                            />
                          {/* </Popover> */}

                        </div>
                      }
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        id={"item-"+index}
                        itemRef={"item-"+index}
                        key={item._id}
                        className="edit-form-input-control input inputItemList"
                        style={editColumn? {
                          display:'flex', 
                          justifyContent:'space-between',
                          direction:'ltr',
                          alignItems:'center',
                          opacity: snapshot.isDragging ? 0.5 : 1,
                          ...provided.draggableProps.style,
                        }
                        :{
                          opacity: snapshot.isDragging ? 0.5 : 1,
                          ...provided.draggableProps.style
                        }}
                      >
                        <span className="draggeableTable-text" >{item.label}</span>
                        {editColumn?
                        <FontAwesomeIcon
                          icon={faClose} 
                          style={{cursor:'pointer'}}
                          onClick={handelAddBranches? ()=>handelAddBranches(item._id) : async()=>{
                            await dispatch(setPropertyToBeRemoveFromSchema(item._id));
                          }}
                        />  
                        :null}
                      </div>
                    </div>

                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      
    );
}

export default DraggableList;