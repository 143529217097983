import "./loadRota.css";
import dayjs from "dayjs";
import { Checkbox, Form, Modal, Select } from "antd"
import Spinner from "@src/components/spinner";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { getRotaLatestBySiteId } from "@src/util/query/rota.query";
import { useMutation, useQuery } from "@apollo/client";
import { getRotaDutiesCountBySiteId } from "@src/util/query/rota.query";
import { upsertRotaLoadMutation } from "@src/util/mutation/rotaLoad.mutation";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { useDispatch } from "react-redux";
import { getLoadedRotaBySiteId } from "@src/util/query/rota.query";
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);


export const LoadRota = ({visible, close, siteDetail})=>{

    const [rotaFrom, setRotaFrom] = useState(null);
    const [rotaTo, setRotaTo] = useState(null);
    const [allocateEmployee, setAllocateEmployee] = useState(false);
    const [isDutiesConfirmed, setDutiesConfirmed] = useState(false);

    const [isDisabled, setIsDisabled] = useState(true);
    useEffect(()=>{
        if(rotaFrom && rotaTo){
            setIsDisabled(false);
        }else{
            setIsDisabled(true);
        }
    }, [rotaFrom, rotaTo]);

    const [fromDates, setFromDates] = useState([]);
    const [toDates, settoDates] = useState([]);

    const {data, loading: rotaLoading} = useQuery(getRotaLatestBySiteId,{
        variables:{
            siteId: siteDetail?._id
        },
        skip: !siteDetail?._id,
        fetchPolicy: 'network-only'
    });

    const generateFutureDatesWithIntervals = (startDate, intervalYears, intervalWeeks) => {
        
        // Generate the first array with weekly intervals
        const fromFutureDates = [];
        const toFutureDates = [];
        const endDate = dayjs(startDate).add(intervalYears, 'year');
        let currentDate = dayjs(startDate);
        
        while (currentDate.isBefore(endDate)) {
            fromFutureDates.push(currentDate.format('DD/MM/YYYY'));
            currentDate = currentDate.add(1, 'week'); // 1-week interval for the first array
        }
    
        // Generate the second array with custom intervals
        fromFutureDates?.map((date)=>{

                const futureDate = dayjs(date,'DD/MM/YYYY').add(intervalWeeks, 'week').subtract(1, 'day').format('DD/MM/YYYY');
                
                toFutureDates.push(futureDate);

        });


        return {
            fromFutureDates,
            toFutureDates,
        };

    };

    const {data: lastLoadedRotaData, loading: lastLoadedRotaLoading} = useQuery(getLoadedRotaBySiteId,{
        variables:{
            siteId: siteDetail?._id
        },
        skip: !siteDetail?._id,
        fetchPolicy:'network-only'
    })
    // console.log(lastLoadedRotaData?.getLoadedRota?.response, "lastLoadedRotaDatalastLoadedRotaData");

    useEffect(()=>{
        if(siteDetail?.contractstartdate && data?.getRotaLatestBySiteId?.response?.week && !rotaLoading && !lastLoadedRotaLoading){
            
            if(lastLoadedRotaData?.getLoadedRota?.response && Object.keys(lastLoadedRotaData?.getLoadedRota?.response)?.length>0){
                const startWeekOfContract = dayjs(lastLoadedRotaData?.getLoadedRota?.response?.rotaFrom).startOf('week');
                
                const dates = generateFutureDatesWithIntervals(startWeekOfContract, 3, data?.getRotaLatestBySiteId?.response?.week)
                
                setFromDates(dates?.fromFutureDates);
                settoDates([]);
            }else{

                const startWeekOfContract = 
                data?.getRotaLatestBySiteId?.response?.startDate?
                dayjs(data?.getRotaLatestBySiteId?.response?.startDate).startOf('week')
                    :
                dayjs(siteDetail?.contractstartdate, 'YYYY-MM-DD').startOf('week');
                const dates = generateFutureDatesWithIntervals(startWeekOfContract, 3, data?.getRotaLatestBySiteId?.response?.week)
                
                setFromDates(dates?.fromFutureDates);
                settoDates(dates?.toFutureDates);
            }


        }
    },[siteDetail, data?.getRotaLatestBySiteId?.response?.week, rotaLoading, lastLoadedRotaData?.getLoadedRota?.response, lastLoadedRotaLoading]);


    useEffect(()=>{
        if(rotaFrom){

            const startToDate = (dayjs(rotaFrom, "DD/MM/YYYY").add(data?.getRotaLatestBySiteId?.response?.week, 'week').subtract(1, 'day'));
            const endDate = dayjs(startToDate).add(3, 'year');
            const toDate = [];
            let currentDate = dayjs(startToDate);
            while (currentDate.isBefore(endDate)) {
                toDate.push(currentDate.format('DD/MM/YYYY'));
                currentDate = currentDate.add(data?.getRotaLatestBySiteId?.response?.week, 'week'); // 1-week interval for the first array
            };
            settoDates(toDate);


        }
    },[rotaFrom]);

    const {data: numberOfDuties} = useQuery(getRotaDutiesCountBySiteId,{
        variables:{
            siteId: siteDetail?._id
        },
        fetchPolicy: 'network-only'
    });


    const [upsertRotaLoad, {loading: upsertRotaloadLoading}] = useMutation(upsertRotaLoadMutation);
    const dispatch = useDispatch();

    const handelUpsertRotaLoad = async ()=>{
        try{

            const rotaLoadInput = {
                rotaFrom: rotaFrom,
                rotaTo: rotaTo,
                allocateEmployee,
                isDutiesConfirmed,
                siteId: siteDetail?._id
            };

            await upsertRotaLoad({
                variables:{
                    input: rotaLoadInput
                }
            });

            close();
            
            dispatch(
                setNotification({
                    error: false,
                    notificationState: true,
                    message: "Rota is being loading"
                })
            );

        }catch(err){
            dispatch(
                setNotification({
                    error: true,
                    notificationState: true,
                    message: "An error occured while loading this rota"
                })
            );
        }
    }


    return(

        <Modal
        
            open={visible}
            width={'30%'}
            style={{top:10, height:'100vh', overflow:'auto'}}

            footer={
                <div className="drawer-footer" style={{padding:'26px 40px', textAlign:'left', display:'flex', columnGap:'16px', marginTop:'-25px' }}>
                    <button  
                        disabled={isDisabled || upsertRotaloadLoading}
                        className={isDisabled || upsertRotaloadLoading? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
                        onClick={handelUpsertRotaLoad}
                    >
                        {upsertRotaloadLoading? <Spinner /> : "Load Rota"}
                    </button>
                    <button  disabled={upsertRotaloadLoading} className={upsertRotaloadLoading? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={close}>
                        Cancel
                    </button>
                </div>
            }
            closable={false}
        
        >
            <React.Fragment>

                <div className='modal-header-title'>
                    <span>
                        Rota Load
                        
                    </span>
                    <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                </div>

                <div className='modal-body'>

                    <div className="mb16">
                        Total Duties : <b>{numberOfDuties?.dutiesSiteRota?.response?.duties}</b>
                    </div>

                    <Form className="rotaDateSelectionField">

                        <Form.Item>
                            <label>
                                Rota Load From Date
                            </label>
                            <Select
                                allowClear
                                showSearch
                                className="custom-select"
                                placeholder="From Date"
                                suffixIcon={<div className="dropdowncaret"></div>}
                                onChange={(e)=>setRotaFrom(e)}
                            >

                                {
                                    fromDates?.map((date)=>(
                                        <Option value={date}>{date}</Option>
                                    ))
                                }

                            </Select>
                        </Form.Item>


                        <Form.Item>
                            <label>
                                Rota Load To Date
                            </label>
                            <Select
                                allowClear
                                showSearch
                                className="custom-select"
                                placeholder="To Date"
                                suffixIcon={<div className="dropdowncaret"></div>}
                                onChange={(e)=>setRotaTo(e)}
                            >
                                {
                                    toDates?.map((date)=>(
                                        <Option value={date}>{date}</Option>
                                    ))
                                }

                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Checkbox onChange={(e)=>setAllocateEmployee(e.target.checked)}>Allocate employee</Checkbox>
                        </Form.Item>
                        
                        {
                            allocateEmployee &&
                            <Form.Item>
                                <Checkbox onChange={(e)=>setDutiesConfirmed(e.target.checked)}>Confirm duties</Checkbox>
                            </Form.Item>
                        }


                    </Form>

                </div>

            </React.Fragment>
            
        </Modal>

    );
}