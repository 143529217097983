import React, { useEffect, useState } from "react";
import { Form, Modal, Select } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import Spinner from "@src/components/spinner";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { rotaUnloadMutation } from "@src/util/mutation/rotaLoad.mutation";
import { getLoadedRotaBySiteId } from "@src/util/query/rota.query";
import { getRotaLatestBySiteId } from "@src/util/query/rota.query";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";


import dayjs from "dayjs";
import { useDispatch } from "react-redux";


export const UnLoadRota = ({visible, close, siteDetail })=>{

    const [rotaUnload, {loading: rotaUnloadLoding}] = useMutation(rotaUnloadMutation);


    const [rotaFrom, setRotaFrom] = useState(null);
    const [rotaTo, setRotaTo] = useState(null);

    const [fromDates, setFromDates] = useState([]);
    const [toDates, settoDates] = useState([]);

    const {data: lastLoadedRotaData, loading: lastLoadedRotaLoading} = useQuery(getLoadedRotaBySiteId,{
        variables:{
            siteId: siteDetail?._id
        },
        skip: !siteDetail?._id,
        fetchPolicy:'network-only'
    });


    const {data:rotaOverView, loading: rotaLoading} = useQuery(getRotaLatestBySiteId,{
        variables:{
            siteId: siteDetail?._id
        },
        skip: !siteDetail?._id,
        fetchPolicy: 'network-only'
    });



    const generateFutureDatesWithIntervals = (startDate, endDate) => {
        console.log(startDate, endDate, "asd das")
        // Generate the first array with weekly intervals
        const fromFutureDates = [];
        let currentDate = startDate;
        
        while (currentDate.isBefore(endDate)) {
            fromFutureDates.push(currentDate.subtract(1, 'day').format('DD/MM/YYYY'));
            currentDate = currentDate.add(1, 'week'); // 1-week interval for the first array
        }

        return fromFutureDates;

    };

    const [isDisabled, setIsDisabled] = useState(true);


    useEffect(()=>{
        if(lastLoadedRotaData?.getLoadedRota?.response && Object.keys(lastLoadedRotaData?.getLoadedRota?.response)?.length>0){
            setFromDates([dayjs(lastLoadedRotaData?.getLoadedRota?.response?.rotaTo).format("DD/MM/YYYY")]);
            
        }else{

        }
        if(!rotaLoading && rotaOverView?.getRotaLatestBySiteId?.response?.startDate){
            const toDate = generateFutureDatesWithIntervals(
                dayjs(lastLoadedRotaData?.getLoadedRota?.response?.rotaFrom).startOf('week'),
                dayjs(lastLoadedRotaData?.getLoadedRota?.response?.rotaTo)
            );

            settoDates(toDate.reverse());
        }else{

        }
    },[lastLoadedRotaData?.getLoadedRota?.response, rotaLoading, rotaOverView?.getRotaLatestBySiteId?.response]);

    useEffect(()=>{
        if(rotaFrom && rotaTo){
            setIsDisabled(false);
        }else{  
            setIsDisabled(true);
        }
    }, [rotaFrom, rotaTo]);

    const dispatch = useDispatch();


    const handelRotaUnload = async () =>{
        try{

            await rotaUnload({
                variables:{
                    toDate: rotaTo,
                    fromDate: rotaFrom,
                    siteId: siteDetail?._id,
                }
            });

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Rota is being unloaded"
            }));

            close();

        }catch(err){

            dispatch(setNotification({
                error: true,
                message: "An error occured while unloading rota",
                notificationState: true,
            }));

        }
    };

    return(

        <Modal
        
            open={visible}
            width={'30%'}
            style={{top:10, height:'100vh', overflow:'auto'}}

            footer={
                <div className="drawer-footer" style={{padding:'26px 40px', textAlign:'left', display:'flex', columnGap:'16px', marginTop:'-25px' }}>
                    <button  
                        disabled={isDisabled || rotaUnloadLoding}
                        className={isDisabled || rotaUnloadLoding? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
                        onClick={handelRotaUnload}
                    >
                        {rotaUnloadLoding? <Spinner /> : "unLoad Rota"}
                    </button>
                    <button  
                        disabled={isDisabled || rotaUnloadLoding} 
                        className={isDisabled || rotaUnloadLoding? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={close}
                    >
                        Cancel
                    </button>
                </div>
            }
            closable={false}
        
        >
            <React.Fragment>

                <div className='modal-header-title'>
                    <span>
                        Rota Unload
                        
                    </span>
                    <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                </div>

                <div className='modal-body'>

                    <Form className="rotaDateSelectionField">
                        <Form.Item>

                            <label>
                                Rota unLoad From Date
                            </label>
                            <Select
                                allowClear
                                showSearch
                                className="custom-select"
                                placeholder="From Date"
                                suffixIcon={<div className="dropdowncaret"></div>}
                                onChange={(e)=>setRotaFrom(e)}
                            >

                                {
                                    fromDates?.map((date)=>(
                                        <Select.Option value={date}>
                                            {date}
                                        </Select.Option>
                                    ))
                                }


                            </Select>
                        </Form.Item>



                        <Form.Item>
                            <label>
                                Rota unLoad To Date
                            </label>
                            <Select
                                allowClear
                                showSearch
                                className="custom-select"
                                placeholder="To Date"
                                suffixIcon={<div className="dropdowncaret"></div>}
                                onChange={(e)=>setRotaTo(e)}
                            >
                                {
                                    toDates?.map((date)=>(
                                        <Option value={date}>{date}</Option>
                                    ))
                                }

                            </Select>
                        </Form.Item>


                    </Form>
                    

                </div>

            </React.Fragment>
            
        </Modal>

    );
}