import "./addRota.css";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import { LoadingOutlined, UserOutlined } from "@ant-design/icons"
import { faChevronDown, faChevronUp, faClockRotateLeft, faClose, faEllipsisVertical, faLayerGroup, faPersonBooth, faPersonBurst, faPersonDigging, faSearch, faStreetView, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Checkbox, Form, Input, InputNumber, Modal, Popover, Select, Avatar, Spin, Tabs, TimePicker, Dropdown, Menu, Switch, Tag } from "antd";
import React, { useEffect, useState, useRef } from "react"
import { shiftTypeQuery } from "@src/util/query/shiftType.query";
import { getPayLevelQuery } from "@src/util/query/paylevel.query";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import Spinner from "@src/components/spinner";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { getReportTemplates } from '@src/util/query/reportTemplate.query';
import { CheckFailureModal } from "../../checkFail.model";
import { getEmployeeForScheduleSearchQuery } from "@src/util/query/employee.query";
import { newRoteDetailMutation } from "@src/util/mutation/rotaDetail.mutation";
import { updateRotaDutyMutation } from "@src/util/mutation/rotaDetail.mutation";



export const AddRota = ({
    rotaModal:{visible=true, title, site, area, week, rotaId, job, weekNumber},
    close, rotaRefetch, siteDetail})=>{

    console.log(weekNumber, "weekweek");
    
    dayjs.extend(utc);
    dayjs.extend(duration);

    const {Option} = Select;
    const {data: dutyTypeData, loading: dutyTypeLoading, refetchDutyType} = useQuery(getPayLevelQuery,{
        fetchPolicy: 'network-only',
    });
    const {data: hourTypeData, loading: hourTypeDataLoading, refetch: refetchHourType} = useQuery(shiftTypeQuery);

    const [dutyType, setDutyType] = useState("");
    const [alarmType, setAlarmType] = useState(null);
    const [hourType, setHourType] = useState("");
    
    const [shiftStartTime, setShiftStartTime] = useState("");
    const [shiftEndTime, setShiftEndTime] = useState("");

    const [unPaidBreakHour, setUnPaidBreakHour] = useState("");
    const [unPaidBreakMintue, setUnPaidBreakMintue] = useState("");

    const [unPaidBillHour, setUnPaidBillHour] = useState("");
    const [unPaidBillMintue, setUnPaidBillMintue] = useState("");

    const [bookingRef, setBookingRef] = useState("");
    const [supplierRef, setSupplierRef] = useState("");

    // assigned employee
    const [assignedEmployee, setAssignedEmployee] = useState();
    const [assignedAgency, setAssignedAgency] = useState();

    
    const [newRotaDetail, {loading: newRotaDetailLoading, error}] = useMutation(newRoteDetailMutation);
    const [employeeSlider, setEmployeeSlider] = useState(false);

   
    const [shiftTime, setShiftTime] = useState("");
    const calculateTimeDifference = (time1, time2, date) => {
        // Define the start and end times
        const startTime = dayjs(date?.format("DD/MM/YYYY").toString() + " " + time1, 'DD/MM/YYYY HH:mm');
        let endTime = dayjs(date?.format("DD/MM/YYYY").toString() + " " + time2, 'DD/MM/YYYY HH:mm');
    
        // Handle case where endTime is before startTime (next day)
        if (endTime.isBefore(startTime)) {
            endTime = endTime.add(1, 'day');  // Adjust if end time is on the next day
        }
    
        // Calculate the difference in hours and minutes between startTime and endTime
        const diffInMilliseconds = endTime.diff(startTime);
        const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
        const hours = Math.floor(diffInHours);
        const minutes = Math.round((diffInHours - hours) * 60);
    
        // Check if the time period crosses midnight
        const midnight = date.endOf('day');
        const firstHourRaw = endTime.isAfter(midnight) 
            ? midnight.diff(startTime, 'minute') / 60 
            : diffInHours;  // If no cross to next day, use total time
    
        // Adjust firstHour calculation
        const firstHour = firstHourRaw < 0 ? 0 : firstHourRaw;  // Make sure it's not negative
    
        // Prepare the response based on whether it crosses midnight
        const res = {
            firstDate: date?.format("DD/MM/YYYY"),
            secondDate: endTime.isAfter(midnight) ? endTime.format("DD/MM/YYYY") : null,  // If time crosses midnight
            firstDateHours: firstHour.toFixed(2),  // Hours before midnight (or total hours if no cross)
            secondDateHours: endTime.isAfter(midnight) ? (diffInHours - firstHour).toFixed(2) : null,  // Time after midnight, if applicable
            totalHours: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0'),
            firstHourRaw
        }
    
        return res;
    }

    useEffect(()=>{
        if(shiftStartTime?.length>0 && shiftEndTime?.length>0){
            const timeCalculation = calculateTimeDifference(shiftStartTime, shiftEndTime, title);
            setShiftTime(timeCalculation);
        }
    },[shiftStartTime, shiftEndTime, title]);

    
    useEffect(()=>{
        if(dutyType?.length>0 && hourType?.length>0 && shiftStartTime?.length>0 && shiftEndTime?.length>0){
            setEmployeeSlider(true);
        }else{
            setEmployeeSlider(false);
        }
    }, [dutyType, hourType, shiftStartTime, shiftEndTime]);


    const [search, setSearch] = useState("");

    const handelSearch = (keyword)=>{
        setSearch(keyword);
    };
    

    const dispatch = useDispatch();
    const [reportTemplateId, setReportTemplateId] = useState("")
    
    const [updateRotaDuty, {loading: updateRotaDutyLoading}] = useMutation(updateRotaDutyMutation);

    const handelSubmit = async ()=>{
        try{

            const rotaInput = days?.map((day)=>{
                return{
                    _id: job?._id || null,
                    rotaId,
                    branchId: siteDetail?.branchDetail?._id,
                    customerId: siteDetail?.customerDetail?._id,
                    siteGroupId: siteDetail?.sitegroupDetail?._id,
                    siteId: site?._id,
                    siteAreaId: area?._id,
                    dutyTypeId: dutyType, 
                    alarmType:  alarmType,
                    hourTypeId: hourType, 
                    shiftStartTime: shiftStartTime,
                    shiftEndTime: shiftEndTime,
                    unPaidBreakDuration: unPaidBreakHour+":"+unPaidBreakMintue,
                    unBillBreakDuration: unPaidBillHour+":"+unPaidBillMintue,
                    bookingRef, supplierRef,
                    assignedEmployee: assignedEmployee?._id, assignedAgency,
                    status: assignedEmployee? assignedEmployee?._id? "2": "2" : "0",
                    // get day number for example
                    // 0 refers to sunday
                    day: day.day(),
                    week: weekNumber+1,
                    reporting: reporting,
                    reportTemplateId: reporting? reportTemplateId : null
    
                };  
            });
            
            if(job && Object.keys(job)?.length>0){
                await updateRotaDuty({
                    variables:{
                        input: rotaInput[0]
                    }
                });
                close();


            }else{

                try{
                    await newRotaDetail({
                        variables:{
                            input: rotaInput
                        }
                    });
                    close();

                }
                catch(err){
                    dispatch(setNotification({
                        error: true,
                        notificationState: true,
                        message: "An employee cannot be assigned to the same day more than once."
                    }))
                }

            }

            
            await rotaRefetch();
            
        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }))
            close();
            await rotaRefetch();

        }
    };


    const [searchOption, setSearchOption] = useState(false);
    const searchRef = useRef(null);
    
    
    const handleVisibleChange = (flag) => {
        setSearchOption(flag);
    };

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setSearchOption(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [reporting, setReporting] = useState(false);

    const {data: standardTemplateData, loading: standardTemplateLoading} = useQuery(getReportTemplates,{
        variables: {
            customerId: null,
            isArchived: false
        },
        fetchPolicy: 'network-only'
    });

    const {data: customerTemplateData, loading: customerTemplateLoading} = useQuery(getReportTemplates,{
        variables: {
            customerId: siteDetail?.customerDetail?._id,
            isArchived: false
        },
        fetchPolicy: 'network-only'
    });

    const [standardTemplates, setStandardTemplates] = useState([]);
    const [customerTemplates, setCustomerTemplates] = useState([]);

    useEffect(()=>{
        if(standardTemplateData?.getReportTemplates?.response?.length){
            setStandardTemplates(standardTemplateData?.getReportTemplates?.response);
        }
        if(customerTemplateData?.getReportTemplates?.response?.length){
            setCustomerTemplates(customerTemplateData?.getReportTemplates?.response);
        }
    },[standardTemplateData?.getReportTemplates?.response, customerTemplateData?.getReportTemplates?.response]);


    // get employee on search to assign and 
    const [getEmployeeOnSearch, {data: empSearchData, loading: empSearchDataLoading, refetch} ]= useLazyQuery(getEmployeeForScheduleSearchQuery);

    
    const [searchResult, setSearchResult] = useState([]);
    
    useEffect(()=>{
        if(empSearchData?.getEmployeeForScheduleSearch?.response){
            setSearchResult(empSearchData?.getEmployeeForScheduleSearch?.response);
        }
    },[empSearchData?.getEmployeeForScheduleSearch?.response]);
    
    useEffect(()=>{
        if(search?.length>0 && shiftStartTime && shiftEndTime){

            const date = title?.format("DD/MM/YYYY");
            const startDate = dayjs(date+" "+shiftStartTime, "DD/MM/YYYY HH:mm").subtract(8, 'hour');
            const endDate = dayjs(date+" "+shiftStartTime, "DD/MM/YYYY HH:mm").add(8, 'hour');

            getEmployeeOnSearch({fetchPolicy: 'cache-and-network',
                variables: {
                    input: {
                        filters:  {advanceFilter: [[{
                            filter: "contain_exactly",
                            filterValue: [search],
                            operator: 'First name'
                        }]]},
                        schedule: {startDate, endDate}
                    }
                },
                fetchPolicy: 'cache-and-network'
            });

        }
    
        
    },[search, shiftStartTime, shiftEndTime]);


    // set width of search result
    const parentRef = useRef(null);

    const [parentWidth, setParentWidth] = useState(null);
    useEffect(() => {
        const updateParentWidth = () => {
          if (parentRef.current) {
            const width = parentRef?.current?.input?.clientWidth;
            setParentWidth(width);
          }
        };
    
        // Call the update function on initial mount and window resize
        updateParentWidth();
        window.addEventListener('resize', updateParentWidth);
    
        // Clean up the event listener on unmount
        return () => {
          window.removeEventListener('resize', updateParentWidth);
        };
    
    }, [visible, empSearchDataLoading, search]);




    const [days, setDays] = useState([title]);


    const handelDays = (isChecked, wday)=>{
        setDays(
            !isChecked? 
            days?.filter((day)=>day?.format("DD/MM/YYYY") !== wday?.format("DD/MM/YYYY"))
            :
            [...days, wday]
        )
    }

    useEffect(()=>{
        if(job && Object.keys(job)?.length>0){
            setDutyType(job?.dutyTypeId);
            setHourType(job?.hourTypeId);
            setShiftStartTime(job?.shiftStartTime);
            setShiftEndTime(job?.shiftEndTime);
            setReporting(job?.reporting);
            setReportTemplateId(job?.reportTemplateId);
            setAssignedEmployee({_id: job?.assignedEmployee, emp: job?.assignedEmployeeDetail, isFromSearch:1})
        }
    },[job]);


    
    return(
        <div className="rota-modal-wrapper">
            <Modal
                open={visible}
                width={employeeSlider?'60%':'40%'}
                style={{top:10, height:'100vh', overflow:'auto'}}
                footer={
                    <div className="drawer-footer" style={{padding:'26px 40px', textAlign:'left', display:'flex', columnGap:'16px', marginTop:'-25px' }}>
                        <button  
                            onClick={()=>handelSubmit(false)}
                            disabled={!employeeSlider || shiftEndTime?.length==0} 
                            className={!employeeSlider || shiftEndTime?.length==0? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
                        >
                            {updateRotaDutyLoading|| newRotaDetailLoading? <Spin indicator={<LoadingOutlined/>}/> : job && Object.keys(job)?.length>0? "Update" : "Save"}
                        </button>
                        <button  disabled={updateRotaDutyLoading || newRotaDetailLoading} className={updateRotaDutyLoading || newRotaDetailLoading? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={close}>
                            Cancel
                        </button>
                    </div>
                }
                closable={false}
            >
                <React.Fragment>
                    <div className='modal-header-title'>
                        <span>
                            {dayjs(title)?.format("ddd, MMM") +" "+ dayjs(title)?.format("DD")} - {site?.sitename} - {area?.areaname} 
                            
                        </span>
                        <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                    </div>

                    <div className='modal-body'>

                        <div className="duty-modal-head">

                                <div>
                                    <span> <FontAwesomeIcon icon={faPersonBooth}/> Area</span>
                                    <div>{area?.areaname}</div>
                                </div>

                                <div>
                                    <span><FontAwesomeIcon icon={faStreetView}/> Site</span>
                                    <div>{site?.sitename}</div>
                                </div>

                                <div>
                                    <span><FontAwesomeIcon icon={faPersonDigging} /> Site Service</span>
                                    <div>{site?.metadata?.servicestype}</div>
                                </div>

                                <div>
                                    <span><FontAwesomeIcon icon={faLayerGroup} /> Site group</span>
                                    <div>{siteDetail?.sitegroupDetail?.sitegroupname}</div>
                                </div>

                                <div>
                                    <span><FontAwesomeIcon icon={faPersonBurst} /> Customer</span>
                                    <div>{siteDetail?.customerDetail?.customername}</div>
                                </div>

                                <div>
                                    <span><FontAwesomeIcon icon={faClockRotateLeft}/> Shift time</span>
                                    <div>{Object.keys(shiftTime)?.length>0? shiftTime?.totalHours : "00:00"}</div>
                                </div>

                        </div>
                        
                        <div style={{display:'flex', gap:'20px'}}>

                            <div className="dutyForm">
                                <table cellSpacing={10}>
                                    <tr>
                                        <td> <label>Duty Type</label></td>
                                        <td>
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                style={{width:'-webkit-fill-available'}}
                                                loading={dutyTypeLoading}
                                                disabled={dutyTypeLoading}
                                                className="custom-select"
                                                suffixIcon={<div className="dropdowncaret"></div>}
                                                placeholder="Select duty type  "
                                                onChange={(e)=>setDutyType(e)}
                                                value={dutyType}
                                            >
                                                {dutyTypeData?.getPayLevel?.response?.map((dutyTypeData)=>(
                                                    <Option value={dutyTypeData?._id}>{dutyTypeData?.name}</Option>
                                                ))}
                                            </Select>
                                        </td>
                                    </tr>

                                   {
                                    dutyType=="65ff5f97cdef94de7cabc3ef" &&
                                    <tr>
                                        <td> <label>Alarm Type</label></td>
                                        <td>
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                style={{width:'-webkit-fill-available'}}
                                                value={alarmType}
                                                className="custom-select"
                                                suffixIcon={<div className="dropdowncaret"></div>}
                                                placeholder="Select alarm type  "
                                                onChange={(e)=>setAlarmType(e)}
                                            >
                                                
                                                <Option value={"FA"}>Fire alarm activation</Option>
                                                <Option value={"IA"}>Intruder alarm activation</Option>

                                            </Select>
                                        </td>
                                    </tr>
                                   } 

                                    <tr>
                                        <td><label>Hour Type</label></td>
                                        <td>
                                            <Select
                                                showSearch
                                                style={{width:'-webkit-fill-available'}}
                                                optionFilterProp="children"
                                                loading={hourTypeDataLoading}
                                                disabled={hourTypeDataLoading}
                                                className='custom-select'  
                                                suffixIcon={<div className='dropdowncaret'></div>}
                                                placeholder="Select hour type"
                                                onChange={(e)=>setHourType(e)}
                                                value={hourType}
                                            >
                                                {hourTypeData?.getShiftType?.response?.map((hour)=>(
                                                    <Option value={hour?._id}>{hour?.name}</Option>
                                                ))}
                                            </Select>
                                        </td>
                                    </tr>
                                    
                                    {/* CURRENT TIME TO 1 HOUR AHEAD FOR ALARM RESPONSE */}

                                    <tr>
                                        <td>
                                            <label>Shift Start Time</label>
                                        </td>

                                        <td>
                                            <Form.Item>
                                                <input 
                                                    type={"time"} 
                                                    className="generic-input-control"
                                                    style={{height:'20px'}}
                                                    placeholder="Shift end time in 24 hours"
                                                    value={shiftStartTime}
                                                    onChange={(e)=>setShiftStartTime(e.target.value)}
                                                />
                                            </Form.Item>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label>Shift End Time</label></td>
                                        <td>
                                            
                                            <Form.Item>
                                                <input 
                                                    type={"time"} 
                                                    className="generic-input-control"
                                                    style={{height:'20px'}}
                                                    placeholder="Shift end time in 24 hours"
                                                    value={shiftEndTime}
                                                    onChange={(e)=>setShiftEndTime(e.target.value)}
                                                />
                                            </Form.Item>

                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label>UnPaid Break Duration</label></td>
                                        <td>
                                            <div style={{display:'flex', gap:'16px'}}>
                                                <Form.Item  className="numberInput" style={{margin:'0'}}>
                                                    <InputNumber placeholder="HH" 
                                                        min={0}
                                                        max={3}
                                                        value={unPaidBreakHour}
                                                        onChange={(e)=>setUnPaidBreakHour(e)}
                                                        className='generic-input-control'                         
                                                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                                        downHandler={<FontAwesomeIcon  style={0 > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                                    />
                                                </Form.Item>
                                                <Form.Item  className="numberInput" style={{margin:'0'}} >
                                                    <InputNumber placeholder="MM"
                                                        min={0}
                                                        max={59}
                                                        value={unPaidBreakMintue}
                                                        className='generic-input-control'   
                                                        onChange={(e)=>setUnPaidBreakMintue(e)}                      
                                                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                                        downHandler={<FontAwesomeIcon  style={0 > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label>UnBill Break Duration</label></td>
                                        <td>
                                            <div style={{display:'flex', gap:'16px'}}>
                                                <Form.Item  className="numberInput" style={{margin:'0'}}>
                                                    <InputNumber placeholder="HH" 
                                                        min={0}
                                                        max={3}
                                                        value={unPaidBillHour}
                                                        onChange={(e)=>setUnPaidBillHour(e)}
                                                        className='generic-input-control'                         
                                                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                                        downHandler={<FontAwesomeIcon  style={0 > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                                    />
                                                </Form.Item>
                                                <Form.Item  className="numberInput" style={{margin:'0'}} >
                                                    <InputNumber placeholder="MM"
                                                        min={0}
                                                        max={59}
                                                        value={unPaidBillMintue}
                                                        onChange={(e)=>setUnPaidBillMintue(e)}
                                                        className='generic-input-control'                         
                                                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                                        downHandler={<FontAwesomeIcon  style={0 > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label>Reporting</label></td>
                                        <td>
                                            <div style={{display:'flex', gap:'16px', alignItems:'center', height: '45px'}}>
                                                
                                                <Switch 
                                                    checkedChildren={reporting?<span>ON</span>:null}
                                                    unCheckedChildren={reporting==false?<span>OFF</span>:null}
                                                    onChange={(e)=>setReporting(e)}
                                                    defaultChecked={reporting}
                                                />

                                                {reporting? 

                                                    <Select
                                                        style={{width:'-webkit-fill-available'}}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            (option?.label?.props?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        className='custom-select'  
                                                        suffixIcon={<div className='dropdowncaret'></div>}
                                                        onChange={(e)=>setReportTemplateId(e)}
                                                        placeholder={"Select report template"}
                                                        value={reportTemplateId}
                                                        options={[
                                                            {
                                                                label: <span style={{color:'rgba(0, 0, 0, 0.45)'}}>Custom Templates</span>,
                                                                title: 'Custom template',
                                                                options: customerTemplates?.map((templates)=>({label: templates?.templatename, value: templates?._id}))
                                                            }, 
                                                            {
                                                                label: <span style={{color:'rgba(0, 0, 0, 0.45)'}}>Standard Templates</span>,
                                                                title: 'Standard template',
                                                                options: standardTemplates?.map((templates)=>({label: templates?.templatename, value: templates?._id}))
                                                            }
                                                        ]}
                                                    />

                                                : null}

                                            </div>
                                        </td>
                                    </tr>
                                {
                                    job && Object.keys(job)?.length>0?
                                    null
                                    :
                                    <tr>
                                        <td colSpan={2}>
                                            {week?.sort((a, b) => {
                                                const aDay = a.day() === 0 ? 7 : a.day(); // Adjust Sunday from 0 to 7
                                                const bDay = b.day() === 0 ? 7 : b.day(); // Adjust Sunday from 0 to 7
                                                return aDay - bDay;
                                            })?.map((wday)=>(
                                                <>
                                                    <input type="checkbox" checked={days?.find((day)=>day.format("DD/MM/YYYY")==wday.format("DD/MM/YYYY"))} value={wday} onChange={(e)=>{wday==title? null :handelDays(e.target.checked, wday)}}/>{wday.format("ddd")} &nbsp;
                                                </>
                                            ))}
                                        </td>
                                    </tr>
                                }

                                </table>
                                
                                
                            </div>
                            
                            {employeeSlider &&
                                <div className={employeeSlider?"emplist":""}>
                                
                                    <div ref={searchRef} style={{display:'flex',alignItems:'center', gap:'5px'}}>
                                        <div style={{width:'100%'}}>
                                            <Input
                                                className="generic-input-control"
                                                placeholder="Type to search employee"
                                                style={{width:'100%', marginBottom:'16px'}}
                                                suffixIcon={<div className="dropdowncaret"></div>}
                                                onChange={(e)=>handelSearch(e.target.value)}
                                                ref={parentRef}
                                            />
                                            
                                            {search?.length>0?
                                                <div className="moveGroupData scheduleSearchEmployee" style={{width: parentWidth}}>
                                                    {
                                                    empSearchDataLoading?
                                                        <div style={{margin:'auto', display:'table', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                           <div className="popoverdataitem" style={{textAlign:'center'}}>
                                                                <Spinner/>
                                                           </div>
                                                        </div>
                                                    :
                                                    
                                                    searchResult?.length==0?
                                                    <div style={{margin:'auto', display:'table', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                           <div className="popoverdataitem" style={{textAlign:'center'}}>
                                                                No employee found
                                                           </div>
                                                    </div>
                                                    :
                                                    searchResult?.map((result)=>{
                                                        const lastname = result?.lastname? result?.lastname : "";
                                                        const firstname = result?.firstname? result?.firstname : "";
                                                        return(
                                                            <div className="popoverdataitem" onClick={()=>{setAssignedEmployee({_id:result?._id, isSuitable: 0, isFromSearch: 1, emp: result}); setSearchResult([]); setSearch(""); }}>
                                                                <div style={{display:'flex', columnGap:'12px'}}>
                                                                    <div className="employeeProfile">
                                                                        <Avatar  size={40} style={{background:'lightgrey'}} src={result?.metadata?.imageURL || <UserOutlined/>} />
                                                                    </div>
                                                                    <div>
                                                                        <div>{firstname +" "+ lastname}</div>
                                                                        <div style={{color:'grey', fontWeight:300}}>{result?.metadata?.employmenttype} {result?.metadata?.agency[0]?.agencyname?  "["+ result?.metadata?.agency[0]?.agencyname +"]" : null }</div>
                                                                    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    )
                                                    }

                                                </div>
                                                :null
                                            }
                                        </div>

                                        <Dropdown ref={searchRef} 
                                            onVisibleChange={handleVisibleChange}
                                            overlay={
                                                <Menu style={{width: '200px', borderRadius:0}}>
                                                    <Menu.Item key="1">
                                                    <Checkbox  onChange={(e)=>("all")}>All Employees</Checkbox> 
                                                    </Menu.Item>    
                                                    <Menu.Item key="2">
                                                    <Checkbox  onChange={(e)=>("agency")}>Agency Employees</Checkbox> 
                                                    </Menu.Item>
                                                </Menu>
                                            } visible={searchOption} placement="bottomLeft"
            
                                        >
                                            <FontAwesomeIcon 
                                                style={{fontSize:'24px', color:'#0091ae', cursor:'pointer', marginBottom:'15px', display:'none'}} 
                                                icon={faEllipsisVertical} onClick={()=>{setSearchOption(!searchOption)}}
                                                
                                            /> 
                                        </Dropdown>

                                    </div>

                                    {
                                        assignedEmployee?.isFromSearch && assignedEmployee?.isFromSearch==1?
                                        <Tag closable onClose={()=>setAssignedEmployee()} style={{marginBottom:'16px', display:'flex', alignItems:'center', columnGap:'10px', width:'fit-content'}}>
                                            <div className="employeeProfile">
                                                <Avatar  size={20} style={{background:'lightgrey'}} src={assignedEmployee?.emp?.metadata?.imageURL || <UserOutlined/>} />
                                            </div>
                                            {assignedEmployee?.emp?.firstname} {assignedEmployee?.emp?.lastname || ""}
                                        </Tag>
                                        :null
                                    }

                                    
                                    
                                </div>
                            }

                        </div>

                    </div>
                                        
                </React.Fragment>
            </Modal>
            
        </div>
    )
}

