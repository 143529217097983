import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { getPayLevelQuery } from '@src/util/query/paylevel.query';
import { getSiteGroupPayTableQuery } from '@src/util/query/siteGroup.query';
import { getPayandBillColumnQuery } from '@src/util/query/payandbillColumn.query';
import { PayRateModal } from '@src/components/payrateModal/payrate.modal.component';
import { UpsertSiteGroupPayTableMutation } from '@src/util/mutation/siteGroup.mutation';
import { findPayDate } from '@src/util/findPayDate/findPayDate';
import { useDispatch } from 'react-redux';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';


export const SiteGroupPayTable = ({id})=>{


    const [remoteColumns, setRemoteColumns] = useState([]);

    const {data, loading:getPayandBillColumnLoading, refetch} = useQuery(getPayandBillColumnQuery,{
      fetchPolicy:'network-only'
    });
    
    useEffect(()=>{
      if(data?.getPayandBillColumn?.response){
        setRemoteColumns([
          {
            title: '',
            dataIndex: 'payLevel',
            width: '20%',
          },
          ...data?.getPayandBillColumn?.response?.map((col)=>(
            {
              title: col?.columnName,
              dataIndex: col?._id,
              editable: true,
              // width:'20%'
            }
          ))
        ]);
      }
    },[data?.getPayandBillColumn?.response]);

    
    const {data: payLevel, loading: payLevelLoading} = useQuery(getPayLevelQuery,{
      fetchPolicy: 'network-only',
    });

    const [dataSource, setDataSource] = useState([]);

    const {data: siteGroupPayTableData, loading: siteGroupPayTableLoading, refetch: siteGroupPayTableRefetch} = useQuery(getSiteGroupPayTableQuery,{
      variables:{
        sitegroupId: id
      },
      fetchPolicy:'network-only',
      skip: !id || id==null || id==undefined
    });
 
    const [columnToBeEdit, setColumnToBeEdit] = useState(null);
    const [payrateModal, setPayrateModal] = useState(false);

    const handelEditPayRate = (pay, payLevelColData, column, pl)=>{
      setColumnToBeEdit({
        value: pay,
        column,
        metadata: payLevelColData,
        payLevel: pl
      });
      setPayrateModal(true);
    }

    const [upsertSiteGroupPayTable, {loading: upsertSiteGroupPayTableLoading}] = useMutation(UpsertSiteGroupPayTableMutation);


    useEffect(()=>{
      
      if(!payLevelLoading && !siteGroupPayTableLoading && !getPayandBillColumnLoading){
       

        setDataSource(payLevel?.getPayLevel?.response?.map((pl)=>{
          
          const columns = data?.getPayandBillColumn?.response?.map((col)=>(col?._id));
          const resultObject = {};
          const payLevelData = siteGroupPayTableData?.getSiteGroupPayTable?.response;

          for (let i = 0; i < columns?.length; i ++) {
            
            const payLevelColData = payLevelData?.find((pld)=>pld?.payLevelId===pl?._id);
            resultObject[columns[i]] =  <span className="link" 
              onClick={()=>handelEditPayRate(payLevelColData?.payTableMeta[columns[i]], payLevelColData, columns[i], pl)}
            >{payLevelColData?.payTableMeta?.hasOwnProperty([columns[i]])? findPayDate(payLevelColData?.payTableMeta[columns[i]]) : 0}</span>
          
            if(columnToBeEdit && Object.keys(columnToBeEdit)?.length>0 && columnToBeEdit?.column==columns[i] && columnToBeEdit?.payLevel?._id == pl?._id)
            {
                setColumnToBeEdit({
                  ...columnToBeEdit,
                  value: payLevelColData?.payTableMeta[columns[i]]
                });
            }

          }


          return({
            key:pl?._id,
            payLevel: pl?.name,
            ...resultObject,
            payLevelId: pl?._id
          })

        }));

      }

    },[upsertSiteGroupPayTableLoading, payLevelLoading, siteGroupPayTableLoading, getPayandBillColumnLoading, payLevel, siteGroupPayTableData?.getSiteGroupPayTable?.response, data?.getPayandBillColumn?.response]);



    const dispatch = useDispatch();

    const handleSave = async(input) => {
        try{
        
          await upsertSiteGroupPayTable({
            variables:{
              input
            }
          });
  
          await siteGroupPayTableRefetch();
  
        }

        catch(err){
          dispatch(setNotification({
            error: false,
            notificationState: true,
            message: "An error has been occured while adding rates on this site",
          }));
        }


    };




    return(
      <div className="hr-section">
          
          <div className="hr-info">
            <div className="hr-info-title">
              Pay Table
            </div>

            <Table
                className='payTable curvedTable'
                rowClassName={() => 'editable-row'}
                dataSource={dataSource}
                columns={remoteColumns}
                loading={siteGroupPayTableLoading || upsertSiteGroupPayTableLoading || payLevelLoading || getPayandBillColumnLoading}
            />    
            
            {
              payrateModal &&
                <PayRateModal 
                  visible={payrateModal}
                  close={()=>{
                    setPayrateModal(false);
                    setColumnToBeEdit(null);
                  }}
                  name={columnToBeEdit?.column}
                  rawData={columnToBeEdit}
                  label={`${data?.getPayandBillColumn?.response?.find((payBill)=>payBill?._id==columnToBeEdit?.column)?.columnName} rate`}
                  handleSave={handleSave}
                  moduleId={"sitegroupId"}
                  loading={siteGroupPayTableLoading || upsertSiteGroupPayTableLoading}
                />
            }                       


        </div>
      </div> 

        
    )
}