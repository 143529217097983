import { faAnchorLock, faBell, faBookDead, faClipboardList, faClock, faMoneyBill, faRoadLock, faSchoolLock, faSearch, faServer, faSubscript, faTableList, faTimes, faUserCog, faUserLock, faUserNinja, faUserSecret, faUsersGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./settingIndex.css";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../util/routes/routes";

export const SettingIndex = ({close})=>{

    const settingMenu = [
        {title:'Your Preferences',icon: <FontAwesomeIcon icon={faUserCog} />, menu: [{title:'General', route:'#/allsetting'}, {title:'Notifications', route:'#/allsetting'}, {title:'Security', route:'#/allsetting'}] },
        {title:'Account Setup',icon: <FontAwesomeIcon icon={faUsersGear}/> , menu: [{title:'User Roles', route: routes?.userRole}, {title:'Users & Teams', route:routes?.addUser}, {title:'Users Access Log', route:routes?.userAccess}] },
        {title:'Data Management',icon: <FontAwesomeIcon icon={faTableList}/>, menu: [{title:'Data Fields', route: routes?.propertySetting}, {title:'Document Checklist', route: routes?.docSetting}, {title:'Form', route:routes?.forms}, {title:'Skills', route:routes?.editskill}] },
        {title:'Pay & Bill Management',icon: <FontAwesomeIcon icon={faMoneyBill}/>, menu: [{title:'Duty Type', route: routes?.paylevel}, {title:'Hour Type', route:routes?.shifttype}, {title:'Pay Table', route:routes?.paytable}, {title:'Premiums', route:routes?.premium}, {title:'Pay & Bill Columns', route:routes?.paybillcol}, {title:'Calculate Pay & Bill', route:routes?.calculatepaybill}] },
        {title:'Report Management',icon: <FontAwesomeIcon icon={faClipboardList}/>, menu: [{title:'Report Field', route: routes?.reportField}, {title:'Report Templates', route:routes?.reportTemplates}, {title:'System Reports', route:routes?.systemReport}] },
        {title:'Schedule Management',icon: <FontAwesomeIcon icon={faClock}/>, menu: [{title:'Schedule Events', route:routes?.scheduleEvent}, {title:'Schedule Checks', route:'#/allsetting'}] },
        {title:'Subscription & Services', icon: <FontAwesomeIcon icon={faServer}/>, menu: [{title:'Services', route:routes.service}, {title:'Payment Methods', route:routes.payment}, {title:'Billing Notification', route:routes.billingNotification}] },
        {title:'System Notifications', icon: <FontAwesomeIcon icon={faBell}/>, menu: [{title:'Compliance Notification', route:routes.service}, {title:'Schedule Notification', route:routes.payment}, {title:'Other Notification', route:routes.billingNotification}] },
    ];

    const [settingMenuItem, setSettingMenuItem] = useState(settingMenu);
    useEffect(()=>{
        setSettingMenuItem(settingMenu)
    },[]);

    const handelSearch = (search)=>{
        if(search?.length>1){
            setSettingMenuItem(
               settingMenu.map(item => ({
                    ...item,
                    menu: item.menu.map(subItem => ({
                      ...subItem,
                      highlighted: subItem.title.toLowerCase().includes(search?.toLowerCase()) // Add a highlighted property
                    }))
                }))
            );
        }else{
            setSettingMenuItem(settingMenu);
        }
    };

    return(
        <div className='searchOverlayViewParent'>
            <div
                
                className='settingOverlayView'
                style={{ top: 0, paddingBottom: 10 }}
            >

            
                <div className="setting-header">

                    <div className='setting-header-text'>All Settings</div>
                    
                    <div style={{display:'flex', alignItems:'center', gap:'50px'}}>

                        <Input 
                            placeholder="Search setting menu"
                            className='generic-input-control setting-searchInput'
                            suffix={<SearchOutlined style={{fontSize:'18px'}}/>}
                            onChange={(e)=>handelSearch(e.target.value)}
                        />

                        <FontAwesomeIcon 
                            onClick={close}
                            style={{fontSize:'16px',cursor:'pointer'}}
                            icon={faTimes}
                        />
                    </div>



                </div>

                <div className="setting-overlay-body">
                    {
                        settingMenuItem?.map((setting)=>(
                            
                            <div className="setting-menu-card">
                                <div className="setting-menu-head">
                                   <span style={{color:'#fb8c5f', fontSize:'16px'}}>{setting?.icon}</span> {setting?.title}
                                </div>
                                {
                                    setting?.menu?.map((item)=>(
                                        <div className="text setting-menu-item">
                                        <Link style={item?.highlighted? {color:'#33475b', background:'yellow'} :{color:'#33475b'}} to={item?.route} >{item?.title}</Link>
                                        </div>
                                    ))
                                }
                            </div>
                            
                        ))
                    }


                </div>

            </div>
        
                
        </div>
    );
}