import { FormOutlined, MessageOutlined, PhoneOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { faArrowLeft, faBackward, faBackwardFast, faCaretLeft, faCaretRight, faClose, faForward, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, DatePicker, Drawer, Input, Popover, Tabs } from "antd";
import "./employeeDetail.css";
import TabPane from "antd/es/tabs/TabPane";
import { useState, useEffect } from "react";
import dayjs from "dayjs";

export const EmployeeDetailModal = ({employeeScheduleDetailModal, close})=>{

    const {visible, employee} = employeeScheduleDetailModal;

    return (
        <Drawer
            title={<EmployeeDetailTitle employee={employee} />}
            placement="right"
            className="empDetailScheduleModal"
            closable={true}
            onClose={close}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={close} className='close-icon'/>}
            open={visible}
            width={700}
            mask={true}
            maskClosable={false}
            maskClassName='filtermask'
            footer={null}
      >

            <>

                <div className='editFieldTabs employeeScheduleDetailTab' >
                    <Tabs defaultActiveKey="1">

                        <TabPane tab={`General`} key="1">


                            <EmployeeScheduleTable />


                        </TabPane>

                        <TabPane tab={`Time Approval`} key="2">
                            
                        </TabPane>

                        <TabPane tab={`Declined`} key="3">
                            
                        </TabPane>

                        <TabPane tab={`Others`} key="4">
                            
                        </TabPane>

                    </Tabs>
                </div>

            </>

        </Drawer>
    );
}



const EmployeeDetailTitle = ({employee})=>{
    return(
        <div className="employeeDetailScheduleTitle">


            <div>
                
                {
                employee?.metadata?.imageURL?
                    <Avatar 
                        size={90} 
                        src={employee?.metadata?.imageURL} 
                    />    

                    :
                    <Avatar size={90}> {employee?.firstname?  employee?.firstname[0]+(employee?.lastname? employee?.lastname[0] : "") : ""} </Avatar>    
                }  

            </div>

                {/* employee detail name */}
            <div className="employeeDetailScheduleTitleDetail">

                <div style={{height:'60px'}}>
                    <h3>{employee?.firstname + " " + (employee?.lastname || "")}</h3>
                </div>

                <div className='text-subtitle'>


                    <div className="activity-btn-grp" style={{justifyContent:'center'}}>
                        
                        <Popover
                            content={"Make a phone call"}
                        >
                            <span>
                                <button className=''>
                                    <PhoneOutlined style={{transform:'rotate(-260deg)'}}/>
                                </button>
                                <span className='tiny-text '>Call</span>
                            </span>
                        </Popover>

                        
                        <Popover
                            content={"Start conversation"}
                        >
                            <span>
                                <button className=''>
                                    <MessageOutlined/>
                                </button>
                                <span className='tiny-text '>Chat</span>
                            </span>
                        </Popover>


                        
                        <Popover content={"Follow this"} >
                            <span>
                                <button className=''>
                                    <UserAddOutlined />
                                </button>
                                <span className='tiny-text '>Follow</span>
                            </span>
                        </Popover>
                    </div>

                </div>

            </div>


        </div>
    );
}

const EmployeeScheduleTable = ()=>{
    

    const [dateRange, setDateRange] = useState([]);

    const handelDateChange = (date, dateString)=>{
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        setStartDate(startOfWeek);
        setEndDate(endOfWeek);
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
          dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
        localStorage.setItem("scheduleDate", JSON.stringify(dates));

    };

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    
    useEffect(()=>{
        const isStoredDateExist = localStorage.getItem("scheduleDate");
        if(isStoredDateExist){
            const storedDate = JSON.parse(isStoredDateExist);
            const startOfWeek = dayjs(storedDate[0]).startOf('week'); // Get start of the week
            const endOfWeek = dayjs(storedDate[0]).endOf('week'); // Get end of the week
            // set start and end date for schedule
            setStartDate(startOfWeek);
            setEndDate(endOfWeek);
            setDateRange([...storedDate?.map((date)=> dayjs(date))]);

        }else{

            const dateString = dayjs();
            const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
            const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
            // set start and end date for schedule
            setStartDate(startOfWeek);
            setEndDate(endOfWeek);

            const dates = [];
            for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
                dates.push(day); // Format dates as YYYY-MM-DD
            };
            setDateRange([...dates]);

        }
    }, []);

    const nextWeek = ()=>{
        const dateString = dayjs(dateRange[0]).add(1, 'week');
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        setStartDate(startOfWeek);
        setEndDate(endOfWeek);
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
          dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
        localStorage.setItem("scheduleDate", JSON.stringify(dates));

    };

    const previousWeek = ()=>{
        const dateString = dayjs(dateRange[0]).subtract(1, 'week');
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        setStartDate(startOfWeek);
        setEndDate(endOfWeek);
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
          dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
        localStorage.setItem("scheduleDate", JSON.stringify(dates));
    };

    return(
        <div className="detailModalBox">
            <div className="title mb16" style={{textAlign:'left'}} >
                Schedule Calendar
            </div>


            <div style={{display:'flex', gap:'20px', margin: 'auto', textAlign:'center', width:'100%'}}>

                <div className="form-grp" style={{display:'flex', height:'40px'}}>

                    <div style={{display:'inline-block', position:'relative'}}>
                                    
                        <Input 
                            readOnly
                            disabled
                            className="generic-input-control"
                            style={{cursor:'pointer', height:'30px', textAlign:'center', color:'black'}}
                            value={dateRange?.length>0? dayjs(dateRange[0]).format('DD MMM') +" - "+ dayjs(dateRange[dateRange?.length-1]).format('DD MMM, YYYY') : null}
                        />
                        <div style={{position:'absolute', top:'0', width:'100%', zIndex:'1', opacity:'0'}}>
                            <DatePicker className="generic-input-control" onChange={handelDateChange} />
                        </div>


                    </div>

                    <span className="icon-btn" onClick={previousWeek}>
                        <FontAwesomeIcon icon={faCaretLeft}  style={{fontSize:'18px'}}/> &nbsp;
                    </span>
                    <span className="icon-btn" onClick={nextWeek} >
                        <FontAwesomeIcon icon={faCaretRight} style={{fontSize:'18px'}}/>
                    </span>

                </div>

                <div className="icon-bound" onClick={()=>handelDateChange(null, dayjs())}
                    style={{width:'60px', display:'flex', alignItems:'center', justifyContent:'center'}}
                >
                    Today
                </div>

                <div className="icon-bound" onClick={()=>handelDateChange(null, dayjs())}
                    style={{width:'60px', display:'flex', alignItems:'center', justifyContent:'center'}}
                    title="Total hours in selected week"
                >
                    04:00
                </div>

            </div>




        </div>
    )
}