import "./siteDetail.css";
import { faChevronLeft, faClipboardCheck, faCopy, faPencil, faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Col, Input, Popover, Row } from "antd";
import { SiteDetailPageLeftSideBar, SiteGroupDetailPageLeftSideBar } from "./component/leftSidebar";
import { SiteGroupPostSection } from "./component/middleSection";
// import { CustomerPayTable } from "../customerpayTable/customerPayTable";
import { getSiteQuery, singleSiteDetailForOperationalTabId } from "@src/util/query/site.query";
import { SitePayTable } from "./component/sitepayTable/sitePayTable";
import { SiteArea } from "./component/area/area";
import { SchedulePage } from "../../schedule/schedule.page";
import { GROUPLIST } from "@src/util/query/group.query";
import { moduleTabs } from "@src/util/tabs/employee.tab";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CopyOutlined, HolderOutlined } from "@ant-design/icons";
import { GenerateFields } from "@src/util/generateFields/generateFields";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { useDispatch } from "react-redux";
import { UpdateSingleSiteMutation } from "@src/util/mutation/site.mutation";
import Spinner from "@src/components/spinner";
import { SiteActivity } from "./component/siteActivity/siteActivity";
import { SiteSkill } from "./component/siteSkill/SiteSkill";
import { SiteCredential } from "./component/siteCredential/siteCredential";
import { lookupTypes } from "@src/util/types/lookup.types";
import { GetLookupStaticValue } from "@src/util/generateFields/lookupStaticValue";
import { DetailPageRightSideBar } from "./component/rightSidebar";
import { SiteGeofencing } from "./component/siteGeofencing/siteGeofencing";
import { SiteCheckCalls } from "./component/siteCheckcalls/siteCheckCall";
import { EditLeftSideView } from "./editLeftSideView";
import { useSelector } from "react-redux";
import { getSiteDetailViewQuery } from '@src/util/query/siteDetailView.query';
import { deleteSiteDetailViewMutation, updateSiteDetailViewMutation } from "@src/util/mutation/siteDetailView.mutation";
import { SiteTask } from "./component/task/task";
import { NotesTab, SiteNotesTab } from "./component/notes/notes.tab";
import { getNote } from "@src/util/query/note.query";
import { objectType } from "@src/util/types/object.types";
import { SiteDocumentTab } from "./component/document/document.page";
import { SiteBilling } from "./component/siteBilling/siteBilling";
import { SiteDutyTask } from "./component/siteDutyTask/siteDutyTask";


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const SiteDetailPage = ()=>{

    const navigate = useNavigate();
    const param = useParams();



    const SiteTabs = [
        {title: 'Profile', url: 'profile'},
        {title: 'Operations', url: 'operations'},
        {title: 'Pay Table', url: 'paytable'},
        {title: 'Duty Task', url: 'task'},
        {title: 'Documents', url: 'documents'},
        {title: 'Notes', url: 'note'},
        {title: 'Schedule', url: 'schedule'}
    ];

    const [activeTab, setActiveTab] = useState(param?.tab);

    // site group details
    const {data: singleSite, loading: singleSiteLoading, refetch} = useQuery(getSiteQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            id: param?.id
        }
    });

    const [site, setSite] = useState({});

    useEffect(()=>{
        if(!param?.edit && !param?.tab){
            setActiveTab("profile");
        }
    },[param]);

    useEffect(()=>{
        if(!singleSiteLoading && singleSite?.site?.response){
            const {sitename, contractstartdate, postcode, sitegroupId, sitegroupDetail, metadata} = singleSite?.site?.response;
            setSite({sitename, contractstartdate, postcode, sitegroup: sitegroupDetail[0]?.sitegroupname, sitegroupId, sitegroupDetail, ...metadata});
        }
    },[singleSite?.site?.response ,singleSiteLoading]);

    const [isFieldChanged, setIsFieldChanged] = useState(false);
    const [saveUpdate, setSaveUpdate] = useState(false);

    const {data:siteTabGrpData, refetch: singleSiteDataRefetch, loading:singleSiteDataLoading} = useQuery(singleSiteDetailForOperationalTabId,{
        variables:{
            singleSiteDetailForOperationalTabId: param?.id
        }
    });
    
    const {data:SiteGrpList, loading:grpListLoading} = useQuery(GROUPLIST ,{
        variables:{
            objectType: 'Site'
        },
        fetchPolicy:'network-only'
    });

    const [filteredGroup, setFilteredGroup] = useState([]);
    const [selectedGrp, setSelectedGrp] = useState("");
    const [selectedGroupProp, setSelectedGroupProp] = useState([]);
    const [editable, setEditable] = useState(false);

    useEffect(()=>{
        if(SiteGrpList?.groupList){
            const {groupList} = SiteGrpList;
            setFilteredGroup(groupList?.filter((list)=>list?.tabs?.includes(moduleTabs.Site[0])).sort((a,b)=> a?.order - b?.order))
            setSelectedGrp(groupList?.filter((list)=>list?.tabs?.includes(moduleTabs.Site[0])).sort((a,b)=> a?.order - b?.order)[0]?.name)
        }
    },[SiteGrpList]);

    
    useEffect(()=>{
        if(selectedGrp){
            const props = filteredGroup?.find((grp)=> grp.name==selectedGrp)
            setSelectedGroupProp(props?.propertyList?.filter((prop)=>prop?.isArchive==false && prop?.isDelete==false));
            
        }
    },[selectedGrp])
    
    const [staticOperationTab, setStaticOperationTab] = useState([
        {id:1, name: 'Check Calls'},
        {id:4, name: 'Site DOB'},
        {id:3, name: 'Site Area'},
        {id:2, name: 'Site Skills'},
        {id:6, name: 'Site Credentials'},
        {id:5, name: 'Setup Billing'},
        {id:7, name: 'Geofencing'},
    ]);

    const [items, setItems] = useState(filteredGroup)
    useEffect(()=>{
        setItems([...filteredGroup,
            ...staticOperationTab
        ]);
    }, [filteredGroup]);

    const onDragEnd = async (result) => {
        // dropped outside the list
        if (!result.destination) {
        return;
        }
        
        const reorderedArr = reorder(
            items,
            result.source.index,
            result.destination.index
            )

        setItems(reorderedArr);
        const orderedGroup = {

            order: reorderedArr.map((item, index)=>index),
            ids : reorderedArr.map((item)=>item.key)
        }


    }


    const [field, setField] = useState([]);
    
    const handelDataValue = ({name, value})=>{
        if(name){
            if(value){
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value}])
                }
            }else{
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value:''
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value:''}])
                }
            }
        }
    }

    const dispatch = useDispatch();
    const [updateSingleSiteMutation, {loading: updateSingleSiteLoading}] = useMutation(UpdateSingleSiteMutation);

    // this handel save for only operational tab
    const handelUpdateSiteGroup = async()=>{
        try{
            
            let schemaFields = [];

            field?.map((field)=>{
                if(field.name==="sitename" || field.name==="postcode" || field.name==="contractstartdate" ){
                    schemaFields.push(field);
                }
                else if(field.name==="sitegroup"){
                    schemaFields.push({...field, name: 'sitegroupId'});
                }
                else{
                    schemaFields.push({...field, metadata:1})
                }
            });


            await updateSingleSiteMutation({
                variables:{
                    input:{
                        _id: param?.id,
                        properties: schemaFields
                    }
                }
            });
            await refetch();
            setField([]);
            await singleSiteDataRefetch();
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Site  was updated successfully"
            }));
        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }   
    }

    const userDetail = useSelector(state=>state?.userAuthReducer?.authenticatedUserDetail);

    
    const {data:getSiteDetailViewData, loading: getSiteDetailViewDataLoading, refetch: newSiteDetailViewRefetch} = useQuery(getSiteDetailViewQuery,{
        variables:{
            userId: userDetail?._id
        }
    });

    const [editableProp, setEditableProp] = useState([]);

    const [updateSiteDetailView, {loading:updateSiteDetailViewLoading}] = useMutation(updateSiteDetailViewMutation);

    useEffect(()=>{
        if(getSiteDetailViewData?.getSiteDetailViewField?.response){
            setEditableProp(getSiteDetailViewData?.getSiteDetailViewField?.response)
        }
    },[getSiteDetailViewData?.getSiteDetailViewField?.response]);

    
    const onDragEndForLeftCustomise = async (result) => {
        // dropped outside the list
        if (!result.destination) {
        return;
        }
        
        const reorderedArr = reorder(
            editableProp,
            result.source.index,
            result.destination.index
            );
        
        
        setEditableProp(reorderedArr);
        
        await updateSiteDetailView({
            variables:{
                input:{
                   ids: reorderedArr?.map((prop)=> prop?._id)
                }
            }
        });

    };

    // console.log(editableProp?.sort((a,b)=>(a.order ?? Number.MAX_SAFE_INTEGER) - (b.order ?? Number.MAX_SAFE_INTEGER)), "getSiteDetailViewDatagetSiteDetailViewData")
    const [deleteSiteDetailView, {loading: deleteSiteDetailViewLoading}] = useMutation(deleteSiteDetailViewMutation);
   
    const { data:note , loading: noteLoading, refetch: noteRefetch} = useQuery(getNote,{
        variables:{
            createdFor: param?.id,
            objectType: objectType?.Site
        },
        fetchPolicy:'network-only',
    });

    useEffect(()=>{
        if(param?.operationTab){
            setSelectedGrp(param?.operationTab);
        }else if(items?.length>0){
            setSelectedGrp(items[0]?.name);
        }
    },[param, items]);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(
          () => {
            
            setTimeout(() => dispatch(setNotification({
                error: false, notificationState: true, message: "Copied"
            })), 0); // Reset the copied state after 2 seconds
          },
          (err) => {
            console.error("Failed to copy text: ", err);
          }
        );
    };

    return(
        <div>
            {/* site group tabs */}

            <div style={{background: 'rgb(245, 248, 250)', padding: '15px 45px 7px 15px', display:'flex', gap:50, alignItems: 'center', position: 'fixed',  width: '100%', zIndex: '996'}}>
                
                {/* back + user name btn */}
                <div style={{display:'flex', alignItems:'center', gap:'25px', paddingBottom:'8px', width:'50%'}}>

                    <div onClick={async()=>{navigate("/user/site"); await newSiteDetailViewRefetch();}} >
                        <FontAwesomeIcon className='left-chevron-icon' icon={faChevronLeft}/> <span className='text-deco' style={{left: '5%', position: 'relative', fontSize:'14px'}}>Back</span> 
                    </div>

                    <div style={{fontSize:'14px'}}>
                        {singleSite?.sitename}
                    </div>
                </div>

                {param?.edit==="edit"?
                    <>
                        <div style={{display:'flex', alignItems:'center', gap:'50px', justifyContent:'center', width:'100%'}}>
                           <b>Customise Mode</b> 
                        </div>

                        <div style={{width:'50%', textAlign:'right', marginRight: '35px', cursor:'pointer'}}>
                            <FontAwesomeIcon icon={faTimes} 
                                className="icon-bound"
                                style={{boxShadow:'none', fontSize:'15px', borderRadius:'15px', padding:'6px 8px', cursor:'pointer'}}
                                onClick={async ()=>{navigate("/user/site-detail/"+param?.id); await newSiteDetailViewRefetch();}}
                            />
                        </div>
                    </>
                :

                // {/* navigation tabs */}
                <>
                    <div style={{display:'flex', alignItems:'center', gap:'50px', justifyContent:'center', width:'100%'}}>
                        {SiteTabs?.map((tab)=>(
                            <div className={activeTab==tab.url? 'emp-menubar emp-menubar-active': 'emp-menubar'} onClick={()=>{ navigate("/user/site-detail/"+param?.id+"/tab/"+tab?.url); setActiveTab(tab.url); }}>{tab.title}</div>
                        ))}
                    </div>

                    <div style={{width:'50%', textAlign:'right', paddingRight:'50px', marginTop:'-5px'}}>
                        <Popover
                            content={"Copy Public URL For this site log"}
                            placement="left"
                        >
                            <Input
                                className="generic-input-control"
                                readOnly
                                onClick={()=>handleCopy(`${window.location.protocol}//${window.location.host}/site/${param?.id}/${site?.sitename}/log`)}
                                onDoubleClick={()=>window.open(`${window.location.protocol}//${window.location.host}/site/${param?.id}/${site?.sitename}/log`)}
                                style={{fontSize:'12px', width:'fit-content', height:'20px'}}
                                value={`${window.location.protocol}//${window.location.host}/site/${param?.id}/${site?.sitename}/log`}
                            />

                        </Popover>
                    </div>
                </>
                }

            </div>

            {param?.edit==="edit"?
            // {/* edit left side view for data fields */}
            <div style={{padding:'50px 5px 5px 5px'}}>
                    <div className="grid-layout">
                        <div className="grid-25">
                            <SiteDetailPageLeftSideBar
                                site={site}
                                loading={singleSiteLoading}
                                isFieldChanged={isFieldChanged}
                                setIsFieldChanged={setIsFieldChanged}
                                saveUpdate={saveUpdate}
                                refetch={refetch}
                                setSaveUpdate={setSaveUpdate}
                                showDetails={false}
                            />

                            {/* all editable tabs will be here */}
                            <DragDropContext onDragEnd={onDragEndForLeftCustomise}>
                                <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="tab-grp"
                                    style={{
                                        width:'auto',
                                        border: '1px solid #ECEFEC',
                                        borderRadius: '12px',
                                        padding: '14px',
                                        marginTop: '10px',
                                        gap: 0,
                                    }}
                                    >
                                    {editableProp?.map((item, index) => (
                                        <Draggable key={item._id} draggableId={item._id} index={index}
                                        
                                        >
                                        {(provided, snapshot) => (
                                            <div className="icon-wrapper">
                                            
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                id={"item-"+index}
                                                itemRef={"item-"+index}
                                                key={item?._id}
                                                // className=
                                                style={{
                                                opacity: snapshot.isDragging ? 0.5 : 1,
                                                ...provided.draggableProps.style
                                                }}
                                                className={"holderwrap"}
                                            >
                                                <li style={{direction:'ltr', justifyContent:'space-between'}}>
                                                    <span>

                                                        <HolderOutlined className="holder"/>
                                                        {item?.propertyDetail?.label}
                                                    </span>
                                                    <FontAwesomeIcon icon={faTimes}
                                                        onClick={async()=>{ await deleteSiteDetailView({
                                                            variables:{
                                                                userId: userDetail?._id,
                                                                propertyId: item?.propertyDetail?._id,
                                                                
                                                            }
                                                        }); await newSiteDetailViewRefetch();}}
                                                    />
                                                </li>
                                                
                                            </div>
                                            </div>

                                        )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                            </DragDropContext>

                            {/* Static Tabs in Operation */}
                            
                            

                        </div>
                        <div className="grid-50">
                            <EditLeftSideView />
                        </div>
                        
                        {/* right side */}

                        <div className="grid-25">
                            <DetailPageRightSideBar
                                site= {site}
                            />
                        </div>
                    </div>
            </div>
            :
            // {/* detail page all tab */}
            <div style={{padding:'50px 5px 5px 5px'}}>
                {activeTab=="schedule"?
                    <div>
                            <SchedulePage siteId={param?.id} />
                    </div>
                :
                activeTab=="operations"?

                    <div className="grid-layout">
                        <div className="grid-25">
                            <SiteDetailPageLeftSideBar
                                site={site}
                                loading={singleSiteLoading}
                                isFieldChanged={isFieldChanged}
                                setIsFieldChanged={setIsFieldChanged}
                                saveUpdate={saveUpdate}
                                refetch={refetch}
                                setSaveUpdate={setSaveUpdate}
                                showDetails={false}
                            />

                            {/* operation group */}
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="tab-grp"
                                    style={{
                                        width:'auto',
                                        border: '1px solid #ECEFEC',
                                        borderRadius: '12px',
                                        padding: '14px',
                                        marginTop: '10px',
                                        gap: 0,
                                    }}
                                    >
                                    {items?.map((item, index) => (
                                        <Draggable key={item.key} draggableId={item.key} index={index}
                                        
                                        >
                                        {(provided, snapshot) => (
                                            <div className="icon-wrapper">
                                            
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                id={"item-"+index}
                                                itemRef={"item-"+index}
                                                key={item.key}
                                                // className=
                                                style={{
                                                opacity: snapshot.isDragging ? 0.5 : 1,
                                                ...provided.draggableProps.style
                                                }}
                                                onClick={()=>navigate("/user/site-detail/"+param?.id+"/tab/"+activeTab+"/"+item?.name)} 
                                                className={selectedGrp===item?.name? "activeTab holderwrap" : "holderwrap"}
                                            >
                                                <li >
                                                    {item.name}
                                                    <HolderOutlined className="holder"/>
                                                </li>
                                                
                                            </div>
                                            </div>

                                        )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                            </DragDropContext>

                            {/* Static Tabs in Operation */}
                            
                            

                        </div>
                        
                        {/* operation middle section */}
                        {staticOperationTab?.map((tab)=>tab.name)?.includes(selectedGrp)?
                            selectedGrp == "Site Area"?
                            <div className="grid-50">
                                <SiteArea id={param?.id} />
                            </div>
                            :
                            selectedGrp == "Site Skills"?
                            <div className="grid-50">
                                <SiteSkill/>
                            </div>
                            :
                            selectedGrp == "Check Calls"?

                            <div className="grid-50">
                                <SiteCheckCalls siteId={param?.id} />
                            </div>

                            :
                            selectedGrp == "Site DOB"?
                            
                            <div className="grid-50">
                                <SiteActivity id={param?.id} />
                            </div>

                            :
                            selectedGrp == "Geofencing"?

                            <div className="grid-50">
                                <SiteGeofencing site={site}/>
                            </div>
                            
                            :selectedGrp == "Site Credentials"?

                            <div className="grid-50">
                                <SiteCredential site={singleSite?.site?.response} refetch={refetch} />
                            </div>

                            :selectedGrp == "Setup Billing"?

                            <div className="grid-50">
                                <SiteBilling />
                            </div>
                            
                            : null
                            
                        :
                            <div className="grid-50">
                                <div className="hr-section">
                                    <div className="hr-info">
                                        <div className="hr-info-title" style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}> 
                                            {selectedGrp} 
                                            {
                                                editable?
                                                <FontAwesomeIcon icon={faTimes} className="icon-bound" 
                                                onClick={()=>setEditable(!editable)}
                                                style={{boxShadow:'none', fontSize:'12px', borderRadius:'15px', padding:'6px 8px', cursor:'pointer'}}
                                                />
                                                :
                                                <FontAwesomeIcon icon={faPencil} className="icon-bound" 
                                                    onClick={()=>setEditable(!editable)}
                                                    style={{boxShadow:'none', fontSize:'12px', borderRadius:'15px', padding:'8px', cursor:'pointer'}}
                                                />
                                            }
                                        </div>
                                        <div style={{display: 'flex', justifyContent: 'flex-start', width:'100%', flexWrap:'wrap', gap:'1px 100px', flexDirection:'column'}}>
                                            {
                                                selectedGroupProp?.length>0? selectedGroupProp?.map((prop)=> {
                                                    
                                                    const label = prop?.label;
                                                    const name = prop?.label.toLowerCase().replace(/\s/g,"");
                                                    const fieldType = prop?.fieldType;
                                                    const newprop = prop;
                                                    
                                                    const {metadata, ...rest} = siteTabGrpData?.singleSiteDetailForOperationalTab?.response;
                                                    const dataFields = {...metadata, ...rest, sitegroup:rest?.sitegroupId};
                                                    
                                                    const {value} = field?.find((f)=>f.name==name) || {value: dataFields[name]} || {value: ""};
                                                    
                                                    return (
                                                        <div>
                                                            {!editable?
                                                            
                                                                <div className='fieldView'>
                                                                    <div>{label}</div>
                                                                    <div>
                                                                        {lookupTypes?.includes(fieldType)?
                                                                            <GetLookupStaticValue
                                                                                type={fieldType}
                                                                                id={value}
                                                                            />
                                                                            :
                                                                            value
                                                                        }
                                                                    </div>
                                                                </div>
                                                                
                                                                
                                                            :
                                                                <GenerateFields 
                                                                    label={label}
                                                                    name={name}
                                                                    fieldType={fieldType}
                                                                    handelDataValue={handelDataValue}
                                                                    property={newprop}
                                                                    value={value}
                                                                    employee={true}
                                                                />
                                                            }
                                                            
                                                        </div>
                                                    );
                                                }): <div>No Data field in this group</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {/* right side */} 

                        
                            <div className="grid-25">
                                <DetailPageRightSideBar
                                    site= {site}
                                />
                            </div>
                        
                        

                    </div>


                :
                    <div className="grid-layout">
                        <div className="grid-25">
                            <SiteDetailPageLeftSideBar
                                site={site}
                                loading={singleSiteLoading}
                                isFieldChanged={isFieldChanged}
                                setIsFieldChanged={setIsFieldChanged}
                                saveUpdate={saveUpdate}
                                refetch={refetch}
                                setSaveUpdate={setSaveUpdate}
                                showDetails={true}
                            />
                            
                        </div>

                    {/* render all top tab here except operations */}

                    {activeTab=="profile"?
                    <>                    
                        <div className="grid-50">
                            <SiteGroupPostSection/>
                        </div>
                    
                        {/* right side */}

                        <div className="grid-25">
                            <DetailPageRightSideBar
                                site= {site}
                            />
                        </div>
                    </>
                    :
                    
                    activeTab=="paytable"?
                        
                        <div className="grid-75">
                            <SitePayTable id={param?.id} />
                        </div>
                    :     
                    activeTab=="task"?
                        <>
                            <div className="grid-75">
                                <SiteDutyTask siteId={param?.id} />
                            </div>
                            
                        </>
                    :     
                    activeTab=="note"?
                        <>
                            <div className="grid-75">
                                <SiteNotesTab  
                                    note={note?.getNote?.response} 
                                    noteLoading={noteLoading} 
                                    noteRefetch={noteRefetch} 
                                />
                            </div>
                            
                        </>
                    :     
                    activeTab=="documents"?

                        <>
                            <div className="grid-75">
                                <SiteDocumentTab singleSiteRefetch={refetch} singleSite={singleSite?.site?.response} />
                            </div>
                            
                        </>

                    :     
                    null
                    }

                    

                    </div>
                }
            </div>
            }

            

        {isFieldChanged ?
            <div className='hr-action-footer'>
                <button disabled={singleSiteLoading} className={singleSiteLoading? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={()=>setSaveUpdate(true)}>Save</button>
                <button disabled={singleSiteLoading} className={singleSiteLoading? 'disabled-btn drawer-outlined-btn' : 'drawer-outlined-btn'} onClick={async()=> await refetch()}>Cancel</button>
                {
                    <span className='text' style={{margin: 0}}>You've changed field value</span>
                }
            </div>
        : null}

        {/* control btn */}
        {
                field?.length>0?
                    <div className='hr-action-footer'>

                        <button disabled={updateSingleSiteLoading} className={updateSingleSiteLoading?'drawer-filled-btn disabled-btn': 'drawer-filled-btn'} onClick={handelUpdateSiteGroup} >
                            {updateSingleSiteLoading? <Spinner/> : "Save"}
                        </button>
                        <button onClick={()=>setField([])} className={updateSingleSiteLoading?'drawer-outlined-btn disabled-btn':'drawer-outlined-btn'} >Cancel</button>
                    
                        <span className='text' style={{margin: 0}}>You've changed {field?.length} data fields</span>
                        
                    </div>
                :null
            }
        </div>
    )
};