import { gql } from "@apollo/client";

export const newScheduleTimesMutation = gql`
mutation($input: ScheduleTimesInput!){
  newScheduleTime(input: $input) {
    response
    message
  }
}
`;

export const updateScheduleTimesMutation = gql`
mutation($input: ScheduleTimesInput!){
  updateScheduleTime(input: $input) {
    response
    message
  }
}
`;

export const updateBulkScheduleTimesMutation = gql`
mutation($input: ScheduleTimesBulkInput!){
  updateBulkScheduleTime(input: $input) {
    message
    response
  }
}
`;