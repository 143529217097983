import { setNoteToggle } from "@src/middleware/redux/reducers/note.reducer";
import { useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight, faCommentDots, faCopy, faDeleteLeft, faPen, faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Avatar, Popover, Tabs } from "antd";
import dayjs from "dayjs";
import ReactQuill from 'react-quill';
import { useMutation } from "@apollo/client";
import { DeleteNoteMutation, UpdateNoteMutation } from "@src/util/mutation/note.mutation";
import { DeleteNoteCommentMutation, NewNoteCommentMutation } from "@src/util/mutation/noteComment.mutation";
import { useSelector } from "react-redux";
import { objectType } from "@src/util/types/object.types";
import { Notes } from "@src/components/notesFloating/notes";
import { NewNoteMutation } from '@src/util/mutation/note.mutation';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { useParams } from "react-router-dom";
import Spinner from "@src/components/spinner";

export const CustomerNotesTab = ({note, noteRefetch, noteLoading}) =>{

    

    // get customer Notes

    useEffect(()=>{
        noteRefetch();
    },[noteRefetch]);

    // modify note data to add collapse functionality
    const [noteList, setNoteList] = useState([]);
    useEffect(()=>{
        if(note?.length>0){
            
            if(sessionStorage.getItem("editComment")){
                setNoteList(note?.map((note)=>{
                    if(note?._id==sessionStorage.getItem("editComment")){
                        return {...note, isCollapsed: false, commentCollapse: false, editNote:false}
                    }else{
                        return {...note, isCollapsed: true, commentCollapse: true, editNote:false}
                    }
                
                }));
                sessionStorage.removeItem("editComment");
            }else{
                setNoteList(note?.map((note)=>({...note, isCollapsed: true, commentCollapse: true, editNote:false})));
            }
            
        }else{
            setNoteList([]);
        }
    },[note]);

    // handel note main collapse
    const handelCollapse = (id, state)=>{
        setNoteList(noteList?.map((note)=>{
            if(note?._id==id){
                return {...note, isCollapsed: state}
            }else{
                return note;
            }
        }));
    }

    const handelCommentCollapse = (id,state)=>{
        setNoteList(noteList?.map((note)=>{
            if(note?._id==id){
                return {...note, commentCollapse: state}
            }else{
                return note;
            }
        }));
    }

    
    const handelNoteEdit = (id,state)=>{
        setNoteList(noteList?.map((note)=>{
            if(note?._id==id){
                return {...note, editNote: state}
            }else{
                return note;
            }
        }));
    }

    const dispatch = useDispatch();
    
    const handelNoteToBeEdit = (id, noteContent) =>{
        setNoteList(noteList?.map((note)=>{
            if(note?._id==id){
                return {...note, editedNote: noteContent}
            }else{
                return note;
            }
        }));
    }

    const handelCancelNotes = (id) =>{
        setNoteList(noteList?.map((list)=>{
            
            if(list?._id==id){
                return {...list, editedNote: null, editNote:false}
            }else{
                return list;
            }

        }));
    }

    const [updateNote,{loading: updateNoteLoading}] = useMutation(UpdateNoteMutation);
    
    const handelUpdateNotes = async(_id)=>{
        const noteToEdit = await noteList?.find((note)=>note?._id==_id);
        await updateNote({
            variables:{
                input:{
                    _id,
                    note: noteToEdit?.editedNote
                }
            }
        });
        
        await noteRefetch();
    }

    const [newNoteComment] = useMutation(NewNoteCommentMutation);

    const handelComment = (id,state)=>{
        setNoteList(noteList?.map((note)=>{
            if(note?._id==id){
                return {...note, comment: state}
            }else{
                return note;
            }
        }));
    }

    const newComment = async (noteId) =>{

        const note = await noteList?.find((note)=>note?._id==noteId);
        await newNoteComment({
            variables:{
                input: {
                    noteId,
                    comment: note?.comment
                }
            }
        });

        setNoteList(noteList?.map((note)=>{
            if(note?._id==noteId){
                delete note?.comment;
                return {...note}
            }else{
                return note;
            }
        }));

        await noteRefetch();  
        
        
        
        sessionStorage.setItem("editComment", noteId);

    }

    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);   

    const [DeleteNoteComment, {loading: deleteNoteCommentLoading}] = useMutation(DeleteNoteCommentMutation);
    const [deleteNote, {loading: deleteNoteLoading}] = useMutation(DeleteNoteMutation);
    const {noteToggle} = useSelector(state=>state.noteReducer);

    const [newNote, {loading: newNoteLoading}] = useMutation(NewNoteMutation);
    const param = useParams();
    const handelNote = async(value)=>{
        try{
            await newNote({
                variables:{
                    input:{
                        note: value,
                        createdFor: param?.id,
                        objectType: objectType?.Customer
                    }
                }
            });
            dispatch(setNotification({
                notificationState: true,
                error:false,
                message: "Note was added successfully"
            }));
            dispatch(setNoteToggle(false));
            await noteRefetch();

        }catch(err){
            dispatch(setNotification({
                notificationState: true,
                error: true,
                message: err.message
            }));
        }
    }


    const { firstname, lastname, metadata:{imageURL}} = authenticatedUserDetail?.employeeDetail[0];

    return(
        
    <>
        <div className="hr-section">
            <div style={{paddingRight:'16px'}}>

                <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div>Notes</div>
                    <button className='middle-note-btn' onClick={()=>dispatch(setNoteToggle(true))}>Create Note</button>

                </div>

                {/* <div className="text" style={{paddingTop:'68px'}}>
                Take notes about this record to keep track of important info. You can even <b>@mention</b> a teammate
                </div> */}

            {
                updateNoteLoading || noteLoading || deleteNoteLoading || deleteNoteCommentLoading?
                    <Spinner />
                :
                    <div className="notes-list-main">

                        
                        {/* <div className="month-stage">
                            September 2023
                        </div> */}

                        {noteList?.length>0 ? noteList?.map((note)=>{

                            return(
                                <div className="note-list">
                                    <div className='note-list-header'>

                                        {/* note left side */}
                                        <div className='note-header-left' onClick={()=>handelCollapse(note?._id, !note?.isCollapsed)} >
                                            <span>
                                                {note?.isCollapsed?
                                                    <FontAwesomeIcon style={{width:'20px'}} icon={faChevronRight} onClick={()=>handelCollapse(note?._id, !note?.isCollapsed)} />
                                                    :
                                                    <FontAwesomeIcon style={{width:'20px'}}  icon={faChevronDown} onClick={()=>handelCollapse(note?._id, !note?.isCollapsed)} />
                                                }
                                            </span>

                                            {/* who notted */}
                                            <div>
                                                Note &nbsp;
                                                <span className="note-header-subtitle">
                                                    by <span style={{textTransform:'capitalize'}}>{note?.createdBy[0]?.firstname +" "+note?.createdBy[0]?.lastname}</span>
                                                </span>
                                            </div> 
                                            {
                                                note?.isCollapsed?
                                                <span>
                                                    <FontAwesomeIcon icon={faCommentDots} className='comment-icon'/> <small style={{color:'#0091ae'}}>{note?.comments?.length}</small>
                                                </span>
                                                :null
                                            }
                                        </div>


                                        <div className="note-header-right">
                                        
                                        {/* right side action popover */}

                                        {note?.isCollapsed? null :
                                            <Popover
                                                overlayClassName='notePopover'
                                                content={
                                                    <div className='popover-data'>
                                                        <div className="popoverdataitem">
                                                            Pin
                                                        </div>
                                                        {/* <div className="popoverdataitem">
                                                            History
                                                        </div> */}
                                                        <div className={note?.createdBy[0]?._id == authenticatedUserDetail?.employeeId? "popoverdataitem" : "disabled-btn popoverdataitem"} onClick={async()=>{
                                                            await deleteNote({
                                                                variables:{
                                                                    noteId: note?._id
                                                                }
                                                            });
                                                            await noteRefetch();
                                                        }}>
                                                            Delete
                                                        </div>
                                                        
                                                        {/* <div className="popoverdataitem">
                                                            Copy link &nbsp; <FontAwesomeIcon icon={faCopy} />
                                                        </div> */}
                                                    </div>
                                                }
                                                trigger={"click"}
                                            >
                                                <span className='popoverHeadText'>Actions <span className='caret'></span></span>

                                            </Popover>
                                        }

                                        {/* date right side */}
                                            <small>{dayjs(note?.createdAt).format("DD/MM/YYYY HH:mm")}</small>
                                        </div>

                                    </div>
                                    
                                    {note?.isCollapsed? 
                                    
                                    // {/* note actual static text before expand */}
                                    <div className={"note-list-body"}  
                                    style={{cursor: 'pointer'}} onClick={()=>handelCollapse(note?._id, !note?.isCollapsed)}>
                                            
                                        <div className="note-list-content">
                                            <span  style={{overflowWrap:'anywhere'}} dangerouslySetInnerHTML={{__html:note?.note}}></span>
                                            
                                            {!note?.isCollapsed && <FontAwesomeIcon icon={faPen} className='comment-icon' onClick={()=>handelNoteEdit(note?._id, !note?.editNote)}/>}
                                            
                                        </div>
                                    </div>
                                    
                                    :
                                    <>
                                        {/* note edit text or update text */}
                                        {note?.editNote?
                                            
                                            <div
                                                style={{

                                                    width:'-webkit-fill-available',
                                                    
                                                }}
                                                className='note-body-main'
                                            >
                                                    
                                                <div className="notes-body"
                                                    style={{
                                                        minHeight:'180px',
                                                        border: '1px solid rgb(223, 227, 235)',
                                                        background: 'rgb(245, 248, 250)',
                                                        marginTop:'10px',
                                                        marginBottom:'17px',
                                                        cursor: 'pointer'
                                                    }}
                                                >

                                                    <ReactQuill onChange={(e)=>handelNoteToBeEdit(note?._id, e)} value={note?.editedNote || note?.note} theme="snow" />


                                                </div>
                                                
                                                <button className='middle-note-btn' onClick={()=>handelUpdateNotes(note?._id)}>Save</button> &emsp;
                                                <button className='light-btn' onClick={()=>{ handelCancelNotes(note?._id); }}>Cancel</button>
                                            </div>
                                            :

                                            // {/* note actual text while expand this can be updated*/}
                                            <div className={note?.isCollapsed?"note-list-body": "note-list-body note-list-body-uncollapse"}>
                                                
                                                <div className={"note-list-content"}>
                                                    <span style={{overflowWrap:'anywhere'}} dangerouslySetInnerHTML={{__html:note?.note}}></span>
                                                    {note?.createdBy[0]?._id == authenticatedUserDetail?.employeeId?
                                                    <FontAwesomeIcon icon={faPen} className='comment-icon' onClick={()=>handelNoteEdit(note?._id, !note?.editNote)}/>: null}
                                                    
                                                </div>
                                            </div>
                                        }



                                        {/* footer */}

                                        <div className="note-list-footer">
                                            <span onClick={()=>handelCommentCollapse(note?._id ,!note?.commentCollapse)}>
                                                <FontAwesomeIcon icon={faCommentDots}/> {note?.commentCollapse? note?.comments?.length>0? note?.comments?.length +' comment' : 'Add comment' : 'Hide comment'}
                                            </span>
                                            {/* <span>1 association <span className='caret'></span></span> */}
                                        </div>



                                        {/* comment area */}

                                        {note?.commentCollapse? null :
                                            <>
                                                {/* list all comments of particualr note */}
                                                {note?.comments?.map((comment)=>
                                                {
                                                    return(
                                                        <div className='comment-section' style={{marginBottom:'10px'}}>
                                                            {/* <Avatar size={35}
                                                                style={{border:0, width: '40px'}}
                                                                icon={<img src='https://avatars.hubspot.net/default-80'/>}
                                                            /> */}
                                                            {imageURL?
                                                                <Avatar size={35} src={imageURL} />
                                                            :
                                                                <Avatar size={35}> {firstname[0]+lastname[0]} </Avatar>
                                                            }

                                                                <div style={{width:'100%'}}>

                                                                    <div>{note?.commentedBy?.find((commentedPerson)=>commentedPerson?._id==comment?.commentedBy)?.firstname}</div>
                                                                    
                                                                    {authenticatedUserDetail?.employeeId==comment?.commentedBy?
                                                                        <div className="edit-comment-icon" >
                                                                            {/* <FontAwesomeIcon icon={faPencil}/> */}
                                                                            <FontAwesomeIcon onClick={async()=>{
                                                                                const result = confirm("Do you want to delete this comment?");
                                                                                if(result){
                                                                                    await DeleteNoteComment({
                                                                                        variables:{
                                                                                            commentId: comment?._id
                                                                                        }
                                                                                    });
                                                                                    await noteRefetch();
                                                                                    sessionStorage.setItem("editComment", note?._id);
                                                                                }
                                                                            }} icon={faTrashCan}/>
                                                                        </div>
                                                                        
                                                                        :
                                                                        null
                                                                    }

                                                                    <div className="notes-body"  style={{marginTop:'10px', width:'100%', overflowWrap:'anywhere'}} dangerouslySetInnerHTML={{__html:comment?.comment}}></div>

                                                                </div>

                                                        </div>
                                                    )
                                                })}
                                            
                                                {/* new comment */}
                                                <div className='comment-section'>
                                                    {/* <Avatar size={35}
                                                        style={{border:0, width: '40px'}}
                                                    icon={<img src='https://avatars.hubspot.net/default-80'/>}/> */}
                                                    {imageURL?
                                                        <Avatar size={35} src={imageURL} />
                                                    :
                                                        <Avatar size={35}> {firstname[0]+lastname[0]} </Avatar>
                                                    }
                                                    
                                                    <div
                                                        style={{

                                                            width:'100%',
                                                            paddingBottom:'10px'
                                                        }}
                                                    >
                                                        <div style={{textTransform:'capitalize'}}>{authenticatedUserDetail?.employeeDetail[0]?.firstname}</div>
                                                        
                                                        <div className="notes-body"
                                                            style={{
                                                                minHeight:'180px',
                                                                border: '1px solid rgb(223, 227, 235)',
                                                                background: 'rgb(245, 248, 250)',
                                                                marginTop:'10px',
                                                                marginBottom:'27px'
                                                            }}
                                                        >

                                                            <ReactQuill 
                                                                theme="snow" 
                                                                className="comment-editor"
                                                                style={{height:'200px'}}
                                                                onChange={(e)=>handelComment(note?._id, e)}
                                                                value={note?.comment}
                                                            />


                                                        </div>

                                                        <button className='middle-note-btn' onClick={()=>newComment(note?._id)}>Save</button> &emsp;
                                                        <button className='light-btn' onClick={()=>handelCommentCollapse(note?._id, !note?.commentCollapse)}>Cancel</button>

                                                    </div>
                                                </div>

                                            </>
                                        }

                                        {/* comment terminated */}

                                    </>
                                    }

                                </div>
                            );
                        }): <div>Notes not added yet</div>}
                            

                    </div>
            }


            </div>
        </div>

        {
        noteToggle?
        <Notes handelNote={handelNote} loading={newNoteLoading}/>
        : null}

    </>
    

    )
}