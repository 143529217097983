import { gql } from "@apollo/client";

export const newCustomerViewMutation = gql `
mutation($input: CustomerViewInput!){
  newCustomerView(input: $input) {
    response
    message
  }
}
`;


export const updateCustomerViewMutation = gql `
mutation($input: CustomerViewInput!){
  updateCustomerView(input: $input) {
    response
    message
  }
}
`;


export const cloneCustomerViewMutation = gql`
mutation($input: CustomerViewInput!){
  cloneCustomerView(input: $input) {
    message
    response
    success
  }
}
`;

export const deleteCustomerViewMutation = gql`
mutation($id: String!){
  deleteCustomerView(_id: $id) {
    message
    response
    success
  }
}
`;