import "./schedule.css";
import dayjs from "dayjs";
import Spinner from "@src/components/spinner";
import WordLetterAvatar from "@src/components/avatar";
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Avatar, Checkbox, DatePicker, Divider, Dropdown, Input, Menu, Modal, Popover, Select } from "antd";
import { faArrowsSpin, faArrowUpFromBracket, faBackward, faBackwardFast, faBookJournalWhills, faBookOpen, faCancel, faCaretLeft, faCaretRight, faClone, faClose, faEllipsisVertical, faForward, faForwardFast, faLocationDot, faPencil, faPersonArrowUpFromLine, faPersonChalkboard, faPersonCircleCheck, faPersonCirclePlus, faPersonCircleQuestion, faPersonCircleXmark, faPlus, faPlusCircle, faRemove, faSearch, faSearchPlus, faTrashCanArrowUp, faUserPlus,  } from "@fortawesome/free-solid-svg-icons";
import { AddDutyModal } from "./addDuty/addDuty.model";
import { getSitesQuery } from "@src/util/query/site.query";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { getEmployeeByBranchId } from "@src/util/query/employee.query";
import { ScheduleQuery } from "@src/util/query/schedule.query";
import { ScheduleStatusType } from "@src/util/types/scheduleStatus";
import { UpdateScheduleByField } from "@src/util/mutation/schedule.mutation";
import { EditDutyModal } from "./editDutyModal/editDuty.modal";
import { getEmployeeForTrainedUnTrained } from "@src/util/query/employee.query";
import { useNavigate } from "react-router-dom";
import { deleteScheduleByScheduleIdMutation } from "@src/util/mutation/schedule.mutation";
import { CancelDutyModal } from "./cancelDuty/cancelDuty.modal";
import { getUkHolidays } from "@src/util/ukholidays/holidays";
import { getEmployeeForScheduleSearchQuery } from "@src/util/query/employee.query";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CheckFailureModal } from "./checkFail.model";
import { newScheduleCheckMutation } from "@src/util/mutation/scheduleChecks.mutation";
import { newScheduleTimesMutation } from "@src/util/mutation/scheduleTimes.mutation";
import { newScheduleMutation } from "@src/util/mutation/schedule.mutation";
import { scheduleCheckFailureList } from "@src/util/scheduleCheckFailureList";
import { Rota } from "./rota/rota.modal";
import { graphqlWsEndpoint } from "../../config/apollo";
import { useSelector } from "react-redux";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";


import { io } from "socket.io-client";
import { LoadRota } from "./rota/loadRota/loadRota";
import { UnLoadRota } from "./rota/unLoadRota/unLoadRota";
import { CaretDownFilled, UserAddOutlined } from "@ant-design/icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { scheduleUpdateBulkByField } from "../../util/mutation/schedule.mutation";
import { useDispatch } from "react-redux";
import { updateBulkScheduleTimesMutation } from "../../util/mutation/scheduleTimes.mutation";
import { ReAssignEmployee } from "./reAssignEmployee.modal";
import { Confirmation } from "../../components/confirmation/confirmation.modal";
import { EmployeeDetailModal } from "./employeeDetailModal/employeeDetail.Modal";


dayjs.extend(utc);
dayjs.extend(timezone);

// Set default timezone for the application (e.g., Europe/London)
dayjs.tz.setDefault('Europe/London');



// status 
// open == 0
// confirm == 1
// unconfirm Assigned Emp == 2
// Assigned to agency == 3

export const SchedulePage = ({siteId})=>{


    const {Option} = Select;

    const [dateRange, setDateRange] = useState([]);

    const handelDateChange = (date, dateString)=>{
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        setStartDate(startOfWeek);
        setEndDate(endOfWeek);
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
          dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
        localStorage.setItem("scheduleDate", JSON.stringify(dates));

    };

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    
    useEffect(()=>{
        const isStoredDateExist = localStorage.getItem("scheduleDate");
        if(isStoredDateExist){
            const storedDate = JSON.parse(isStoredDateExist);
            const startOfWeek = dayjs(storedDate[0]).startOf('week'); // Get start of the week
            const endOfWeek = dayjs(storedDate[0]).endOf('week'); // Get end of the week
            // set start and end date for schedule
            setStartDate(startOfWeek);
            setEndDate(endOfWeek);
            setDateRange([...storedDate?.map((date)=> dayjs(date))]);

        }else{

            const dateString = dayjs();
            const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
            const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
            // set start and end date for schedule
            setStartDate(startOfWeek);
            setEndDate(endOfWeek);

            const dates = [];
            for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
                dates.push(day); // Format dates as YYYY-MM-DD
            };
            setDateRange([...dates]);

        }
    }, []);

    const nextWeek = ()=>{
        const dateString = dayjs(dateRange[0]).add(1, 'week');
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        setStartDate(startOfWeek);
        setEndDate(endOfWeek);
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
          dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
        localStorage.setItem("scheduleDate", JSON.stringify(dates));

    }

    const previousWeek = ()=>{
        const dateString = dayjs(dateRange[0]).subtract(1, 'week');
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        setStartDate(startOfWeek);
        setEndDate(endOfWeek);
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
          dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
        localStorage.setItem("scheduleDate", JSON.stringify(dates));
    }

    const tableRef = useRef();

    const handelCellMouseEnter = (cell)=>{
        cell?.children[0]?.classList.add('hide');
        cell?.children[1]?.classList.remove('hide');
    };

    const handelCellMouseLeave = (cell)=>{
        cell?.children[0]?.classList.remove('hide');
        cell?.children[1]?.classList.add('hide');
    };

    const [dutyModal, setDutyModal] = useState({});
    const [warningModal, setWarningModal] = useState(false);

    // get site list
    const {data: siteData, loading: siteLoading, refetch} = useQuery(getSitesQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        },
        fetchPolicy:'cache-and-network'
    });


    const [site, setSite] = useState({});
    const [siteAreas, setSiteAreas] = useState([]);
    const [selectedSiteAreas, setSelectedSiteAreas] = useState([]);
    const [siteScheduleAreas, setSiteScheduleAreas] = useState([]);

    
    useEffect(()=>{
        if(siteId && siteData?.sites?.length>0){
            setSite(siteData?.sites?.find((site)=>site?._id==siteId));
        }else if(siteId==undefined && siteData?.sites?.length>0 && localStorage.getItem("scheduledSite")){
            setSite(siteData?.sites?.find((site)=>site?._id==JSON.parse(localStorage.getItem("scheduledSite"))?._id));   
        }
    },[siteId, siteData?.sites]);

    // setSite For already selected site
    useEffect(()=>{
        if(localStorage.getItem("scheduledSite")){
            refetch();
        }
    },[localStorage.getItem("scheduledSite"), siteId]);

    
    useEffect(()=>{
        if(site && Object.keys(site)?.length>0){
            const siteArea = siteData?.sites?.filter((remotesite)=>site._id===remotesite._id)?.map((site)=>site?.siteArea);
            if(siteArea?.length>0){
                setSiteAreas([...siteArea[0]]);
            }
        }
    },[site, siteData?.sites]);

    
    // const {data: scheduleData, loading: scheduleDataLoading, refetch: scheduleRefetch} = useQuery(ScheduleQuery,{
    //     variables:{
    //         siteId: site?._id,
    //         startDate: startDate, 
    //         endDate: endDate
    //     },
    //     skip: !site?._id || startDate==null || endDate==null,
    //     fetchPolicy: 'network-only'
    // });

    // useEffect(()=>{
    //     console.log(startDate, endDate, "end-dateee");
    // },[startDate, endDate]);

    // ====================================
    //         WEB SOCKET FOR SCHEDULE
    // ====================================
    const [scheduleData, setScheduleData] = useState([]);
    const [scheduleDataLoading, setScheduleDataLoading] = useState(false);
    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);  
    
    const handleScheduleUpdate = (data) => {
        // Only update if data has changed (or based on specific conditions)
        setScheduleData((prevData) => {
            if (JSON.stringify(prevData) !== JSON.stringify(data?.response)) {
                return data?.response;
            }
            return prevData; // Keep existing state if no change
        });
    };
   
    useEffect(() => {
        // Connect to the WebSocket server
        const socket = io(graphqlWsEndpoint, {
            path: '/ws/socket.io/'
        });

        // When connected, subscribe to the schedule updates
        socket.on("connect", () => {
            socket.emit("subscribe", {
                action: 'subscribe',
                module: 'schedule',
                param: {
                    siteId: site?._id,
                    startDate: startDate,
                    endDate: endDate,
                    userId: authenticatedUserDetail?._id,  // Replace with actual userId
                },
            }); // Emit the subscription    
        });

        // Listen for schedule updates
        
        socket.on("schedule", handleScheduleUpdate);

        // Cleanup on unmount
        return () => {

            socket.off("schedule", handleScheduleUpdate);
            socket.disconnect();
        };
    }, [site, startDate, endDate, authenticatedUserDetail]);

    
    const scheduleRefetch = async ()=> {}

    useEffect(()=>{
        if(Object.keys(site)?.length>0){
            if(selectedSiteAreas?.length>0){
                const areas = siteData?.sites?.filter((remotesite)=>site._id===remotesite._id)?.map((site)=>site?.siteArea)[0]?.filter((area)=>selectedSiteAreas.includes(area._id));
                const areaWiseJob = areas?.map((area)=>{
                    const jobs = scheduleData;
                    const isAreaJobExist = jobs?.filter((job)=>job.siteAreaId===area?._id);
                    if(isAreaJobExist?.length>0){
                        return {
                            ...area,
                            jobs: isAreaJobExist
                        }
                    }else{
                        return area;
                    }
                });
                setSiteScheduleAreas(areaWiseJob);
            }else{
                const areas = siteData?.sites?.filter((remotesite)=>site._id===remotesite._id)?.map((site)=>site?.siteArea)[0];
                const jobs = scheduleData;
                const areaWiseJob = areas?.map((area)=>{
                    const isAreaJobExist = jobs?.filter((job)=>job.siteAreaId==area?._id);
                    if(isAreaJobExist?.length>0){
                        return {
                            ...area,
                            jobs: isAreaJobExist
                        }
                    }else{
                        return area;
                    }
                });
                setSiteScheduleAreas(areaWiseJob);
            }
        }else{
            setSiteScheduleAreas([]);
        }
    },[selectedSiteAreas, site, scheduleDataLoading, scheduleData]);


    useEffect(()=>{
        const rows = document.querySelectorAll(".addDuty");
        rows.forEach((row)=>{
            row.addEventListener('mouseenter', () => handelCellMouseEnter(row));
            row.addEventListener('mouseleave', () => handelCellMouseLeave(row));        
        });
    },[dateRange, site, siteScheduleAreas]);

    const {data: employeeData, loading: employeeDataLoading} = useQuery(getEmployeeByBranchId,{
        variables:{
            branchId: site?.branchDetail?._id
        },
        fetchPolicy: 'cache-and-network',
        skip: !site?.branchDetail?._id
    });


    // update schedule By Field 
    const [updateSchedule, {loading:updateScheduleLoading}] = useMutation(UpdateScheduleByField);

    const handelUpdateSchedule = async (_id, field, value)=>{
        await updateSchedule({
            variables:{
                input:{
                    _id, field, value
                }
            }
        });
        // if(field=="isPublished" && value===true)
        // await updateSchedule({
        //     variables:{
        //         input:{
        //             _id, field:"status", value: 3
        //         }
        //     }
        // });
        await scheduleRefetch();
    };

    const [editModalData, setEditModalData] = useState({});

    const navigation = useNavigate();

    const [hoveredJob, setHoveredJob] = useState("");
    useEffect(()=>{
        return ()=>{
            setHoveredJob("");
        }
    },[]);

    // get trained employee for left sidebar
    
    const {data: trainedUnTrainedData, loading: trainUnTrainedLoading, refetch: trainUnTrainedRefetch} = useQuery(getEmployeeForTrainedUnTrained,{
        variables:{
            input:{
                siteId: site?._id,
                schedule: {startDate: null, endDate: null}
            }
        },
        fetchPolicy:'network-only'
    });

    const [trainedEmpSite, setTrainedEmpSite] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(()=>{
        setTrainedEmpSite(trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response?.filter((emp)=>emp?.firstname?.toLowerCase()?.includes(search.toLowerCase())));
    },[search, trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response]);

    useEffect(()=>{
        if(trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response){
            setTrainedEmpSite([...trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response]);
        }
    },[trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response]);


    
    const [deleteSchedule, {loading:deleteScheduleLoading}] = useMutation(deleteScheduleByScheduleIdMutation);

    // handel cancel duty
    const [cancelledDuty, setCancelledDuty] = useState({});

    const handelDutyCancel = (job)=>{
        setCancelledDuty(job);
    }

    const [bankHoliday, setBankHoliday] = useState([]);
    const getHolidays = async()=>{
      const holiday = await getUkHolidays();
      setBankHoliday(holiday?.englandWales)
    }
  
    useEffect(()=>{
      getHolidays();
    },[]);

// ==============================================================================

            // drag and drop duty functionality start from here

// ===============================================================================


    const [failureChecks, setFailureChecks] = useState(false);
    const [isAnyCheckTrue, setAnyCheckTrue] = useState(false);
    
    // get employee on search to assign and 
    const [getEmployeeOnSearch, {data: empSearchData, loading: empSearchDataLoading} ]= useLazyQuery(getEmployeeForScheduleSearchQuery);
    const [dragOver, setDragOver] = useState(null);

    const [dragStartData, setDragStartData] = useState(null);

    const [controlHover, setControlHover] = useState(false);
    const handleDragStart = (event, rowId) => {
        setControlHover(false);
        // Set the dragged row's id in the dataTransfer object
        event.dataTransfer.setData('rowId', rowId);
        event.dataTransfer.effectAllowed = 'move';
        setDragStartData(rowId); 

    };

    
    // Function to allow dropping
    const handleDragOver = (event) => {
        event.preventDefault(); // Necessary to allow drop
    };

    const [cloneSchedule, setCloneSchedule] = useState(false);
    
    

    const [newSchedule, {loading: newScheduleLoading}] = useMutation(newScheduleMutation);
    const [newScheduleOperationTime, {loading: newScheduleTimeLoading}] = useMutation(newScheduleTimesMutation);
    const [newScheduleCheck, {loading:newScheduleCheckLoading}] = useMutation(newScheduleCheckMutation);
    // Function to handle drop event
    const handleDrop = async (event, targetRowId) => {
        
        event.preventDefault();
        const draggedRowId = event.dataTransfer.getData('rowId');
        setDragStartData(draggedRowId);

        setDragOver((prev)=>{
            if(prev!==targetRowId){
                setControlHover(false);
            }
            return targetRowId
        });

        if(isAnyCheckTrue && failureChecks && Object.keys(failureChecks)?.length>0 && Object.values(failureChecks)?.some((check)=>check==true) ){
            
            setCheckFailureModalVisible(true);
            setAnyCheckTrue(false);

        }else if(cloneSchedule && !isAnyCheckTrue && !empSearchDataLoading && !employeeDataLoading && !newScheduleLoading && !newScheduleTimeLoading && !newScheduleCheckLoading){

            setCloneSchedule(false);
            setAnyCheckTrue(false);
            await handelSubmit();

        }else if(dragOver && dragStartData && Object.keys(dragStartData)?.length>0 && isAnyCheckTrue==false && !dragStartData?.assignedEmployee){
            handelSubmit();
        }else{
            // console.log("targggg", dragOver && dragStartData , Object.keys(dragStartData)?.length>0 , isAnyCheckTrue==false , !dragStartData?.assignedEmployee, dragStartData);

        }    

    };


    useEffect(()=>{
        if(dragOver && dragStartData?.assignedEmployeeDetail){
            const date = dragOver?.format("DD/MM/YYYY");
            const shiftTime = dragStartData?.shiftStartTime;
            const startDate = dayjs(date+" "+shiftTime, "DD/MM/YYYY HH:mm").utc().subtract(8, 'hour').tz('Europe/London');
            const endDate = dayjs(date+" "+shiftTime, "DD/MM/YYYY HH:mm").utc().add(8, 'hour').tz('Europe/London');
           
            getEmployeeOnSearch({
                variables: {
                    input: {
                        filters:  {advanceFilter: [[{
                            filter: "contain_exactly",
                            filterValue: [dragStartData?.assignedEmployeeDetail?.firstname],
                            operator: 'First name'
                        },{
                            filter: "contain_exactly",
                            filterValue: [dragStartData?.assignedEmployeeDetail?.lastname],
                            operator: 'Last name'
                        }]]},
                        schedule: {startDate: startDate?.local(), endDate}
                    }
                },
                fetchPolicy: 'network-only'
            });

        }

    },[dragOver, dragStartData]);
    

    const [allowedSkilledForSpecificArea, setAllowedSkillsForSpecificArea] = useState([]);
    useEffect(()=>{
        if(site?.siteskills?.length>0){
            setAllowedSkillsForSpecificArea(site?.siteskills?.filter((areaSkill)=>areaSkill?.areaId==dragStartData?.siteAreaId && areaSkill?.status)?.map((skill)=>skill?.skillId))
        }
    },[site, dragStartData]);
    
    useEffect(()=>{
        if(empSearchData?.getEmployeeForScheduleSearch?.response && !empSearchDataLoading && (dragOver) ){
            checkEmployeeIfSuitable(empSearchData?.getEmployeeForScheduleSearch?.response[0]);

        }
    },[empSearchData?.getEmployeeForScheduleSearch?.response, empSearchDataLoading]);

    const[checkFailureModalVisible, setCheckFailureModalVisible] = useState(false);
    // check employee from search if it is suitable or not for specific job
    const checkEmployeeIfSuitable = (emp)=>{

        const empHoliday = emp?.employeeHolidayDetail?.find((eh)=>dayjs(eh?.holidayDate).format("DD/MM/YYYY")===(dayjs(dragOver).format("DD/MM/YYYY")))
        const empHolidayCheck = empHoliday && Object.keys(empHoliday)?.length>0? true: false;
        // if site block or not
        let blockedSite = false;
        if(emp?.blockedSites?.length>0){
            blockedSite = emp?.blockedSites?.find((bs)=>bs?.siteId==site?._id)? true: false;
        }
        // branch check
        const isBranchExist = emp?.branch?.find((b)=>b?._id==site?.branchDetail?._id);
        const branchCheck = isBranchExist? false : true;

        //============ rest period check
        // const date = dragOver.format("DD/MM/YYYY");
       
        const restPeriodCheck = emp?.schedules[0]?.schedule?.hasOwnProperty('_id') ? true: false;
        //============ rest period check terminated
        
        //=================== duty check / experience check
        const trainingCheck= emp?.trainedEmployeeOnSite?.find((te)=>te?.siteId==site?._id)? false: true;

        //=================== duty check / experience check terminated

        //================== skill check 
        const skillCheck = allowedSkilledForSpecificArea?.length==0? false : !allowedSkilledForSpecificArea.every((areaSkill)=> emp?.employeeskills?.find((empSkill)=>empSkill?.skill==areaSkill && empSkill?.isArchive!=true));
        //================== skill check terminated
        
        //======================= visa expiry check
        const visaExpiryCheck = emp?.metadata?.requirevisa=="Yes"? 
            emp?.metadata?.visaexpirydate? 
            dayjs(emp?.metadata?.visaexpirydate, "YYYY/MM/DD").isAfter(dayjs())? false: true
            : true  
        // this check will be true if visa required and date is empty
        :false
        //======================= visa expiry check terminated

        // skill expiry check
        let skillExpiryCheck = false;
        const matchedSkill = emp?.employeeskills?.filter((empSkill)=> 
            allowedSkilledForSpecificArea?.find((skill)=>skill==empSkill?.skill) && empSkill?.isArchive!=true)?.
            filter((skill)=> skill?.fields?.find((f)=>f?.name=="expirydate"))
            .map((skill)=> {
                const isExist = skill?.fields?.find((f)=>f?.name=="expirydate");
                if(isExist){
                    return isExist.value;
                }
            });
            
        if(matchedSkill?.length>0){
            skillExpiryCheck = matchedSkill?.every((date)=> dayjs(date).local().isAfter(dayjs())) ? false : true
        }
            
        
        if(restPeriodCheck || skillCheck || trainingCheck || skillExpiryCheck || visaExpiryCheck || branchCheck || blockedSite || empHolidayCheck){
            // setCheckFailureModalVisible(true);
            setFailureChecks({restPeriodCheck, skillCheck, trainingCheck, skillExpiryCheck, visaExpiryCheck, branchCheck, empHolidayCheck, employeeData: {...emp, site:site?._id}, blockedSite});
            setAnyCheckTrue(true);
            setTimeout(()=>{
                setControlHover(true);
            },0);

        }else{
            setCloneSchedule(true);
            setAnyCheckTrue(false);
            setFailureChecks(false);
            setCheckFailureModalVisible(false);
            setTimeout(()=>{
                setControlHover(true);
            },0);
        }



        
    };


    const calculateTimeDifference = (time1, time2, date) => {
        // Define the start and end times
        const startTime = dayjs(date?.format("DD/MM/YYYY").toString() + " " + time1, 'DD/MM/YYYY HH:mm');
        let endTime = dayjs(date?.format("DD/MM/YYYY").toString() + " " + time2, 'DD/MM/YYYY HH:mm');
    
        // Handle case where endTime is before startTime (next day)
        if (endTime.isBefore(startTime)) {
            endTime = endTime.add(1, 'day');  // Adjust if end time is on the next day
        }
    
        // Calculate the difference in hours and minutes between startTime and endTime
        const diffInMilliseconds = endTime.diff(startTime);
        const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
        const hours = Math.floor(diffInHours);
        const minutes = Math.round((diffInHours - hours) * 60);
    
        // Check if the time period crosses midnight
        const midnight = date.endOf('day');
        const firstHourRaw = endTime.isAfter(midnight) 
            ? midnight.diff(startTime, 'minute') / 60 
            : diffInHours;  // If no cross to next day, use total time
    
        // Adjust firstHour calculation
        const firstHour = firstHourRaw < 0 ? 0 : firstHourRaw;  // Make sure it's not negative
    
        // Prepare the response based on whether it crosses midnight
        const res = {
            firstDate: date?.format("DD/MM/YYYY"),
            secondDate: endTime.isAfter(midnight) ? endTime.format("DD/MM/YYYY") : null,  // If time crosses midnight
            firstDateHours: firstHour.toFixed(2),  // Hours before midnight (or total hours if no cross)
            secondDateHours: endTime.isAfter(midnight) ? (diffInHours - firstHour).toFixed(2) : null,  // Time after midnight, if applicable
            totalHours: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0'),
            firstHourRaw
        }
    
        return res;
    }

    const handelSubmit = async ()=>{
        if(dragOver){
            try{
                
                const shiftTime = calculateTimeDifference(dragStartData?.shiftStartTime, dragStartData?.shiftEndTime, dragOver);
                const scheduleInput = {
                    branchId: site?.branchDetail?._id,
                    customerId: site?.customerDetail?._id,
                    siteGroupId: site?.sitegroupDetail?._id,
                    siteId: site?._id,
                    siteAreaId: dragStartData?.siteAreaId,
                    dutyTypeId: dragStartData?.dutyTypeId, 
                    hourTypeId: dragStartData?.hourTypeId, 
                    shiftStartTime: dragStartData?.shiftStartTime,
                    shiftEndTime: dragStartData?.shiftEndTime,
                    unPaidBreakDuration: dragStartData?.unPaidBreakHour+":"+dragStartData?.unPaidBreakMintue,
                    unBillBreakDuration: dragStartData?.unPaidBillHour+":"+dragStartData?.unPaidBillMintue,
                    bookingRef: dragStartData?.bookingRef, supplierRef: dragStartData?.supplierRef,
                    assignedEmployee: dragStartData?.assignedEmployee || null, 
                    assignedAgency: dragStartData?.assignedAgency || null,
                    status: dragStartData?.assignedEmployee? "2" : dragStartData?.assignedAgency? "3" : "0",
                    date: dayjs(dragOver).format("DD/MM/YYYY"),
                    shiftEndDateTime: 
                    shiftTime?.secondDate? 
                    new Date(dayjs(shiftTime?.secondDate+" "+dragStartData?.shiftEndTime, "DD/MM/YYYY HH:mm"))
                    :
                    new Date(dayjs(shiftTime?.firstDate+" "+dragStartData?.shiftEndTime, "DD/MM/YYYY HH:mm")),

                    firstDate: new Date(dayjs(shiftTime?.firstDate+" "+dragStartData?.shiftStartTime, "DD/MM/YYYY HH:mm")),
                    secondDate: shiftTime?.secondDate? 
                    new Date(dayjs(shiftTime?.secondDate+" "+dragStartData?.shiftEndTime, "DD/MM/YYYY HH:mm"))
                    : null,
                    
                    firstDateHours: shiftTime?.firstDateHours,
                    secondDateHours: shiftTime?.secondDateHours,

                    isPublished: dragStartData?.isPublished,
                    reporting: dragStartData?.reporting,
                    reportTemplateId: dragStartData?.reportTemplateId

                };

                
                const newScheduleResponse = await newSchedule({
                    variables:{
                        input: scheduleInput
                    }
                });
                
                // if employee assigned on this job
                
                if(dragStartData?.assignedEmployee && newScheduleResponse?.data?.newSchedule?.response?._id){
                    const operationalTimeInput = {
                        siteId: site?._id,
                        scheduleId: newScheduleResponse?.data?.newSchedule?.response?._id,
                        operationalStartDate: new Date(dayjs(shiftTime?.firstDate+" "+dragStartData?.shiftStartTime, "DD/MM/YYYY HH:mm")),
                        operationalStartTime:  dragStartData?.shiftStartTime,
                        operationalEndDate: shiftTime?.secondDate? 
                        new Date(dayjs(shiftTime?.secondDate+" "+dragStartData?.shiftEndTime, "DD/MM/YYYY HH:mm"))
                        : new Date(dayjs(shiftTime?.firstDate+" "+dragStartData?.shiftEndTime, "DD/MM/YYYY HH:mm")),
                        operationalEndTime: dragStartData?.shiftEndTime,
                        operationalDutyType: dragStartData?.scheduletimesDetail?.operationalDutyType,
                        operationalHourType: dragStartData?.scheduletimesDetail?.operationalHourType,
                        operationalAssignedEmployee: dragStartData?.scheduletimesDetail?.operationalAssignedEmployee
                    };

                    
                    await newScheduleOperationTime({
                        variables:{
                            input: operationalTimeInput
                        }
                    });

                    const failureIfAny = failureChecks && Object.keys(failureChecks)?.filter((check)=>failureChecks[check]===true)?.map((check)=> scheduleCheckFailureList[check]?.description);
                    
                    await newScheduleCheck({
                        variables:{
                            input:{
                                scheduleId: newScheduleResponse?.data?.newSchedule?.response?._id,
                                employeeId: dragStartData?.assignedEmployee,
                                check: failureIfAny? failureIfAny : [],
                                date: dayjs(),
                            }
                        }
                    });

                    setFailureChecks(null);
                    setAnyCheckTrue(false);
                    setDragStartData(null);
                    setDragOver(null);
                    setCheckFailureModalVisible(false);
                    await scheduleRefetch();
                    

                }else{
                    setFailureChecks(null);
                    setAnyCheckTrue(false);
                    setDragStartData(null);
                    setDragOver(null);
                    setCheckFailureModalVisible(false);
                    await scheduleRefetch();
                }


                
                
            }catch(err){
                await scheduleRefetch();
                setFailureChecks(false);
                setAnyCheckTrue(false);
                setDragStartData(null);
                setDragOver(null);
                setCheckFailureModalVisible(false);

            }
        }
    };

    const handelScheduleClone = async ()=>{
        try{
            setCheckFailureModalVisible(false);
            handelSubmit();

        }catch(err){
            setCheckFailureModalVisible(false);
            setFailureChecks(null);
            setAnyCheckTrue(false);
            setDragStartData(null);
            setDragOver(null);
            setCheckFailureModalVisible(false);
            
        }
    }


// ==============================================================================

            // drag and drop duty functionality terminated from here

// ===============================================================================



// ==============================================================================

            // Rota functionality start from here

// ==============================================================================

    const [createRotaModal, setCreateRotaModal] = useState(false);
    const [loadRotaModal, setLoadRotaModal] = useState(false);
    const [unLoadRotaModal, setUnLoadRotaModal] = useState(false);

    

// ==============================================================================

            // Rota functionality terminate from here

// ==============================================================================

    const [editDutyId, setEditDutyId] = useState(null);

    const [dutyPopoverVisibility, setDutyPopoverVisibility] = useState(null);


    const popoverRef = useRef(null);
    const dutyRef = useRef(null);

    useEffect(() => {
        // Function to handle clicks outside the box
        const handleClickOutside = (event) => {
        
            if (popoverRef?.current && !popoverRef?.current?.contains(event?.target)) {
                // Perform your desired action here
                setDutyPopoverVisibility(null);
            }

        };
    
        // Attach the event listener when the component mounts
        document.addEventListener('click', handleClickOutside);
    
        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
    }, []);




    // ============================================================
    
        // ================== COPY PASTE DUTY STARTED FROM HERE
    
    // ============================================================
    
    
    const [scheduleToCopy, setScheduleToCopy] = useState(null);

  
    const handelCopy = async (selectedElement) => {
        sessionStorage.setItem("selectedElement", JSON.stringify(selectedElement));
        
        const {date:selectedDate} = selectedElement;
        
        const date = selectedDate?.format("DD/MM/YYYY");
        const shiftTime = scheduleToCopy?.shiftStartTime;
        const startDate = dayjs(date+" "+shiftTime, "DD/MM/YYYY HH:mm").utc().subtract(8, 'hour').tz('Europe/London');
        const endDate = dayjs(date+" "+shiftTime, "DD/MM/YYYY HH:mm").utc().add(8, 'hour').tz('Europe/London');
        
        setCheckFailureModalVisible(false);
        setAnyCheckTrue(false);
        setCloneSchedule(false);
        setFailureChecks(false)

        if(scheduleToCopy?.assignedEmployeeDetail){

            const empSearch = await getEmployeeOnSearch({
                variables: {
                    input: {
                        filters:  {advanceFilter: [[{
                            filter: "contain_exactly",
                            filterValue: [scheduleToCopy?.assignedEmployeeDetail?.firstname],
                            operator: 'First name'
                        },{
                            filter: "contain_exactly",
                            filterValue: [scheduleToCopy?.assignedEmployeeDetail?.lastname||""],
                            operator: 'Last name'
                        }]]},
                        schedule: {startDate: startDate?.local(), endDate}
                    }
                },
                fetchPolicy: 'network-only'
            });

            checkEmployeeIfSuitableOnCopy(empSearch?.data?.getEmployeeForScheduleSearch?.response[0], selectedDate);
        }else{
            await handelSubmitForCopyPaste();
        }

    };

    const checkEmployeeIfSuitableOnCopy = async (emp, selectedDate)=>{

        const empHoliday = emp?.employeeHolidayDetail?.find((eh)=>dayjs(eh?.holidayDate).format("DD/MM/YYYY")===(dayjs(selectedDate).format("DD/MM/YYYY")))
        const empHolidayCheck = empHoliday && Object.keys(empHoliday)?.length>0? true: false;
        // if site block or not
        let blockedSite = false;
        if(emp?.blockedSites?.length>0){
            blockedSite = emp?.blockedSites?.find((bs)=>bs?.siteId==site?._id)? true: false;
        }
        // branch check
        const isBranchExist = emp?.branch?.find((b)=>b?._id==site?.branchDetail?._id);
        const branchCheck = isBranchExist? false : true;

        //============ rest period check
       
        const restPeriodCheck = emp?.schedules[0]?.schedule?.hasOwnProperty('_id') ? true: false;
        //============ rest period check terminated
        
        //=================== duty check / experience check
        const trainingCheck= emp?.trainedEmployeeOnSite?.find((te)=>te?.siteId==site?._id)? false: true;

        //=================== duty check / experience check terminated

        //================== skill check 
        const skillCheck = allowedSkilledForSpecificArea?.length==0? false : !allowedSkilledForSpecificArea.every((areaSkill)=> emp?.employeeskills?.find((empSkill)=>empSkill?.skill==areaSkill && empSkill?.isArchive!=true));
        //================== skill check terminated
        
        //======================= visa expiry check
        const visaExpiryCheck = emp?.metadata?.requirevisa=="Yes"? 
            emp?.metadata?.visaexpirydate? 
            dayjs(emp?.metadata?.visaexpirydate, "YYYY/MM/DD").isAfter(dayjs())? false: true
            : true  
        // this check will be true if visa required and date is empty
        :false
        //======================= visa expiry check terminated

        // skill expiry check
        let skillExpiryCheck = false;
        const matchedSkill = emp?.employeeskills?.filter((empSkill)=> 
            allowedSkilledForSpecificArea?.find((skill)=>skill==empSkill?.skill) && empSkill?.isArchive!=true)?.
            filter((skill)=> skill?.fields?.find((f)=>f?.name=="expirydate"))
            .map((skill)=> {
                const isExist = skill?.fields?.find((f)=>f?.name=="expirydate");
                if(isExist){
                    return isExist.value;
                }
            });
            
        if(matchedSkill?.length>0){
            skillExpiryCheck = matchedSkill?.every((date)=> dayjs(date).local().isAfter(dayjs())) ? false : true
        }
            
        
        if(restPeriodCheck || skillCheck || trainingCheck || skillExpiryCheck || visaExpiryCheck || branchCheck || blockedSite || empHolidayCheck){
            // setCheckFailureModalVisible(true);
            setFailureChecks({restPeriodCheck, skillCheck, trainingCheck, skillExpiryCheck, visaExpiryCheck, branchCheck, empHolidayCheck, employeeData: {...emp, site:site?._id}, blockedSite});
           
            setCheckFailureModalVisible(true);

            setTimeout(()=>{
                setControlHover(true);
            },0);

        }else{

            await handelSubmitForCopyPaste();
            setFailureChecks(null);
            setCheckFailureModalVisible(false);
            setTimeout(()=>{
                setControlHover(true);
            },0);

        }



        
    };


    const handelSubmitForCopyPaste = async ()=>{
        const selectedElement = JSON.parse(sessionStorage.getItem("selectedElement"));
        if(selectedElement){
            try{
                
                console.log(selectedElement, "removeItemremoveItem");
                const shiftTime = calculateTimeDifference(scheduleToCopy?.shiftStartTime, scheduleToCopy?.shiftEndTime, dayjs(selectedElement?.date));
                const scheduleInput = {
                    branchId: site?.branchDetail?._id,
                    customerId: site?.customerDetail?._id,
                    siteGroupId: site?.sitegroupDetail?._id,
                    siteId: site?._id,
                    siteAreaId: selectedElement?.siteAreaId,
                    dutyTypeId: scheduleToCopy?.dutyTypeId, 
                    hourTypeId: scheduleToCopy?.hourTypeId, 
                    shiftStartTime: scheduleToCopy?.shiftStartTime,
                    shiftEndTime: scheduleToCopy?.shiftEndTime,
                    unPaidBreakDuration: scheduleToCopy?.unPaidBreakHour+":"+scheduleToCopy?.unPaidBreakMintue,
                    unBillBreakDuration: scheduleToCopy?.unPaidBillHour+":"+scheduleToCopy?.unPaidBillMintue,
                    bookingRef: scheduleToCopy?.bookingRef, supplierRef: scheduleToCopy?.supplierRef,
                    assignedEmployee: scheduleToCopy?.assignedEmployee || null, 
                    assignedAgency: scheduleToCopy?.assignedAgency || null,
                    status: scheduleToCopy?.assignedEmployee? "2" : scheduleToCopy?.assignedAgency? "3" : "0",
                    date: dayjs(selectedElement?.date).format("DD/MM/YYYY"),
                    shiftEndDateTime: 
                    shiftTime?.secondDate? 
                    new Date(dayjs(shiftTime?.secondDate+" "+scheduleToCopy?.shiftEndTime, "DD/MM/YYYY HH:mm"))
                    :
                    new Date(dayjs(shiftTime?.firstDate+" "+scheduleToCopy?.shiftEndTime, "DD/MM/YYYY HH:mm")),

                    firstDate: new Date(dayjs(shiftTime?.firstDate+" "+scheduleToCopy?.shiftStartTime, "DD/MM/YYYY HH:mm")),
                    secondDate: shiftTime?.secondDate? 
                    new Date(dayjs(shiftTime?.secondDate+" "+scheduleToCopy?.shiftEndTime, "DD/MM/YYYY HH:mm"))
                    : null,
                    
                    firstDateHours: shiftTime?.firstDateHours,
                    secondDateHours: shiftTime?.secondDateHours,

                    isPublished: scheduleToCopy?.isPublished,
                    reporting: scheduleToCopy?.reporting,
                    reportTemplateId: scheduleToCopy?.reportTemplateId

                };

                
                const newScheduleResponse = await newSchedule({
                    variables:{
                        input: scheduleInput
                    }
                });

                
                // if employee assigned on this job
                
                if(scheduleToCopy?.assignedEmployee && newScheduleResponse?.data?.newSchedule?.response?._id){
                    const operationalTimeInput = {
                        siteId: site?._id,
                        scheduleId: newScheduleResponse?.data?.newSchedule?.response?._id,
                        operationalStartDate: new Date(dayjs(shiftTime?.firstDate+" "+scheduleToCopy?.shiftStartTime, "DD/MM/YYYY HH:mm")),
                        operationalStartTime:  scheduleToCopy?.shiftStartTime,
                        operationalEndDate: shiftTime?.secondDate? 
                        new Date(dayjs(shiftTime?.secondDate+" "+scheduleToCopy?.shiftEndTime, "DD/MM/YYYY HH:mm"))
                        : new Date(dayjs(shiftTime?.firstDate+" "+scheduleToCopy?.shiftEndTime, "DD/MM/YYYY HH:mm")),
                        operationalEndTime: scheduleToCopy?.shiftEndTime,
                        operationalDutyType: scheduleToCopy?.scheduletimesDetail?.operationalDutyType,
                        operationalHourType: scheduleToCopy?.scheduletimesDetail?.operationalHourType,
                        operationalAssignedEmployee: scheduleToCopy?.scheduletimesDetail?.operationalAssignedEmployee
                    };

                    
                    await newScheduleOperationTime({
                        variables:{
                            input: operationalTimeInput
                        }
                    });

                    const failureIfAny = failureChecks && Object.keys(failureChecks)?.filter((check)=>failureChecks[check]===true)?.map((check)=> scheduleCheckFailureList[check]?.description);
                    
                    await newScheduleCheck({
                        variables:{
                            input:{
                                scheduleId: newScheduleResponse?.data?.newSchedule?.response?._id,
                                employeeId: scheduleToCopy?.assignedEmployee,
                                check: failureIfAny? failureIfAny : [],
                                date: dayjs(),
                            }
                        }
                    });

                    setFailureChecks(null);
                    setAnyCheckTrue(false);
                    setDragStartData(null);
                    setDragOver(null);
                    setCheckFailureModalVisible(false);
                    // setSelectedElement(null);
                    sessionStorage.removeItem("selectedElement");
                    setScheduleToCopy(null);
                    await scheduleRefetch();
                    

                }else{
                    setFailureChecks(null);
                    setAnyCheckTrue(false);
                    setDragStartData(null);
                    setDragOver(null);
                    setCheckFailureModalVisible(false);
                    // setSelectedElement(null);
                    sessionStorage.removeItem("selectedElement");
                    setScheduleToCopy(null);
                    await scheduleRefetch();
                }


                
                
            }catch(err){
                
                await scheduleRefetch();
                setFailureChecks(false);
                setAnyCheckTrue(false);
                setDragStartData(null);
                setDragOver(null);
                setCheckFailureModalVisible(false);
                // setSelectedElement(null);
                sessionStorage.removeItem("selectedElement");
                setScheduleToCopy(null);

            }
        }
    };

    
    // ============================================================
    
        // ================== COPY PASTE DUTY Terminated FROM HERE
    
    // ============================================================


    const [selectedArea, setSelectedArea] = useState([]);
    const [selectedDuties, setSelectedDuties] = useState([]);


    const handelAreaCheckbox = (isChecked, areaId)=>{
        const isAreaExist = selectedArea?.find((area)=>area?.areaId==areaId);
        
        if(isChecked){

            setSelectedDuties(scheduleData?.filter((schedule)=>schedule?.siteAreaId==areaId)
                ?.map((job)=>({
                    dutyId: job?._id,
                    siteAreaId: areaId,
                    startDate: dayjs(job?.firstDate),
                    isChecked: true,
                    status: (
                        job?.isDutyCancel?
                            "Cancelled"
                        :
                        (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.clocking==2 && job?.checkCall[0]?.totalCalls!==0)? 
                        // reporting initated here
                        job?.reporting? 
                            (job?.isReportSubmitted==true? "Completed" :  "Pending" ) 
                        : 
                            "Compeleted" 
                        // reporting terminated here
                            
                        :  job?.checkCall[0]?.totalCalls==0?
                            'Open'

                        : (job?.checkCall[0]?.totalCalls > job?.checkCall[0]?.fulfilledCalls && job?.checkCall[0]?.fulfilledCalls>0 && job?.checkCall[0]?.clocking<2)?

                            'On Duty'

                        :
                            ScheduleStatusType[job?.status]
                    ),
                }))
            );

        }else{

            setSelectedDuties(scheduleData?.filter((schedule)=>schedule?.siteAreaId==areaId)
                ?.map((job)=>({
                    dutyId: job?._id,
                    siteAreaId: areaId,
                    startDate: dayjs(job?.firstDate),
                    status: (
                        job?.isDutyCancel?
                            "Cancelled"
                        :
                        (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.clocking==2 && job?.checkCall[0]?.totalCalls!==0)? 
                        // reporting initated here
                        job?.reporting? 
                            (job?.isReportSubmitted==true? "Completed" :  "Pending" ) 
                        : 
                            "Compeleted" 
                        // reporting terminated here
                            
                        :  job?.checkCall[0]?.totalCalls==0?
                            'Open'

                        : (job?.checkCall[0]?.totalCalls > job?.checkCall[0]?.fulfilledCalls && job?.checkCall[0]?.fulfilledCalls>0 && job?.checkCall[0]?.clocking<2)?

                            'On Duty'

                        :
                            ScheduleStatusType[job?.status]
                    ),
                    isChecked: false
                }))
            );

        }


        if(isAreaExist){
            setSelectedArea(
                selectedArea?.map((area)=>{
                    if(area?.areaId == areaId){
                        return {
                            areaId,
                            isChecked
                        }
                    }else{
                        return area;
                    }
                })
            );
        }else{
            if(selectedArea?.length>0){
                setSelectedArea([...selectedArea, {isChecked, areaId}]);

            }else{
                setSelectedArea([{isChecked, areaId}]);
            }
        }
    };

    const handelDutyCheckbox = (isChecked, dutyId, siteAreaId,job)=>{
        const isScheduleExist = selectedDuties?.find((area)=>area?.dutyId==dutyId);
        if(isScheduleExist){
            setSelectedDuties(
                selectedDuties?.map((area)=>{
                    if(area?.dutyId == dutyId){
                        return {
                            dutyId,
                            siteAreaId,
                            startDate: dayjs(job?.firstDate),
                            status: (

                                job?.isDutyCancel?
                                    "Cancelled"
                                :
                                (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.clocking==2 && job?.checkCall[0]?.totalCalls!==0)? 
                                // reporting initated here
                                job?.reporting? 
                                    (job?.isReportSubmitted==true? "Completed" :  "Pending" ) 
                                : 
                                    "Compeleted" 
                                // reporting terminated here
                                    
                                :  job?.checkCall[0]?.totalCalls==0?
                                    'Open'
        
                                : (job?.checkCall[0]?.totalCalls > job?.checkCall[0]?.fulfilledCalls && job?.checkCall[0]?.fulfilledCalls>0 && job?.checkCall[0]?.clocking<2)?
        
                                    'On Duty'
        
                                :
                                    ScheduleStatusType[job?.status]

                            ),
                            
                            isChecked
                        }
                    }else{
                        return area;
                    }
                })
            );
        }else{
            if(selectedDuties?.length>0){
                setSelectedDuties([...selectedDuties, 
                    {isChecked, dutyId, siteAreaId,

                        startDate: dayjs(job?.firstDate),
                        status: (

                            job?.isDutyCancel?
                                "Cancelled"
                            :
                            (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.clocking==2 && job?.checkCall[0]?.totalCalls!==0)? 
                            // reporting initated here
                            job?.reporting? 
                                (job?.isReportSubmitted==true? "Completed" :  "Pending" ) 
                            : 
                                "Compeleted" 
                            // reporting terminated here
                                
                            :  job?.checkCall[0]?.totalCalls==0?
                                'Open'
    
                            : (job?.checkCall[0]?.totalCalls > job?.checkCall[0]?.fulfilledCalls && job?.checkCall[0]?.fulfilledCalls>0 && job?.checkCall[0]?.clocking<2)?
    
                                'On Duty'
    
                            :
                                ScheduleStatusType[job?.status]
                                
                        )
                    }
                ]);

            }else{
                setSelectedDuties([{isChecked, dutyId, siteAreaId,

                    startDate: dayjs(job?.firstDate),
                    status: (

                        job?.isDutyCancel?
                            "Cancelled"
                        :
                        (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.clocking==2 && job?.checkCall[0]?.totalCalls!==0)? 
                        // reporting initated here
                        job?.reporting? 
                            (job?.isReportSubmitted==true? "Completed" :  "Pending" ) 
                        : 
                            "Compeleted" 
                        // reporting terminated here
                            
                        :  job?.checkCall[0]?.totalCalls==0?
                            'Open'

                        : (job?.checkCall[0]?.totalCalls > job?.checkCall[0]?.fulfilledCalls && job?.checkCall[0]?.fulfilledCalls>0 && job?.checkCall[0]?.clocking<2)?

                            'On Duty'

                        :
                            ScheduleStatusType[job?.status]
                            
                    )
                }]);
            }
        }
    };


    useEffect(()=>{
        console.log(selectedDuties, "selectedDuties");

        if(selectedDuties?.length>0){
            selectedDuties?.map((selectedDuty)=>{

                const isTrue = scheduleData?.filter((sc)=> sc?.siteAreaId==selectedDuty?.siteAreaId)
                ?.every((sc)=> selectedDuties?.find((duty)=>duty?.dutyId ==sc?._id)?.isChecked==true);

                const isAreaExist = selectedArea?.find((area)=>area?.areaId==selectedDuty?.siteAreaId)


                if(isAreaExist){
                    setSelectedArea(
                        selectedArea?.map((area)=>{
                            if(area?.areaId == selectedDuty?.siteAreaId){
                                return {
                                    areaId: selectedDuty?.siteAreaId,
                                    isChecked: isTrue
                                }
                            }else{
                                return area;
                            }
                        })
                    );
                }else{
                    if(selectedArea?.length>0){
                        setSelectedArea([...selectedArea, {isChecked: isTrue, areaId: selectedDuty?.siteAreaId}]);

                    }else{
                        setSelectedArea([{isChecked: isTrue, areaId: selectedDuty?.siteAreaId}]);
                    }
                }
                

            });
        }else{
            setSelectedArea([]);
        }
        

    },[selectedDuties]);

    const [scheduleUpdateBulk, {loading: scheduleUpdateBulkByFieldLoading}] = useMutation(scheduleUpdateBulkByField)
    const [scheduleTimesBulkUpdate, {loading: scheduleTimesBulkUpdateLoading}] = useMutation(updateBulkScheduleTimesMutation);

    
    const dispatch = useDispatch();

    const [swapEmployee, setSwapEmployee] = useState(false);
    
    
    const bulkConfirm = async ()=>{
        try{

            const permittedStatuses = ['Unconfirm'];
            const permittedId = selectedDuties?.filter((sd)=> permittedStatuses.includes(sd?.status))?.map((sc)=> sc?.dutyId);
            await scheduleUpdateBulk({
                variables: {
                    bulkScheduleUpdateInput: {
                        _ids: permittedId,
                        fields: {status: '1'}
                        
                    }
                }
            });
            setSelectedDuties([]);
            setSelectedArea([]);
            dispatch(
                setNotification({
                    error: false,
                    notificationState: true,
                    message: "Bulk operation was performed successfully"
                })
            );

        }catch(err){
            dispatch(
                setNotification({
                    error: true,
                    notificationState: true,
                    message: err.message
                })
            );
        }
    };


    const bulkUnConfirm = async ()=>{
        try{

            const permittedStatuses = ['Confirmed'];
            const permittedId = selectedDuties?.filter((sd)=> permittedStatuses.includes(sd?.status))?.map((sc)=> sc?.dutyId);
            await scheduleUpdateBulk({
                variables: {
                    bulkScheduleUpdateInput: {
                        _ids: permittedId,
                        fields: {status: '2'}
                        
                    }
                }
            });
            setSelectedDuties([]);
            setSelectedArea([]);
            dispatch(
                setNotification({
                    error: false,
                    notificationState: true,
                    message: "Bulk operation was performed successfully"
                })
            );

        }catch(err){
            dispatch(
                setNotification({
                    error: true,
                    notificationState: true,
                    message: err.message
                })
            );
        }
    };
    
   
    const bulkRemoveEmp = async ()=>{
        try{

            const permittedStatuses = ['Confirmed', 'Unconfirm'];
            const permittedId = selectedDuties?.filter((sd)=> permittedStatuses.includes(sd?.status))?.map((sc)=> sc?.dutyId);
            await scheduleUpdateBulk({
                variables: {
                    bulkScheduleUpdateInput: {
                        _ids: permittedId,
                        fields: {status: '0', assignedEmployee: null}
                        
                    }
                }
            });

            await scheduleTimesBulkUpdate({
                variables: {
                    input: {
                        scheduleIds: permittedId,
                        fields: {operationalAssignedEmployee: null, operationalAssignedAgency: null}
                    }
                }
            }); 

            

            setSelectedDuties([]);
            setSelectedArea([]);
            dispatch(
                setNotification({
                    error: false,
                    notificationState: true,
                    message: "Bulk operation was performed successfully"
                })
            );

        }catch(err){
            dispatch(
                setNotification({
                    error: true,
                    notificationState: true,
                    message: err.message
                })
            );
        }
    };

   
    const bulkSwapEmp = async (status, assignedEmployee, failureChecks)=>{
        try{

            const permittedStatuses = ['Confirmed', 'Unconfirm', 'Open'];
            const permittedId = selectedDuties?.filter((sd)=> permittedStatuses.includes(sd?.status))?.map((sc)=> sc?.dutyId);
            await scheduleUpdateBulk({
                variables: {
                    bulkScheduleUpdateInput: {
                        _ids: permittedId,
                        fields: {status, assignedEmployee, clearChecks: true}
                        
                    }
                }
            });
            await scheduleTimesBulkUpdate({
                variables: {
                    input: {
                        scheduleIds: permittedId,
                        fields: {operationalAssignedEmployee: assignedEmployee, operationalAssignedAgency: null}
                    }
                }
            }); 


            const failureIfAny = failureChecks && Object.keys(failureChecks)?.filter((check)=>failureChecks[check]===true)?.map((check)=> scheduleCheckFailureList[check]?.description);

            await Promise.all(permittedId?.map(async(id)=> {
                await newScheduleCheck({
                    variables:{
                        input:{
                            scheduleId: id,
                            employeeId: assignedEmployee,
                            check: failureIfAny? failureIfAny : [],
                            date: dayjs(),
                        }
                    }
                });
            }));
            
            setSelectedDuties([]);
            setSelectedArea([]);
            dispatch(
                setNotification({
                    error: false,
                    notificationState: true,
                    message: "Bulk operation was performed successfully"
                })
            );
            

        }catch(err){
            dispatch(
                setNotification({
                    error: true,
                    notificationState: true,
                    message: err.message
                })
            );
        }
    };

    const [confirmationModal, setConfirmationModal] = useState(null);

    const menu = (
        <Menu >
          <Menu.Item key="1" onClick={()=>{ setConfirmationModal({modal: true, handelConfirmation: bulkConfirm, message: "This action will confirm only un-confirmed duties"}) }}>Confirm</Menu.Item>
          <Menu.Item key="2" onClick={()=>{  setConfirmationModal({modal: true, handelConfirmation: bulkUnConfirm, message: "This action will un-confirm only confirmed duties"}); }}>Unconfirm</Menu.Item>
          <Menu.Item key="3" onClick={()=>{  setConfirmationModal({modal: true, handelConfirmation: ()=>setSwapEmployee(true) , message: "This action will swap the employee on confirmed & unconfirmed duties"}); } }>Swap Employee</Menu.Item>
          <Menu.Item key="4" 
            onClick={()=>{  
                setConfirmationModal({
                    modal: true, 
                    handelConfirmation: bulkRemoveEmp, 
                    message: "This action will remove employee from un-confirm & confirmed duties"
            })}}>
                Remove Employee
            </Menu.Item>

        </Menu>
    );


    
    const [employeeScheduleDetailModal, setEmployeeScheduleDetailModal] = useState({visible: false, employeeId: null});
    


    return (
        <React.Fragment>

            <div className="main-schedule">

                {/* employee search section */}
                <div className="schedule-emp" >
                    <div className="shift-publisher">
                        <div style={{fontSize:'1.17em', fontWeight:'bold'}}> No Shifts </div>   
                        <div className="text" style={{marginBottom:'0'}}>Add shift to publish</div>             
                    </div>
                    <div className="mt16 emp-list-section">
                        <div className="emp-title">
                            <span>Employees</span>
                        {/* <span className="leftPaneLeftTab leftPaneActiveTab">Employees</span> 
                        <span className="leftPaneRightTab">Archived Employees</span>  */}
                        </div>
                        <div className="emp-list">
                            <Input 
                                type="search"
                                className="generic-input-control"
                                placeholder="Search Employees"
                                style={{fontSize:'14px'}}
                                onChange={(e)=>setSearch(e.target.value)}
                                value={search}
                                suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                            />

                            {!employeeDataLoading? 
                                trainedEmpSite?.map((emp)=>(
                                    <div className="employeeProfile">
                                        {emp?.metadata?.imageURL?
                                        <Avatar size={40} src={emp?.metadata?.imageURL} />
                                        :
                                        <WordLetterAvatar word={emp?.firstname+" "+(emp?.lastname || "")}/>
                                        }
                                        <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
                                            <span style={{fontSize:'13px'}}>{emp.firstname+" "+(emp.lastname || "")}</span>
                                            <span style={{fontSize:'10px'}}>{emp?.metadata?.employmenttype}</span>
                                        </div>
                                    </div>
                                ))
                                :
                                <Spinner/>
                            }
                        </div>
                    </div>
                </div>



                {/* schedule section */}
                <div className="schedule" >

                    <div className="schedule-description" style={{marginLeft:'5px'}}>
                        {/* left section */}
                        <div className="schedule-description-left">
                            
                            <div>
                                <div>Site</div>
                                <span onClick={()=>navigation("/user/site-detail/"+site?._id)}>{site?.sitename || "--"}</span>
                            </div>
                            
                            <div>
                                <div>Site Group</div>
                                <span  onClick={()=>navigation("/user/sitegroup-detail/"+site?.sitegroupDetail?._id)}>{site?.sitegroupDetail?.sitegroupname || "--"}</span>
                            </div>
                            
                            <div>
                                <div>Customer</div>
                                <span onClick={()=>navigation("/user/customer-detail/"+site?.customerDetail?._id)}>{site?.customerDetail?.customername || "--"}</span>
                            </div>
                            
                            <div>
                                <div>Branch</div>
                                <span onClick={()=>navigation("/user/branch-detail/"+site?.branchDetail?._id)}>{site?.branchDetail?.branchname || "--"}</span>
                            </div>

                        </div>

                        {/* right section */}
                        <div className="schedule-description-right">
                            
                            <div style={{display:'flex', gap:'20px'}}>
                                <div className="schedule-item">
                                    <div>0</div>
                                    <div>Billed</div>
                                </div>
                                <div className="schedule-item-divider"></div>
                                <div className="schedule-item">
                                    <div>0</div>
                                    <div>Paid</div>
                                </div>
                            </div>

                            <div style={{display:'flex', gap:'20px'}}>
                                <div  className="schedule-item">
                                    <div>0</div>
                                    <div>Contracted</div>
                                </div>

                                <div className="schedule-item-divider"></div>

                                <div className="schedule-item">
                                    <div>0</div>
                                    <div>Current</div>
                                </div>

                                <div className="schedule-item-divider"></div>


                                <div  className="schedule-item">
                                    <div>0</div>
                                    <div>Differences</div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="schedule-section" style={{marginLeft:'5px'}}>

                        <div style={{display:'flex', gap:'20px', alignItems: 'center', width:'30%'}}>

                            <div style={{display:'flex', gap:'15px', width:'108% ', alignItems:'center', overflow:'hidden', paddingLeft:'3px', paddingBottom:'5px'}}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    className="custom-select"
                                    style={{width:'70%'}}
                                    placeholder="Select site"
                                    loading={siteLoading || scheduleDataLoading}
                                    suffixIcon={<div className="dropdowncaret"></div>}
                                    value={Object.keys(site)?.length > 0 && siteData?.sites?.length > 0? JSON.stringify(site) : null}
                                    onChange={(e)=>{setSite(JSON.parse(e)); localStorage.setItem("scheduledSite", e)}}
                                >
                                    {siteData?.sites?.map((site)=>(
                                        <Option value={JSON.stringify(site)}>{site?.sitename}</Option>
                                    ))}
                                </Select>
                                {/* <FontAwesomeIcon  icon={faLocationDot} /> */}
                            </div>

                            {/* <Select
                                showSearch
                                optionFilterProp="children"
                                mode="multiple"
                                className="custom-select schedule-siteArea"
                                style={{width:'100%', background:'transparent'}}
                                placeholder="Select site area"
                                suffixIcon={null}
                                disabled={siteAreas?.length>0? false : true}
                                // value={selectedSiteAreas?.length>0? selectedSiteAreas: []}
                                onChange={(e)=>setSelectedSiteAreas(e)}
                            >
                                {
                                    siteAreas?.length>0 && siteAreas?.map((area)=>(
                                        <Option value={area?._id} label={area?.areaname}>{area?.areaname}</Option>
                                    ))
                                }
                            </Select> */}

                        </div>
                        
                        <div style={{display:'flex', gap:'15px', alignItems: 'center', paddingRight:'16px'}}>
                            
                            <div style={{display:'flex', gap:'5px', alignItems:'center'}}>
                                <FontAwesomeIcon className="icon-bound" icon={faBackward}/>
                                <FontAwesomeIcon className="icon-bound" onClick={previousWeek} style={{height:'16px', width:'16px'}} icon={faCaretLeft} />
                                <div style={{display:'inline-block', position:'relative'}}>
                                    <Input 
                                        className="generic-input-control"
                                        style={{width:'100%', textAlign:'center'}}
                                        value={dateRange?.length>0? dayjs(dateRange[0]).format('DD MMM') +" - "+ dayjs(dateRange[dateRange?.length-1]).format('DD MMM, YYYY') : null}
                                        readOnly
                                    />
                                    <div style={{position:'absolute', top:'0', width:'100%', zIndex:'1', opacity:'0'}}>
                                        <DatePicker className="generic-input-control" onChange={handelDateChange} />
                                    </div>
                                </div>
                                <FontAwesomeIcon className="icon-bound" style={{height:'16px', width:'16px'}} onClick={nextWeek} icon={faCaretRight} />
                                <FontAwesomeIcon className="icon-bound"  icon={faForward} />
                            </div>

                            <div className="icon-bound" onClick={()=>handelDateChange(null, dayjs())}>Today</div>
                            <Popover
                                trigger={"click"}
                                overlayClassName='settingCustomPopover'
                                open={dutyPopoverVisibility=="rota"}
                                content={
                                    <div  className="popover-data">
                                        <div className="popoverdataitem" onClick={()=>{ setCreateRotaModal(true); setDutyPopoverVisibility(null); }}>Create Rota</div>
                                        <div className="popoverdataitem" onClick={()=>{ setLoadRotaModal(true); setDutyPopoverVisibility(null); }}>Load Rota</div>
                                        <div className="popoverdataitem" onClick={()=>{ setUnLoadRotaModal(true); setDutyPopoverVisibility(null); }}>unLoad Rota</div>
                                    </div>
                                }
                            >
                                <div className="icon-bound"
                                    ref={popoverRef}
                                    onClick={()=>setDutyPopoverVisibility("rota")}
                                >
                                    <FontAwesomeIcon ref={popoverRef} icon={faArrowsSpin} />
                                </div>
                            </Popover>

                        </div>

                    </div>

{/* schedule view table for shift display */}

                    <div className="schedule-view" style={empSearchDataLoading?{opacity:0.3, cursor:'not-allowed'}:{opacity:1}}>
                        <table style={{width:'-webkit-fill-available'}} cellSpacing={0} ref={tableRef}  className="general-border">
                            <thead>
                                <tr>
                                    {
                                        dateRange?.map((date)=>(
                                            <th style={dayjs().format('DD/MM/YYYY')==dayjs(date).format("DD/MM/YYYY")?{background: 'lightyellow'}:{}}>
                                                {dayjs(date)?.format("ddd, MMM") +" "+ dayjs(date)?.format("DD")} 
                                                
                                                {
                                                // if the date is today and there is bank holiday on same date
                                                dayjs().format('DD/MM/YYYY')==dayjs(date).format("DD/MM/YYYY") && bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY")) ? <Popover content={bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"))?.title}><sub>&nbsp;(Today & BH)</sub></Popover> 
                                                :
                                                // if there is only bank holiday
                                                bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"))?
                                                <Popover content={bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"))?.title}>
                                                    &nbsp;(BH {bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"))?.title})
                                                </Popover>
                                                :
                                                // if the date is today date
                                                dayjs().format('DD/MM/YYYY')==dayjs(date).format("DD/MM/YYYY")?
                                                <>
                                                    &nbsp;(Today)
                                                </>
                                                : null}
                                            </th>
                                        ))
                                    }
                                </tr>
                            </thead>

                        {!siteLoading || !scheduleDataLoading || !employeeDataLoading ? siteScheduleAreas?.map((area, index)=>(
                            <>
                                    <tr>
                                        <td colSpan={7} className="area">


                                            <div style={{display:'flex', justifyContent:'space-between'}}>

                                                <div>
                                                    {
                                                        (selectedArea?.find((arr)=>arr?.areaId==area?._id)?.isChecked || false)?
                                                        <Checkbox 
                                                            checked={
                                                                (selectedArea?.find((arr)=>arr?.areaId==area?._id)?.isChecked || false) || 
                                                                scheduleData?.filter((sc)=> sc?.siteAreaId==area?._id)
                                                                ?.every((sc)=> selectedDuties?.find((duty)=>duty?.dutyId ==sc?._id)?.isChecked==true)
                                                            } 
                                                            onChange={(e)=>handelAreaCheckbox(e.target.checked, area?._id)}
                                                        >
                                                        <span style={{color:'#00a4bd'}}>{area?.areaname}</span> 
                                                        </Checkbox>  
                                                    :
                                                        
                                                        (selectedDuties?.some((duty)=>duty?.siteAreaId==area?._id && duty?.isChecked)) === true ?
                                                        <Checkbox
                                                            onChange={(e)=>handelAreaCheckbox(e.target.checked, area?._id)}
                                                            indeterminate={
                                                                (selectedArea?.find((arr)=>arr?.areaId==area?._id)?.isChecked || false) ||
                                                                (selectedDuties?.some((duty)=>duty?.siteAreaId==area?._id && duty?.isChecked)) === true
                                                            }
                                                        >
                                                            <span style={{color:'#00a4bd'}}>{area?.areaname}</span> 
                                                        </Checkbox>  

                                                    :
                                                        
                                                        <Checkbox
                                                            onChange={(e)=>handelAreaCheckbox(e.target.checked, area?._id)}
                                                        >
                                                            <span style={{color:'#00a4bd'}}>{area?.areaname}</span> 
                                                        </Checkbox>  

                                                    }
                                                </div>

                                                <div style={{display:'flex', width:'20%', justifyContent:'right', alignItems:'center', gap:'20px'}}>
                                                    {
                                                        (selectedDuties?.some((duty)=>duty?.siteAreaId==area?._id && duty?.isChecked)) === true?

                                                        <Dropdown overlay={menu}>
                                                            <div style={{marginRight:'1%', cursor:'pointer'}}>
                                                                Actions <CaretDownFilled />
                                                            </div>
                                                        </Dropdown>
                                                        :
                                                        null
                                                    }

                                                    {scheduleToCopy && 
                                                    <button style={{cursor:'pointer', color:'red', margin: 0,}} 
                                                        onClick={()=>{
                                                            setScheduleToCopy(null);
                                                            sessionStorage.removeItem('selectedElement')
                                                        }}
                                                    > 
                                                        Cancel Copy 
                                                    </button>  }
                                                </div>

                                            </div>
                              
                                      
                                        </td>
                                    </tr>


                                    <tr key={index}>
                                        {dateRange?.map((date, index)=>{
                                        return(
                                        area?.jobs?.find((job)=>dayjs(job?.date,"DD/MM/YYYY").format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"))?
                                        
                                        <td style={dayjs().format('DD/MM/YYYY')==dayjs(date).format("DD/MM/YYYY")?{background: 'lightyellow', cursor:'grab'}:{cursor:'grab'}}
                                            id={index}
                                            key={index}
                                            onDrop={(e)=>handleDrop(e, date)} 
                                            onDragOverCapture={(e)=>{setDragOver(date);}}
                                            onDragOver={handleDragOver}
                                            // onMouseLeave={()=>{setDragOver(null); setControlHover(false)}}

                                        >

                                            {area?.jobs?.map((job)=>(
                                            dayjs(job?.date, "DD/MM/YYYY").format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY") ?
                                            <div className={job?.isDutyCancel? "dutyDetail cancelDuty" : "dutyDetail"}
                                                key={job?._id+"-"+index}
                                                id={job?._id}
                                                draggable
                                                draggedrowid={job?._id}
                                                onDragStart={(e)=>handleDragStart(e, job)}
                                                onMouseEnter={()=>setHoveredJob(job?._id)} 
                                                onMouseLeave={()=>setHoveredJob("")}
                                            >
                                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                                
                                                {job?.assignedEmployeeDetail?
                                                    <span className="schedule-avatar-container">
                                                        

                                                        <Avatar.Group
                                                            maxCount={1}
                                                            maxStyle={{
                                                            color: '#f56a00',
                                                            backgroundColor: '#fde3cf',
                                                            }}
                                                            style={{width:'45px'}}
                                                        >
                                                            
                                                            {
                                                            job?.assignedEmployeeDetail?.metadata?.imageURL?
                                                                <Avatar 
                                                                    size={40} 
                                                                    src={job?.assignedEmployeeDetail?.metadata?.imageURL} 
                                                                    onClick={()=>setEmployeeScheduleDetailModal({visible: true, employee: job?.assignedEmployeeDetail, jobId: job?._id}) } 
                                                                    style={employeeScheduleDetailModal?.jobId===job?._id?{border: '3px solid lightblue '}:{}}
                                                                />    

                                                            :
                                                                <Avatar size={40} onClick={()=>setEmployeeScheduleDetailModal({visible: true, employee: job?.assignedEmployeeDetail })}> {job?.assignedEmployeeDetail?.firstname?  job?.assignedEmployeeDetail?.firstname[0]+(job?.assignedEmployeeDetail?.lastname? job?.assignedEmployeeDetail?.lastname[0] : "") : ""} </Avatar>    
                                                            }  
                                                        </Avatar.Group>    

                                                    </span>                                                
                                                    :   
                                                    job?.assignedAgencyDetail?
                                                    <span className="schedule-avatar-container">


                                                        

                                                        <Avatar.Group
                                                            maxCount={1}
                                                            maxStyle={{
                                                            color: '#f56a00',
                                                            backgroundColor: '#fde3cf',
                                                            }}
                                                            style={{
                                                                width:'45px'}}
                                                        >
                                                            <Avatar size={40} title={job?.assignedAgencyDetail?.agencyname}> {job?.assignedAgencyDetail?.agencyname[0]+job?.assignedAgencyDetail?.agencyname[job?.assignedAgencyDetail?.agencyname?.length-1]} </Avatar>                                                        

                                                        </Avatar.Group>

                                                    </span>

                                                    : 
                                                    <span className="schedule-avatar-container">


                                                        

                                                        <Avatar.Group
                                                            maxCount={1}
                                                            maxStyle={{
                                                            color: '#f56a00',
                                                            backgroundColor: '#fde3cf',
                                                            }}
                                                            style={{
                                                                width:'45px'}}
                                                        >
                                                            <Avatar size={40} title={job?.assignedAgencyDetail?.agencyname}> 
                                                                <UserAddOutlined />
                                                            </Avatar>                                                        

                                                        </Avatar.Group>

                                                    </span>  
                                                    
                                                }
                                                    <div style={{display:'flex', gap:'1px', flexDirection:'column', width:'-webkit-fill-available'}}>
                                                        {/* duty time and schedule status */}
                                                        <div className="duty-time">
                                                            {/* shift time */}
                                                            <div className="schedule-time">
                                                                { 
                                                                    
                                                                    job?.shiftStartTime
                                                                    
                                                                    + "-" + 
                                                
                                                                    job?.shiftEndTime

                                                                }
                                                            </div>

                                                            {/* status */}
                                                            <div className={
                                                                
                                                                job?.isDutyCancel? 'openDuty schedule-status' :
                                                                job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.clocking==2 && job?.checkCall[0]?.totalCalls!==0? 
                                                                // reporting initated here
                                                                job?.reporting? 
                                                                    (job?.isReportSubmitted==true? "completedDuty schedule-status" :  "pendingDuty schedule-status" ) : 
                                                                    "completedDuty schedule-status" 
                                                                // reporting terminated here
                                                                 
                                                                :  job?.checkCall[0]?.totalCalls==0?
                                                                    'openDuty schedule-status'

                                                                : (job?.checkCall[0]?.totalCalls > job?.checkCall[0]?.fulfilledCalls && job?.checkCall[0]?.fulfilledCalls>0)?
                                                                    'confirmDuty schedule-status'
                                                                :
                                                                job?.status==0?'openDuty schedule-status':
                                                                job?.status==1?'confirmDuty schedule-status':
                                                                job?.status==2?'unConfirmDuty schedule-status':
                                                                job?.status==3?'unallocatedDuty schedule-status'
                                                                : null

                                                            }
                                                                
                                                            >
                                                                
                                                                {
                                                                    job?.isDutyCancel?
                                                                        "Cancelled"
                                                                    :
                                                                    (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.clocking==2 && job?.checkCall[0]?.totalCalls!==0)? 
                                                                    // reporting initated here
                                                                    job?.reporting? 
                                                                        (job?.isReportSubmitted==true? "Completed" :  "Pending" ) 
                                                                    : 
                                                                        "Compeleted" 
                                                                    // reporting terminated here
                                                                     
                                                                    :  job?.checkCall[0]?.totalCalls==0?
                                                                        'Open'

                                                                    : (job?.checkCall[0]?.totalCalls > job?.checkCall[0]?.fulfilledCalls && job?.checkCall[0]?.fulfilledCalls>0 && job?.checkCall[0]?.clocking<2)?

                                                                        'On Duty'
    
                                                                    :
                                                                        ScheduleStatusType[job?.status]
                                                                }
                                                                {/* unconfirmed */}
                                                            </div>
                                                        </div> 

                                                        {/* job type */}
                                                        <div className="schedule-dutyType">{job?.hourTypeDetail[0]?.name}, {job?.dutyTypeDetail[0]?.code}</div> 

                                                        {/* condition to render either agency name or employee or both */}
                                                        {job?.assignedEmployeeDetail?
                                                            
                                                            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'-5px'}} className={"schedule-detail-wrap"}>
                                                                <div className="schedule-name">{
                                                                job?._id==hoveredJob && (job?.assignedEmployeeDetail.firstname+" "+job?.assignedEmployeeDetail.lastname)?.length>17? 
                                                                    job?.assignedEmployeeDetail.firstname 
                                                                : 
                                                                    job?.assignedEmployeeDetail.firstname+" "+(job?.assignedEmployeeDetail.lastname || "")
                                                                }</div>
                                                                <div className="control-icon">
                                                                    {
                                                                        (job?.isDutyCancel || (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.fulfilledCalls>0 && (job?.reporting? job?.isReportSubmitted==true? true : false : true)))?
                                                                        null:
                                                                        <div className="controlledIcon" onClick={()=>{ setEditDutyId(job?._id); setDutyModal({visible:true, title:date, site, area}); }} ><FontAwesomeIcon style={{fontSize:'10px'}} icon={faPencil}/></div> 
                                                                    }
                                                                    <Popover
                                                                        placement="bottom"
                                                                        trigger="click"  
                                                                        open={job?._id == dutyPopoverVisibility}  
                                                                        overlayClassName='settingCustomPopover'
                                                                        content={
                                                                            <span>

                                                                                <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); setScheduleToCopy(job); }}> <FontAwesomeIcon icon={faCopy}/> Copy Duty</div>
                                                                                <div className="divider"/>
                                                                            {(job?.hasOwnProperty("isPublished") && job.status==3) || (job?.checkCall[0]?.fulfilledCalls>0)? null:
                                                                                    <>

                                                                                        {job.status==0? null:
                                                                                        <>
                                                                                            {
                                                                                            (job?.isPublished && job?.checkCall[0]?.fulfilledCalls>0)? null :
                                                                                            job?.isPublished? 
                                                                                                !job?.isDutyCancel &&
                                                                                                <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); handelUpdateSchedule(job?._id, "status", job?.status==1? 2: 1);}}>{job?.status==1?<span> <FontAwesomeIcon icon={faPersonCircleQuestion}/> Unconfirm</span>:<span><FontAwesomeIcon icon={faPersonCircleCheck}/> Confirm</span>} </div>
                                                                                             :
                                                                                                <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); handelUpdateSchedule(job?._id, "isPublished", true);}}> <FontAwesomeIcon icon={faArrowUpFromBracket}/> {"Publish"}</div>
                                                                                            }
                                                                                        </>
                                                                                        }
                                                                                        {job?.status==0? <div className="popoverdataitem" onClick={()=>{setDutyPopoverVisibility(null);}}> <FontAwesomeIcon icon={faSearchPlus}/> Schedule Assist</div> : null}
                                                                                    
                                                                                    </>
                                                                            
                                                                            }    

                                                                                <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); setEditModalData({jobId: job?._id, ...site, selectedarea:area }) }}>
                                                                                  <FontAwesomeIcon icon={faBookJournalWhills} />  Duty Detail 
                                                                                </div>
                                                                            
                                                                            
                                                                            {
                                                                                
                                                                                <>
                                                                                    <Divider
                                                                                        style={{margin:0}}
                                                                                    />
                                                                                {(job?.scheduletimesDetail?.actualEndDate || job?.isDutyCancel || job?.checkCall[0]?.clocking==2) ? null :
                                                                                    <div className="popoverdataitem" onClick={async ()=>{
                                                                                        if(job?.status=="1"){
                                                                                            setDutyPopoverVisibility(null);
                                                                                            handelDutyCancel(job);

                                                                                        }else{
                                                                                            setDutyPopoverVisibility(null);
                                                                                            await deleteSchedule({
                                                                                                variables: {
                                                                                                    scheduleId: job?._id
                                                                                                }
                                                                                            });
                                                                                            await scheduleRefetch();
                                                                                        }
                                                                                    }}>{job?.status=="1"?  <span> <FontAwesomeIcon icon={faCancel}/> Cancel Duty</span> : <span> <FontAwesomeIcon icon={faTrashCanArrowUp}/> Delete Duty</span>}</div>
                                                                                }
                                                                                </>
                                                                            }

                                                                            </span>
                                                                        }
                                                                    >
                                                                        {
                                                                            // (job?.isDutyCancel || (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.fulfilledCalls === job?.checkCall[0]?.totalCalls && (job?.reporting? job?.isReportSubmitted==true? true : false : true)))?
                                                                            // null:
                                                                            <div className="controlledIcon" onClick={()=>setDutyPopoverVisibility(job?._id)} ref={popoverRef}> <FontAwesomeIcon style={{fontSize:'12px'}}  icon={faEllipsisVertical} ref={popoverRef} /> </div> 

                                                                        }
                                                                    </Popover>
                                                                </div> 
                                                            </div>

                                                            // this section would be render when assign to agency
                                                            :job?.assignedAgencyDetail?    
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'-5px'}} className={"schedule-detail-wrap"}>
                                                                    <div className="schedule-name">
                                                                    {
                                                                        job?._id==hoveredJob && (job?.assignedAgencyDetail?.agencyname)?.length>19? 
                                                                            job?.assignedAgencyDetail?.agencyname.slice(0,16)
                                                                        : 
                                                                        job?.assignedAgencyDetail?.agencyname
                                                                    }
                                                                    </div>

                                                                    {
        
                                                                        <div className="control-icon">
                                                                            {
                                                                                (job?.isDutyCancel || (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.fulfilledCalls === job?.checkCall[0]?.totalCalls && (job?.reporting? job?.isReportSubmitted==true? true : false : true)))?
                                                                                    null:

                                                                                <div className="controlledIcon" onClick={()=>{setEditDutyId(job?._id); setDutyModal({visible:true, title:date, site, area}); }}  ><FontAwesomeIcon style={{fontSize:'10px'}} icon={faPencil}/></div> 
                                                                            }
                                                                            
                                                                            <Popover
                                                                                placement="bottom"
                                                                                trigger={"click"}
                                                                                open={job?._id == dutyPopoverVisibility}
                                                                                overlayClassName='settingCustomPopover'
                                                                                content={
                                                                                    <>

                                                                                    <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); setScheduleToCopy(job); }}><FontAwesomeIcon icon={faCopy}/> Copy Duty </div>
                                                                                    <div className="divider"/>
                                                                                    {(job?.hasOwnProperty("isPublished") && job.status==3) || (job?.scheduletimesDetail &&  job?.scheduletimesDetail?.actualStartDate) ? null:
                                                                                            <>
                                                                                                {job.status==0 || job.status==2? null:
                                                                                                <>
                                                                                                    
                                                                                                    {
                                                                                                        job?.isPublished? null :
                                                                                                        <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); handelUpdateSchedule(job?._id, "isPublished", true);}}> <FontAwesomeIcon icon={faArrowUpFromBracket}/> {"Publish"}</div>
                                                                                                    }
                                                                                                    {
                                                                                                        job?.isDutyCancel? null :
                                                                                                        <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); handelUpdateSchedule(job?._id, "status", job?.status==1? 2: 1)}}>{job?.status==1?<span> <FontAwesomeIcon icon={faPersonCircleQuestion}/> Unconfirm</span>:<span><FontAwesomeIcon icon={faPersonCircleCheck}/> Confirm</span>}</div>
                                                                                                    }
                                                                                                </>
                                                                                                }
                                                                                                {job?.status==0? <div className="popoverdataitem" onClick={()=>{setDutyPopoverVisibility(null);}}> <FontAwesomeIcon icon={faSearchPlus}/> Schedule Assist</div> : null}
                                                                                            
                                                                                            </>
                                                                                    
                                                                                    }    
                                                                                        <div className="popoverdataitem" onClick={()=>{setDutyPopoverVisibility(null); setEditModalData({jobId: job?._id, ...site, selectedarea:area })}}>
                                                                                            <FontAwesomeIcon icon={faBookJournalWhills} /> Duty Detail
                                                                                        </div>
                                                                                    
                                                                                    {
                                                                                        (job?.scheduletimesDetail) ? 
                                                                                        <>
                                                                                            <Divider
                                                                                                style={{margin:0}}
                                                                                            />
                                                                                        {
                                                                                            job?.scheduletimesDetail?.actualEndDate? null :
                                                                                        
                                                                                            <div className="popoverdataitem" onClick={async ()=>{
                                                                                                if(job?.status=="1"){
                                                                                                    setDutyPopoverVisibility(null);
                                                                                                    handelDutyCancel(job);

                                                                                                }else{
                                                                                                    setDutyPopoverVisibility(null);
                                                                                                    await deleteSchedule({
                                                                                                        variables: {
                                                                                                            scheduleId: job?._id
                                                                                                        }
                                                                                                    });
                                                                                                    await scheduleRefetch();
                                                                                                }
                                                                                            }}>{job?.status=="1"? <span> <FontAwesomeIcon icon={faCancel}/> Cancel Duty</span> : <span> <FontAwesomeIcon icon={faTrashCanArrowUp}/> Delete Duty</span>}</div>
                                                                                         
                                                                                        }   
                                                                                        </>
                                                                                        :null
                                                                                    }
                                                                                        
                                                                                        
                                                                                    </>
                                                                                }
                                                                            >
                                                                                {
                                                                                    // (job?.isDutyCancel || (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.fulfilledCalls === job?.checkCall[0]?.totalCalls && (job?.reporting? job?.isReportSubmitted==true? true : false : true)))?
                                                                                    // null:
                                                                                    <div className="controlledIcon" onClick={()=>setDutyPopoverVisibility(job?._id)} ref={popoverRef}><FontAwesomeIcon style={{fontSize:'12px'}} ref={popoverRef} icon={faEllipsisVertical}/></div> 

                                                                                }
                                                                            </Popover>
                                                                        </div> 
                                                                    }
                                                                    
                                                                </div>

                                                            
                                                            :
                                                                // this section is when duty is open and nott assigned to any one
                                                            
                                                            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'-5px'}}>
                                                                <div className="schedule-name" style={{visibility:'hidden'}}>xyz</div>
                                                                <div className="control-icon">
                                                                <div className="controlledIcon" onClick={()=>{setEditDutyId(job?._id); setDutyModal({visible:true, title:date, site, area}); }} ><FontAwesomeIcon style={{fontSize:'10px'}}   icon={faPencil}/></div> 
                                                                <Popover
                                                                    placement="bottom"
                                                                    trigger={"click"}
                                                                    open={job?._id == dutyPopoverVisibility}
                                                                    overlayClassName='settingCustomPopover'
                                                                    content={
                                                                        <span>

                                                                            <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); setScheduleToCopy(job); }}> <FontAwesomeIcon icon={faCopy}/> {"Copy Duty"} </div>
                                                                            <div className="divider"/>

                                                                        {(job?.hasOwnProperty("isPublished") && job.status==3) || (job?.scheduletimesDetail &&  job?.scheduletimesDetail?.actualStartDate) ? null:
                                                                                <>
                                                                                    <>
                                                                                    {
                                                                                        job?.isPublished? null
                                                                                        // <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); handelUpdateSchedule(job?._id, "status", job?.status==1? 2: 1)}}>{job?.status==1?"Unconfirm":"Confirm"}</div>
                                                                                        
                                                                                        :
                                                                                        <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); handelUpdateSchedule(job?._id, "isPublished", job?.isPublished? false: true); }}> <FontAwesomeIcon icon={faArrowUpFromBracket}/> {job?.isPublished?"Unpublish":"Publish"}</div>
                                                                                    }
                                                                                    </>
                                                                                    
                                                                                    {job?.status==0? <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); }}> <FontAwesomeIcon icon={faSearchPlus}/> Schedule Assist</div> : null}
                                                                                
                                                                                </>
                                                                        
                                                                        }    
                                                                            <div className="popoverdataitem" onClick={()=>{  setDutyPopoverVisibility(null);  setEditModalData({jobId: job?._id, ...site, selectedarea:area })}}>
                                                                            <FontAwesomeIcon icon={faBookJournalWhills} /> Duty Detail
                                                                            </div>

                                                                            <Divider
                                                                                style={{margin:0}}
                                                                            />

                                                                            <div className="popoverdataitem" onClick={async ()=>{
                                                                                    setDutyPopoverVisibility(null); 
                                                                                    await deleteSchedule({
                                                                                        variables: {
                                                                                            scheduleId: job?._id
                                                                                        }
                                                                                    });
                                                                                    await scheduleRefetch();
                                                                            }}> <FontAwesomeIcon icon={faTrashCanArrowUp}/> Delete Duty</div>
                                                                            
                                                                            
                                                                        </span>
                                                                    }
                                                                >
                                                                    <div className="controlledIcon" onClick={()=>setDutyPopoverVisibility(job?._id)} ref={popoverRef}> <FontAwesomeIcon style={{fontSize:'12px'}}  icon={faEllipsisVertical} ref={popoverRef} /> </div> 
                                                                </Popover>
                                                                </div> 
                                                            </div>     
                                                                  
                                                        }  
                                                              
                                                    </div>

                                                </div>

                                                {/* sign to denote particular parties i.e agency or employee */}
                                                <div className="meta-info">
                                                    {job?.assignedAgency?.length>0? 
                                                        <div className="entity">A</div>
                                                        :job?.assignedEmployeeDetail?.metadata?.agency?
                                                        <Popover content={"Assigned officer is from agency/supplier"}>
                                                            <div className="entity" title="Agency Employee">A</div>
                                                        </Popover>
                                                        :
                                                        null
                                                        // job?.assignedEmployeeDetail?.metadata?.agency
                                                    }
                                                    {
                                                        job?.checkCall[0]?.taskCalls>0 ?
                                                        <Popover
                                                            content={
                                                                job?.checkCall[0]?.taskCalls - job?.checkCall[0]?.compeletedTaskCalls === 0?
                                                                "Duty Tasks are completed."
                                                                :
                                                                job?.checkCall[0]?.taskCalls - job?.checkCall[0]?.compeletedTaskCalls+" task"
                                                            }
                                                        >
                                                            <div className={job?.checkCall[0]?.compeletedTaskCalls==job?.checkCall[0]?.taskCalls? "entity" : "entity warning-color"}
                                                                onClick = {()=> {setDutyPopoverVisibility(null); setEditModalData({jobId: job?._id, ...site, selectedarea:area,  activeTab:'11'}) }}
                                                            >T</div>
                                                        </Popover>
                                                        :null
                                                    }
                                                    {
                                                        // check if report is mandatory for this job
                                                        job?.reporting?
                                                            // check if job report is submitted
                                                            job?.isReportSubmitted?
                                                            <Popover content={"Report was submitted"}>
                                                                <div className={"entity"}onClick = {()=> {setDutyPopoverVisibility(null); setEditModalData({jobId: job?._id, ...site, selectedarea:area,  activeTab:'10'}) }}
                                                                >R</div>
                                                            </Popover>
                                                            :
                                                            <Popover
                                                                content={"Report is mandatory for this job"}
                                                            >
                                                                <div className={"entity reportWarning"}onClick = {()=> {setDutyPopoverVisibility(null); setEditModalData({jobId: job?._id, ...site, selectedarea:area,  activeTab:'10'}) }}
                                                                >R</div>
                                                            </Popover>
                                                        :
                                                        null
                                                    }

                                                    {
                                                        job?.schedulepremium?.length>0?
                                                        <Popover content={"Premium applied on this duty"}>
                                                            <div className={"entity"}>P</div>
                                                        </Popover>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        job?.paybill?.length>0?
                                                        <Popover content={"Pay & Bill for this duty is calculated"}>
                                                            <div className={"entity"}>&pound;</div>
                                                        </Popover>
                                                        :
                                                        null
                                                    }

                                                    {
                                                        job?.schedulechecks && job?.schedulechecks?.length>0?
                                                        job?.schedulechecks[0]?.check?.length>0 
                                                        &&
                                                        // <Popover content={job?.schedulechecks?.check?.length+" check(s) failed"}>
                                                            <div className={"entity"}>{job?.schedulechecks[0]?.check?.length}</div>
                                                        // </Popover>
                                                        :
                                                        null
                                                    }
                                                    
                                                </div>
                                                
                                                {/* <div className="duty-triangle">
                                                </div> */}

                                                <Checkbox 
                                                
                                                    className="dutyCheckbox" 
                                                    checked={selectedDuties?.find((duty)=>duty?.dutyId==job?._id)?.isChecked || false} 
                                                    onChange={(e)=>handelDutyCheckbox(e.target.checked, job?._id, job?.siteAreaId, job)} 
                                                    style={(selectedDuties?.find((duty)=>duty?.dutyId==job?._id)?.isChecked || false)? {display:'flex'} : {}}
                                                ></Checkbox>

                                            </div>
                                            :
                                            null
                                            ))}

                                            <Popover
                                                overlayClassName='settingCustomPopover'
                                                placement="right"
                                                content={scheduleToCopy? 
                                                    <div  className="popover-data">
                                                        <div className="popoverdataitem" onClick={()=>{ handelCopy({date, siteAreaId: area?._id}) }} >  <FontAwesomeIcon icon={faPersonChalkboard}/> Paste Duty</div>
                                                        <div className="popoverdataitem" onClick={()=>{ date.isBefore(dayjs()) && date.format("DD/MM/YYYY")!=dayjs().format("DD/MM/YYYY") ? setDutyModal({visible:true, title:date, site, area}) : setDutyModal({visible:true, title:date, site, area}); }}> <FontAwesomeIcon icon={faPersonCirclePlus}/> New Duty</div>
                                                        <div className="popoverdataitem" onClick={()=>{ 
                                                            setScheduleToCopy(null);
                                                            sessionStorage.removeItem('selectedElement'); 
                                                        }}> <FontAwesomeIcon icon={faCancel}/> Cancel</div>
                                                    </div>
                                                    : null
                                                }
                                            >
                                                <div
                                                    key={index}
                                                    ref={dutyRef}
                                                    className="addDuty" 
                                                    style={{borderBottom:'0'}} 
                                                    onClick={()=>{
                                                        (newScheduleCheckLoading || newScheduleTimeLoading || newScheduleLoading || empSearchDataLoading || scheduleDataLoading || siteLoading || employeeDataLoading || trainUnTrainedLoading)?
                                                        false
                                                        :
                                                        scheduleToCopy?
                                                        null
                                                        :
                                                        date.isBefore(dayjs()) && date.format("DD/MM/YYYY")!=dayjs().format("DD/MM/YYYY") ? setDutyModal({visible:true, title:date, site, area}) : setDutyModal({visible:true, title:date, site, area});
                                                    }}
                                                >

                                                    {
                                                        (newScheduleCheckLoading || newScheduleTimeLoading || newScheduleLoading || empSearchDataLoading || scheduleDataLoading || siteLoading || employeeDataLoading || trainUnTrainedLoading)?
                                                            null
                                                    
                                                        :
                                                        
                                                        dayjs(dragOver).format("DD/MM/YYYY")==dayjs(date).format('DD/MM/YYYY')?
                                                            empSearchDataLoading ?
                                                                    <Spinner color={"#ff7a53"} />
                                                                :
                                                                    <>
                                                                        <span><FontAwesomeIcon icon={faPlus} className="addDutyIco"/></span>
                                                                        {
                                                                            scheduleToCopy? 
                                                                            <span className="hide" style={{fontSize:'13px'}}>Duty Actions</span>                                    
                                                                            :
                                                                            <span className="hide" style={{fontSize:'13px'}}>Add Duty</span>                                    
                                                                        }
                                                                    </>
                                                            :
                                                            <>
                                                                <span><FontAwesomeIcon icon={faPlus} className="addDutyIco"/></span>
                                                                {
                                                                    scheduleToCopy?
                                                                    <span className="hide" style={{fontSize:'13px'}}>Duty Actions</span>                                    
                                                                    :
                                                                    <span className="hide" style={{fontSize:'13px'}}>Add Duty</span>                                    
                                                                }                               
                                                            </> 
                                                    
                                                    }       
                                                    
                                                </div>
                                            </Popover>

                                        </td>

                                            :
                                        <td style={dayjs().format('DD/MM/YYYY')==dayjs(date).format("DD/MM/YYYY")?{background: 'lightyellow'}:{}}
                                            key={index+"-"+date}
                                            // it will handel the last drop
                                            onDrop={(e)=>handleDrop(e, date)} 
                                            // its mean the drop element is on it before drop
                                            onDragOverCapture={(e)=>{setDragOver(date);}}
                                            // it would allow to drop
                                            onDragOver={handleDragOver}
                                            // onMouseLeave={()=>{setDragOver(null); setControlHover(false)}}
                                        >          
                                            <Popover
                                                overlayClassName='settingCustomPopover'
                                                content={
                                                    scheduleToCopy?
                                                    <div  className="popover-data">
                                                         <div className="popoverdataitem" onClick={()=>{ handelCopy({date, siteAreaId: area?._id}) }} >  <FontAwesomeIcon icon={faPersonChalkboard}/> Paste Duty</div>
                                                        <div className="popoverdataitem" onClick={()=>{ date.isBefore(dayjs()) && date.format("DD/MM/YYYY")!=dayjs().format("DD/MM/YYYY") ? setDutyModal({visible:true, title:date, site, area}) : setDutyModal({visible:true, title:date, site, area}); }}> <FontAwesomeIcon icon={faPersonCirclePlus}/> New Duty</div>
                                                        <div className="popoverdataitem" onClick={()=>{ 
                                                            setScheduleToCopy(null);
                                                            sessionStorage.removeItem('selectedElement'); 
                                                        }}> <FontAwesomeIcon icon={faCancel}/> Cancel</div>
                                                    </div>
                                                    : null
                                                }
                                            >

                                                <div 
                                                    key={index} 
                                                    className="addDuty" 
                                                    style={{borderBottom:'0'}} 
                                                    ref={dutyRef}
                                                    onClick={()=>{
                                                        (newScheduleCheckLoading || newScheduleTimeLoading || newScheduleLoading || empSearchDataLoading || scheduleDataLoading  || siteLoading || employeeDataLoading || trainUnTrainedLoading)?
                                                            false
                                                        :
                                                        scheduleToCopy?
                                                            null
                                                        :
                                                            date.isBefore(dayjs()) && date.format("DD/MM/YYYY")!=dayjs().format("DD/MM/YYYY") ? setDutyModal({visible:true, title:date, site, area}) : setDutyModal({visible:true, title:date, site, area}) 
                                                    }}
                                                >
                                                    
                                                    {
                                                        (newScheduleCheckLoading || newScheduleTimeLoading || newScheduleLoading  || empSearchDataLoading || scheduleDataLoading  || siteLoading || employeeDataLoading || trainUnTrainedLoading)?
                                                            null
                                                        
                                                            :

                                                        dayjs(dragOver).format("DD/MM/YYYY")==dayjs(date).format('DD/MM/YYYY')?
                                                        
                                                            empSearchDataLoading ?

                                                                <Spinner color={"#ff7a53"} />
                                                            :
                                        
                                                            <>    
                                                                <span><FontAwesomeIcon icon={faPlus} className="addDutyIco"/></span>
                                                                {
                                                                    scheduleToCopy?
                                                                    <span className="hide" style={{fontSize:'13px'}}>Duty Actions</span>                                    
                                                                    :
                                                                    <span className="hide" style={{fontSize:'13px'}}>Add Duty</span>                                    
                                                                }                                
                                                            </>
                                                        
                                                        :
                                            
                                                        <>    
                                                            <span><FontAwesomeIcon icon={faPlus} className="addDutyIco"/></span>
                                                            {
                                                                scheduleToCopy?
                                                                <span className="hide" style={{fontSize:'13px'}}>Duty Actions</span>                                    
                                                                :
                                                                <span className="hide" style={{fontSize:'13px'}}>Add Duty</span>                                    
                                                            }                             
                                                        </>

                                                    }
                                                    
                                                    
                                                </div>                                                      
                                            </Popover>
                                        </td>
                                        )})}
                                    </tr>
                            </>    
                        )):
                            <tr>
                                <td colSpan={7} style={{textAlign:'center'}}>
                                    <Spinner/>
                                </td>
                            </tr>
                        }
                        </table>
                    </div>

                </div>

            </div>
            
            
            {
                swapEmployee &&
                <ReAssignEmployee 
                    site= {site}
                    confirm= {bulkSwapEmp}
                    selectedDuties= {selectedDuties}
                    visible= {swapEmployee}
                    close= {()=>setSwapEmployee(false)}
                    allowedSkilledForSpecificArea= {allowedSkilledForSpecificArea}
                />
            }
            

            {
                dutyModal?.visible?
                <>
                    <AddDutyModal 
                        dutyModal={dutyModal} 
                        close={()=>{setDutyModal({}); setEditDutyId(null)}} 
                        employeeData={employeeData} 
                        employeeDataLoading={employeeDataLoading} 
                        // scheduleRefetch={()=>{}}
                        scheduleRefetch={scheduleRefetch}
                        editDutyId={editDutyId}
                    />
                   
                </>
                :null
            }
            {
                Object.keys(editModalData)?.length>0 ?
                <EditDutyModal
                    editData={editModalData}
                    close={()=>setEditModalData({})}
                    // refetch={()=>{}}
                    refetch={scheduleRefetch}
                />
                :null
            }

            {/* warning model on passed dates */}
            {
                warningModal?.modal &&
                <WarningModal 
                    visible={warningModal?.modal}
                    close={()=>setWarningModal(false)}
                    message={warningModal?.message}
                />
            }

            {/* cancel duty modal */}
            {
                // cancelled duty object would be treated as boolean to open and close modal
                cancelledDuty && Object.keys(cancelledDuty)?.length>0?
                <CancelDutyModal
                    visible={true}
                    close={()=>setCancelledDuty({})}
                    cancelledDutyData={cancelledDuty}
                    refetch={scheduleRefetch}
                    // refetch={()=>{}}
                />
                : null
            }

            {
                checkFailureModalVisible &&
                <CheckFailureModal 
                    visible={checkFailureModalVisible} 
                    save={scheduleToCopy? handelSubmitForCopyPaste : handelScheduleClone} 
                    failureChecks={failureChecks} 
                    closeForSave={()=>setCheckFailureModalVisible(false)}
                    close={()=>{ 
                        setFailureChecks(null);
                        setAnyCheckTrue(false);
                        setDragStartData(null);
                        setDragOver(null);
                        setCheckFailureModalVisible(false);
                        // setSelectedElement(null);
                        sessionStorage.removeItem("selectedElement");
                        setScheduleToCopy(null);
                        
                    }} 
                />
            }

            {
                createRotaModal &&
                <Rota siteId={site?._id} siteDetail={site} branchId={site?.branchDetail?._id} customerId={site?.customerDetail?._id} close={()=>setCreateRotaModal(false)} visible={createRotaModal}/>
            }

            {
                loadRotaModal &&
                <LoadRota visible={loadRotaModal} siteDetail={site}   close={()=>setLoadRotaModal(false)} />
            }

            {
                unLoadRotaModal &&
                <UnLoadRota visible={unLoadRotaModal} siteDetail={site}  close={()=>setUnLoadRotaModal(false)} />
            }

            {
                confirmationModal?.modal &&
                
                <Confirmation 
                    visible={confirmationModal?.modal} 
                    close={()=>{setConfirmationModal(null);}}
                    message={
                        <div className="confirmationMessage">
                            {confirmationModal?.message}
                        </div>
                    } 
                    error={true}
                    submit={()=>{confirmationModal?.handelConfirmation(); setConfirmationModal(null); }}
                    btnText1='Confirm'
                    btnText2='Cancel'
                    loading={false}
                />
            }

            {
                employeeScheduleDetailModal?.visible &&
                <EmployeeDetailModal employeeScheduleDetailModal={employeeScheduleDetailModal} close={()=>setEmployeeScheduleDetailModal(false)} />
            }
        </React.Fragment>
    )
};

const WarningModal = ({close, visible, message})=>{
    return(
        <Modal
            visible={visible}
            footer={null}
            closable={false}
        >

        <div className='modal-header-title'>
            <span style={{letterSpacing:'0.2px'}}> Warning </span>
            <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
        </div>

            <div className='modal-body'>
                <Alert type="error" message={message} showIcon/>
            </div>

        </Modal>
    );
}