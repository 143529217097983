import { gql } from "@apollo/client";

export const upsertRotaLoadMutation = gql`
mutation($input: RotaLoadedInput!){
  upsertRotaLoad(input: $input) {
    message
    response
  }
}
`;


export const rotaUnloadMutation = gql`
mutation($toDate: String!, $fromDate: String!, $siteId: String!){
  unloadRota(toDate: $toDate, fromDate: $fromDate, siteId: $siteId) {
    message
    response
  }
}
`;