import { useQuery } from "@apollo/client";
import { Col, Row } from "antd";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AgencyDetailPageLeftSideBar } from "./component/leftSidebar";
import { SiteGroupPostSection } from "./component/middleSection";
import { getSingleAgency } from "../../../util/query/agency.query";
import { AgencyPayTable } from "../agencypayTable/agencyPayTable";
import { getNote } from "@src/util/query/note.query";
import { objectType } from "@src/util/types/object.types";
import { AgencyNotesTab } from "./agencyNotes.page";


export const AgencyDetailPage = ()=>{

    const navigate = useNavigate();

    const agencytabs = [
        {title:'General', url:'general'},
        {title:'Pay Table', url:'paytable'},
        {title:'Notes', url:'note'},
    ];

    
    const param = useParams();
    

    const [activeTab, setActiveTab] = useState(param?.tab);
    useEffect(()=>{
        if(!param?.edit && !param?.tab){
            setActiveTab("general");
        }
    },[param]);


    // site group details
    const {data: singleAgency, loading: singleAgencyLoading, refetch} = useQuery(getSingleAgency,{fetchPolicy: 'cache-and-network',
        variables: {
            id: param?.id
        }
    });


    const [agency, setAgency] = useState({});

    useEffect(()=>{
        if(!singleAgencyLoading){
            const {agencyname, metadata} = singleAgency?.agency;
            setAgency({agencyname, ...metadata});
        }
    },[singleAgency,singleAgencyLoading]);

    const [isFieldChanged, setIsFieldChanged] = useState(false);
    const [saveUpdate, setSaveUpdate] = useState(false);



    
    const { data:note , loading: noteLoading, refetch: noteRefetch} = useQuery(getNote,{
        variables:{
            createdFor: param?.id,
            objectType: objectType?.Agency
        },
        fetchPolicy:'network-only',
    });


    return(
        <div>
            {/* site group tabs */}

            <div style={{background: 'rgb(245, 248, 250)', padding: '15px 45px 7px 15px', display:'flex', gap:50, alignItems: 'center', position: 'fixed',  width: '100%', zIndex: '996'}}>
                
                {/* back + user name btn */}
                <div style={{display:'flex', alignItems:'center', gap:'25px', paddingBottom:'8px', width:'50%'}}>

                    <div onClick={()=>navigate("/user/agency")} >
                        <FontAwesomeIcon className='left-chevron-icon' icon={faChevronLeft}/> <span className='text-deco' style={{left: '5%', position: 'relative', fontSize:'14px'}}>Back</span> 
                    </div>

                    <div style={{fontSize:'14px'}}>
                        {singleAgency?.agencyname}
                    </div>
                </div>

                {/* navigation tabs */}
                <div style={{display:'flex', alignItems:'center', gap:'50px', justifyContent:'center', width:'100%'}}>
                    {agencytabs?.map((tab)=>(
                        <div className={activeTab==tab?.url? 'emp-menubar emp-menubar-active': 'emp-menubar'} onClick={()=>{
                            navigate("/user/agency-detail/"+param?.id+"/tab/"+tab?.url); setActiveTab(tab.url); 
                        }}>{tab?.title}</div>
                    ))}
                </div>

                <div style={{width:'50%'}}></div>

            </div>

            <div style={{padding:'50px 5px 5px 5px'}}>
                
                    
                    <div  className="grid-layout">
                        <div className="grid-25">
                            <AgencyDetailPageLeftSideBar
                                agency={agency}
                                loading={singleAgencyLoading}
                                setIsFieldChanged={setIsFieldChanged}
                                saveUpdate={saveUpdate}
                                setSaveUpdate={setSaveUpdate}
                                refetch={refetch}
                                isFieldChanged={isFieldChanged}
                                showDetails={true}
                                
                            />
                        </div>
                        
                        {activeTab=='paytable'?

                            <div className="grid-75">
                                <AgencyPayTable id={param?.id}/>
                            </div>
                        :
                        activeTab=='general'?
                            <div className="grid-75">
                                <SiteGroupPostSection/>
                            </div>

                        :
                        activeTab==='note' &&
                            <div className="grid-75">
                                <AgencyNotesTab note={note?.getNote?.response} noteLoading={noteLoading} noteRefetch={noteRefetch} />
                            </div>
                        }

                    </div>
                
                
            </div>

            
            {isFieldChanged ?
                <div className='hr-action-footer'>
                    <button disabled={singleAgencyLoading} className={singleAgencyLoading? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={()=>setSaveUpdate(true)}>Save</button>
                    <button disabled={singleAgencyLoading} className={singleAgencyLoading? 'disabled-btn drawer-outlined-btn' : 'drawer-outlined-btn'} onClick={async()=> await refetch()}>Cancel</button>
                    {
                        <span className='text' style={{margin: 0}}>You've changed field value</span>
                    }
                </div>
            : null}

        </div>
    )
};