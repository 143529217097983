import "./createTemplateModal.css";
import React, { useEffect, useState } from "react";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { Alert, Checkbox, Collapse, Form, Input, Modal } from "antd";
import Spinner from "@src/components/spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery } from "@apollo/client";
import { getReportDataFieldGroupQuery } from "@src/util/query/reportDataField.query";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { newReportTemplateMutation, updateReportTemplateMutation } from "../../../util/mutation/reportTemplate.mutation";




export const CreateTemplateModal = ({ visible, close, customer, refetchTemplates, templateToBeEdit })=>{

    const {data, loading, refetch} = useQuery(getReportDataFieldGroupQuery);
    const [dataFieldGrp, setDataFieldGrp] = useState([]);
    const [propSearch, setPropSearch] = useState("");
    const [formDataField, setFormDataField] = useState([]);


    useEffect(()=>{
        setFormDataField([]);
        setDataFieldGrp([]);
        refetch();
    },[visible]);
    

    useEffect(()=>{
        if(data?.getReportDataFieldByGroup?.data?.length>0){
            setDataFieldGrp(data?.getReportDataFieldByGroup?.data?.map((props)=>{
                const properties = props?.properties?.filter((prop)=>prop)
                return {
                    ...props,
                    properties
                }
            }))
        }
    },[data?.getReportDataFieldByGroup?.data]);

    useEffect(()=>{
        if(Object.keys(templateToBeEdit)?.length>0){
            setFormDataField(templateToBeEdit?.rawReportFields);
            setTemplatename(templateToBeEdit?.templatename);
        }else{
            setFormDataField([]);
        }
    },[templateToBeEdit]);

    
    useEffect(()=>{

        setDataFieldGrp(data?.getReportDataFieldByGroup?.data?.map((props)=>{
            const properties = props?.properties?.filter((prop)=>prop)
            // const properties = props?.properties?.filter((prop)=>objectData?.find((branchProp)=>branchProp.propertyId==prop._id))
            const filteredProperties = properties?.filter((prop)=>prop.label.toLowerCase().includes(propSearch.toLowerCase()));
           
            return {
                ...props,
                properties: filteredProperties
            }
        }));

    }, [propSearch]);


    const handelDataFieldChange = (status, field) => {
               
        if(status){
            const groups = formDataField?.map((dataField)=>Object.keys(dataField)[0]);
            const isGrpExist = groups?.find((grp)=>grp==field?.groupId);
            if(isGrpExist){
                setFormDataField(formDataField?.map((dataField)=>{
                    if(Object.keys(dataField)[0]==isGrpExist){
                        return {
                            [isGrpExist] : [...Object.values(dataField)[0], {...field}]
                        }
                    }else{
                        return dataField;
                    }
                }));

            }else{
                setFormDataField([...formDataField, 
                    {[field?.groupId] : [{...field}]} 
                ]);
            }
        }else{

            const groups = formDataField?.map((dataField)=>Object.keys(dataField)[0]);
            const isGrpExist = groups?.find((grp)=>grp==field?.groupId);
            if(isGrpExist){
                
                setFormDataField(formDataField?.map((dataField)=>{
                    if(Object.keys(dataField)[0]==isGrpExist){
                        return {
                            [isGrpExist] : [...Object.values(dataField)[0]?.filter((localfield)=>localfield?._id!==field?._id)]
                        }
                    }else{
                        return dataField;
                    }
                }));

            }

        }

    };

    const dispatch = useDispatch();

    const onDragEnd = (result) => {
        const { source, destination, type } = result;

        if (!destination) {
          return;
        }
    
        if (type === 'SECTION') {
          const newData = Array.from(formDataField);
          const [removed] = newData.splice(source.index, 1);
          newData.splice(destination.index, 0, removed);
    
          setFormDataField(newData);
        } else {
          const sourceSectionKey = Object.keys(formDataField[source.droppableId])[0];
          const destSectionKey = Object.keys(formDataField[destination.droppableId])[0];
    
          const sourceItems = Array.from(formDataField[source.droppableId][sourceSectionKey]);
          const [removed] = sourceItems.splice(source.index, 1);
    
          if (source.droppableId === destination.droppableId) {
            sourceItems.splice(destination.index, 0, removed);
            setFormDataField(prevData => {
              const newData = Array.from(prevData);
              newData[source.droppableId][sourceSectionKey] = sourceItems;
              return newData;
            });
          } else {
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "Oops! You can not move fields to other sections"
            }));
            // const destItems = Array.from(formDataField[destination.droppableId][destSectionKey]);
            // destItems.splice(destination.index, 0, removed);
            // setFormDataField(prevData => {
            //   const newData = Array.from(prevData);
            //   newData[source.droppableId][sourceSectionKey] = sourceItems;
            //   newData[destination.droppableId][destSectionKey] = destItems;
            //   return newData;
            // });
          }
        }
    }; 

    const [newReportTemplate, {loading: newReportTemplateLoading}] = useMutation(newReportTemplateMutation);
    const [templatename, setTemplatename] = useState("");
    const [continueModal, setContinueModal] = useState(false);


    const handelSubmit = async () => {
        try{
            
            const data = formDataField?.map((field)=> {
                return {
                    [Object.keys(field)[0]] : [...Object.values(field)[0]?.map((f)=>f?._id)]
                }
            });

            await newReportTemplate({
                variables:{
                    input: {
                        rawReportFields: formDataField,
                        reportFields: data,
                        customerId: customer?._id || null,
                        templatename: templatename
                    }
                }
            });
            setContinueModal(false);
            close();
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Template was created successfully"
            }));

            await refetchTemplates();

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered while creating template"
            }));
        }
    };
    

    const [updateReportTemplate, {loading: updateReportTemplateLoading}] = useMutation(updateReportTemplateMutation);

    const handelEdit = async () => {
        try{
            
            const data = formDataField?.map((field)=> {
                return {
                    [Object.keys(field)[0]] : [...Object.values(field)[0]?.map((f)=>f?._id)]
                }
            });

            await updateReportTemplate({
                variables:{
                    input: {
                        _id: templateToBeEdit?._id,
                        rawReportFields: formDataField,
                        reportFields: data,
                        customerId: customer?._id || null,
                        templatename: templatename
                    }
                }
            });
            setContinueModal(false);
            close();
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Template was updated successfully"
            }));

            await refetchTemplates();

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered while updating template"
            }));
        }
    };


    return (
        <>
            <Modal
                visible={visible}
                width="100%"
                height="100vh"
                className='createUserModal'
                footer={
                    <div 
                        className="drawer-footer"
                        style={{display:'flex', alignItems:'right', justifyContent:'right'}}
                    >

                        <button disabled={loading || newReportTemplateLoading || updateReportTemplateLoading}  className={loading || newReportTemplateLoading || updateReportTemplateLoading ? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={close}>
                            Cancel
                        </button>

                        <button  
                            disabled={loading || newReportTemplateLoading || formDataField?.length==0 || updateReportTemplateLoading} 
                            className={loading || newReportTemplateLoading || formDataField?.length==0 || updateReportTemplateLoading? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'}
                            onClick={()=>setContinueModal(true)}
                        >
                        {newReportTemplateLoading || updateReportTemplateLoading? <Spinner/> : Object.keys(templateToBeEdit)?.length>0? "Update & Continue" : "Save & Continue"}
                        </button>

                    </div>
                }
                closable={false}

            >

                <React.Fragment>

                    <div className='modal-header-title'>
                        <span style={{letterSpacing:'0.2px'}}> {Object.keys(templateToBeEdit)?.length>0? "Update" : "Create"} Template {customer && Object.keys(customer)?.length>0 && "For " + customer?.customername} </span>
                        <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                    </div>

                    <div className='modal-body' style={{margin:'0', display:'flex', gap:'50px', padding: 0, height: '83vh', overflow:'auto'}}>
                        
                        <div className="createTemplateLeft">
                            <div className="h5" style={{letterSpacing: '0.4px'}}>Report Data Fields</div>
                            <Input 
                                type="search"
                                style={{ width: '-webkit-fill-available', backgroundColor: 'white'  }}
                                placeholder='Search'
                                className='generic-input-control'
                                // value={propSearch}
                                // onChange={(e)=>setPropSearch(e.target.value)}
                                suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                            />
                            <div className="proplistView">
                                {
                                    loading?
                                        <Spinner/>
                                    :
                                    dataFieldGrp?.map((list, index)=>(
                                        list?.properties?.length>0?
                                        propSearch?.length>1? 
                                        <Collapse  activeKey={propSearch?.length>1?[index?.toString()]:[]} >
                                            <Collapse.Panel  key={index.toString()} header={<div className="proplistHead">{list._id}</div>}>
                                                {list?.properties?.map((prop)=>{

                                                   
                                                    return(
            
                                                        <div className="list-item popoverdataitem" style={{textWrap:'balance'}}>
                                                            <Checkbox
                                                                style={{width:'100%'}}
                                                                onChange={(e)=>handelDataFieldChange(e.target.checked, prop)}
                                                                value={prop?.label}
                                                                checked={formDataField?.some((dataField)=>Object.values(dataField)[0])?.find((reportField)=>reportField?._id==prop?._id)}
                                                            >
                                                                {prop.label} 
                                                            </Checkbox>    
                                                        </div>
            
                                                    )}
                                                )}
                                            </Collapse.Panel>
                                        </Collapse>
                                        :
                                        <Collapse>
                                            <Collapse.Panel  key={index.toString()} header={<div className="proplistHead">{list._id}</div>}>
                                                {list?.properties?.map((prop)=>{
                                                    
                                                   

                                                    return(
            
                                                        
                                                        <div className="list-item popoverdataitem" >
                                                            <Checkbox
                                                                style={{width:'100%'}}
                                                                onChange={(e)=>handelDataFieldChange(e.target.checked, prop)}
                                                                value={prop?.label}
                                                                checked={
                                                                    formDataField?.some(
                                                                        (dataField)=>Object.values(dataField)[0]?.find((df)=>df?._id == prop?._id)
                                                                    )
                                                                }
                                                            >
                                                                {prop.label} 
                                                            </Checkbox>    
                                                        </div>
            
                                                    )}

                                                )}
                                            </Collapse.Panel>
                                        </Collapse>
                                        
                                        : null
                                    ))
                                }
                            </div>
                        </div>
                        
                        <div className="createTemplateRight border-radius">

                            {/* <div className="h5 modal-header-title" style={{letterSpacing: '0.4px', color:'white'}}>Report Form</div> */}
                            <div style={{padding:'0px', margin:'0px -12px'}}>
                                <div className='hr-info-title'>
                                    Report Form
                                </div>
                            </div>


                            {formDataField?.length>0?
                                <Alert
                                    description={<b className='info-alert'>Each section and each field within the section can be reorder.</b>}
                                    type="info"
                                    style={{margin:'20px 0'}}
                                    closable
                                    closeText={<FontAwesomeIcon  className='alert-close-icon' icon={faTimes}/>}
                                />
                                : null
                            }

                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="all-sections" type="SECTION">
                                    {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {formDataField.map((section, index) => {
                                        const sectionKey = Object?.keys(section)[0];
                                        const sectionItems = section[sectionKey] || [];
                                        const groupName = sectionItems[0]?.groupName || "";
                                        return (
                                            sectionItems?.length>0?
                                            <Draggable key={sectionKey} draggableId={sectionKey} index={index}>
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <fieldset style={{marginTop:'16px'}} className='border-radius'>
                                                        <legend>
                                                            {groupName}
                                                        </legend>
                                                        
                                                        <Droppable droppableId={`${index}`} type="ITEM">
                                                            {(provided, snapshot) => (
                                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                {sectionItems.map((item, itemIndex) => (
                                                                <Draggable key={item._id} draggableId={item._id} index={itemIndex}>
                                                                    {(provided) => (
                                                                    <span className="allprop input form-section-body">
                                                                        <div                                                                
                                                                            className="edit-form-input-control input inputItemList"
                                                                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                            style={{
                                                                                opacity: snapshot.isDragging ? 0.5 : 1,
                                                                                width: '80%',
                                                                                marginLeft: '5%',
                                                                                ...provided.draggableProps.style,
                                                                                display:'flex',
                                                                                justifyContent:'space-between',
                                                                                alignItems:'center'
                                                                            }}
                                                                        >
                                                                            <span className="draggeableTable-text" >{item.label}</span>
                                                                            {/* <FontAwesomeIcon
                                                                                style={{fontSize:'16px', cursor:'pointer', width: '30px'}} 
                                                                                icon={faClose}
                                                                                onClick={()=>handelDataFieldChange(false, item)}
                                                                            /> */}
                                                                        </div>
                                                                        
                                                                    </span>
                                                                    )}
                                                                </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                            )}
                                                        </Droppable>
                                                    </fieldset>
                                                </div>
                                            )}
                                            </Draggable>
                                            :
                                            null
                                        );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            
                        

                        </div>
                        

                    </div>

                </React.Fragment>

            </Modal>

            {/* name modal */}
            <Modal
                visible={continueModal}
                width={600}
                footer={
                    <div 
                        className="drawer-footer"
                        style={{display:'flex', alignItems:'right', justifyContent:'right'}}
                    >

                        <button disabled={loading || newReportTemplateLoading || updateReportTemplateLoading}  className={loading || newReportTemplateLoading  || updateReportTemplateLoading ? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={()=>setContinueModal(false)}>
                            Cancel
                        </button>

                        <button  
                            disabled={loading || newReportTemplateLoading || updateReportTemplateLoading} 
                            className={loading || newReportTemplateLoading || updateReportTemplateLoading? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'}
                            onClick={Object.keys(templateToBeEdit)?.length>0? handelEdit : handelSubmit}
                        >
                        {newReportTemplateLoading || updateReportTemplateLoading? <Spinner/> : Object.keys(templateToBeEdit)?.length>0? "Update" :"Create"}
                        </button>

                    </div>
                }
                closable={false}
            >
                <React.Fragment>

                    <div className='modal-header-title'>
                        <span style={{letterSpacing:'0.2px'}}>  {Object.keys(templateToBeEdit)?.length>0? "Update " : "Set "}  Template Name </span>
                        <span  onClick={()=>setContinueModal(false)}><FontAwesomeIcon className='close' icon={faClose}/></span>
                    </div>

                    <div className='modal-body' style={{margin:'10px'}}>
                        <Form.Item>
                            <label> Template name </label>
                            <Input 
                                className="generic-input-control"
                                placeholder="Template name"
                                value={templatename}
                                onChange={(e)=>setTemplatename(e.target.value)}
                            />
                        </Form.Item>
                    </div>


                </React.Fragment>

            </Modal>
        </>
    );
}