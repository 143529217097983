// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createTemplateLeft{
    width: 20%;
    box-shadow: 0 0 15px rgba(0,0,0,.19);
    padding: 32px;
    height: max-content;
    min-height: 76vh;
    position: fixed;
}

.createTemplateRight{
    width: 40%;
    box-shadow: 0 0 15px rgba(0,0,0,.19);
    padding: 32px 40px 50px 40px;
    margin: 32px;
    margin-left: 35%;
    height: max-content;
}`, "",{"version":3,"sources":["webpack://./src/pages/setting/reportTemplate/createTemplateModal.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,oCAAoC;IACpC,4BAA4B;IAC5B,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".createTemplateLeft{\n    width: 20%;\n    box-shadow: 0 0 15px rgba(0,0,0,.19);\n    padding: 32px;\n    height: max-content;\n    min-height: 76vh;\n    position: fixed;\n}\n\n.createTemplateRight{\n    width: 40%;\n    box-shadow: 0 0 15px rgba(0,0,0,.19);\n    padding: 32px 40px 50px 40px;\n    margin: 32px;\n    margin-left: 35%;\n    height: max-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
