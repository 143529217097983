import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSiteGroup } from "@src/util/query/siteGroup.query";
import { useQuery } from "@apollo/client";
import { objectType } from "@src/util/types/object.types";
import { getNote } from "@src/util/query/note.query";
import { SiteGroupDetailPageLeftSideBar } from "./component/leftSidebar";
import { SiteGroupPostSection } from "./component/middleSection";
import { SiteGroupPayTable } from "../sitegroupPayTable/sitegroupPayTable";
import { SiteGroupNotesTab } from "./sitegroupNote.tab";

export const SiteGroupDetailPage = ()=>{

    const param = useParams();
    const navigate = useNavigate();

    const siteGrouptabs = [
        {title: 'General', url: 'general'},
        {title: 'Pay Table', url: 'paytable'},
        {title: 'Notes', url: 'note'},
    ];

    const [activeTab, setActiveTab] = useState(param?.tab);


    useEffect(()=>{
        if(!param?.edit && !param?.tab){
            setActiveTab("general");
        }
    },[param]);

    // site group details
    const {data: siteGroupData, loading: siteGroupLoading, refetch} = useQuery(getSiteGroup,{fetchPolicy: 'cache-and-network',
        variables: {
            id: param?.id
        }
    });

    const [siteGroup, setSiteGroup] = useState({});

    useEffect(()=>{
        if(!siteGroupLoading){
            const {sitegroupname, customer, branch, metadata, customerId, branchId} = siteGroupData?.siteGroup?.response;
            setSiteGroup({sitegroupname, customer, branch, customerId, branchId, ...metadata});
        }
    },[siteGroupData,siteGroupLoading]);

    const [isFieldChanged, setIsFieldChanged] = useState(false);
    const [saveUpdate, setSaveUpdate] = useState(false);


    
    const { data:note , loading: noteLoading, refetch: noteRefetch} = useQuery(getNote,{
        variables:{
            createdFor: param?.id,
            objectType: objectType?.SiteGroup
        },
        fetchPolicy:'network-only',
    });


    return(
        <>
        <div>
            {/* site group tabs */}

            <div style={{background: 'rgb(245, 248, 250)', padding: '15px 45px 7px 15px', display:'flex', gap:50, alignItems: 'center', position: 'fixed',  width: '100%', zIndex: '996'}}>
                
                {/* back + user name btn */}
                <div style={{display:'flex', alignItems:'center', gap:'25px', paddingBottom:'8px', width:'50%'}}>

                    <div onClick={()=>navigate("/user/sitegroup")} >
                        <FontAwesomeIcon className='left-chevron-icon' icon={faChevronLeft}/> <span className='text-deco' style={{left: '5%', position: 'relative', fontSize:'14px'}}>Back</span> 
                    </div>

                    <div style={{fontSize:'14px'}}>
                        {siteGroup?.sitegroupname}
                    </div>
                </div>

                {/* navigation tabs */}
                <div style={{display:'flex', alignItems:'center', gap:'50px', justifyContent:'center', width:'100%'}}>
                    {siteGrouptabs?.map((tab)=>(
                        <div className={activeTab==tab?.url? 'emp-menubar emp-menubar-active': 'emp-menubar'} onClick={async ()=>{
                            navigate("/user/sitegroup-detail/"+param?.id+"/tab/"+tab?.url); setActiveTab(tab.url); 
                        }}>{tab?.title}</div>
                    ))}
                </div>

                <div style={{width:'50%'}}></div>

            </div>

            <div style={{padding:'50px 5px 5px 5px'}}>
                
                    
                    <div className="grid-layout">
                        <div className="grid-25">
                            <SiteGroupDetailPageLeftSideBar
                                siteGroup={siteGroup}
                                loading={siteGroupLoading}
                                setIsFieldChanged={setIsFieldChanged}
                                saveUpdate={saveUpdate}
                                setSaveUpdate={setSaveUpdate}
                                refetch={refetch}
                            />
                        </div>

                        <div className="grid-75">
                        {
                            activeTab=="general"?
                            <SiteGroupPostSection/>
                            :
                            activeTab==="paytable"?
                            <SiteGroupPayTable id={param?.id}/>
                            :
                            activeTab==="note" &&
                            <SiteGroupNotesTab 
                                
                                note={note?.getNote?.response} 
                                noteLoading={noteLoading} 
                                noteRefetch={noteRefetch} 
                            />
                            
                        }

                        </div>
                    </div>
                
                
            </div>

        </div>
        {isFieldChanged>0 ?
            <div className='action-footer'>
                <button disabled={siteGroupLoading} className={siteGroupLoading? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={()=>setSaveUpdate(true)}>Save</button>
                <button disabled={siteGroupLoading} className={siteGroupLoading? 'disabled-btn drawer-outlined-btn' : 'drawer-outlined-btn'} onClick={async()=> await refetch()}>Cancel</button>
                {
                    <span className='text' style={{margin: 0}}>You've changed field value</span>
                }
            </div>
        : null}
        </>
    )
};