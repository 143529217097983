// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publicreportbtn{
    background: rgb(46, 63, 80);
    border: 1px;
    height: 30px;
    width: 50px;
    color: white;
    font-size: 20px;
}

.scheduleReportField{
    width:80%;
    margin:auto;
}

.scheduleReportMain{
    display: table;
    margin: auto !important;
    width: 80%;
    margin-bottom: 100px !important;
}

@media only screen and (max-width: 768px) {
    .scheduleReportMain{
        display: block !important;
        width: 100% !important;
    }
    .scheduleReportField{
        width:100% !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/schedule/publicAccessDutyReport/style.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,WAAW;IACX,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,WAAW;AACf;;AAEA;IACI,cAAc;IACd,uBAAuB;IACvB,UAAU;IACV,+BAA+B;AACnC;;AAEA;IACI;QACI,yBAAyB;QACzB,sBAAsB;IAC1B;IACA;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".publicreportbtn{\n    background: rgb(46, 63, 80);\n    border: 1px;\n    height: 30px;\n    width: 50px;\n    color: white;\n    font-size: 20px;\n}\n\n.scheduleReportField{\n    width:80%;\n    margin:auto;\n}\n\n.scheduleReportMain{\n    display: table;\n    margin: auto !important;\n    width: 80%;\n    margin-bottom: 100px !important;\n}\n\n@media only screen and (max-width: 768px) {\n    .scheduleReportMain{\n        display: block !important;\n        width: 100% !important;\n    }\n    .scheduleReportField{\n        width:100% !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
