import { useMutation, useQuery } from "@apollo/client";
import { Checkbox, Form, Table, Tabs } from "antd";
import Input from "antd/es/input/Input";
import TabPane from "antd/es/tabs/TabPane";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Spinner from "@src/components/spinner";
import { upsertSiteCheckCallMutation } from "@src/util/mutation/siteCheckCall.mutation";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { getSiteCheckCallBySiteId } from "@src/util/query/siteCheckCall.query";
import { deleteSiteCheckCallCalendarMutation, newSiteCheckCallCalendarMutation, updateSiteCheckCallCalendarMutation } from "../../../../../util/mutation/siteCheckCallCalendar.mutation";
import { getSiteCheckCallCalendarQuery } from "@src/util/query/siteCheckCallCalendar.query";

export const SiteCheckCalls = ({siteId})=>{

    const [onPreTimeWindow, setOnPreTimeWindow] = useState("");
    const [onPostTimeWindow, setOnPostTimeWindow] = useState("");
    const [offPreTimeWindow, setOffPreTimeWindow] = useState("");
    const [offPostTimeWindow, setOffPostTimeWindow] = useState("");
    const [chkPreTime, setChkPreTime] = useState("");
    const [chkPostTime, setChkPostTime] = useState("");
    const [allowCallsPostPreWindow, setAllowCallsPostPreWindow] = useState(false);
    const [allowUnidentifiedCalls, setAllowUnidentifiedCalls] = useState(false);
    const [signatureClockOut, setSignatureClockOut] = useState(false);
    const [firstPicture, setFirstPicture] = useState(false);
    const [secondPicture, setSecondPicture] = useState(false);
    const [firstPictureTitle, setFirstPictureTitle] = useState("");
    const [secondPictureTitle, setSecondPictureTitle] = useState("");

    const dispatch = useDispatch();

    const {data: siteCheckCallData, loading: siteCheckCallLoading, refetch} = useQuery(getSiteCheckCallBySiteId,{
        variables:{
            siteId,
        },
        fetchPolicy: 'network-only'
    });

    // set pre-set values
    useEffect(()=>{
        if(siteCheckCallData?.getSiteCheckCall?.response){
            const checkCall = siteCheckCallData?.getSiteCheckCall?.response;
            setOnPreTimeWindow(checkCall?.onPreTimeWindow);
            setOnPostTimeWindow(checkCall?.onPostTimeWindow);
            setOffPreTimeWindow(checkCall?.offPreTimeWindow);
            setOffPostTimeWindow(checkCall?.offPostTimeWindow);
            setChkPreTime(checkCall?.chkPreTime);
            setChkPostTime(checkCall?.chkPostTime);
            setAllowCallsPostPreWindow(checkCall?.allowCallsPostPreWindow);
            setAllowUnidentifiedCalls(checkCall?.allowUnidentifiedCalls);
            setSignatureClockOut(checkCall?.signatureClockOut);
            setFirstPicture(checkCall?.firstPicture);
            setSecondPicture(checkCall?.secondPicture);
            setFirstPictureTitle(checkCall?.firstPictureTitle);
            setSecondPictureTitle(checkCall?.secondPictureTitle)
        }
    }, [siteCheckCallData?.getSiteCheckCall?.response]);


    const [upsertCheckCall, {loading:upsertSiteCheckCallLoading}] = useMutation(upsertSiteCheckCallMutation);

    const handelSubmit = async()=>{
        try{

            await upsertCheckCall({
                variables:{
                    input:{
                        _id: siteCheckCallData?.getSiteCheckCall?.response?._id || null,
                        siteId,
                        onPreTimeWindow,
                        onPostTimeWindow,
                        offPreTimeWindow,
                        offPostTimeWindow,
                        chkPreTime,
                        chkPostTime,
                        allowCallsPostPreWindow,
                        allowUnidentifiedCalls,
                        signatureClockOut, firstPicture, secondPicture,
                        firstPictureTitle,
                        secondPictureTitle,
                    }
                }
            });

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Check call setting was saved successfully"
            }));

            await refetch();

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }
    };

    const columns = [
        {
            id:1, title: 'Monday', dataIndex: 'mon', 
        },
        {
            id:2, title: 'Tuesday', dataIndex: 'tue', 
        },
        {
            id:3, title: 'Wednesday', dataIndex: 'wed', 
        },
        {
            id:4, title: 'Thursday', dataIndex: 'thu', 
        },
        {
            id:5, title: 'Friday', dataIndex: 'fri', 
        },
        {
            id:6, title: 'Saturday', dataIndex: 'sat', 
        },
        {
            id:7, title: 'Sunday', dataIndex: 'sun', 
        },
    ];

    const [checkCallTableData, setCheckCallTableData] = useState([]);

    // reterive time
    const {data: siteCheckCallCalendarData, loading: siteCheckCallCalendarDataLoading, refetch: siteCheckCallCalendarRefetch} = useQuery(getSiteCheckCallCalendarQuery,{
        variables:{
            siteId
        },
        fetchPolicy:'network-only'
    });

   
    // create new time 
    const [newSiteCall, {loading: newSiteCallLoading}] = useMutation(newSiteCheckCallCalendarMutation);

    // update existing time
    const [updateSiteCall, {loading: updateSiteCallLoading}] = useMutation(updateSiteCheckCallCalendarMutation); 

    // delete existing time
    const [deleteSiteCall, {loading: deleteSiteCallLoading}] = useMutation(deleteSiteCheckCallCalendarMutation);

    const handelCheckCallTime = async (day, value, id) =>{
        try{
            if(id){

                await updateSiteCall({
                    variables:{
                        input:{
                            _id: id,
                            [day]: value
                        }
                    }
                });

            }else{
                await newSiteCall({
                    variables:{
                        input: {
                            siteId,
                            [day]: value
    
                        },
    
                    }
                });
            }

            // dispatch(setNotification({
            //     error: false,
            //     notificationState: true,
            //     message: "Check call calendar updated"
            // }));

            await siteCheckCallCalendarRefetch();

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }
    };

    useEffect(()=>{
        if(siteCheckCallCalendarData?.getSiteCheckCallCalendarBySiteId?.response){
            
            setCheckCallTableData([...siteCheckCallCalendarData?.getSiteCheckCallCalendarBySiteId?.response?.map((calendar)=>{
                return {
                    mon : calendar?.mon? <div>{calendar?.mon}</div> :  <Input type="time" format="HH:mm" className="generic-input-control"   onBlur={(e)=>handelCheckCallTime("mon", e.target.value, calendar?._id)}  onPressEnter={(e)=>handelCheckCallTime("mon", e.target.value, calendar?._id)}   />,
                    tue : calendar?.tue? <div>{calendar?.tue}</div> :  <Input type="time" format="HH:mm" className="generic-input-control"   onBlur={(e)=>handelCheckCallTime("tue", e.target.value, calendar?._id)}  onPressEnter={(e)=>handelCheckCallTime("tue", e.target.value, calendar?._id)}   />,
                    wed : calendar?.wed? <div>{calendar?.wed}</div> :  <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("wed", e.target.value, calendar?._id)} onBlur={(e)=>handelCheckCallTime("wed", e.target.value, calendar?._id)}   />,
                    thu : calendar?.thu? <div>{calendar?.thu}</div> :  <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("thu", e.target.value, calendar?._id)} onBlur={(e)=>handelCheckCallTime("thu", e.target.value, calendar?._id)}   />,
                    fri : calendar?.fri? <div>{calendar?.fri}</div> :  <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("fri", e.target.value, calendar?._id)} onBlur={(e)=>handelCheckCallTime("fri", e.target.value, calendar?._id)}   />,
                    sat : calendar?.sat? <div>{calendar?.sat}</div> :  <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("sat", e.target.value, calendar?._id)} onBlur={(e)=>handelCheckCallTime("sat", e.target.value, calendar?._id)}   />,
                    sun : calendar?.sun? <div>{calendar?.sun}</div> :  <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("sun", e.target.value, calendar?._id)} onBlur={(e)=>handelCheckCallTime("sun", e.target.value, calendar?._id)}   />,
                }
            }), {
                mon: <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("mon", e.target.value)}   onBlur={(e)=>handelCheckCallTime("mon", e.target.value)}   />, 
                tue: <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("tue", e.target.value)}   onBlur={(e)=>handelCheckCallTime("tue", e.target.value)}   />, 
                wed: <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("wed", e.target.value)}   onBlur={(e)=>handelCheckCallTime("wed", e.target.value)}   />, 
                thu: <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("thu", e.target.value)}   onBlur={(e)=>handelCheckCallTime("thu", e.target.value)}   />, 
                fri: <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("fri", e.target.value)}   onBlur={(e)=>handelCheckCallTime("fri", e.target.value)}   />, 
                sat: <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("sat", e.target.value)}   onBlur={(e)=>handelCheckCallTime("sat", e.target.value)}   />, 
                sun: <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("sun", e.target.value)}   onBlur={(e)=>handelCheckCallTime("sun", e.target.value)}   />
            }]);

        }else{
            setCheckCallTableData([
                {
                    mon: <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("mon", e.target.value)}   onBlur={(e)=>handelCheckCallTime("mon", e.target.value)}      />, 
                    tue: <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("tue", e.target.value)}   onBlur={(e)=>handelCheckCallTime("tue", e.target.value)}      />, 
                    wed: <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("wed", e.target.value)}   onBlur={(e)=>handelCheckCallTime("wed", e.target.value)}      />, 
                    thu: <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("thu", e.target.value)}   onBlur={(e)=>handelCheckCallTime("thu", e.target.value)}      />, 
                    fri: <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("fri", e.target.value)}   onBlur={(e)=>handelCheckCallTime("fri", e.target.value)}      />, 
                    sat: <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("sat", e.target.value)}   onBlur={(e)=>handelCheckCallTime("sat", e.target.value)}      />, 
                    sun: <Input type="time" format="HH:mm" className="generic-input-control"   onPressEnter={(e)=>handelCheckCallTime("sun", e.target.value)}   onBlur={(e)=>handelCheckCallTime("sun", e.target.value)}      />
                }
            ]);

        }
    },[siteCheckCallCalendarData?.getSiteCheckCallCalendarBySiteId?.response]);

    
    return (
        <div className="hr-section">
            <div className="hr-info">
                <div className="hr-info-title">
                    Check Calls
                </div>
                <Tabs defaultActiveKey="1" >
                    
                    <TabPane tab={`Call Setting`} key="1">
                        {
                            upsertSiteCheckCallLoading || siteCheckCallLoading?
                            <div style={{display:'table', margin:'auto', textAlign:'center'}}>
                                <Spinner/>
                            </div>

                            :

                            <div style={{display:'flex', gap:'30px', flexDirection:'column'}}>
                                <div style={{display:'flex', gap:'30px', alignItems:'center'}}>
                                    <Form.Item style={{width:'30%'}}>
                                        <label>ON pre time</label>
                                        <Input className="generic-input-control" placeholder="ON pre time" 
                                            onChange={(e)=>setOnPreTimeWindow(e.target.value)}
                                            value={onPreTimeWindow}
                                        />
                                    </Form.Item>

                                    <Form.Item style={{width:'30%'}}>
                                        <label>ON post time</label>
                                        <Input className="generic-input-control" placeholder="ON post time"
                                            onChange={(e)=>setOnPostTimeWindow(e.target.value)}
                                            value={onPostTimeWindow}
                                        />
                                    </Form.Item>

                                    <Form.Item style={{width:'40%'}}>
                                        <Checkbox style={{marginBottom:'10px'}}
                                            checked={allowCallsPostPreWindow}
                                            onChange={(e)=>setAllowCallsPostPreWindow(e.target.checked)}
                                        >Allow Calls To Be Taken Outside Of The Pre/Post Time Window</Checkbox>
                                        <Checkbox style={{marginLeft:'0'}}
                                            onChange={(e)=>setAllowUnidentifiedCalls(e.target.checked)}
                                            checked={allowUnidentifiedCalls}
                                        >Allow Calls To Be Taken Where The Caller ID Cannot Be Identified</Checkbox>
                                    </Form.Item>
                                        
                                        
                                </div>

                                <div style={{display:'flex', gap:'30px', alignItems:'center'}}>
                                    <Form.Item style={{width:'30%'}}>
                                        <label>OFF pre time</label>
                                        <Input className="generic-input-control" placeholder="OFF pre time" 
                                            onChange={(e)=>setOffPreTimeWindow(e.target.value)}
                                            value={offPreTimeWindow}
                                        />
                                    </Form.Item>

                                    <Form.Item style={{width:'30%'}}>
                                        <label>OFF post time</label>
                                        <Input className="generic-input-control" placeholder="OFF post time" 
                                            onChange={(e)=>setOffPostTimeWindow(e.target.value)}
                                            value={offPostTimeWindow}
                                        />
                                    </Form.Item>

                                    <Form.Item style={{width:'40%'}}>
                                        <Checkbox style={{marginBottom:'10px'}}
                                            onChange={(e)=>setSignatureClockOut(e.target.checked)}
                                            checked={signatureClockOut}
                                        >Take signature at Clock-out</Checkbox>

                                        <Checkbox style={{marginLeft:'0', marginBottom: '10px'}}
                                            onChange={(e)=>setFirstPicture(e.target.checked)}
                                            checked={firstPicture}
                                        > Take picture 1 on call booking</Checkbox>

                                        <Input placeholder="Picture 1 title" className="generic-input-control"
                                            onChange={(e)=>setFirstPictureTitle(e.target.value)}
                                            value={firstPictureTitle}
                                        />
                                    </Form.Item>
                                        
                                        
                                </div>

                                <div style={{display:'flex', gap:'30px', alignItems:'center'}}>
                                    <Form.Item style={{width:'30%'}}>
                                        <label>CHK pre time</label>
                                        <Input className="generic-input-control" placeholder="OFF pre time" 
                                            onChange={(e)=>setChkPreTime(e.target.value)}
                                            value={chkPreTime}
                                        />
                                    </Form.Item>

                                    <Form.Item style={{width:'30%'}}>
                                        <label>CHK post time</label>
                                        <Input className="generic-input-control" placeholder="OFF post time" 
                                            onChange={(e)=>setChkPostTime(e.target.value)}
                                            value={chkPostTime}
                                        />
                                    </Form.Item>

                                    <Form.Item style={{width:'40%'}}>
                                        <Checkbox style={{marginBottom: '10px'}}
                                            onChange={(e)=>setSecondPicture(e.target.checked)}
                                            checked={secondPicture}
                                        >Take picture 2 on call booking</Checkbox>
                                        <Input placeholder="Picture 2 title" className="generic-input-control"
                                            onChange={(e)=>setSecondPictureTitle(e.target.value)}
                                            value={secondPictureTitle}
                                        />
                                    </Form.Item>
                                        
                                        
                                </div>


                            </div>
                        }
                        <div className="footer">
                            <button style={{direction:'rtl'}} onClick={handelSubmit} disabled={upsertSiteCheckCallLoading || siteCheckCallLoading} className={upsertSiteCheckCallLoading || siteCheckCallLoading? "disabled-btn drawer-filled-btn" : "drawer-filled-btn"}>Save</button>
                        </div>

                    </TabPane>

                    <TabPane tab={`Check Calls`} key="3">
                        <Table
                            columns={columns}
                            dataSource={checkCallTableData}
                            locale={{emptyText:"no check calls made yet"}}
                            className="curvedTable"
                        />
                    </TabPane>
                    
                    <TabPane tab={`Call ID`} key="2">
                    </TabPane>
                    
                </Tabs>
                            
            </div>
        </div>
    )
};