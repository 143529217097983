import { createSlice } from '@reduxjs/toolkit';

const allViewReducer = createSlice({
  name: 'allViewReducer',
  initialState: {
    isViewRenderedFromAllViewPage: false,
    viewToRender:{}
  },
  reducers: {
    setViewRenderFromAllViewPage: (state, action) => {
       return {
        ...state,
        isViewRenderedFromAllViewPage: action.payload.status,
        viewToRender: action.payload.viewToRender
       }     
    },
  },
});


export const { setArchivePropertyLoading, 
    setViewRenderFromAllViewPage
} = allViewReducer.actions;
export default allViewReducer.reducer;