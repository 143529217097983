import { gql } from "@apollo/client";

export const newSiteViewMutation = gql `
mutation($input: SiteViewInput!){
    newSiteView(input: $input) {
      response
      message
    }
}
`;


export const updateSiteViewMutation = gql `
mutation($input: SiteViewInput!){
    updateSiteView(input: $input) {
      response
      message
    }
}
`;


export const cloneSiteViewMutation = gql`
mutation($input: SiteViewInput!){
  cloneSiteView(input: $input) {
    message
    response
  }
}
`;


export const deleteSiteViewMutation = gql`
mutation($id: String!){
  deleteSiteView(_id: $id) {
    message
    response
    success
  }
}
`;