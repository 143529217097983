import { gql } from "@apollo/client";

export const createSiteGroupViewMutation = gql `
mutation($input: SiteGroupViewInput!){
  newSiteGroupView(input: $input) {
    response
    message
  }
}
`;


export const updateSiteGroupViewMutation = gql `
mutation($input: SiteGroupViewInput!){
  updateSiteGroupView(input: $input) {
    response
    message
  }
}
`;

export const cloneSiteGroupViewMutation = gql`
mutation($input: SiteGroupViewInput!){
  cloneSiteGroupView(input: $input) {
    message
    response
    success
  }
}
`;

export const deleteSiteGroupViewMutation = gql`
mutation($id: String!){
  deleteSiteGroupView(_id: $id) {
    message
    response
    success
  }
}
`;