import { Table } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPayBillByScheduleIdQuery } from "@src/util/query/paybill.query";
import { getScheduledJobById } from "@src/util/query/schedule.query";
import dayjs from "dayjs";


export const EditDutyPayBillTab = ({scheduleId})=>{


    const {data: paybillData, loading: paybillLoading, refetch: refetchPayBill} = useQuery(getPayBillByScheduleIdQuery,{
        variables:{
            scheduleId,
        },
        fetchPolicy:'network-only'
    });

    useEffect(()=>{
        refetchPayBill();
    },[]);

    const columns = [
        {title: 'Type', dataIndex:'type', 
            // render: (text, row, index) => mergeRows(text, row, index),
        },
        {title: 'Start', dataIndex:'start'},
        {title: 'Finish', dataIndex:'finish'},
        {title: 'Hour Type', dataIndex:'hourType'},
        {title: 'Premium', dataIndex:'premium'},
        {title: 'Hours', dataIndex:'hour'},
        {title: 'Rate', dataIndex:'rate'},
        {title: 'Rate Applied', dataIndex:'rateapply'},
        {title: 'Ext Value', dataIndex:'ext'},
        {title: 'CreatedAt', dataIndex:'createdAt'},
    ];


    // Function to split dates
    function splitDates(startDate, endDate) {
        if (startDate.isSame(endDate, 'day')) {
        // If the dates are on the same day, return only the original dates
        return [{ start: startDate.format(), end: endDate.format() }];
        } else {
        // Split into 4 parts if the dates are on different days
        const endOfFirstDay = startDate.endOf('day');
        const startOfSecondDay = endDate.startOf('day');
    
        return [
            { start: startDate.format(), end: endOfFirstDay.format() },       // Original first date to end of that day
            { start: startOfSecondDay.format(), end: endDate.format() }        // Start of second day to original second date
        ];
        }
    }

    const [dataSource, setDataSource] = useState();

    useEffect(()=>{
        if(paybillData?.getPayBill?.response){
            const paybill = paybillData?.getPayBill?.response;
            const payDetail = paybill?.paybillDetail?.payDetail;
            const billDetail = paybill?.paybillDetail?.billDetail;

            let payPremiumOnly = paybill?.payBillPremium.flatMap(subArray => 
                subArray.filter(item => item.hasOwnProperty('payPremium'))
            );

            let billPremiumOnly = paybill?.payBillPremium.flatMap(subArray => 
                subArray.filter(item => item.hasOwnProperty('billPremium'))
            );

            
            const payDates = splitDates(dayjs(payDetail?.payStartDateTime), dayjs(payDetail?.payEndDateTime));
            let pay = [
                {
                    type: <div style={{fontWeight:800}}>Pay</div>,
                    start: dayjs(payDates[0]?.start).local().format("DD/MM/YYYY HH:mm"),
                    finish: dayjs(payDates[0]?.end).local().format("DD/MM/YYYY HH:mm"),
                    hourType: payDetail?.name,
                    hour: parseFloat(paybill?.paybillDetail?.payFirstDateHour).toFixed(2),
                    rate: parseFloat(paybill?.payRate).toFixed(2),
                    rateapply: paybill?.paybillDetail?.payRateDescription,
                    ext: parseFloat(paybill?.firstDatePay).toFixed(2),
                    createdAt: dayjs(paybill?.createdAt).format("DD/MM/YYYY HH:mm")
                },

            ];
            if(payDates && payDates?.length>0){
                if(payDates[1]){
                    pay.push({
                        type: 'Pay',
                        start: dayjs(payDates[1]?.start).local().format("DD/MM/YYYY HH:mm"),
                        finish: dayjs(payDates[1]?.end).local().format("DD/MM/YYYY HH:mm"),
                        hourType: payDetail?.name,
                        hour: parseFloat(paybill?.paybillDetail?.paySecondDateHour).toFixed(2),
                        rate: parseFloat(paybill?.payRate).toFixed(2),
                        rateapply: paybill?.paybillDetail?.payRateDescription,
                        ext: parseFloat(paybill?.secondDatePay).toFixed(2),
                        createdAt: dayjs(paybill?.createdAt).format("DD/MM/YYYY HH:mm")
                    });
                }
            }

            if(payPremiumOnly && payPremiumOnly?.length>0){
                payPremiumOnly?.map((pp)=>{
                    const {payPremium: premia} = pp;
                    pay.push({
                        type: "",
                        start: "",
                        finish: "",
                        hourType: "",
                        premium: premia?.premiumName,
                        hour: (premia?.totalPremiumHour),
                        rate: premia?.premiumRate,
                        ext: (premia?.totalPremiumPay) || premia?.rejected
                    });
                })
            }

            
            pay.push({
                type: <div style={{padding:'20px 0', fontSize:'14px', color:'#28a745', fontWeight:'800'}}>Total Pay</div>,
                start: "",
                finish: "",
                hourType: "",
                hour: <div style={{padding:'20px 0', fontSize:'14px', color:'#28a745', fontWeight:'800'}}>{parseFloat(paybill?.paybillDetail?.totalPayHour).toFixed(2)}</div>,
                rate: "",
                ext: <div style={{padding:'20px 0', fontSize:'14px', color:'#28a745 ', fontWeight:'800'}}> {parseFloat(paybill?.totalPay).toFixed(2)} </div>
            });

            const billDates = splitDates(dayjs(billDetail?.billStartDateTime), dayjs(billDetail?.billEndDateTime));
            let bill = [
                {
                    type: <div style={{fontWeight:800}}>Bill</div>,
                    start: dayjs(billDates[0]?.start).local().format("DD/MM/YYYY HH:mm"),
                    finish: dayjs(billDates[0]?.end).local().format("DD/MM/YYYY HH:mm"),
                    hourType: billDetail?.name,
                    hour: parseFloat(paybill?.paybillDetail?.billFirstDateHour)?.toFixed(2),
                    rate: parseFloat(paybill?.billRate).toFixed(2),
                    rateapply: paybill?.paybillDetail?.billRateDescription,
                    ext: parseFloat(paybill?.firstDateBill).toFixed(2),
                    createdAt: dayjs(paybill?.createdAt).format("DD/MM/YYYY HH:mm")
                },

            ];
            if(billDates && billDates?.length>0){
                if(billDates[1]){
                    bill.push({
                        type: 'Bill',
                        start: dayjs(billDates[1]?.start).local().format("DD/MM/YYYY HH:mm"),
                        finish: dayjs(billDates[1]?.end).local().format("DD/MM/YYYY HH:mm"),
                        hourType: billDetail?.name,
                        hour: parseFloat(paybill?.paybillDetail?.billSecondDateHour).toFixed(2),
                        rate: parseFloat(paybill?.billRate).toFixed(2),
                        rateapply: paybill?.paybillDetail?.billRateDescription,
                        ext: parseFloat(paybill?.secondDateBill).toFixed(2),
                        createdAt: dayjs(paybill?.createdAt).format("DD/MM/YYYY HH:mm")

                    });
                }
            }

            if(billPremiumOnly && billPremiumOnly?.length>0){
                billPremiumOnly?.map((pp)=>{
                    const {billPremium: premia} = pp;
                    bill.push({
                        type: "",
                        start: "",
                        finish: "",
                        hourType: "",
                        premium: premia?.premiumName,
                        hour: (premia?.totalPremiumHour),
                        rate: premia?.premiumRate,
                        ext: (premia?.totalPremiumBill) || premia?.rejected
                    });
                })
            }

            bill.push({
                type: <div style={{padding:'20px 0', fontSize:'14px', color:'#dc3545', fontWeight:'800'}}>Total Bill</div>,
                start: "",
                finish: "",
                hourType: "",
                hour: <div style={{padding:'20px 0', fontSize:'14px', color:'#dc3545', fontWeight:'800'}}>{paybill?.paybillDetail?.totalBillHour? parseFloat(paybill?.paybillDetail?.totalBillHour).toFixed(2) : ""}</div>,
                rate: "",
                ext: <div style={{padding:'20px 0', fontSize:'14px', color:'#dc3545', fontWeight:'800'}}> {parseFloat(paybill?.totalBill).toFixed(2)} </div>,
            })

            setDataSource([...pay, ...bill])
        }
    }, [paybillData]);

    return(
          
        <div className="box-shadow border-radius" style={{overflow:'auto', height:'75vh'}}>

            <div style={{padding:'16px'}}>
                    <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between'}}>
                        Pay & Bill
                        <button className="drawer-outlined-btn" style={{padding:'5px 10px'}}> <FontAwesomeIcon icon={faFilePdf}/> Export Pdf</button>
                    </div>
            </div>

            <Table
                className="curvedTable"
                style={{fontWeight:'400'}}
                columns={columns}
                dataSource={dataSource}
                locale={{emptyText:'Pay/Bill not calculated yet'}}
                loading={paybillLoading}
                pagination={false}
            />


        </div>
    );
}