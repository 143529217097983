import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import Spinner from "@src/components/spinner";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker, Form, Input, InputNumber, Modal, Table } from "antd";
import { faChevronDown, faChevronUp, faClose } from "@fortawesome/free-solid-svg-icons";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { updateEmployeeMutation } from "@src/util/mutation/employee.mutation";


export const PayRateModal = ({close, visible, label, name, rawData, handleSave, loading, moduleId})=>{

    const [value, setValue] = useState(null);
    useEffect(()=>{
        if(rawData?.value){
            setValue(rawData?.value);
        }else{
            setValue(null);
        }
    },[rawData]);

    const columns = [
        {id:1, key: 1, title:'Effective Date', dataIndex:'effectivedate'},
        {id:2, key: 2, title:'Rate/h', dataIndex:'rate'},
        {id:3, key: 3, title:'Action', dataIndex:'action'},
    ];

    const [newPayRateModal, setNewPayRateModal] = useState(false);
    const [dataSource, setDataSource] = useState([]);
   const param = useParams();

    const dispatch = useDispatch();


    const handelPayrateDelete = async (index)=>{
        const confirmation = window.confirm(`Do you want to really delete this ${label}?`);
        if(confirmation){
            value?.splice(index, 1);

            if(value?.length>0){
                const input = {
                    [moduleId]: param?.id,
                    payLevelId: rawData?.payLevel?._id,
                    object: {
                        key: rawData?.column,
                        value: [...value]
                    }
                }
                await handleSave(input);


            }else{

                const input = {
                    [moduleId]: param?.id,
                    payLevelId: rawData?.payLevel?._id,
                    object: {
                        key: rawData?.column,
                        value: []
                    }
                }
                await handleSave(input);

            }

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: label+" deleted successfully"
            }));

        }else{
            return;
        }
    };

    useEffect(()=>{
        if(value?.length>0){
            setDataSource(value?.map((pay, index)=>({
                id: index,
                effectivedate: <div style={{fontWeight:400}}>{dayjs(pay?.effectivedate).format("DD/MM/YYYY")}</div>,
                rate: <div style={{fontWeight:400}}>{pay?.rate}/h</div>,
                action: <div style={{fontWeight:400, color: 'red', cursor:'pointer'}} onClick={()=>handelPayrateDelete(index)}>Delete</div>
            })));
        }else{
            setDataSource([]);
        }
    },[value, value?.length]);


    return(
        <Modal
            open={visible}
            width={'100%'}
            className="createUserModal"
            footer={null}
            closable={false}
        >
            <React.Fragment>
                {/* {contextHolder} */}
                <div className='modal-header-title'>
                    <span> Pay rates </span>
                    <span onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                </div>
                <div className='modal-body'>

                    <div className="row-flex" style={{justifyContent:'space-between'}}>

                        <div>

                            <div>{label} Detail</div>
                            <div className="text">Please provide {label} details. If there are any new rates effective from a specific day or month, we will ensure to maintain accurate records for you.</div>
                            
                        </div>

                        <button className="drawer-outlined-btn" onClick={()=>setNewPayRateModal(true)}>
                            New {label}
                        </button>

                    </div>
                    

                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        loading={loading}
                        locale={{emptyText: `No ${label} has been set`}}
                    />
                </div>
                <SetPayRateModal 
                    name={name}
                    value={value}
                    label={label}
                    visible={newPayRateModal}
                    close={()=>setNewPayRateModal(false)}
                    rawData={rawData}
                    handleSave={handleSave}
                    loading={loading}
                    moduleId={moduleId}
                />
            </React.Fragment>
        </Modal>
    );
}


export const SetPayRateModal = ({rawData, visible, close, label, value=[], loading, handleSave, moduleId})=>{

    const [effectivedate, setEffectiveDate] = useState(null);
    const [rate, setRate] = useState(null);

    const [isBtnDisabled, setIsBtnDisabled] = useState(true);

    useEffect(()=>{
        if(effectivedate && rate>0){
            setIsBtnDisabled(false);
        }else{
            setIsBtnDisabled(true);
        }
    },[effectivedate, rate]);

    const dispatch = useDispatch();
    
    const param = useParams();

    const handelpayrate = async()=>{
        try{

            const isEffectiveDateAlreadyExist = value?.length>0? value?.find((pay)=>dayjs(pay?.effectivedate).isSame(effectivedate, 'day')) : null;

            let updatedPayRate = [];
            if(isEffectiveDateAlreadyExist){
                updatedPayRate = value?.map((pay)=>{
                    if(dayjs(pay?.effectivedate).isSame(effectivedate, 'day')){
                        return{
                            effectivedate,
                            rate
                        }
                    }else{
                        return pay;
                    }
                });
            }else{
                if(value?.length>0){
                    updatedPayRate = [...value, {effectivedate, rate}]
                }else{
                    updatedPayRate.push({effectivedate, rate});
                }
            }


            const input = {
                [moduleId]: param?.id,
                payLevelId: rawData?.payLevel?._id,
                object: {
                    key: rawData?.column,
                    value: [...updatedPayRate]
                }
            }

            close();

            await handleSave(input);

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: label+" was added successfully"
            }));

            close();
        }
        catch(err){

            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));

        }
    }


    return(
        <Modal
            open={visible}
            width={600}
            footer={
                <div style={{padding:'26px 40px', textAlign:'left', display:'flex', columnGap:'16px', marginTop:'-25px' }}>
                    <button  
                    onClick={handelpayrate}
                    disabled={loading || isBtnDisabled} 
                    className={(loading || isBtnDisabled) ? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
                    >
                        {loading? <Spinner/>: "Save"}
                    </button>
                    <button disabled={loading} className={loading? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={close}>
                        Cancel
                    </button>
                </div>
            }
            closable={false}
        >

            <React.Fragment>
                {/* {contextHolder} */}
                <div className='modal-header-title'>
                    <span>New Pay rates </span>
                    <span onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                </div>
                <div className='modal-body'>
                    <Form>
                        <Form.Item>
                            <label>Effective Date</label>
                            <DatePicker
                                className="generic-input-control"
                                value={effectivedate && (dayjs(effectivedate, "DD/MM/YYYY")) }
                                onChange={(rawDate, dateString)=>setEffectiveDate(rawDate)}
                                format="DD/MM/YYYY"
                            />
                        </Form.Item>
                        
                        <Form.Item  className="numberInput" style={{margin:'0', width:'100%'}}>
                            <label>Rate/h</label>
                            <InputNumber 
                                min={1}
                                name="bufferTime"
                                upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                downHandler={<FontAwesomeIcon  style={{color:'#0091ae'}} icon={faChevronDown} />}
                                className="generic-input-control"
                                step={0.1} // Increment/decrement step for floating points
                                precision={2}
                                onChange={(e)=>{
                                    setRate(e)
                                }}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </React.Fragment>


        </Modal>
    )
}