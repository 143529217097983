import { gql } from "@apollo/client";

export const UpdateEmployeeViewMutation = gql `
mutation($input: EmployeeViewInput!){
    updateEmployeeView(input: $input) {
      response
    }
}
`;


export const newEmployeeViewMutation = gql `
mutation($input: EmployeeViewInput!){
    newEmployeeView(input: $input) {
      response
    }
}
`;

export const deleteEmployeeViewMutation = gql
`mutation($id: String!){
  deleteEmployeeView(_id: $id) {
    message
    response
    success
  }
}`;

export const cloneEmployeeViewMutation = gql`
mutation($input: EmployeeViewInput!){
  cloneEmployeeView(input: $input) {
    message
    response
    success
  }
}
`;