// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.searchViewModalParent{
    position: fixed;
    z-index: 998;
    background: white;
    width: 100%;
    top: 5.2vh;
    min-height: 100vw;
    overflow-y: auto;
    /* height: 93vh;
    max-height: 93vh; */
}

.searchViewModal{
    top: 7vh !important;
    pointer-events: auto;
}

.search-header{
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    padding: 20px 48px;
    justify-content: space-between;
    background-color: rgb(245, 248, 250);
}

.search-header-text{
    font-size: 14px;
    color: rgb(124, 152, 182);
}

.search-body{
    width: 80%;
    margin: auto;
    display: table;
    padding: 20px 48px;
}

.filterSection{
    display: flex;
    gap: 30px;
    align-items: center;
}

.searchFilter{
    font-size: 14px;
    color: rgb(124, 152, 182);
}

.searchFilter .ant-tag{
    border-radius: 0px;
    border: 1px solid rgb(124, 152, 182);
    padding: 0 8px;
    background: white;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/searchView/searchView.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,WAAW;IACX,UAAU;IACV,iBAAiB;IACjB,gBAAgB;IAChB;uBACmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,8BAA8B;IAC9B,oCAAoC;AACxC;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,oCAAoC;IACpC,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["\n.searchViewModalParent{\n    position: fixed;\n    z-index: 998;\n    background: white;\n    width: 100%;\n    top: 5.2vh;\n    min-height: 100vw;\n    overflow-y: auto;\n    /* height: 93vh;\n    max-height: 93vh; */\n}\n\n.searchViewModal{\n    top: 7vh !important;\n    pointer-events: auto;\n}\n\n.search-header{\n    display: flex;\n    position: relative;\n    flex-direction: row;\n    align-items: center;\n    padding: 20px 48px;\n    justify-content: space-between;\n    background-color: rgb(245, 248, 250);\n}\n\n.search-header-text{\n    font-size: 14px;\n    color: rgb(124, 152, 182);\n}\n\n.search-body{\n    width: 80%;\n    margin: auto;\n    display: table;\n    padding: 20px 48px;\n}\n\n.filterSection{\n    display: flex;\n    gap: 30px;\n    align-items: center;\n}\n\n.searchFilter{\n    font-size: 14px;\n    color: rgb(124, 152, 182);\n}\n\n.searchFilter .ant-tag{\n    border-radius: 0px;\n    border: 1px solid rgb(124, 152, 182);\n    padding: 0 8px;\n    background: white;\n    cursor: pointer;\n    font-size: 12px;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
